import { useQuery } from "react-query";
import { IOpportunityObject } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getOpportunity = async (id: string | number | null) => {
  if (!id) return;
  const response = await axiosInstance.get(`/opportunities/${id}`);
  return response.data;
};

const useGetOpportunity = (id: string | number | null) => {
  const response = useQuery<IOpportunityObject, Error>(
    [queryKeys.opportunities, { id }],
    () => getOpportunity(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetOpportunity;
