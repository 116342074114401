import styled from "@emotion/styled";
import RichTextEditor1 from "../../../../RichTextEditor/RichTextEditor1";
import { WidgetItem } from "../../WidgetToolbar/WidgetToolbar";
import WidgetToolbarForLibraryPopup from "../../WidgetToolbarForLibraryPopup";
import CustomModal from "../CustomModal";

export const ModalHeader = styled.div`
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  padding-left: 2rem;
  font-family: var(--font-lexend);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  color: #1e3a8a;
`;

export const ModalContent = styled.div`
  height: 100%;
  padding: 1.6rem;
  .editor {
    padding: 1.2rem;
    padding-top: 0;
    border: 1px solid #cbd5e1;
    border-radius: 0.8rem;
    height: inherit;
    overflow-y: auto;
  }
`;

const EditorModal = ({
  showModal,
  onSave,
  onCancel,
  isLoading,
  onClose,
  editorRef,
  onChange,
  text,
}: any) => {
  return (
    <CustomModal
      width="calc(100% - 30rem)"
      height="calc(100% - 9rem)"
      contentHeight="100%"
      style={{
        margin: "6rem 15rem 3rem 15rem",
        border: "1px solid #BFDBFE",
        boxShadow: "0px .6rem 3rem rgba(37, 99, 235, 0.06)",
        borderRadius: "0.8rem",
      }}
      showModal={showModal}
      disableFocusLock={true}
      showCancel={false}
      closeModal={() => {
        onClose();
      }}
      renderHeader={() => {
        return (
          <ModalHeader>
            <Title>Executive summary</Title>
            <WidgetToolbarForLibraryPopup
              config={[
                {
                  type: WidgetItem.CANCEL,
                  onClick: () => {
                    onCancel();
                  },
                },
                {
                  type: WidgetItem.SAVE,
                  isLoading: isLoading,
                  onClick: onSave,
                },
              ]}
            />
          </ModalHeader>
        );
      }}
      renderContent={() => {
        return (
          <ModalContent>
            <RichTextEditor1
              className="editor"
              onChange={onChange}
              value={text || ""}
              placeholder="Enter text here"
              editorRef={editorRef}
              showMenubar={false}
            ></RichTextEditor1>
          </ModalContent>
        );
      }}
    />
  );
};

export default EditorModal;
