import { useContext } from "react";
import { AuthContext } from "../../context";
import { getLoggedInUser } from "../../helpers/signin";
import useGetTeams from "./useGetTeams";

export const useLoggedInTeam = () => {
  const { authState } = useContext(AuthContext);
  const { data: allTeams } = useGetTeams();
  const teamDetails = getLoggedInUser()?.team;
  const loggedInTeam = allTeams.data.find(
    (team: any) => team.id === teamDetails?.id
  );
  if (authState.isGuestUser) return allTeams.data[0];

  return loggedInTeam;
};
