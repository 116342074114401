import styled from "@emotion/styled";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { ClipLoader } from "react-spinners";

interface ButtonWrapperProps {
  isRemove?: boolean;
}

const ButtonWrapper = styled.a<ButtonWrapperProps>`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  line-height: 150%;
  color: ${({ isRemove }) => (isRemove ? "#DC2626" : "#16A34A")};
  border: 0.5px solid ${({ isRemove }) => (isRemove ? "#DC2626" : "#16A34A")};
  padding: 0.6rem 1rem;
  background-color: ${({ isRemove }) => (isRemove ? "" : "#f0fdf4")};
  border-radius: 0.6rem;
  font-size: 1.4rem;
  cursor: pointer;
`;

interface SolutionAddedRemoveBtnProps {
  isLoading: boolean;
}

const SolutionAddedRemoveButton = ({
  isLoading,
  ...props
}: SolutionAddedRemoveBtnProps) => {
  const [isRemove, setIsRemove] = useState(false);
  const handleMouseEnter = (e: any) => {
    setIsRemove(true);
  };
  const handleMouseLeave = (e: any) => {
    setIsRemove(false);
  };
  return (
    <ButtonWrapper
      {...props}
      isRemove={isRemove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isLoading ? (
        <ClipLoader size={16} color={"var(--text-grey)"} />
      ) : isRemove ? (
        <>
          <FiTrash2 />
          Remove
        </>
      ) : (
        "Added"
      )}
    </ButtonWrapper>
  );
};

export default SolutionAddedRemoveButton;
