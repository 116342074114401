import { AxiosResponse } from "axios";
import { noop } from "lodash";
import { useState, useEffect } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../api/axios";
import { AppName } from "../../../../../constants";
import {
  IAppsCard,
  IStatusButton,
  APP_CONNECT_STATUS,
  IAppStatusDropdownData,
} from "../../../../../interfaces/integration";
import {
  AppsContainer,
  AppsTitle,
  AppsCardContainer,
  CardWithIcon,
  CardIconContainer,
  CardContent,
  CardTitle,
  CardDescription,
  StatusButtonContainer,
  StatusText,
  DropDownList,
  DropDownButtonItem,
  CardFooter,
} from "./styled";
import { trackUserAction } from "../../../../../analytics";
import { userActivityEvents } from "../../../../../analytics/userActivityEvents";

const AppsCard = ({
  title,
  appData,
  onCardClick,
  CardContentFooter,
}: IAppsCard) => {
  return (
    <AppsContainer>
      {title && <AppsTitle>{title}</AppsTitle>}
      <AppsCardContainer>
        {appData.map((data, i) => (
          <CardWithIcon
            key={data.name + i}
            onClick={() => {
              onCardClick(data);
            }}
          >
            <CardIconContainer>{data.icon}</CardIconContainer>
            <CardContent>
              <div>
                <CardTitle>{data.label}</CardTitle>
                <CardDescription>{data.description}</CardDescription>
              </div>
              <CardFooter>{CardContentFooter(data)}</CardFooter>
            </CardContent>
          </CardWithIcon>
        ))}
      </AppsCardContainer>
    </AppsContainer>
  );
};

export default AppsCard;

export const StatusButton = ({
  appData,
  onDisconnect = noop,
}: IStatusButton) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [status, setStatus] = useState(APP_CONNECT_STATUS.CONNECT);
  const [label, setLabel] = useState("Connect");
  const [showDropDown, setShowDropDown] = useState(false);
  const [dropDownData, setDropDownData] = useState<IAppStatusDropdownData[]>();

  useEffect(() => {
    if (
      ![APP_CONNECT_STATUS.DISCONNECTED, APP_CONNECT_STATUS.ERROR].includes(
        status
      )
    ) {
      let status = appData.is_connected
        ? APP_CONNECT_STATUS.CONNECTED
        : APP_CONNECT_STATUS.CONNECT;
      let label = appData.is_connected ? "Connected" : "Connect";
      setLabel(label);
      setStatus(status);
    }
  }, [appData]);

  const disconnectHubspot = () => {
    return axiosInstance.delete("/auth/hubspot/install/");
  };

  const disconnectSalesforce = () => {
    return axiosInstance.delete("/salesforce/login/");
  };

  const handleDisconnectOptionClick = () => {
    const performPostDisconnectAction = (res: AxiosResponse) => {
      if (res.status === 200) {
        toast.success(`${appData.label} disconnected successfully`);
        setStatus(APP_CONNECT_STATUS.DISCONNECTED);
        setLabel("Disconnected");
        onDisconnect();
      } else {
        toast.error(
          `Oops! Something went wrong while disconnecting ${appData.label}`
        );
      }
    };
    if (appData.name === AppName.HUBSPOT)
      disconnectHubspot().then((res) => performPostDisconnectAction(res));
    if (appData.name === AppName.SALESFORCE)
      disconnectSalesforce().then((res) => performPostDisconnectAction(res));
  };

  useEffect(() => {
    if (status === APP_CONNECT_STATUS.CONNECTED) {
      setDropDownData([
        {
          label: "Disconnect account",
          onDropdownItemClick: handleDisconnectOptionClick,
        },
      ]);
    } else if (status === APP_CONNECT_STATUS.DISCONNECTED) {
      setDropDownData([
        {
          label: "Connect to account",
          onDropdownItemClick: () => {
            setDropDownData([]);
            setStatus(APP_CONNECT_STATUS.CONNECT);
            setLabel("Connecting...");
            window.location.href = appData.url;
          },
        },
      ]);
    } else {
      setDropDownData([]);
    }
  }, [status]);

  useEffect(() => {
    const appName = searchParams.get("app");
    const type = searchParams.get("type");
    const message = searchParams.get("message");
    if (appName === appData.name && type === "error" && message) {
      setStatus(APP_CONNECT_STATUS.ERROR);
      setLabel("Error try again");
      setDropDownData([]);
    }
  }, [location.search]);

  return (
    <StatusButtonContainer
      className={status}
      onClick={(e) => {
        e.stopPropagation();
        if (dropDownData && dropDownData?.length) {
          setShowDropDown((prevState) => !prevState);
        } else {
          setStatus(APP_CONNECT_STATUS.CONNECT);
          setLabel("Connecting...");
          window.location.href = appData.url;
          trackUserAction(
            appData.name.includes(AppName.SALESFORCE)
              ? userActivityEvents.SETTINGS__SALESFORCE_CONNECT_CLICKED
              : userActivityEvents.SETTINGS__HUBSPOT_CONNECT_CLICKED
          );
        }
      }}
    >
      <StatusText className={status}>{label}</StatusText>
      {dropDownData && !!dropDownData?.length && (
        <HiChevronDown size={18} className={status} />
      )}
      {showDropDown && (
        <DropDownList className="dropdown-list">
          {dropDownData?.map(
            (
              {
                label = "",
                onDropdownItemClick = noop,
              }: IAppStatusDropdownData,
              i: number
            ) => (
              <DropDownButtonItem
                className={status}
                key={label + i}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(false);
                  onDropdownItemClick();
                }}
              >
                {label}
              </DropDownButtonItem>
            )
          )}
        </DropDownList>
      )}
    </StatusButtonContainer>
  );
};
