import { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import { AuthContext } from "../../../context";
import { history } from "../../../utils/history";
import { FallBack } from "../../compositional";
import SharedViewEmailPopup from "../../compositional/Reusable/components/Modals/SharedViewEmailPopup";
import GuestUserErrorPage, {
  ErrorCode,
  GENERIC_ERROR_MSG,
  RESOURCE_INACTIVE_ERROR_MSG,
  RESOURCE_NOT_FOUND_ERROR_MSG,
  TError,
} from "./GuestUserErrorPage";
import { ISharableResPublicData } from "../../../interfaces/solution";
import { apiConfig } from "../../../config";
import { validateIsSharedPreview } from "../../../api/hooks/useSetAuthUser";

interface IPostData {
  code: string;
  is_preview?: boolean;
  visitor?: {
    email: string;
    name?: string;
  };
}

export default function GuestUserAuth() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = useParams();
  const code = params.code || sessionStorage.getItem("guestCode");
  const isSharedResourcePreview = searchParams.get("preview") === "true";

  const { authDispatch } = useContext(AuthContext);
  const [error, setError] = useState<TError>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sharedResourcePublicData, setSharedResourcePublicData] =
    useState<ISharableResPublicData>();
  const handleResourceNotFoundError = (errorCode?: ErrorCode) => {
    setIsLoading(false);
    setError({ text: RESOURCE_NOT_FOUND_ERROR_MSG, code: errorCode });
  };

  const handleResourceInactiveError = () => {
    setIsLoading(false);
    setError({ text: RESOURCE_INACTIVE_ERROR_MSG });
  };

  const handleGenericError = () => {
    setIsLoading(false);
    setError({ text: GENERIC_ERROR_MSG });
  };

  const tokenForGuestUser = async (postData: IPostData) => {
    let url = apiConfig.urls.shareableResToken;
    const searchParams = new URLSearchParams(window.location.search);
    const isPreviewFromUrl = searchParams.get("preview") === "true";
    const tokenResponse = await axiosInstance
      .post(url, postData)
      .catch((err: AxiosError) => {
        const errorResponse = err.response;
        if (errorResponse?.status === 400) {
          handleResourceNotFoundError(ErrorCode.BAD_REQUEST);
        } else if (errorResponse?.status === 404) {
          const errorCode = errorResponse?.data?.code;
          if (errorCode === ErrorCode.NOT_FOUND) {
            handleResourceNotFoundError(errorCode);
          } else if (errorCode === ErrorCode.INACTIVE) {
            handleResourceInactiveError();
          }
        } else {
          handleGenericError();
        }
      });
    if (tokenResponse?.status === 200) {
      setIsLoading(false);
      const guestToken = tokenResponse.data.access;
      sessionStorage.setItem("guestAccess", guestToken);
      sessionStorage.setItem("guestIsPreview", `${isPreviewFromUrl}`);
      authDispatch({
        type: "SET_USER",
        payload: {
          isGuestUser: true,
          isPreview: isPreviewFromUrl,
        },
      });
    }
  };

  const callFuntionOnMount = async () => {
    if (!code || code === "null") {
      return handleResourceNotFoundError();
    }
    setIsLoading(true);
    // localStorage.setItem("guestCode", code);
    sessionStorage.setItem("guestCode", code);
    sessionStorage.removeItem("guestAccess");

    const postDataToGetToken = {
      code,
      is_preview: false,
    } as IPostData;

    if (isSharedResourcePreview) {
      // Checks for shared resource client preview
      validateIsSharedPreview();
      postDataToGetToken.is_preview = true;
      await tokenForGuestUser(postDataToGetToken);
    } else {
      // Guest user login
      // Get sharable resource public data
      const sharedResourcePublicDataUrl = `${apiConfig.urls.shareableResPublicData}?code=${code}`;
      const publicDataResponse = await axiosInstance
        .get(sharedResourcePublicDataUrl)
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 404) {
            return handleResourceNotFoundError(ErrorCode.NOT_FOUND);
          } else {
            return handleGenericError();
          }
        });
      const sharableResPublicData: ISharableResPublicData =
        publicDataResponse?.data;

      if (!sharableResPublicData.is_active) {
        return handleResourceInactiveError();
      }

      if (!sharableResPublicData.is_email_required) {
        return await tokenForGuestUser(postDataToGetToken);
      }

      // Show email popup if email not found in browser storage
      const guestEmail = localStorage.getItem(`guestEmail:${code}`);
      if (guestEmail) {
        postDataToGetToken.visitor = {
          email: guestEmail,
        };
        tokenForGuestUser(postDataToGetToken);
      } else {
        setIsLoading(false);
        setShowPopup(true);
        setSharedResourcePublicData(sharableResPublicData);
      }
    }
  };

  useEffect(() => {
    callFuntionOnMount();
  }, []);

  const handleEmailPopupSubmit = (email: string) => {
    if (code) {
      localStorage.setItem(`guestEmail:${code}`, email);
      const postDataToGetToken = {
        code,
        visitor: {
          email,
        },
      } as IPostData;

      tokenForGuestUser(postDataToGetToken);
    }
  };

  if (!code) history.push("/");

  if (!isLoading && error?.text)
    return (
      <GuestUserErrorPage
        error={error}
        showRefreshButton={error?.text === GENERIC_ERROR_MSG}
      />
    );

  return (
    <div>
      {showPopup && (
        <SharedViewEmailPopup
          sharedResourcePublicData={
            sharedResourcePublicData as ISharableResPublicData
          }
          onSubmit={handleEmailPopupSubmit}
        />
      )}
      <FallBack fullPage />
    </div>
  );
}
