import axiosInstance from "../../../api/axios";
import { appConfig, userActivityEvents } from "../../../config";
import { AuthContext } from "../../../context";
import {
  clearUpAndGoToHome,
  storeUserInfoAndAuthenticationDetails,
} from "../../../helpers";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../../utils/history";
import { identifyUser, trackUserAction } from "../../../analytics";
import { SIGNIN_METHODS } from "../../../api/constants";
import {
  ErrorContent,
  ErrorWrapper,
  Text,
} from "../../compositional/ErrorBoundary/ContentAreaErrorBoundary/styled";
import HeaderBand from "../../compositional/UserInfoLayout/components/HeaderBand";
import { GatherUserInfoTitle as Title } from "../../compositional/UserInfoLayout/components/common";
const {
  landingPage,
  urls: { workspaceSelection: workspaceSelectionUrl },
  emailIds: { support: supportEmail },
} = appConfig;

const Email = () => {
  const { authState, authDispatch } = useContext(AuthContext);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const setRedirectPath = () => {
      const redirectPath = localStorage.getItem("redirectPath");
      if (redirectPath) {
        sessionStorage.setItem("redirectPath", redirectPath);
        localStorage.removeItem("redirectPath");
      }
    };
    if (authState.isLoggedIn) return history.push(landingPage.apply(appConfig));
    else {
      const error = urlParams.get("error");
      const code = urlParams.get("code");
      if (error) {
        return setErrorMessage(error);
      }
      if (!code) {
        return history.push("/");
      }
      axiosInstance
        .post("/auth/magic/login/", {
          code,
        })
        .then((res) => {
          if (res.status === 200) {
            setRedirectPath();
            storeUserInfoAndAuthenticationDetails(
              authDispatch,
              res.data,
              () => {
                trackUserAction(userActivityEvents.SIGNIN__FLOW_COMPLETED, {
                  method: SIGNIN_METHODS.MAGIC_LINK,
                });
                identifyUser(res.data.user, res.data.team.id);
                localStorage.setItem("loggedInUser", JSON.stringify(res.data));
                // history.push(workspaceSelectionUrl);
                history.push("/");
              }
            );
          } else {
            trackUserAction(userActivityEvents.SIGNIN__ERROR);
            clearUpAndGoToHome();
          }
        })
        .catch((err) => {
          clearUpAndGoToHome();
          trackUserAction(userActivityEvents.SIGNIN__ERROR, { error: err });
        });
    }
  }, []);

  return (
    <>
      <HeaderBand />
      {errorMessage && (
        <ErrorWrapper style={{ marginTop: "14.3rem" }}>
          <ErrorContent>
            <Title style={{ lineHeight: "150%" }}>
              {errorMessage === "UserNotFound"
                ? `Your ${appConfig.appName} account is not setup yet!`
                : "Oops.. Something went wrong!"}
            </Title>
            <Text>
              {errorMessage === "UserNotFound" && (
                <span>
                  But don’t worry. You just need to contact your company admin
                  or drop an email to{" "}
                  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>, and we
                  will get you setup asap.
                </span>
              )}
            </Text>
          </ErrorContent>
        </ErrorWrapper>
      )}
    </>
  );
};

export default Email;
