import { nanoid } from "nanoid";
import { useEffect } from "react";
import { addPdfIFrame } from "../helpers/adobePdfViewer";

const triggerWindowScroll = () => {
  // This will trigger the scroll event listners on the document
  window.dispatchEvent(new Event("scroll"));
};

const useAdobePdfViewer = () => {
  // Replaces all embed pdf iframes with AdobePDF viewer
  useEffect(() => {
    const pdfNodes: NodeListOf<HTMLDivElement> | undefined =
      document.querySelectorAll("[data-is-pdf]");
    if (pdfNodes?.length) {
      pdfNodes?.forEach((pdfNode: HTMLDivElement) => {
        const iframeEles = pdfNode.getElementsByTagName("iframe");
        const fileUrl = iframeEles.length ? iframeEles[0].src : null;
        if (fileUrl) {
          const newId = nanoid();
          const filename = pdfNode.dataset.documentFileName as string;
          const divId = newId;
          pdfNode.id = newId;
          addPdfIFrame(fileUrl, filename, divId);
        }
        delete pdfNode.dataset.isPdf;
        pdfNode.style.maxWidth = "100%";
      });

      // Adding a scroll event listner on the main container to trigger
      // the scroll events added by Adobe ViewerSDK on the documnet
      const mainContainer = document.getElementsByTagName("main")[0];
      mainContainer.addEventListener("scroll", triggerWindowScroll);
    }
  });
};

export default useAdobePdfViewer;
