import { useContext } from "react";
import { SolutionsContext } from "../../context";
import { useLoggedInTeam } from "./useLoggedInTeam";

export const useSharingLink = (code?: string, isPreview?: boolean) => {
  const { shareableResObj } = useContext(SolutionsContext);
  let { domain: teamDomain } = useLoggedInTeam();
  const subdomain = window.location.host.split(".")[0];
  if (!(code || shareableResObj?.code)) return "";
  const sharingLink = `${window.location.origin}/shared/${
    code ? code : shareableResObj?.code
  }`;
  if (isPreview) return sharingLink;
  if (subdomain && ["web", "staging"].includes(subdomain) && teamDomain) {
    return sharingLink.replace(subdomain, teamDomain);
  }
  return sharingLink;
};
