import moment from "moment";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

interface IGooglePicker {
  onFileSelection: (fileId: string, googlePickerToken: string) => void;
  onClose: Function;
}

const GOOGLE_DRIVE_API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
const GOOGLE_DRIVE_CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const GOOGLE_DRIVE_APP_ID = process.env.REACT_APP_GOOGLE_DRIVE_APP_ID;
const googleDriveScope =
  "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly";

const GooglePicker = ({ onFileSelection, onClose }: IGooglePicker) => {
  useEffect(() => {
    let tokenClient;
    const googlePickerTokenData = sessionStorage.getItem(
      "googlePickerTokenData"
    );
    let googlePickerToken =
      googlePickerTokenData && JSON.parse(googlePickerTokenData)?.access_token;
    const tokenCreatedTime =
      googlePickerTokenData && JSON.parse(googlePickerTokenData)?.created_at;
    const currentTime = new Date().toISOString();
    const tokenExpiresIn =
      3300 - moment(currentTime).diff(moment(tokenCreatedTime), "second");

    window.gapi.load("client:picker", initializePicker);

    async function initializePicker() {
      if (googlePickerToken && tokenExpiresIn > 0) {
        createPicker();
      } else {
        const tokenClientCallback = async (response: any) => {
          if (response.error === "access_denied") {
            onClose();
          } else if (response.error !== undefined) {
            onClose();
            toast.error("Oops, something went wrong");
            console.error(response);
          } else {
            const googlePickerTokenData = {
              access_token: response.access_token,
              created_at: new Date().toISOString(),
            };
            sessionStorage.setItem(
              "googlePickerTokenData",
              JSON.stringify(googlePickerTokenData)
            );
            googlePickerToken = response.access_token;
            await createPicker();
          }
        };

        const tokenClientErrorCallback = (err: any) => {
          if (["popup_failed_to_open", "popup_closed"].includes(err.type)) {
            onClose();
            if (err.type === "popup_failed_to_open") console.error(err.message);
          }
        };
        tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: GOOGLE_DRIVE_CLIENT_ID,
          scope: googleDriveScope,
          callback: tokenClientCallback,
          error_callback: tokenClientErrorCallback,
        });
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: "consent" });
      }
    }

    function createPicker() {
      // const view = new google.picker.View(google.picker.ViewId.DOCS);
      // Start with just slides
      const docsView = new window.google.picker.DocsView(
        window.google.picker.ViewId.PRESENTATIONS
      );
      docsView?.setIncludeFolders(true);
      const picker = new window.google.picker.PickerBuilder()
        .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
        .setDeveloperKey(GOOGLE_DRIVE_API_KEY)
        .setAppId(GOOGLE_DRIVE_APP_ID)
        .setOAuthToken(googlePickerToken)
        .addView(docsView)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }

    async function pickerCallback(data: any) {
      if (data.action === window.google.picker.Action.CANCEL) {
        onClose();
      }
      if (data.action === window.google.picker.Action.PICKED) {
        const document = data[window.google.picker.Response.DOCUMENTS][0];
        const fileId = document[window.google.picker.Document.ID];
        onFileSelection(fileId, googlePickerToken || "");
      }
    }
  }, []);

  return <></>;
};

export default GooglePicker;
