import axiosInstance from "../axios";
import {
  IDocumentObject,
  IMutationState,
  TAssetItemMutationData,
} from "../../interfaces";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useGetAssetMetadata from "./useGetAssetMetadata";
import { AssetObjectType } from "../../config/assetsConfig";
import { apiConfig } from "../../config";
import { toast } from "react-toastify";
import { queryKeys } from "../constants";

const categorizeByFiles = (mutationData: TAssetItemMutationData) => {
  return Object.entries(mutationData).reduce(
    (fileFieldsAndOtherFieldsObj, [key, value]) => {
      if (key === "documents" && Array.isArray(value)) {
        const documentIdArr: number[] = [];
        const filesToUpload: File[] = value.filter((documentIdOrFile) => {
          if (documentIdOrFile instanceof File) {
            return true;
          } else {
            documentIdArr.push(documentIdOrFile);
            return false;
          }
        });
        fileFieldsAndOtherFieldsObj["documentFiles"] = filesToUpload;
        fileFieldsAndOtherFieldsObj["others"]["documents"] = documentIdArr;
      } else {
        const valueType = value instanceof File ? "files" : "others";
        fileFieldsAndOtherFieldsObj[valueType][key] = value;
      }
      return fileFieldsAndOtherFieldsObj;
    },
    {
      files: {} as any,
      others: {} as any,
      documentFiles: [] as File[],
    }
  );
};

const uploadDocuments = (files: File[]) => {
  return files.map((file) => {
    const formData = new FormData();
    formData.append("document", file);
    return axiosInstance.post("/documents/", formData);
  });
};

const updateAssetItemFiles = async (url: string, filesObj: object) => {
  const formData = new FormData();
  Object.entries(filesObj).forEach(([key, file]) => formData.append(key, file));
  return await axiosInstance.patch(url, formData);
};

const updateAssetItem = async (
  assetObjectName: string | undefined,
  { id, ...updateData }: TAssetItemMutationData
) => {
  if (!assetObjectName) throw Error;
  const apiEndpoint = `${apiConfig.urls.assets}${assetObjectName}/${id}/`;
  const { files, others, documentFiles } = categorizeByFiles(updateData);
  const newDocuments: IDocumentObject[] = (
    await Promise.all(uploadDocuments(documentFiles))
  ).map((res) => res.data);
  const newDocumentIdArr = newDocuments.map((documentObj) => documentObj.id);
  const data = {
    ...others,
    documents: updateData.documents
      ? [...others.documents, ...newDocumentIdArr]
      : undefined,
  };
  if (Object.keys(files).length) await updateAssetItemFiles(apiEndpoint, files);
  return await axiosInstance.patch(apiEndpoint, data);
};

const createAssetItem = async (
  assetObjectName: string | undefined,
  postData: TAssetItemMutationData
) => {
  if (!assetObjectName) throw Error;
  const apiEndpoint = `${apiConfig.urls.assets}${assetObjectName}/`;
  const { files, others, documentFiles } = categorizeByFiles(postData);
  const newDocuments: IDocumentObject[] = (
    await Promise.all(uploadDocuments(documentFiles))
  ).map((res) => res.data);
  const newDocumentIdArr = newDocuments.map((documentObj) => documentObj.id);
  const data = {
    ...others,
    documents: postData.documents
      ? [...others.documents, ...newDocumentIdArr]
      : undefined,
  };
  const newAssetItem = await axiosInstance.post(apiEndpoint, data);
  await updateAssetItemFiles(`${apiEndpoint}${newAssetItem.data.id}/`, files);
  return newAssetItem;
};

interface IAssetTypeObj {
  object?: AssetObjectType;
  slug?: string;
  type?: string;
}

function useCreateUpdateAssetItem(assetTypeObj: IAssetTypeObj) {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  const queryClient = useQueryClient();
  const { data: assetMetadata } = useGetAssetMetadata();

  const assetMetadataObj = assetMetadata?.assets.find((metadataObj) =>
    Object.entries(metadataObj).some(
      (keyValuePair) =>
        assetTypeObj[keyValuePair[0] as keyof IAssetTypeObj] === keyValuePair[1]
    )
  );

  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSettled: (data: any, error: any, variables: any, context: any) => {
      if (variables.id) {
        queryClient.invalidateQueries([
          assetMetadataObj?.slug,
          { id: variables.id },
        ]);
      } else {
        queryClient.setQueryData(
          [assetMetadataObj?.slug, { id: data.data.id }],
          data.data
        );
        queryClient.invalidateQueries([queryKeys.assetcount]);
      }

      if (data?.data?.object === AssetObjectType.DISCOVERY_QUESTION) {
        const categoryDropdownOptions =
          assetMetadata?.dropdowns[AssetObjectType.DISCOVERY_QUESTION].category;
        const categoryOption = categoryDropdownOptions.find(
          (dropdownOption: any) => dropdownOption.value === data.data.category
        );
        if (!categoryOption) {
          queryClient.invalidateQueries([queryKeys.metaData]);
        }
      }
    },
  };

  const mutationFunction = (mutationData: TAssetItemMutationData) => {
    return mutationData.id
      ? updateAssetItem(assetMetadataObj?.slug, mutationData)
      : createAssetItem(assetMetadataObj?.slug, mutationData);
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );

  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);

  return { mutate, ...mutationState };
}

export default useCreateUpdateAssetItem;
