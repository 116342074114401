import styled from "@emotion/styled";

interface ILayoutWrapper {
  color: string;
}

export const LayoutWrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 6.4rem auto;
  grid-template-areas: "sidebar main";
  color: ${({ color }: ILayoutWrapper) => color};
`;

export const SidebarWrapper = styled.aside`
  grid-area: sidebar;
  position: relative;
  background: ${({ theme }: any) => theme.colors?.sidebar.background};
`;

export const HeaderWrapper = styled.header`
  grid-area: header;
  background-color: var(--mid-blue);
`;

interface IMainWrapper {
  background: string;
}

export const MainWrapper = styled.main`
  grid-area: main;
  overflow-y: auto;
  height: calc(100vh);
  background: ${({ background }: IMainWrapper) => background};
`;
