import styled from "@emotion/styled";

export const HtmlDiv = styled.div`
  all: revert;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  p,
  code,
  pre {
    margin: revert;
    font: revert;
  }
  ul {
    list-style: revert;
    margin: revert;
    padding-left: revert;
  }
  ol {
    margin: revert;
    padding-left: revert;
    list-style: revert;
  }
  blockquote {
    background: #f9f9f9;
    border-left: 0.5rem solid #ccc;
    margin: 1.5rem 10px;
    padding: 0.5rem 10px;
  }
  blockquote p {
    display: inline;
  }
  strong {
    font-weight: 500;
  }
  em {
    font-style: italic;
  }
  table {
    td {
      border: 1px solid var(--border-gray);
      padding: 1rem;
      vertical-align: top;
    }
    tr:first-of-type td {
      font-weight: 500;
    }
  }
  p:nth-of-type(1),
  h1:nth-of-type(1),
  h2:nth-of-type(1),
  h3:nth-of-type(1),
  h4:nth-of-type(1),
  h5:nth-of-type(1),
  h6:nth-of-type(1) {
    margin-top: 0;
  }
  .b-embed {
    max-width: 97%;
    margin-bottom: 1rem;
  }
  [data-ephox-embed-iri] {
    max-width: 65% !important;
    margin-bottom: 1rem;
    padding-bottom: 36.56% !important; //{65 * (9/16)} to maintain aspect ratio of 16:9
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .ql-indent-1 {
    padding-left: 3rem;
  }
  .ql-indent-2 {
    padding-left: 6rem;
  }
  .ql-indent-3 {
    padding-left: 9rem;
  }
  .ql-indent-4 {
    padding-left: 12rem;
  }
  .ql-indent-5 {
    padding-left: 15rem;
  }
  .ql-indent-6 {
    padding-left: 18rem;
  }
  .ql-indent-7 {
    padding-left: 21rem;
  }
  .ql-align-center {
    text-align: center;
  }
  .tiny-pageembed--21by9,
  .tiny-pageembed--16by9,
  .tiny-pageembed--4by3,
  .tiny-pageembed--1by1 {
    display: block;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }

  .tiny-pageembed--21by9 {
    padding-top: 42.857143%;
  }

  .tiny-pageembed--16by9 {
    padding-top: 56.25%;
  }

  .tiny-pageembed--4by3 {
    padding-top: 75%;
  }

  .tiny-pageembed--1by1 {
    padding-top: 100%;
  }

  .tiny-pageembed--21by9 iframe,
  .tiny-pageembed--16by9 iframe,
  .tiny-pageembed--4by3 iframe,
  .tiny-pageembed--1by1 iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .tox-checklist > li:not(.tox-checklist--hidden) {
    list-style: none;
    margin: 0.25em 0;
    position: relative;
  }
  .tox-checklist > li:not(.tox-checklist--hidden)::before {
    content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-unchecked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2215%22%20height%3D%2215%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%234C4C4C%22%20rx%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
    cursor: pointer;
    height: 1em;
    margin-left: -1.5em;
    margin-top: 0.125em;
    position: absolute;
    width: 1em;
  }
  .tox-checklist li:not(.tox-checklist--hidden).tox-checklist--checked::before {
    content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-checked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%234099FF%22%20fill-rule%3D%22nonzero%22%20rx%3D%222%22%2F%3E%3Cpath%20id%3D%22Path%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.5703186%2C3.14417309%20C11.8516238%2C2.73724603%2012.4164781%2C2.62829933%2012.83558%2C2.89774797%20C13.260121%2C3.17069355%2013.3759736%2C3.72932262%2013.0909105%2C4.14168582%20L7.7580587%2C11.8560195%20C7.43776896%2C12.3193404%206.76483983%2C12.3852142%206.35607322%2C11.9948725%20L3.02491697%2C8.8138662%20C2.66090143%2C8.46625845%202.65798871%2C7.89594698%203.01850234%2C7.54483354%20C3.373942%2C7.19866177%203.94940006%2C7.19592841%204.30829608%2C7.5386474%20L6.85276923%2C9.9684299%20L11.5703186%2C3.14417309%20Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
  }
`;
