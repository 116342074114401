export const readFile = async (file: any) => {
  const reader = new FileReader();

  return new Promise((res, rej) => {
    reader.onload = function () {
      const resultData = reader.result;
      return res(resultData);
    };

    reader.onerror = function () {
      return rej("Error reading file");
    };

    reader.readAsDataURL(file);
  });
};

export const getImageFileExtensionFromType = (fileType: string) => {
  const typeMap = {
    jpeg: "jpg",
    png: "png",
    gif: "gif",
    bmp: "bmp",
    webp: "webp",
  } as any;
  return typeMap[fileType] || "jpg";
};
