import styled from "@emotion/styled";

interface IRichTextEditorWrapperProps {
  hide?: boolean;
}

export const RichTextEditorWrapper = styled.div<IRichTextEditorWrapperProps>`
  display: ${(props) => (props.hide ? "none" : "block")};
  background-color: var(--white);
  .b-embed {
    width: 97%;
    margin-bottom: 1rem;
  }
  cursor: text;
  strong {
    font-weight: 500;
  }
  em {
    font-style: italic;
  }
  .ql-container {
    display: ${(props) => (props.hide ? "none" : "block")};
    font-family: var(--font-lexend) !important;
    border: none;
  }

  .ql-tooltip {
    border-radius: 0.6rem;
    border-color: var(--border-gray);
  }

  .ql-tooltip[data-mode="link"] {
    input {
      min-height: 3rem;
      border-radius: 0.6rem;
      border-color: var(--border-gray);
      font-size: 1.3rem;
      color: var(--text-gray);
    }
    ::before {
      content: "Link";
    }
  }

  .ql-editor {
    padding: 0;
    padding-bottom: 1.5rem;
    font-size: 1.4rem;
    line-height: 150%;
    border: none;

    ::before {
      color: #a1a1aa88;
      font-size: 1.3rem;
      font-weight: 400;
      font-style: normal;
      left: 0;
    }
    ul,
    p {
      margin: revert;
    }
    p:first-child:not(style) {
      margin-top: 0;
    }
  }

  .ql-toolbar {
    border: none;
    padding: 0;
    font-family: var(--font-lexend);
    color: #94a3b8;
    display: flex;
    align-items: center;
    height: 2.5rem;
  }

  .ql-formats {
    display: flex;
    align-items: center;
    color: #94a3b8;
    margin-right: 0rem !important;
    > [class*="ql-"] {
      margin-right: 1.2rem;
      :last-child {
        margin-right: 0;
      }
    }
    .ql-active div {
      color: #475569;
    }

    ::after {
      content: "|";
      margin: 0 1.2rem;
      color: var(--white);
      border-right: 1px solid #94a3b855;
    }
    :last-of-type {
      ::after {
        border: none;
      }
    }

    .ql-size {
      ::after {
        content: "";
      }
    }
    .ql-picker-label {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      color: #64748b;
      border-radius: 0.8rem;
      border: 0.05rem solid #e2e8f0;
      ::before {
        color: #64748b;
      }
      svg {
        display: none;
      }
    }

    .ql-picker-options {
      min-width: 13rem;
      padding: 1rem 0;
      border-radius: 1.2rem;
      box-shadow: 0 1.6rem 5rem rgba(0, 0, 0, 0.12);
      .ql-picker-item[data-value="3"]::before {
        font-size: 1.6rem;
      }
      .ql-picker-item[data-value=""]::before {
        font-size: 1.2rem;
        font-weight: 400;
      }
      .ql-picker-item {
        padding: 1rem;
        ::before {
          color: #475569;
        }
        :hover {
          background-color: #eff6ff66;
        }
      }
      .ql-selected {
        background-color: #eff6ff88;
      }
    }
  }
  .tox-statusbar__branding {
    display: none;
  }
  .tox-tinymce {
    border: none;
    border-radius: 0;
  }
  #tinymce {
    font-size: 1.4rem;
    font-family: --var-lexend;
    font-weight: 400;
  }
  .tox.tox-tinymce {
    min-height: inherit;
  }
  .tox .tox-spinner > div {
    height: 1.3rem;
    width: 1.3rem;
  }
`;

interface IEmbedSelectProps {
  position: {
    x?: number;
    y?: number;
  };
}

export const EmbedSelect = styled.div<IEmbedSelectProps>`
  position: fixed;
  visibility: ${({ position }) =>
    position.x || position.y ? "visible" : "hidden"};
  left: ${({ position }) => position.x || 0}px;
  top: ${({ position }) => position.y || 0}px;
  margin-top: 1rem;
  background-color: var(--white);
  min-width: 13rem;
  padding: 1rem 0.5rem;
  border-radius: 1.2rem;
  box-shadow: 0 1.6rem 5rem rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 10;
  user-select: none;
`;

export const EmbedSelectOption = styled.div`
  background-color: white;
  padding: 0.5rem;
  :hover {
    background-color: #eff6ff66;
  }
`;
