import styled from "@emotion/styled";

export const NotFoundWrapper = styled.div`
  .not-found-icon-container {
    margin-top: 8.5rem;
    margin-bottom: 4.2rem;
  }
  .description-text {
    color: var(--not-found-text);
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "Lexend";
  }
  .error-code {
    color: var(--not-found-text);
    font-size: 1rem;
    line-height: 150%;
    font-family: "Lexend";
  }
`;
