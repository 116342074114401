import { useEffect } from "react";
import styled from "@emotion/styled";
import { Button } from "../../../../styled";
import { appConfig, userActivityEvents } from "../../../../../config";
import { setIsOwnerAndSignup, getLoggedInUser } from "../../../../../helpers";
import { ILoggedInUserResponse } from "../../../../../interfaces";
import { history } from "../../../../../utils/history";
import { Header, Layout, Main, GatherUserInfoTitle as Title } from "../common";
import HeaderBand from "../HeaderBand";
import { trackUserAction } from "../../../../../analytics";

const {
  landingPage,
  urls: {
    joinFromWorkspace: joinFromWorkspaceUrl,
    gatherUserAccountDetails: gatherUserAccountDetailsUrl,
  },
} = appConfig;

const WorkspaceSelectionContainer = styled.div`
  margin-top: 5.4rem;
  padding: 0rem 1.6rem;
  .rect-container {
    border: 2px solid #3d3e43;
    padding: 1.3rem 2.3rem;
    padding-right: 1.3rem;
    border-radius: 7px;
    margin-bottom: 1.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 49rem;
`;

const RowLeft = styled.div`
  font-size: 2rem;
  max-width: 28rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2;
  margin-bottom: 0;
  .faded-text {
    color: #808080;
    font-size: 1.4rem;
  }
`;
const RowRight = styled.div`
  .btn-workspace {
    width: 10rem;
    font-size: 1.3rem;
    padding: 1rem;
  }
`;

const ChooseWorkspace = () => {
  const loggedInUser = getLoggedInUser();

  useEffect(() => {
    if (loggedInUser) {
      const { teams, team } = loggedInUser;
      if (teams && teams.length === 1 && teams[0].is_joined) {
        const { is_owner, is_signup } = teams[0];
        setIsOwnerAndSignup({
          loggedInUser,
          is_owner,
          is_signup,
        });
        localStorage.setItem("teamId", teams[0].id);
        history.push(
          loggedInUser.is_signup
            ? gatherUserAccountDetailsUrl
            : `${landingPage.apply(appConfig)}`
        );
      } else if (!teams) {
        localStorage.setItem("teamId", team.id);
        history.push(
          loggedInUser.is_signup
            ? gatherUserAccountDetailsUrl
            : `${landingPage.apply(appConfig)}`
        );
      }
    }
  }, [loggedInUser]);

  function handleGetIn(team: any, loggedInUser: ILoggedInUserResponse) {
    localStorage.setItem("teamId", team.id);
    trackUserAction(userActivityEvents.SIGNIN__WORKSPACE_SELECTED, {
      team_id: team.id,
      team_name: team.name,
    });
    history.push(
      loggedInUser.is_signup
        ? gatherUserAccountDetailsUrl
        : `${landingPage.apply(appConfig)}`
    );
  }

  function handleJoin(team: any) {
    trackUserAction(userActivityEvents.SIGNIN__JOIN_WORKSPACE_CLICKED, {
      team_id: team.id,
      team_name: team.name,
    });
    const path = `${joinFromWorkspaceUrl}?code=${team.code}`;
    history.push(path);
  }

  return (
    loggedInUser && (
      <Layout>
        <HeaderBand />
        <Main>
          <Title>Choose the workspace to get into</Title>
          <WorkspaceSelectionContainer>
            {loggedInUser?.teams?.map((team, index) => {
              const { id, name, is_joined, status, code, is_signup, is_owner } =
                team;
              return (
                <Row className="rect-container" key={id}>
                  <RowLeft>
                    <p>{name}</p>
                    {status === "pending" && (
                      <p className="faded-text">Invite pending</p>
                    )}
                  </RowLeft>
                  <RowRight>
                    {is_joined && (
                      <Button
                        onClick={() => {
                          // modify isSignup and isOwner fields
                          setIsOwnerAndSignup({
                            loggedInUser,
                            is_owner,
                            is_signup,
                          });
                          handleGetIn(team, loggedInUser);
                        }}
                        className="btn-workspace">
                        GET IN
                      </Button>
                    )}
                    {!is_joined && code && (
                      <Button
                        onClick={() => {
                          setIsOwnerAndSignup({
                            loggedInUser,
                            is_owner,
                            is_signup,
                          });
                          handleJoin(team);
                        }}
                        className="btn-workspace">
                        JOIN
                      </Button>
                    )}
                  </RowRight>
                </Row>
              );
            })}
          </WorkspaceSelectionContainer>
        </Main>
      </Layout>
    )
  );
};

export default ChooseWorkspace;
