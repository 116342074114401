import React from "react";
import { noop } from "lodash";
import {
  Card as CardContainer,
  CardContentWrapper,
  CardFooter,
  CardRightArrow,
  Description,
  TagAndButtonContainer,
  ThumbnailContainer,
  Title,
  TitleContainer,
} from "../styled";
import rightArrowGray from "../../../../../../assets/icons/rightArrowGray.svg";
import SanitizedHtmlDiv from "../../../../../styled/SanitizedHtmlDiv";
import { Tag } from "../../Tags";
import { ICardItem } from "..";
import { ITag } from "../../Tags/Tag";


interface ICard {
  item: ICardItem;
  WidgetToolbar?: (props?: object) => React.ReactNode;
  onCardClick?: Function;
}
const Card = ({ item, WidgetToolbar, onCardClick = noop }: ICard) => {
  const getTags = (tagsArr: ITag[]) => {
    return tagsArr.map((tagObject: ITag, i: number) => (
      <Tag
        key={tagObject.label + i}
        label={tagObject.label}
        toolTipText={tagObject.toolTipText}
      />
    ));
  };

  return (
    <>
      <CardContainer
        className="library-asset-card"
        data-id={`${item.id}`}
        onClick={(e: any) => {
          onCardClick && onCardClick(e, item);
        }}
      >
        <CardContentWrapper>
          {item?.thumbnailUrl ? (
            <ThumbnailContainer>
              <img src={item.thumbnailUrl}></img>
            </ThumbnailContainer>
          ) : (
            item?.ThumbnailComponent?.()
          )}
          <div style={{ width: "100%" }}>
            <TitleContainer>
              <Title>{item.title}</Title>
              {WidgetToolbar ? (
                WidgetToolbar({
                  id: `widget-${item.id}`,
                  "data-id": item.id,
                  "data-item": item,
                  style: {
                    alignSelf: "flex-start",
                    visibility: "visible",
                  },
                })
              ) : (
                <></>
              )}
            </TitleContainer>
            <CardFooter>
              <div
                style={{
                  width: `calc(100% - ${
                    document.getElementById(`widget-${item.id}`)?.offsetWidth
                  }px)`,
                }}
              >
                {!!item?.tags?.length && (
                  <TagAndButtonContainer>
                    {getTags(item?.tags)}
                  </TagAndButtonContainer>
                )}
              </div>
              <Description>
                <SanitizedHtmlDiv value={item.description}></SanitizedHtmlDiv>
              </Description>
            </CardFooter>
          </div>
        </CardContentWrapper>
        {onCardClick && (
          <CardRightArrow>
            <img
              src={rightArrowGray}
              className="right_arrow"
              alt="See details"
            />
          </CardRightArrow>
        )}
      </CardContainer>
    </>
  );
};

export default Card;
