import styled from "@emotion/styled";
import { RefObject } from "react";

interface SidebarWithTabsWrapperProps {
  sidebarRef: RefObject<HTMLDivElement>;
}

export const SidebarWithTabsWrapper = styled.div<SidebarWithTabsWrapperProps>`
  font-family: var(--font-lexend);
  display: flex;
  min-height: 100%;
  background: var(--white);
  font-size: 1.4rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border: 0.5px solid #e2e8f0;
  border-radius: 1.2rem;
  overflow: hidden;
  .tabbed__sidebar {
    position: fixed;
  }
`;

export const TabbedSidebar = styled.div`
  background: #fdfdfd;
  min-height: 100%;
  min-width: 24.4rem;
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  overflow-y: auto;
`;

export const SelectedTabContainer = styled.div`
  width: calc(100% - 24.4rem);
  background: #fff;
  overflow-y: auto;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding-bottom: 3rem;
  margin-left: 26.4rem;
`;

interface ITabProps {
  isSelected: boolean;
}

export const Tab = styled.div<ITabProps>`
  color: var(--text-blue-grey);
  font-size: 1.4rem;
  line-height: 2.1rem;
  padding: 1.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ isSelected }: ITabProps) =>
    isSelected
      ? `
    background: var(--selected-tab);
    position: relative;
    color: #2563EB;
    justify-content: space-between;
    `
      : `
      transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #F8F8F8;
    }  
  `};
`;
export const TagTitle = styled.div`
  display: flex;
`;

export const TagCount = styled.div`
  display: flex;
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: right;
  color: #2563eb;
`;

export const AssetAddButtonContainer = styled.div`
  position: absolute;
  bottom: 3rem;
`;

export const AssetAddButton = styled.a`
  background: ${({ theme }: any) => theme.colors.button.background};
  font-size: 1.2rem;
  color: #f8fafc;
  font-weight: 400;
  font-family: var(--font-lexend);
  margin-left: 2rem;
  padding: 1.2rem 1.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
`;
