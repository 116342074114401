import capitalize from "lodash/capitalize";

export const appConfig = {
  appName: capitalize(process.env.REACT_APP_NAME),
  domain: process.env.REACT_APP_DOMAIN,
  get websiteUrl(): string {
    return process.env.REACT_APP_WEBSITE_URL || `https://${appConfig.domain}`;
  },
  emailIds: {
    get support(): string {
      return `support@${appConfig.domain}`;
    },
  },
  isGoogleAuthEnabled: true,
  isSignInWithEmailEnabled: true,
  publicRoutePaths: [
    "/oauth/hubspot",
    "/oauth/salesforce",
    "/terms",
    "/privacy",
    "/logout",
  ],
  userUpdationPages: ["/user/account-details", "/user/invite-users"],
  pagesWithCenteredToastContainer: ["/user/settings"],
  pathsToBeOpenedInCompletePage: [
    "/privacy",
    "/terms",
    "/user/account-details",
    "/user/invite-users",
    "/user/invitee-details",
    "/user/workspace",
    "/join-from-workspace",
    "/documents/:id/edit",
  ],
  urls: {
    home: "/", //there can be two home routes one for private and public so need to segregate it before changing hardcoding
    workspaceSelection: "/user/workspace",
    joinFromWorkspace: "/join-from-workspace",
    gatherUserAccountDetails: "/user/account-details",
    inviteUsers: "/user/invite-users",
    signin: "/signin",
    magicLink: "/user/magic-link",
  },
  landingPage: function () {
    const { home } = this.urls;
    return home;
  },
  defaultTimezone: {
    label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "Asia/Kolkata",
    offset: 5.5,
  },
  pageSpecificConfigs: {
    integrations: {
      mechanisms: ["csv-upload", "hubspot-integration"],
    },
    allOpportunities: {
      defaultWidth: 21,
    },
    assets: {
      parentUrl: "/user/assets",
      pathUrl: {
        case_study: "/user/assets/case-studies",
        discovery_question: "/user/assets/discovery-questions",
        battle_card: "/user/assets/battle-cards",
        feature: "/user/assets/features",
        objection: "/user/assets/objections",
      },
      staticAssetMapper: {
        discovery_question: "question",
        case_study: "title",
        battle_card: "competitor_name",
        feature: "persona",
        relationship_parameter: "value",
        generic_resource: "title",
        objection: "objection",
      },
      currentlyDisabledTabs: [], //array of tab indices
      assetscount: {
        "Discovery Questions": "discovery_question",
        "Case Studies": "case_study",
        "Battle Cards": "battle_card",
        "Objection Handling": "objection",
        "Slide Decks": "slide_decks",
        "Test Generic Resource": "test_generic_resource",
      },
    },
    settings: {
      parentUrl: "/user/settings",
      pathUrl: {
        solution_parameters: "/user/settings/solution_parameters",
        integrations: "/user/settings/integrations",
      },
    },
    solution: {
      parentUrl: "/user/solutions",
      createUrl: "/user/solutions/create",
      analyticsTableDefaultSortField: "last_viewed_at",
    },
    campaign: {
      parentUrl: "/user/campaigns",
    },
    campaignUser: {
      parentUrl: "/user/campaign-users",
    },
  },
  formats: {
    cellDateFormat: "Do MMM YYYY",
    cellDateTimeFormat: "MMM Do, hh:mma",
  },
};

export const EXCLUDE_DOMAINS_FOR_SUBDOMAIN_CHECK = [
  "dev",
  "web",
  "staging",
  "localhost:3000",
];
