import jwtDecode from "jwt-decode";
import { useContext, useEffect, useRef } from "react";
import { QueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context";
import { IToken } from "../../interfaces";
import axiosInstance from "../axios";
import { getLoggedInUser, logout } from "../../helpers";
import { EXCLUDE_DOMAINS_FOR_SUBDOMAIN_CHECK } from "../../config/appConfig";
import { history } from "../../utils/history";
import { getUser } from "./useGetUser";

export const validateIsSharedPreview = () => {
  let isPreview = false;
  let guestCode = sessionStorage.getItem("guestCode");
  let refreshToken = localStorage.getItem("refresh");
  const searchParams = new URLSearchParams(window.location.search);
  const isPreviewFromUrl = searchParams.get("preview") === "true";

  if (isPreviewFromUrl) {
    const subdomain = window.location.host.split(".")[0];
    const hasCustomDomain = !["web", "staging", "localhost:3000"].includes(
      subdomain
    );
    isPreview = Boolean(hasCustomDomain || (!hasCustomDomain && refreshToken));
    if (!isPreview) {
      sessionStorage.removeItem("guestIsPreview");
      window.location.replace(`/shared/${guestCode}`);
    }
  }
  return isPreview;
};

export const useSetAuthUser = ({
  queryClient,
}: {
  queryClient?: QueryClient;
}) => {
  const location = useLocation();
  const { authDispatch, authState } = useContext(AuthContext);
  const isSharedPath = location.pathname.startsWith("/shared");

  // useRef to get latest state of authState inside listener
  const authStateRef = useRef(authState);

  useEffect(() => {
    authStateRef.current = authState;
    const loggedInUser = getLoggedInUser();
    const userId = loggedInUser?.user.id;
    const getUserInfo = async function () {
      if (!userId) return;
      return await getUser(userId);
    };
    getUserInfo()
      .then((userInfo) => {
        if (userInfo?.is_admin !== loggedInUser?.user.is_admin)
          logout(authDispatch);
      })
      .catch((err) => {
        if (!location.pathname.startsWith("/shared")) Promise.reject(err);
      });
  }, [authState]);

  const refreshAccessTokenOfLoggedInUser = async () => {
    let refreshToken = localStorage.getItem("refresh");
    if (!refreshToken) return Promise.reject("No refresh token found");
    return await axiosInstance
      .post("/auth/token/refresh/", {
        refresh: refreshToken,
      })
      .then((res) => {
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
      });
  };

  const refreshTokens = () => {
    const searchParams = new URLSearchParams(location.search);
    const isSharedResourcePreview = searchParams.get("preview") === "true";
    let accessToken = localStorage.getItem("access");
    let refreshToken = localStorage.getItem("refresh");
    let guestCode = sessionStorage.getItem("guestCode");
    let guestToken = sessionStorage.getItem("guestAccess");
    const tokenInfo = accessToken && (jwtDecode(accessToken) as IToken);
    const isSharedPath = location.pathname.startsWith("/shared");
    if (isSharedPath) {
      validateIsSharedPreview();
      if (!authStateRef.current.isGuestUser && guestToken) {
        authDispatch({
          type: "SET_USER",
          payload: {
            isGuestUser: true,
            isPreview:
              isSharedResourcePreview ||
              sessionStorage.getItem("guestIsPreview") === "true",
          },
        });
      } else if (!guestToken) {
        const urlParams = new URLSearchParams();
        if (isSharedResourcePreview) urlParams.append("preview", "true");
        history.push(`/shared/${guestCode}?${urlParams.toString()}`);
      }
    } else if (
      tokenInfo &&
      (authStateRef.current.isGuestUser || !authStateRef.current.userInfo)
    ) {
      authDispatch({
        type: "SET_USER",
        payload: {
          userInfo: {
            userId: tokenInfo.user_id.toString(),
            email: tokenInfo.email,
            display_name: tokenInfo.display_name,
          },
        },
      });
    }

    if (!accessToken && refreshToken && !isSharedPath) {
      refreshAccessTokenOfLoggedInUser().then(() => {
        authDispatch({
          type: "SET_USER",
          payload: {
            isGuestUser: false,
          },
        });
        queryClient?.invalidateQueries();
      });
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem("access");
    let refreshToken = localStorage.getItem("refresh");

    if (!accessToken && refreshToken && !isSharedPath) {
      refreshAccessTokenOfLoggedInUser().then(() => {
        authDispatch({
          type: "SET_USER",
          payload: {
            isGuestUser: false,
          },
        });
        queryClient?.invalidateQueries();
      });
    }
  });

  useEffect(() => {
    const subdomain = window.location.host.split(".")[0];
    const loggedInUser = localStorage.getItem("loggedInUser");
    const loggedInUserTeamDomain =
      loggedInUser && JSON.parse(loggedInUser)?.team.domain;
    if (
      loggedInUserTeamDomain &&
      !EXCLUDE_DOMAINS_FOR_SUBDOMAIN_CHECK.includes(subdomain) &&
      subdomain !== loggedInUserTeamDomain
    ) {
      logout(authDispatch);
      history.replace("/");
    } else {
      refreshTokens();
      // To handle case when user switches between user/guestUser tabs
      window.addEventListener("focus", refreshTokens);
    }
    return () => {
      window.removeEventListener("focus", refreshTokens);
      sessionStorage.removeItem("guestAccess");
    };
  }, []);
};
