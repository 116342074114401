import { FieldMetaProps } from "formik";

interface IFormErrorTextProps {
  formikMeta?: FieldMetaProps<any>;
}

const FormErrorText = ({ formikMeta }: IFormErrorTextProps) => {
  const isErrorObject = typeof formikMeta?.error !== "string";
  const errorText = isErrorObject
    ? Object.values(formikMeta?.error || {}).map((fieldErrorText) =>
        fieldErrorText.replace(/^./, (str) => str.toUpperCase())
      )
    : formikMeta.error?.replace(/^./, (str) => str.toUpperCase());
  return (
    <>
      {formikMeta && formikMeta.touched && formikMeta.error ? (
        <div id="error-text">* {errorText}</div>
      ) : null}
    </>
  );
};

export { FormErrorText };
