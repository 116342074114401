import { useEffect, useState } from "react";
import { trackUserAction } from "../../../../../../analytics";
import useGetAssetMetadata from "../../../../../../api/hooks/useGetAssetMetadata";
import { userActivityEvents } from "../../../../../../config";
import {
  getAbsoluteFileUrlFromPath,
  getFileNameFromUrl,
} from "../../../../../../helpers";
import useHtmlRenderers from "../../../../../../hooks/useHtmlRenderers";
import { IAssetType } from "../../../../../../interfaces/assets";
import { IDocumentObject } from "../../../../../../interfaces/common";
import SanitizedHtmlDiv from "../../../../../styled/SanitizedHtmlDiv";
import ViewFileButton from "../../Buttons/ViewFileButton";
import { DocumentViewerModal } from "../../Modals";
import { Tag } from "../../Tags";
import {
  CardContentWrapper,
  CardFooter,
  Description,
  DocumentsContainer,
  TagAndButtonContainer,
  Title,
  VerticalCardsContainer,
  VerticalCardsHeader,
} from "./styled";
import { Card } from "./styled";

interface ISharedGenericResourceCard {
  title?: string;
  data: [];
}

const SharedVerticalCards = ({ title, data }: ISharedGenericResourceCard) => {
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const { data: assetsMetadata } = useGetAssetMetadata();
  const [assetSlug, setAssetSlug] = useState("");

  useEffect(() => {
    if (assetsMetadata && assetsMetadata?.assets) {
      assetsMetadata?.assets?.forEach((assetType: IAssetType) => {
        if (title?.toLowerCase() === assetType.label.toLowerCase()) {
          setAssetSlug(assetType.slug);
        }
      });
    }
  }, [assetsMetadata]);

  // Use AdobePDF Viewer to render pdfs
  useHtmlRenderers();

  const getDocuments = (dataItem: any) => {
    return dataItem.documents
      .filter((documentObj: IDocumentObject) => documentObj.url)
      .map((documentObj: IDocumentObject) => {
        return (
          <ViewFileButton
            showToolTip={true}
            hideButtonLabel={false}
            key={documentObj.id}
            filePath={documentObj.url}
            onClick={() => {
              setFileUrl(getAbsoluteFileUrlFromPath(documentObj.url));
              setShowModal(true);
              trackUserAction(
                userActivityEvents.LIBRARY__DOCUMENT_BUTTON_CLICKED,
                {
                  fileName: getFileNameFromUrl(documentObj.url),
                  assetName: dataItem.title,
                  assetType: dataItem.object,
                }
              );
            }}
          ></ViewFileButton>
        );
      });
  };

  return (
    <>
      <div>
        <VerticalCardsContainer>
          {title && <VerticalCardsHeader>{title}</VerticalCardsHeader>}
          {data.map((item: any, i: number) => {
            const isLastItem = item === data.at(-1);
            return (
              <Card
                key={item.id}
                style={isLastItem ? { borderBottom: "none" } : {}}
              >
                <CardContentWrapper>
                  <Title>{item.title || item.description}</Title>
                  <CardFooter>
                    <Description>
                      <SanitizedHtmlDiv
                        value={item.description}
                      ></SanitizedHtmlDiv>
                    </Description>
                    {!!item.documents.length && (
                      <TagAndButtonContainer>
                        {!!item.documents.length && (
                          <DocumentsContainer>
                            {getDocuments(item)}
                          </DocumentsContainer>
                        )}
                      </TagAndButtonContainer>
                    )}
                  </CardFooter>
                </CardContentWrapper>
              </Card>
            );
          })}
        </VerticalCardsContainer>
      </div>
      <DocumentViewerModal
        fileUrl={fileUrl}
        isOpen={showModal}
        onDismissal={() => setShowModal(false)}
      ></DocumentViewerModal>
    </>
  );
};

export default SharedVerticalCards;
