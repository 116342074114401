import axiosInstance from "../axios";
import { IMutationState } from "../../interfaces";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

interface IAnalyticsLogsMutationObject {
  type?: string;
  payload?: any;
}

const updateAnalyticsLogs = async (postData: IAnalyticsLogsMutationObject) => {
  return await axiosInstance.post(`/analytics/logs/shareables/`, postData);
};

const useUpdateAnalyticsLogs = () => {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  const mutationOption = {
    onError: (error: any, data: any, variables: any) => {
      toast.error("Oops, something went wrong");
    },
    onSettled: (data: any) => {},
  };

  const mutationFunction = (mutationData: IAnalyticsLogsMutationObject) => {
    return updateAnalyticsLogs(mutationData);
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );

  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);

  return { mutate, ...mutationState };
};

export default useUpdateAnalyticsLogs;
