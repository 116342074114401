import { Dispatch } from "react";
import {
  ISolutionAssetType,
  ISolutionObject,
  ISolutionSharingObject,
} from "../solution";

export const initialShareableResObj: ISolutionSharingObject = {
  code: "",
  id: undefined,
  is_active: false,
  is_email_required: false,
};

export enum SolutionsActionType {
  SET_SOLUTION_ID = "SET_SOLUTION_ID",
  SET_SOLUTION_OBJECT = "SET_SOLUTION_OBJECT",
}

export enum SolutionsAssetActionType {
  SET_ASSET_TYPE = "SET_ASSET_TYPE",
  SET_ASSET_ID = "SET_ASSET_ID",
  SET_SHOW_ASSET_MODAL = "SET_SHOW_ASSET_MODAL",
  SET_IS_CREATE_EDIT = "SET_IS_CREATE_EDIT",
  SET_IS_DELETE = "SET_IS_DELETE",
}

export interface ISolutionsState {
  solutionId?: string | number;
  solutionObject?: ISolutionObject;
}

export interface ISolutionsAssetState {
  assetType: ISolutionAssetType | null;
  assetId: string | number;
  showAssetModal: boolean;
  isCreateEdit: boolean;
  isDelete: boolean;
}

type TSetSolutionId = {
  type:
    | SolutionsActionType.SET_SOLUTION_ID
    | SolutionsActionType.SET_SOLUTION_OBJECT;
  payload: string | number | any;
};

type TSetSolutionsAsset = {
  type:
    | SolutionsAssetActionType.SET_ASSET_TYPE
    | SolutionsAssetActionType.SET_ASSET_ID
    | SolutionsAssetActionType.SET_IS_CREATE_EDIT
    | SolutionsAssetActionType.SET_IS_DELETE
    | SolutionsAssetActionType.SET_SHOW_ASSET_MODAL;
  payload: ISolutionAssetType | string | number | boolean | null | any;
};

export type TSolutionsReducerAction = TSetSolutionId;

export type TSolutionsAssetReducerAction = TSetSolutionsAsset;

export interface ISolutionsContext {
  solutionsState: ISolutionsState;
  solutionsDispatch: Dispatch<TSolutionsReducerAction>;
  solutionSearchText: string;
  setSolutionSearchText: React.Dispatch<React.SetStateAction<string>>;
  solutionsAssetState: ISolutionsAssetState;
  solutionsAssetDispatch: Dispatch<TSolutionsAssetReducerAction>;
  shareableResObj: ISolutionSharingObject;
  setShareableResObj: Dispatch<ISolutionSharingObject>;
}
