import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { noop } from "lodash";
import { useQueryClient } from "react-query";
import { RichTextEditorWrapper } from "./styled";
import { getHtmlForEmbed } from "../../../api/richTextEditor";
import EmbedUploadPopup from "../Reusable/components/Modals/EmbedUploadPopup";
import axiosInstance from "../../../api/axios";
import { editorElementStyle } from "../../../config/editorConfig";
import GooglePicker from "./GoogleDrive/GooglePicker";
import { AssetsContext, SolutionsContext } from "../../../context";
import { apiConfig } from "../../../config";
import { IDocumentObject } from "../../../interfaces";
import {
  getDocumentHtml,
  insertDocumentInEditor,
} from "../../../helpers/richTextEditor1";
import GoogleSlideEditModal, {
  IGoogleSlidesData,
} from "../Reusable/components/Modals/GoogleSlideEditModal";
import { useGetSettings } from "../../../api/hooks";
import { toast } from "react-toastify";
import { queryKeys } from "../../../api/constants";
import { useTheme } from "@emotion/react";

const initialGoogleSlideData = {
  documentId: "",
  solutionAssetId: "",
  solutionAssetTypeId: "",
  genericResourceId: "",
  genericResourceTypeId: "",
  isLoading: false,
};

interface IRichTextEditorProps {
  className?: string;
  value: string;
  onChange?: Function;
  placeholder?: string;
  editorRef: any;
  showMenubar?: boolean;
  height?: number | string;
  hideToolBar?: boolean;
  setFocused?: React.Dispatch<SetStateAction<boolean>>;
}

const TINYMCE_EDITOR_APIKEY = process.env.REACT_APP_TINYMCE_EDITOR_APIKEY;
const FILE_SERVER_BASE_URL = process.env.REACT_APP_FILE_SERVER_BASE_URL;

const RichTextEditor1 = ({
  className = "",
  value: initValue,
  onChange = noop,
  placeholder = "",
  editorRef,
  showMenubar = false,
  height,
  hideToolBar = false,
  setFocused,
}: IRichTextEditorProps) => {
  const { data: settingsData } = useGetSettings();
  const theme: any = useTheme();
  const [value, setValue] = useState(initValue || "");
  const [showModal, setShowModal] = useState(false);
  const [showEmbedUrlModal, setShowEmbedUrlModal] = useState(false);
  const [showGoogleSlidesEditModal, setShowGoogleSlidesEditModal] =
    useState(false);
  const [googleSlideData, setGoogleSlideData] = useState<IGoogleSlidesData>(
    initialGoogleSlideData
  );
  const [showGooglePicker, setShowGooglePicker] = useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);
  const { solutionsState, solutionsAssetState } = useContext(SolutionsContext);
  const { editItemId: assetItemId, activeItemType: activeAssetItemType } =
    useContext(AssetsContext);
  const queryClient = useQueryClient();
  const isGdriveUploadEnabled = settingsData?.is_gdrive_upload_enabled;
  const alignmentConfiguration = {
    icon: "align-left",
    tooltip: "Alignment",
    items: "alignleft aligncenter alignright alignjustify",
  };
  const listConfiguration = {
    icon: "unordered-list",
    tooltip: "List",
    items: "bullist numlist",
  };
  const colorConfiguration = {
    icon: "text-color",
    tooltip: "Text color",
    items: "forecolor backcolor",
  };
  const indentConfiguration = {
    icon: "indent",
    tooltip: "Indent",
    items: "indent outdent",
  };
  const moreToolbarConfiguration = {
    icon: "image-options",
    tooltip: "More",
    items: "pageembed",
  };
  const uploadConfiguration = {
    icon: "browse",
    text: "Upload Image/PDF",
    onAction: () => {
      handleUploadClick();
      setShowModal(true);
      setShowEmbedUrlModal(false);
    },
  };
  const embedUrlConfiguration = {
    icon: "embed-page",
    text: "Embed from URL",
    onAction: () => {
      setShowEmbedUrlModal(true);
      setShowModal(false);
    },
  };
  const pluginArray = [
    "advlist",
    "autolink",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
    "save",
    "insertdatetime",
    "pageembed",
    "export",
    "powerpaste",
    "checklist",
    "tinymcespellchecker",
  ];

  const toolbarValue =
    "undo redo | blocks " +
    "|bold italic underline color | alignment" +
    " list checklist indentGroup | table " +
    `${
      !!isGdriveUploadEnabled ? "customUploadMenuButton" : "upload"
    } embedUrl | more`;

  const handleUploadClick = () => {
    inputElementRef.current?.click();
  };

  const filePickerCallbackFunction = (cb: any, value: any, meta: any) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", (e) => {
      //@ts-ignore
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const id = "blobid" + new Date().getTime();
        //@ts-ignore
        const blobCache = tinymce.activeEditor.editorUpload.blobCache;
        //@ts-ignore
        const base64 = reader.result.split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        /* call the callback and populate the Title field with the file name */
        cb(blobInfo.blobUri(), { title: file.name });
      });
      reader.readAsDataURL(file);
    });

    input.click();
  };
  const addGroupToolbarButton = (
    identifier: string,
    configuration: any,
    editor: any
  ) => {
    editor.ui.registry.addGroupToolbarButton(identifier, configuration);
  };

  const addCustomToolbarButton = (
    identifier: string,
    configuration: any,
    editor: any
  ) => {
    editor.ui.registry.addButton(identifier, configuration);
  };

  const hideAndShowToolbar = (showToolbar: boolean) => {
    // if (hideToolBar) {
    //   const el = document.querySelector("[data-alloy-vertical-dir]");
    //   (el as HTMLElement).style.display = showToolbar ? "block" : "none";
    // }
  };

  const createUploadMenuButton = (editor: any) => {
    editor.ui.registry.addMenuButton("customUploadMenuButton", {
      text: "Upload",
      icon: "browse",
      tooltip: "Upload",
      fetch: (callback: Function) => {
        const items = [
          {
            type: "menuitem",
            text: "Image/PDF",
            onAction: () => {
              handleUploadClick();
              setShowModal(true);
              setShowEmbedUrlModal(false);
            },
          },
          {
            type: "menuitem",
            text: "Google Slide",
            onAction: () => {
              setShowGooglePicker(true);
            },
          },
        ];
        callback(items);
      },
    });
  };

  const setupFunction = (editor: any) => {
    const isAnchorElement = (node: any) => {
      return node.nodeName.toLowerCase() === "a" && node.href;
    };

    const getAnchorElement = () => {
      const node = editor.selection.getNode();
      return isAnchorElement(node) ? node : null;
    };

    editor.ui.registry.addContextForm("link-form", {
      launch: {
        type: "contextformtogglebutton",
        icon: "link",
      },
      label: "Link",
      predicate: isAnchorElement,
      initValue: () => {
        const elm = getAnchorElement();
        return !!elm ? (elm as HTMLAnchorElement).href : "";
      },
      commands: [
        {
          type: "contextformtogglebutton",
          icon: "link",
          tooltip: "Link",
          active: false,
          onAction: (formApi: any) => {
            const value = formApi.getValue();
            editor.execCommand("mceInsertLink", false, value);
            formApi.hide();
          },
        },
        {
          type: "contextformtogglebutton",
          icon: "embed",
          tooltip: "embed",
          active: false,
          onAction: (formApi: any) => {
            const element = getAnchorElement();
            const url = (element as HTMLAnchorElement).href;
            // @ts-ignore
            tinymce.activeEditor.setProgressState(true);
            getHtmlForEmbed(url).then((html) => {
              const embedContainer = document.createElement("div");
              embedContainer.innerHTML = html;
              embedContainer.classList.add("b-embed");
              embedContainer.style.overflow = "hidden";
              editor.execCommand(
                "InsertHTML",
                false,
                `${embedContainer.outerHTML}`
              );
              editor.insertContent(`<p></p>`);
              // @ts-ignore
              tinymce.activeEditor.setProgressState(false);
            });

            formApi.hide();
          },
        },
      ],
    });
    editor.on("blur", () => {
      hideAndShowToolbar(false);
      setFocused && setFocused(false);
    });
    editor.on("focus", () => {
      hideAndShowToolbar(true);
      setFocused && setFocused(true);
    });
    editor.on("mouseover", (e: any) => {
      const embedContainer = e.target.parentNode;
      const button = e.target;
      const documentId = embedContainer?.dataset?.documentId;
      const solutionAssetId =
        button?.dataset?.solutionAssetId || solutionsAssetState.assetId || "";
      const solutionAssetTypeId =
        button?.dataset?.solutionAssetTypeId ||
        solutionsAssetState.assetType?.id ||
        "";
      const genericResourceId =
        button?.dataset?.genericResourceId || assetItemId || "";
      const genericResourceTypeId =
        button?.dataset?.genericResourceTypeId || activeAssetItemType.id || "";
      let googleSlideData: IGoogleSlidesData = { documentId };
      if (solutionsState.solutionId) {
        googleSlideData = {
          ...googleSlideData,
          solutionAssetId,
          solutionAssetTypeId,
        };
      } else {
        googleSlideData = {
          ...googleSlideData,
          genericResourceId,
          genericResourceTypeId,
        };
      }
      if (
        button?.className === "google-slides-edit-button" &&
        button.dataset.hasRedirectUrl === "false"
      ) {
        button.onclick = () => {
          setGoogleSlideData(googleSlideData);
          setShowGoogleSlidesEditModal(true);
        };
        // button.dataset.hasRedirectUrl = "true";
      }
    });
    editor.on("SelectionChange", () => {
      const node = editor.selection.getNode();
      if (node.className.includes("b-embed") && node.childNodes[0]) {
        editor.selection.select(node.childNodes[0]);
      }
    });
    editor.on("keydown", (e: any) => {
      const node = editor.selection.getNode();
      const handleDeleteBackspace = () => {
        if (["google-slides-edit-button"].includes(node.className)) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
        if (node.className.includes("b-embed")) {
          node.remove();
          e.preventDefault();
          editor.insertContent(`<p></p>`);
        } else if (node.parentNode?.className.includes("b-embed")) {
          node.parentNode.remove();
          e.preventDefault();
          editor.insertContent(`<p></p>`);
        }
      };
      switch (e.key) {
        case "Delete":
        case "Backspace": {
          handleDeleteBackspace();
          break;
        }
        case "ArrowLeft": {
          if (node.parentNode?.className.includes("b-embed")) {
            if (node.parentNode?.previousSibling) {
              editor.selection.select(node.parentNode.previousSibling);
              editor.selection.collapse();
            } else {
              const pNode = document.createElement("p");
              pNode.append(document.createElement("br"));
              node.parentNode.parentNode.insertBefore(pNode, node.parentNode);
              editor.selection.setCursorLocation(pNode);
            }
          }
          break;
        }
        case "ArrowRight": {
          if (node.parentNode.className.includes("b-embed")) {
            if (node.parentNode?.nextSibling) {
              editor.selection.select(node.parentNode.nextSibling);
              editor.selection.collapse();
            } else {
              editor.insertContent(`<p></p>`);
            }
          }
          break;
        }
        case "ArrowUp": {
          if (node.parentNode?.className.includes("b-embed")) {
            if (!node.parentNode?.previousSibling) {
              const pNode = document.createElement("p");
              pNode.append(document.createElement("br"));
              node.parentNode.parentNode.insertBefore(pNode, node.parentNode);
              editor.selection.setCursorLocation(pNode);
            }
          }
          break;
        }
        default:
      }
    });
    addGroupToolbarButton("more", moreToolbarConfiguration, editor);
    addGroupToolbarButton("alignment", alignmentConfiguration, editor);
    addGroupToolbarButton("list", listConfiguration, editor);
    addGroupToolbarButton("color", colorConfiguration, editor);
    addGroupToolbarButton("indentGroup", indentConfiguration, editor);
    addCustomToolbarButton("upload", uploadConfiguration, editor);
    addCustomToolbarButton("embedUrl", embedUrlConfiguration, editor);
    createUploadMenuButton(editor);
  };

  const imagesUploadHandler = (blobInfo: any, progress: any) =>
    new Promise<string>((resolve, reject) => {
      const formData = new FormData();
      formData.append(
        "image",
        blobInfo.blob(),
        blobInfo.blob().name || blobInfo.filename()
      );
      formData.append("is_embed", JSON.stringify(true));
      axiosInstance
        .post("/images/", formData)
        .then((res) => {
          const url = `${process.env.REACT_APP_API_BASE_URL}images/${res.data.id}/file/`;
          resolve(url);
        })
        .catch((err) => reject("Error" + err));
    });

  const onGooglePickerClose = () => {
    setShowGooglePicker(false);
  };

  const syncGDriveFile = (postData: any, documentId: number) => {
    const url = `${apiConfig.urls.documents}${documentId}/sync/`;
    return axiosInstance({
      method: "post",
      url,
      baseURL: FILE_SERVER_BASE_URL,
      data: postData,
    }).then((res) => {
      queryClient.setQueryData(
        [queryKeys.documents],
        (oldDocList: IDocumentObject[] | undefined) => {
          let updatedDocList: IDocumentObject[] = [];
          if (oldDocList) {
            const updatedDocIndex = oldDocList.findIndex(
              (doc) => doc.id === res.data.id
            );
            if (updatedDocIndex === -1)
              updatedDocList = [...oldDocList, res.data];
            else {
              updatedDocList = [...oldDocList];
              updatedDocList.splice(updatedDocIndex, 1, res.data);
            }
          } else updatedDocList.push(res.data);
          return updatedDocList;
        }
      );
      return res;
    });
  };

  const uploadGDriveFile = (postData: any) => {
    return axiosInstance({
      method: "post",
      url: apiConfig.urls.documents,
      baseURL: FILE_SERVER_BASE_URL,
      data: postData,
    }).then((res) => {
      queryClient.setQueryData(
        [queryKeys.documents],
        (oldDocList: IDocumentObject[] | undefined) => [
          ...(oldDocList ? oldDocList : []),
          res.data,
        ]
      );
      return res;
    });
  };

  const onFileSelection = async (fileId: string, googlePickerToken: string) => {
    setShowGooglePicker(false);
    const solutionAssetTypeId =
      solutionsState.solutionId && solutionsAssetState.assetType?.id
        ? `${solutionsAssetState.assetType?.id}`
        : "";
    const solutionAssetId =
      solutionsState.solutionId && solutionsAssetState.assetId
        ? `${solutionsAssetState.assetId}`
        : "";
    const genericResourceId = assetItemId ? `${assetItemId}` : "";
    const genericResourceTypeId = activeAssetItemType.id
      ? `${activeAssetItemType.id}`
      : "";
    let postData: any = {
      type: "gdrive",
      file_id: fileId,
      access_token: googlePickerToken,
    };
    if (solutionsState.solutionId) {
      postData = {
        ...postData,
        solution_asset_type: solutionAssetTypeId,
        solution_asset: solutionAssetId,
      };
    } else {
      postData = {
        ...postData,
        generic_resource_type: genericResourceTypeId,
        generic_resource: genericResourceId,
      };
    }
    try {
      //@ts-ignore
      tinymce.activeEditor.setProgressState(true);
      const googleDriveDocument: IDocumentObject = (
        await uploadGDriveFile(postData)
      ).data;
      insertDocumentInEditor(
        getDocumentHtml(
          googleDriveDocument,
          solutionsState.solutionId
            ? {
                solutionAssetId,
                solutionAssetTypeId,
              }
            : {
                genericResourceId,
                genericResourceTypeId,
              }
        )
      );
    } catch (e) {
      console.error(e);
      //@ts-ignore
      tinymce.activeEditor.setProgressState(false);
      toast.error("Oops, something went wrong");
    }
  };

  const handleSyncButtonClick = async (
    updatedDocumentData: IDocumentObject
  ) => {
    setGoogleSlideData((prevData: any) => ({ ...prevData, isLoading: true }));
    let postData;
    if (solutionsState.solutionId) {
      postData = {
        solution_asset_type: googleSlideData?.solutionAssetTypeId,
        solution_asset: googleSlideData?.solutionAssetId,
      };
    } else {
      postData = {
        generic_resource_type: googleSlideData?.genericResourceTypeId,
        generic_resource: googleSlideData?.genericResourceId,
      };
    }
    try {
      const editorValueWrapperEle = document.createElement("div");
      //@ts-ignore
      editorValueWrapperEle.innerHTML = tinymce.activeEditor.getContent();
      const embedElement = editorValueWrapperEle.querySelector(
        `[data-document-id="${googleSlideData?.documentId}"]`
      ) as HTMLElement;
      const googleDriveDocument: IDocumentObject = (
        await syncGDriveFile(postData, updatedDocumentData.id)
      ).data;
      embedElement.parentNode?.replaceChild(
        getDocumentHtml(
          googleDriveDocument,
          solutionsState.solutionId
            ? {
                solutionAssetId: googleSlideData?.solutionAssetId || "",
                solutionAssetTypeId: googleSlideData?.solutionAssetTypeId || "",
              }
            : {
                genericResourceId: googleSlideData?.genericResourceId || "",
                genericResourceTypeId:
                  googleSlideData?.genericResourceTypeId || "",
              }
        ),
        embedElement
      );
      setValue(editorValueWrapperEle.innerHTML);
      onChange(editorValueWrapperEle.innerHTML);
      setShowGoogleSlidesEditModal(false);
      // @ts-ignore
      tinymce.activeEditor.setProgressState(false);
      setGoogleSlideData((prevData: any) => ({
        ...prevData,
        isLoading: false,
      }));
    } catch (e) {
      console.error(e);
      setGoogleSlideData((prevData: any) => ({
        ...prevData,
        isLoading: false,
      }));
      toast.error("Oops, something went wrong");
    }
  };

  return (
    <>
      <RichTextEditorWrapper className={className}>
        <Editor
          apiKey={TINYMCE_EDITOR_APIKEY}
          onInit={(evt, editor) => {
            hideAndShowToolbar(false);
            editorRef.current = editor;
          }}
          value={value}
          onEditorChange={(newValue, editor) => {
            setValue(newValue);
            onChange(newValue);
          }}
          //Event fired when an object(such as an doc) has finished being resized
          onObjectResized={(target, editor) => {
            const editorValueWrapperEle = document.createElement("div");
            editorValueWrapperEle.innerHTML = editor.getContent();
            Array.from(
              editorValueWrapperEle.getElementsByClassName("b-embed")
            ).forEach((embedElement) => {
              const embedIframe = embedElement.getElementsByTagName(
                "iframe"
              )[0] as HTMLIFrameElement;
              if (embedIframe && embedIframe.style.height.includes("px")) {
                (embedElement as HTMLElement).style.height =
                  embedIframe.style.height;
              }
            });
            setValue(editorValueWrapperEle.innerHTML);
            onChange(editorValueWrapperEle.innerHTML);
          }}
          init={{
            height: "100%",
            placeholder: placeholder,
            menubar: showMenubar,
            plugins: pluginArray,
            toolbar: toolbarValue,
            toolbar_mode: "floating",
            content_style:
              "body { font-family:Lexend,sans-serif,Arial !important; font-size:14px !important; font-weight: 400 !important; margin: 0;  } " +
              "[data-ephox-embed-iri] { max-width: 65% !important; margin-bottom: 1rem; padding-bottom: 36.5% !important; }" +
              "img { height: auto; max-width: 100%; }" +
              `.b-embed {width: ${editorElementStyle.other.maxWidth};margin-bottom: 1rem;}` +
              `.google-slides-edit-button { font-weight: 500; font-size: 0.9rem;padding: 0.4rem 0.8rem;color: #ffffff;background-color: ${theme.colors.button.background};
                border-radius: 0.3rem;z-index: 999;position: absolute;top: 0.8rem;right: 0.8rem;border: none;user-select: none;
              }` +
              `td { vertical-align: top; }`,
            a11y_advanced_options: true,
            file_picker_callback: filePickerCallbackFunction,
            file_picker_types: "image",
            images_upload_handler: imagesUploadHandler,
            images_reuse_filename: true,
            tinycomments_mode: "embedded",
            powerpaste_allow_local_images: true,
            powerpaste_word_import: "prompt",
            powerpaste_html_import: "prompt",
            powerpaste_googledocs_imports: "prompt",
            paste_preprocess: (pluginApi, data: any) => {
              //drag and drop images handler
              if (data?.source === "imagedrop") {
                const newDiv = document.createElement("div");
                newDiv.innerHTML = data.content;
                newDiv.style.maxWidth = editorElementStyle.image.maxWidth;
                data.content = `${newDiv.outerHTML}<p/>`;
              }
            },
            setup: setupFunction,
            spellchecker_language: "en",
            statusbar: false,
            relative_urls: false,
            extended_valid_elements:
              "a[href|target=_blank|style|contenteditable|id|class]",
            keep_styles: false,
            block_formats:
              "Normal text=p; Title=h2; Heading 2=h3; Heading 3=h4;",
          }}
        />
      </RichTextEditorWrapper>
      <EmbedUploadPopup
        showModal={showModal}
        setShowModal={setShowModal}
        showEmbedUrlModal={showEmbedUrlModal}
        setShowEmbedUrlModal={setShowEmbedUrlModal}
        inputElementRef={inputElementRef}
      />
      {showGooglePicker && (
        <GooglePicker
          onClose={onGooglePickerClose}
          onFileSelection={onFileSelection}
        />
      )}
      {showGoogleSlidesEditModal && (
        <GoogleSlideEditModal
          data={googleSlideData}
          onCancel={() => {
            setShowGoogleSlidesEditModal(false);
          }}
          onSyncClick={handleSyncButtonClick}
        />
      )}
    </>
  );
};

export default RichTextEditor1;
