import { useEffect } from "react";

export const replaceSvgImgWithObject = ({
  htmlElement,
  htmlStr = "",
}: {
  htmlElement?: HTMLDivElement | null;
  htmlStr?: string;
}) => {
  // Replaces all embed svg img with object tags
  if (!htmlElement && htmlStr) {
    htmlElement = document.createElement("div");
    htmlElement.innerHTML = htmlStr;
  }
  const svgNodes: NodeListOf<HTMLDivElement> = (
    htmlElement || document
  ).querySelectorAll("[data-is-svg]");
  if (svgNodes?.length) {
    svgNodes?.forEach((imgNode) => {
      const imgEles = imgNode.getElementsByTagName("img");
      const fileUrl = imgEles.length ? imgEles[0].src : null;
      if (fileUrl) {
        imgNode.innerHTML = `<object data="${fileUrl}" type="image/svg+xml"/>`;
      }
      delete imgNode.dataset.isSvg;
    });
  }
  return htmlElement?.innerHTML;
};

const useReplaceSvgImgWithObject = (htmlElement?: HTMLDivElement | null) => {
  // Replaces all embed svg img with object tags
  // This is to support svg animation in firefox
  useEffect(() => {
    replaceSvgImgWithObject({ htmlElement });
  });
};

export default useReplaceSvgImgWithObject;
