import {
  campaignAddEditFormConfig,
  campaignEditFormStructure,
} from "../../../config/campaignConfig";

interface ICampaignFormInitialData {
  emails: object[];
  csv: any;
  name: string;
}

const useAddEditCampaignFormData = (
  editItemId?: string,
  initialFormData?: ICampaignFormInitialData
) => {
  const isEdit = !!editItemId;
  let formData = campaignAddEditFormConfig;
  if (isEdit) {
    const editFormFields = campaignEditFormStructure.flatMap(
      (fieldGroup) => fieldGroup.fields
    );
    formData = {
      ...formData,
      formFields: formData.formFields.filter((fieldObj) =>
        editFormFields.includes(fieldObj.name)
      ),
    };
    //edit form data handling here
  }

  const extraData = formData.formFields.reduce(
    (reducedOptionsObj, formFieldObj) => {
      let initialValue: any = "";
      initialValue = formFieldObj.fieldData?.initialValue;

      return {
        initialValues: {
          ...reducedOptionsObj.initialValues,
          [formFieldObj.name]: initialValue,
        },
        validationSchema: {
          ...reducedOptionsObj.validationSchema,
          [formFieldObj.name]: formFieldObj.validation,
        },
      };
    },
    {
      initialValues: {} as any,
      validationSchema: {} as any,
    }
  );

  extraData.initialValues.emails = initialFormData?.emails;
  extraData.initialValues.csv = initialFormData?.csv;
  extraData.initialValues.name = initialFormData?.name;

  return {
    ...formData,
    ...extraData,
    isEdit,
  };
};

export default useAddEditCampaignFormData;
