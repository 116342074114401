import { FormikFileInputField } from "../components/compositional/Forms";
import { FormikRelParamField } from "../components/compositional/Forms/RelationshipParamField";
import { FormikRelParamWithToggleField } from "../components/compositional/Forms/RelationshipParamField/RelParamWithToggleField";
import { FormikSelectField } from "../components/compositional/Forms/SelectField/SelectField";
import { FormikTextAreaField } from "../components/compositional/Forms/TextAreaField";
import { FormikTextField } from "../components/compositional/Forms/TextField/TextField";
import { FormikTextFieldWithTag } from "../components/compositional/Forms/TextField/TextFieldWithTag";
import { FileInputType, FormFieldType } from "../constants";
import NumberField from "../ui/Form/NumberField";
import SelectField from "../ui/Form/SelectField";
import SwitchField from "../ui/Form/SwitchField";
import TextField from "../ui/Form/TextField";
import TextareaField from "../ui/Form/TextareaField";

export const fieldMapper: any = {
  [FormFieldType.TEXT]: (props: any) =>
    (mapper as any)[FormFieldType.TEXT][props.uiType]?.(props) ||
    mapper[FormFieldType.TEXT]["default"]?.(props),
  [FormFieldType.SELECT]: (props: any) =>
    (mapper as any)[FormFieldType.SELECT][props.uiType]?.(props) ||
    mapper[FormFieldType.SELECT]["default"]?.(props),
  [FormFieldType.ICON_UPLOAD]: (props: any) =>
    (mapper as any)[FormFieldType.ICON_UPLOAD][props.uiType]?.(props) ||
    mapper[FormFieldType.ICON_UPLOAD]["default"]?.(props),
  [FormFieldType.FILE_UPLOAD]: (props: any) =>
    (mapper as any)[FormFieldType.FILE_UPLOAD][props.uiType]?.(props) ||
    mapper[FormFieldType.FILE_UPLOAD]["default"]?.(props),
  [FormFieldType.CSV_FILE_UPLOAD]: (props: any) =>
    (mapper as any)[FormFieldType.CSV_FILE_UPLOAD][props.uiType]?.({
      ...props,
    }) || mapper[FormFieldType.CSV_FILE_UPLOAD]["default"]?.(props),
  [FormFieldType.FILE_SELECT_OR_UPLOAD]: (props: any) =>
    (mapper as any)[FormFieldType.FILE_SELECT_OR_UPLOAD][props.uiType]?.({
      ...props,
    }) || mapper[FormFieldType.FILE_SELECT_OR_UPLOAD]["default"]?.(props),
  [FormFieldType.TEXT_AREA]: (props: any) =>
    (mapper as any)[FormFieldType.TEXT_AREA][props.uiType]?.(props) ||
    mapper[FormFieldType.TEXT_AREA]["default"]?.(props),
  [FormFieldType.TAGS]: (props: any) =>
    (mapper as any)[FormFieldType.TAGS][props.uiType]?.(props) ||
    mapper[FormFieldType.TAGS]["default"]?.(props),
  [FormFieldType.TAGS_WITH_TOGGLE]: (props: any) =>
    (mapper as any)[FormFieldType.TAGS_WITH_TOGGLE][props.uiType]?.({
      ...props,
    }) || mapper[FormFieldType.TAGS_WITH_TOGGLE]["default"]?.(props),
  [FormFieldType.TEXT_WITH_TAG]: (props: any) =>
    (mapper as any)[FormFieldType.TEXT_WITH_TAG][props.uiType]?.(props) ||
    mapper[FormFieldType.TEXT_WITH_TAG]["default"]?.(props),
  [FormFieldType.TOGGLE]: (props: any) =>
    (mapper as any)[FormFieldType.TOGGLE][props.uiType]?.(props) ||
    mapper[FormFieldType.TOGGLE]["default"]?.(props),
  [FormFieldType.NUMBER]: (props: any) =>
    (mapper as any)[FormFieldType.NUMBER][props.uiType]?.(props) ||
    mapper[FormFieldType.NUMBER]["default"]?.(props),
};

const mapper = {
  [FormFieldType.TEXT]: {
    defaultType: "type1",
    type1: (props: any) => <FormikTextField {...props} />,
    type2: (props: any) => <TextField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.SELECT]: {
    defaultType: "type1",
    type1: (props: any) => <FormikSelectField {...props} />,
    type2: (props: any) => <SelectField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.ICON_UPLOAD]: {
    defaultType: "type1",
    type1: (props: any) => (
      <FormikFileInputField type={FileInputType.ICON} {...props} />
    ),
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.FILE_UPLOAD]: {
    defaultType: "type1",
    type1: (props: any) => <FormikFileInputField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.CSV_FILE_UPLOAD]: {
    defaultType: "type1",
    type1: (props: any) => (
      <FormikFileInputField {...props} type={FileInputType.CSV} />
    ),
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.FILE_SELECT_OR_UPLOAD]: {
    defaultType: "type1",
    type1: (props: any) => (
      <FormikFileInputField {...props} type={FileInputType.SELECT_UPLOAD} />
    ),
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.TEXT_AREA]: {
    defaultType: "type1",
    type1: (props: any) => <FormikTextAreaField {...props} />,
    type2: (props: any) => <TextareaField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.TAGS]: {
    defaultType: "type1",
    type1: (props: any) => <FormikRelParamField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.TAGS_WITH_TOGGLE]: {
    defaultType: "type1",
    type1: (props: any) => <FormikRelParamWithToggleField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.TEXT_WITH_TAG]: {
    defaultType: "type1",
    type1: (props: any) => <FormikTextFieldWithTag {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.TOGGLE]: {
    defaultType: "type2",
    type2: (props: any) => <SwitchField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
  [FormFieldType.NUMBER]: {
    defaultType: "type2",
    type2: (props: any) => <NumberField {...props} />,
    get default() {
      return (this as any)[this.defaultType];
    },
  },
};
