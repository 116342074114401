import styled from "@emotion/styled";

export const WidgetWrapper = styled.div`

  width: 14rem;
  height: 4rem;
  background: var(--widget-wrapper);
  margin-bottom: -2.6rem;
  border-radius: 1rem;
  color: white;
`;
