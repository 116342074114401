enum SearchParamsName {
  COMPANY_NAME = "companyName",
  COMPANY_LOGO_URL = "companyLogoUrl",
}

interface IUpdatePageEmbedIframeUrl {
  html: string;
  companyName: string;
  companyLogoUrl: string;
}

export const updatePageEmbedIframeUrl = ({
  html,
  companyName = "",
  companyLogoUrl = "",
}: IUpdatePageEmbedIframeUrl) => {
  const newDiv = document.createElement("div");
  newDiv.innerHTML = html;
  Array.from(newDiv.getElementsByClassName("tiny-pageembed")).forEach(
    (embedElement) => {
      const pageEmbedIframe = embedElement.getElementsByTagName(
        "iframe"
      )[0] as HTMLIFrameElement;
      const iframeUrl = new URL(pageEmbedIframe.src);
      const searchParams = new URLSearchParams(iframeUrl.search);
      if (
        searchParams.get(SearchParamsName.COMPANY_LOGO_URL) &&
        searchParams.get(SearchParamsName.COMPANY_NAME)
      ) {
        searchParams.set(
          SearchParamsName.COMPANY_LOGO_URL,
          `${companyLogoUrl}`
        );
        searchParams.set(SearchParamsName.COMPANY_NAME, `${companyName}`);
        pageEmbedIframe.src = `${
          iframeUrl.origin + iframeUrl.pathname
        }/?${searchParams.toString()}`;
      }
    }
  );
  return newDiv.innerHTML || "";
};
