import { ReactNode } from "react";
import {
  DialogOverlayWrapper,
  DialogContentWrapper,
  ContentMain,
  ModalFooter,
  Cancel,
  ModalHeader,
} from "./styled";

interface ICustomModal {
  renderHeader?: () => ReactNode;
  renderContent?: () => ReactNode;
  renderAction?: () => ReactNode;
  showModal: boolean;
  closeModal: Function;
  showFooter?: boolean;
  width?: string; // in rem
  height?: string;
  showCancel?: boolean;
  marginTop?: string;
  border?: string;
  borderRadius?: string;
  style?: any;
  disableFocusLock?: boolean;
  contentHeight?: string;
  contentStyle?: object;
}

// renderAction is basically a button that specifies the action to be performed by modal
// It can be delete/save or anything that needs to be performed.

const CustomModal = ({
  renderHeader,
  renderContent,
  showModal,
  closeModal,
  renderAction,
  showFooter = false,
  showCancel = true,
  width = "44rem",
  height,
  contentHeight = "auto",
  contentStyle = {},
  style,
  disableFocusLock = false,
}: ICustomModal) => {
  return (
    <DialogOverlayWrapper
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        position: "absolute",
        zIndex: "100",
      }}
      isOpen={showModal}
      onDismiss={() => closeModal()}
      dangerouslyBypassFocusLock={disableFocusLock}
    >
      <DialogContentWrapper
        className="dialog-content"
        aria-label="Login"
        style={{
          boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
          width,
          height: height ? height : "auto",
          overflow: "hidden",
          ...style,
        }}
      >
        <ModalHeader> {renderHeader && renderHeader()}</ModalHeader>
        <ContentMain style={{ ...contentStyle, height: contentHeight }}>
          {renderContent && renderContent()}
        </ContentMain>
        {showFooter && (
          <ModalFooter>
            {showCancel && <Cancel onClick={() => closeModal()}>Cancel</Cancel>}
            {renderAction && renderAction()}
          </ModalFooter>
        )}
      </DialogContentWrapper>
    </DialogOverlayWrapper>
  );
};

export default CustomModal;
