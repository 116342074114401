import { AiOutlinePlusCircle } from "react-icons/ai";
import {
  FiEdit,
  FiTrash2,
  FiEye,
  FiEyeOff,
  FiChevronDown,
} from "react-icons/fi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { TbPencil } from "react-icons/tb";
import { ClipLoader } from "react-spinners";
import {
  AddGuidance,
  DeleteWidget,
  EditWidget,
  WidgetContainer,
  HideAndShow,
  AddToSolutionWidget,
  RemoveFromSolution,
  Divider,
  SolutionEditWidget,
  AccordionButton,
} from "./styled";

export enum WidgetItem {
  ADD_TO_SOLUTION = "add_to_solution",
  EDIT = "edit",
  LIBRARY_AND_SOLUTION_EDIT = "library_and_solution_edit",
  DELETE = "delete",
  ADDGUIDANCE = "add-guidance",
  HIDEANDSHOW = "hideAndShow",
  REMOVE_FROM_SOLUTION = "remove_from_solution",
  VIEW_IN_LIBRARY = "view_in_library",
  CLOSE = "close",
  CANCEL = "cancel",
  SAVE = "save",
  DIVIDER = "divider",
  ACCORDION_BUTTON = "accordion_button",
}

interface IWidgetConfigItem {
  type: WidgetItem;
  onClick: Function;
  hide?: boolean;
  opacity?: string;
  isLoading?: boolean;
  label?: string;
  style?: object;
  className?: string;
}

export interface IWidgetToolbarProps {
  config: IWidgetConfigItem[];
  [x: string]: any;
}

const defaultWidgetMapper = {
  [WidgetItem.EDIT]: (props: any) => (
    <EditWidget {...props}>
      <FiEdit style={{ opacity: props.opacity }} />
    </EditWidget>
  ),
  [WidgetItem.LIBRARY_AND_SOLUTION_EDIT]: (props: any) => (
    <SolutionEditWidget {...props}>
      <TbPencil style={{ opacity: props.opacity }} />
      {props.label && (
        <span {...props.style}>&nbsp;{props.label ?? props.label}</span>
      )}
    </SolutionEditWidget>
  ),
  [WidgetItem.ADD_TO_SOLUTION]: ({ isLoading, ...props }: any) => (
    <AddToSolutionWidget {...props}>
      {isLoading ? (
        <ClipLoader size={16} />
      ) : (
        <>
          <AiOutlinePlusCircle />
          {props.label !== "" && (
            <span
              style={{
                fontSize: "1.5rem",
              }}
            >
              &nbsp;{props.label ? props.label : "Add to pitch"}
            </span>
          )}
        </>
      )}
    </AddToSolutionWidget>
  ),
  [WidgetItem.REMOVE_FROM_SOLUTION]: ({ isLoading, ...props }: any) => (
    <RemoveFromSolution {...props}>
      {isLoading ? (
        <ClipLoader size={16} color="#dc2626" />
      ) : (
        <>
          <FiTrash2 size={18} />
          <span>
            &nbsp;{props.label ? props.label : "Remove from solution"}
          </span>
        </>
      )}
    </RemoveFromSolution>
  ),
  [WidgetItem.DELETE]: (props: any) => (
    <DeleteWidget {...props}>
      <FiTrash2 style={{ opacity: props.opacity }} />
    </DeleteWidget>
  ),
  [WidgetItem.ADDGUIDANCE]: (props: any) => (
    <AddGuidance {...props}>
      <IoMdAddCircleOutline />
    </AddGuidance>
  ),
  [WidgetItem.HIDEANDSHOW]: (props: any) => (
    <HideAndShow {...props}>
      {props.hide ? (
        <FiEye></FiEye>
      ) : (
        <FiEyeOff style={{ opacity: props.opacity }}></FiEyeOff>
      )}
    </HideAndShow>
  ),
  [WidgetItem.DIVIDER]: (props: any) => (
    <Divider {...props}>
      <div className="vertical-divider"></div>
    </Divider>
  ),
  [WidgetItem.ACCORDION_BUTTON]: (props: any) => (
    <AccordionButton {...props}>
      <FiChevronDown></FiChevronDown>
      {props.label && (
        <span {...props.style}>&nbsp;{props.label ?? props.label}</span>
      )}
    </AccordionButton>
  ),
};

export const WidgetToolbar = ({
  config,
  children,
  ...props
}: IWidgetToolbarProps) => {
  const widgetMapper = props.widgetMapper || defaultWidgetMapper;
  return (
    <WidgetContainer {...props}>
      {children}
      {config.map((widgetConfigObj: IWidgetConfigItem, i) => {
        return widgetMapper[widgetConfigObj.type]({
          key: widgetConfigObj.type + i,
          ...widgetConfigObj,
        });
      })}
    </WidgetContainer>
  );
};

export default WidgetToolbar;
