import { Routes, Route } from "react-router-dom";
import Logout from "../../../routes/Logout";
import Term from "../../../routes/Term";
import { HubSpotOauth } from "../../auth";
import SalesforceOauth from "../../auth/Salesforce";
import Privacy from "../../../routes/Condition";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/oauth/hubspot" element={<HubSpotOauth />} />
      <Route path="/oauth/salesforce" element={<SalesforceOauth />} />
      <Route path="/terms" element={<Term />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};

export default PublicRoutes;
