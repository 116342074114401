export const light = {
  name: "light",
  colors: {
    body: "rgb(5, 23, 41)",
    text: "",
    sidebar: {
      background: "#0d38b4",
      icon: "",
      text: "",
      active: "",
    },
    button: {
      text: "",
      background: "#2563eb",
    },
    link: {
      text: "",
    },
  },
  font: "",
};

export const dark = {
  name: "dark",
  colors: {
    body: "#18022D",
    sidebar: {
      background: "#18022D",
      icon: "",
      text: "",
      active: "",
    },
    text: "",
    button: {
      text: "",
      background: "#9A49FF",
    },
    link: {
      text: "",
    },
  },
  font: "",
};
