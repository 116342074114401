import styled from "@emotion/styled";

export const ThumbnailCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin: 0.2rem;
  width: 16rem;
  height: 11rem;
  background: #ffffff;
  border: 0.1rem solid #f1f5f9;
  border-radius: 0.8rem;
  overflow: hidden;
`;

export const ThumbnailImageWrapper = styled.div`
  width: 100%;
  height: 8rem;
  background: #f1f5f9;
  overflow: hidden;
`;

export const ThumbnailImage = styled.img`
  width: 100%;
  height: auto;
  color: #f1f5f9;
`;

export const ThumbnailLabelContainer = styled.div`
  width: 100%;
  padding: 0.8rem;
  user-select: none;
`;

export const ThumbnailLabel = styled.p`
  width: 100%;
`;

export const ThumbnailDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #64748b;
`;
