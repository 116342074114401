import React, { useState } from "react";
import validator from "validator";
import CustomModal from "../CustomModal";
import {
  AnalyticsEmailContainer,
  EmailInput,
  ErrorText,
  PopupText,
  ValidationErrorContainer,
  ViewProposalButton,
} from "./styled";
import { MdOutlineError } from "react-icons/md";
import { ISharableResPublicData } from "../../../../../../interfaces/solution";

interface ISharedViewEmailPopup {
  sharedResourcePublicData: ISharableResPublicData;
  onSubmit: Function;
}

const SharedViewEmailPopup = ({
  sharedResourcePublicData,
  onSubmit,
}: ISharedViewEmailPopup) => {
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");

  const handleViewProposalBtnClick = () => {
    if (!email) {
      setErrorText("Email ID can not be empty.");
      return;
    } else if (!validator.isEmail(email)) {
      setErrorText("Email ID is invalid. Try again.");
      return;
    }
    onSubmit(email);
  };
  const mediaQueryList = window.matchMedia("(max-width: 800px)");

  return (
    <div>
      <CustomModal
        width={`${mediaQueryList.matches ? "90%" : "41.4rem"}`}
        style={{
          marginTop: "16rem",
          borderRadius: "1.2rem",
          minHeight: "33.3rem",
        }}
        showFooter={true}
        showCancel={false}
        showModal={true}
        closeModal={() => {}}
        renderContent={() => (
          <AnalyticsEmailContainer>
            <div>
              {sharedResourcePublicData?.logo && (
                <img src={sharedResourcePublicData.logo} alt="team logo"></img>
              )}
            </div>
            <PopupText>
              Please enter your work email ID to view this pitch
            </PopupText>
            <EmailInput
              isValidEmail={!errorText}
              autoFocus
              type="email"
              placeholder="Enter your work email ID"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorText("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleViewProposalBtnClick();
                }
              }}
            ></EmailInput>
            {errorText && (
              <ValidationErrorContainer>
                <MdOutlineError color="#b91c1c" size={16} />
                <ErrorText>{errorText}</ErrorText>
              </ValidationErrorContainer>
            )}
          </AnalyticsEmailContainer>
        )}
        renderAction={() => (
          <ViewProposalButton onClick={handleViewProposalBtnClick}>
            <span>Open</span>
          </ViewProposalButton>
        )}
      />
    </div>
  );
};

export default SharedViewEmailPopup;
