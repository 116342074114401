import { capitalize } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import useGetSolutionAssets from "../../../../../../api/hooks/useGetSolutionAssets";
import {
  AssetsContext,
  IAssetsContext,
  SolutionsContext,
} from "../../../../../../context";
import { updatePageEmbedIframeUrl } from "../../../../../../helpers/updatePageEmbedIframeUrl";
import {
  ISolutionsState,
  SolutionsAssetActionType,
} from "../../../../../../interfaces";
import {
  ISolutionAsset,
  ISolutionAssetType,
} from "../../../../../../interfaces/solution";
import FallBack from "../../../../Fallback";
import { NotFound } from "../../../../Reusable/components";
import ClearAllFilter from "../../../../Reusable/components/ClearAllFilter";
import VerticalCards from "../../../../Reusable/components/VerticalCards";
import SharedVerticalCards from "../../../../Reusable/components/VerticalCards/SharedVerticalCards";
import {
  WidgetItem,
  WidgetToolbar,
} from "../../../../Reusable/components/WidgetToolbar";
import { NoContentWrapper, AddAssetsBtn, Button } from "../../Solution";
import { SolutionAssetsListContainer } from "./styled";

interface ISolutionAssetsListProps {
  title?: string;
  isShared?: boolean;
  type?: ISolutionAssetType | null;
}

const parseSolutionAssetsData = (
  solutionAssetsListData: ISolutionAsset[],
  solutionsState: ISolutionsState
) => {
  return solutionAssetsListData.map((solutionAsset: ISolutionAsset) => {
    if (solutionsState?.solutionId) {
      solutionAsset.description = updatePageEmbedIframeUrl({
        html: solutionAsset.description_html,
        companyName: solutionsState.solutionObject?.opportunity?.name || "",
        companyLogoUrl: solutionsState.solutionObject?.logo || "",
      });
    }
    return solutionAsset;
  });
};

const SolutionAssetsList = ({
  title,
  isShared = false,
  type,
}: ISolutionAssetsListProps) => {
  const { solutionsState, solutionsAssetState, solutionsAssetDispatch } =
    useContext(SolutionsContext);
  const { showLibraryModal, setShowLibraryModal } =
    useContext<IAssetsContext>(AssetsContext);

  let { data, isLoading } = useGetSolutionAssets({
    solutionId: solutionsState.solutionId || "",
    filters: { type: type ? type.id : solutionsAssetState.assetType?.id },
  });

  const solutionAssetsListData = data?.data;

  const [parsedSolutionAssets, setParsedSolutionAssets] = useState<any>([]);

  const parsedSolutionAssetsData = useMemo(
    () => parseSolutionAssetsData(solutionAssetsListData || [], solutionsState),
    [solutionAssetsListData, solutionsState]
  );
  useEffect(() => {
    setParsedSolutionAssets(parsedSolutionAssetsData);
  }, [parsedSolutionAssetsData]);

  if (isLoading) return <FallBack />;

  const handleCardClick = (e: React.MouseEvent, itemId: string | number) => {
    e.preventDefault();
    solutionsAssetDispatch({
      type: SolutionsAssetActionType.SET_ASSET_ID,
      payload: itemId,
    });
    solutionsAssetDispatch({
      type: SolutionsAssetActionType.SET_SHOW_ASSET_MODAL,
      payload: true,
    });
  };

  const isCreatedFromLibrary = !!solutionsAssetState.assetType?.created_from;

  if (isShared) return <SharedVerticalCards data={parsedSolutionAssets} />;

  return parsedSolutionAssets.length ? (
    <SolutionAssetsListContainer>
      <VerticalCards
        data={parsedSolutionAssets}
        onCardClick={handleCardClick}
        hideToolbarOnBlur={true}
        hideTags={isShared}
        WidgetToolbar={(props: any) => {
          return (
            <>
              <WidgetToolbar
                {...props}
                style={{
                  border: "1px solid #DC2626",
                  padding: "0",
                  alignSelf: "flex-end",
                  visibility: "hidden",
                }}
                config={[
                  {
                    type: WidgetItem.REMOVE_FROM_SOLUTION,
                    label: "Remove",
                    onClick: (e: MouseEvent) => {
                      e.stopPropagation();
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_ASSET_ID,
                        payload: props["data-id"],
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_IS_DELETE,
                        payload: true,
                      });
                    },
                  },
                ]}
              />
              <WidgetToolbar
                {...props}
                style={{
                  padding: "0 0.3rem",
                  alignSelf: "flex-end",
                  visibility: "hidden",
                }}
                config={[
                  {
                    style: { padding: "0.6rem 1rem" },
                    type: WidgetItem.EDIT,
                    onClick: (e: MouseEvent) => {
                      e.stopPropagation();
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_ASSET_ID,
                        payload: props["data-id"],
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_IS_CREATE_EDIT,
                        payload: true,
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_SHOW_ASSET_MODAL,
                        payload: true,
                      });
                    },
                  },
                ]}
              />
            </>
          );
        }}
      />
    </SolutionAssetsListContainer>
  ) : (
    <NoContentWrapper>
      <NotFound
        descriptionText={
          !showLibraryModal
            ? `"${capitalize(title)}" not added. Empty "${capitalize(
                title
              )}" section will not show in the shared buyer view.`
            : "No results found"
        }
      />
      {!showLibraryModal && (
        <div style={{ marginTop: "1rem" }}>
          <Button
            onClick={() => {
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_ASSET_ID,
                payload: "",
              });
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_IS_CREATE_EDIT,
                payload: true,
              });
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_SHOW_ASSET_MODAL,
                payload: true,
              });
            }}
          >
            Create new
          </Button>
          {isCreatedFromLibrary && (
            <AddAssetsBtn
              onClick={() => {
                setShowLibraryModal(true);
              }}
            >
              Add from library
            </AddAssetsBtn>
          )}
        </div>
      )}
      <ClearAllFilter />
    </NoContentWrapper>
  );
};

export default SolutionAssetsList;
