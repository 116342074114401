import { ChangeEvent, useEffect, useState } from "react";
import axiosInstance from "../../api/axios";
import validator from "validator";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { Link, useLocation } from "react-router-dom";
import { history } from "../../utils/history";
import { appConfig } from "../../config";
import googleIconSvg from "../../assets/icons/googleIcon.svg";
import { Button, ValidationErrorText } from "../../components";
import { getLoggedInUser } from "../../helpers/signin";
import appLogo from "../../assets/icons/appLogo.png";

import HeaderBand from "../../components/compositional/UserInfoLayout/components/HeaderBand";
import { trackUserAction } from "../../analytics";
import {
  Bold,
  Divider,
  Input,
  LogoContent,
  SignInContainer,
  SignInContent,
  SignInMechanisms,
  SignInWapper,
  SignInWithAuthButton,
  Terms,
} from "./styled";
import { userActivityEvents } from "../../analytics/userActivityEvents";
import { SIGNIN_METHODS } from "../../api/constants";

const {
  urls: { magicLink: magicLinkUrl, signin: signinUrl },
} = appConfig;

interface ITerms {
  color: string;
}

interface IAuthUrl {
  google: string;
}

const SignIn = () => {
  const { isGoogleAuthEnabled, isSignInWithEmailEnabled } = appConfig;
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [authUrls, setAuthUrls] = useState<IAuthUrl>({
    google: "",
  });

  const [enableGoogleFromQueryParams, setEnableGoogleFromQueryParams] =
    useState(false);

  const initialLoginByEmail = {
    value: "",
    error: "",
    isLoading: false,
  };
  const [loginByEmail, setLoginByEmail] = useState(initialLoginByEmail);

  useEffect(() => {
    urlParams.get("enabled") === SIGNIN_METHODS.GOOGLE &&
      setEnableGoogleFromQueryParams(true);
  }, []);

  useEffect(() => {
    // this is nothing to do with auth - just to ensure that user is redirected to same page if not signed in
    if (!localStorage.getItem("access") && location.pathname !== "/") {
      history.replace("/");
    }
  }, [location]);

  useEffect(() => {
    axiosInstance
      .get(`/auth/login/urls/?app_name=${process.env.REACT_APP_NAME}`)
      .then((res) => {
        if (res.data) {
          setAuthUrls({ google: res.data.google });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Please reload your page");
      });

    return () => {
      setAuthUrls({ google: "" });
    };
  }, []);

  function handleInput(e: ChangeEvent<HTMLInputElement>) {
    const emailId = e.target.value;
    setLoginByEmail((state) => ({
      ...state,
      value: emailId,
      error: "",
    }));
  }

  function handleEmailSubmit(e: any) {
    e.preventDefault();
    setLoginByEmail((state) => ({ ...state, isLoading: true }));
    if (!loginByEmail.value) {
      setLoginByEmail((state) => ({
        ...state,
        error: "Email id cannot be empty",
        isLoading: false,
      }));
      return;
    } else if (!validator.isEmail(loginByEmail.value)) {
      setLoginByEmail((state) => ({
        ...state,
        error: "Enter a valid email id",
        isLoading: false,
      }));
      return;
    }
    trackUserAction(userActivityEvents.SIGNIN__BUTTON_CLICKED, {
      method: SIGNIN_METHODS.MAGIC_LINK,
    });
    axiosInstance
      .post("/auth/magic/login/initiate/", {
        email_id: loginByEmail.value,
        app_name: process.env.REACT_APP_NAME,
      })
      .then((res) => {
        if (res.status === 200) {
          const redirectPath = sessionStorage.getItem("redirectPath") || "";
          localStorage.setItem("redirectPath", redirectPath);
          history.push(magicLinkUrl, { email: `${loginByEmail.value}` });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          trackUserAction(userActivityEvents.SIGNIN__ERROR, {
            status: "UserNotFound",
            error: err,
          });
          return history.push("/login/magic?error=UserNotFound");
        } else {
          console.error(err);
          toast.error("Oops.. Something went wrong. Please try again");
          trackUserAction(userActivityEvents.SIGNIN__ERROR, {
            error: err,
          });
        }
      })
      .finally(() =>
        setLoginByEmail((state) => ({ ...state, isLoading: false }))
      );
  }

  const handleSignInWithGoogleButtonClick = () => {
    trackUserAction(userActivityEvents.SIGNIN__BUTTON_CLICKED, {
      method: SIGNIN_METHODS.GOOGLE,
    });
    history.push(authUrls.google);
  };

  return (
    <>
      {/* <HeaderBand /> */}
      <SignInWapper>
        <SignInContainer>
          <LogoContent>
            <img src={appLogo} className="logo" alt={appConfig.appName} />
          </LogoContent>

          <SignInContent>
            <p className="subtitle">
              We suggest using the email address you use at work
            </p>
            <SignInMechanisms>
              {isGoogleAuthEnabled && (enableGoogleFromQueryParams || true) && (
                <SignInWithAuthButton
                  onClick={handleSignInWithGoogleButtonClick}
                >
                  <img className="icon" src={googleIconSvg} alt="Google icon" />
                  <span>
                    Sign in with <strong>Google</strong>
                  </span>
                </SignInWithAuthButton>
              )}

              {isSignInWithEmailEnabled && (
                <>
                  <Divider>OR</Divider>
                  <form onSubmit={handleEmailSubmit}>
                    <div>
                      <Input
                        value={loginByEmail.value}
                        onChange={handleInput}
                        placeholder="Enter your work email id"
                      />
                      {loginByEmail.error && (
                        <ValidationErrorText>
                          {loginByEmail.error}
                        </ValidationErrorText>
                      )}
                    </div>
                    <Terms color={"#858585"}>
                      By continuing, you're agreeing to our
                      <Bold>
                        <Link target="_blank" to="/terms">
                          Terms of Service
                        </Link>
                      </Bold>
                      and
                      <Bold>
                        <Link target="_blank" to="/privacy">
                          Privacy Policy
                        </Link>
                      </Bold>
                    </Terms>
                    <Button type="submit" className="sign-in-email-btn">
                      <span style={{ paddingRight: "1rem" }}>
                        Sign in with Email
                      </span>
                      {loginByEmail.isLoading && (
                        <ClipLoader color="white" size={20} />
                      )}
                    </Button>
                  </form>
                </>
              )}
            </SignInMechanisms>
          </SignInContent>
        </SignInContainer>
      </SignInWapper>
    </>
  );
};

export default SignIn;
