import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Select";
import { cn } from "../lib/utils";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "./Form";

interface IFormSelectField {
  label: string;
  name: string;
  description?: string;
  required?: boolean;
  form: any;
  [x: string]: any;
}

const SelectField = ({
  label,
  description,
  required,
  name,
  form,
  ...allprops
}: IFormSelectField) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel
              className={cn(
                "text-2xl font-normal text-foreground",
                required && "after:content-['_*']"
              )}
            >
              {label}
            </FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger className="h-14 px-4 py-2 text-xl focus:border-purple-600 focus:shadow-purple focus:ring-0 rounded-lg">
                  <SelectValue placeholder={allprops.placeholder} />
                </SelectTrigger>
              </FormControl>
              <SelectContent className="border border-solid border-gray-200 drop-shadow-md p-0 m-0 rounded-lg">
                {allprops.options?.map(
                  (option: { label: string; value: string }) => (
                    <SelectItem
                      key={option.value}
                      value={`${option.value}`}
                      className="text-xl rounded-md py-2 pl-2.5 pr-8"
                    >
                      {option.label}
                    </SelectItem>
                  )
                )}
              </SelectContent>
            </Select>
            <FormMessage className="text-lg font-light" />
          </FormItem>
        );
      }}
    />
  );
};

export default SelectField;
