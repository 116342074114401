import { FieldInputProps, FieldMetaProps, useField } from "formik";
import { useEffect, useRef, useState } from "react";
import { FormErrorText } from "../common";
import { TextFieldWrapper } from "./styled";
import { cn } from "../../../../ui/lib/utils";

interface IFormTextField {
  label: string;
  name: string;
  formikField?: FieldInputProps<any>;
  formikMeta?: FieldMetaProps<any>;
  className?: string;
  focusedClassName?: string;
  [x: string]: any;
}

const FormTextField = ({
  label,
  className,
  focusedClassName,
  ...allProps
}: IFormTextField) => {
  // TODO: To come back later and make the change to the wrapper styling
  const [isFocused, setFocused] = useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);

  const { formikField, formikMeta, required: isRequired, ...props } = allProps;

  useEffect(() => {
    inputElementRef.current?.addEventListener("focusin", () =>
      setFocused(true)
    );
    inputElementRef.current?.addEventListener("focusout", () =>
      setFocused(false)
    );
  }, []);

  return (
    <TextFieldWrapper
      className={cn(className, isFocused ? focusedClassName : "")}
      isFocused={isFocused}
      isRequired={label ? isRequired : false}
    >
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <input
        ref={inputElementRef}
        type="text"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        {...formikField}
        {...{ value: formikField?.value || "" }}
        {...props}
      ></input>
      <FormErrorText formikMeta={formikMeta}></FormErrorText>
    </TextFieldWrapper>
  );
};

const FormikTextField = (props: IFormTextField) => {
  const [field, meta] = useField(props);
  return (
    <FormTextField
      formikField={field}
      formikMeta={meta}
      {...props}
    ></FormTextField>
  );
};

export { FormTextField, FormikTextField };
