import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../../../hooks";
import arrowDown from "../../../../../assets/icons/downArrow.svg";
import filterSearch from "../../../../../assets/icons/filterSearch.svg";
import {
  DropdownLabel,
  DropdownList,
  DropdownListItem,
  DropdownListWrapper,
  DropdownSearch,
  DropdownSelectedItem,
  DropdownViewable,
  DropdownWrapper,
  ListItemCheckbox,
  ListItemLabel,
  ListItemWrapper,
} from "./styled";
import { noop } from "lodash";
import { RemoveSearchText } from "../../../Reusable/components/SearchBar/styled";
import { IoMdClose } from "react-icons/io";
import { IDropdownCollectionItem } from "../../../../../interfaces/dropdown";
interface IDisplayDropdownList {
  list: IDropdownCollectionItem[];
  handleCheckboxSelection: Function;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
function DisplayDropdownList({
  list,
  handleCheckboxSelection,
  setIsOpen,
}: IDisplayDropdownList) {
  return (
    <DropdownList>
      {list?.map((item, i) => {
        return (
          <DropdownListItem
            key={i}
            onClick={() => {
              handleCheckboxSelection(item);
              setIsOpen(false);
            }}
          >
            <ListItemWrapper>
              <ListItemCheckbox type="radio" onChange={noop} />
              <ListItemLabel>{item.label}</ListItemLabel>
            </ListItemWrapper>
          </DropdownListItem>
        );
      })}
    </DropdownList>
  );
}

interface IDropdownWithTemplates {
  listOfItemTobeDisplayed: IDropdownCollectionItem[] | undefined;
  stateOfDropdowns: any;
  setStateOfDropdowns: any;
  label: string;
  background: any;
  color: string;
  selectColor: string;
  dropDownBgColor: any;
}

const DropdownWithTemplates = ({
  label,
  listOfItemTobeDisplayed,
  stateOfDropdowns,
  setStateOfDropdowns,
  background,
  color,
  selectColor,
  dropDownBgColor,
}: IDropdownWithTemplates) => {

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));
  const currentStateOfDropdowns = { ...stateOfDropdowns };

  useEffect(() => {
    if (listOfItemTobeDisplayed) {
      const selectedTemplate = listOfItemTobeDisplayed.some(
        (item: any) => item.isSelected
      );
      if (!selectedTemplate) {
        const newSuggested = [...listOfItemTobeDisplayed];
        const newSelected = newSuggested.splice(0, 1);
        setStateOfDropdowns((state: any) => {
          return {
            ...state,
            suggested: newSuggested,
            selected: newSelected,
          };
        });
      } else {
        const newSuggested = listOfItemTobeDisplayed.filter(
          (item: any) => !item.isSelected
        );
        const newSelected = listOfItemTobeDisplayed.filter(
          (item: any) => item.isSelected
        );
        setStateOfDropdowns((state: any) => {
          return {
            ...state,
            suggested: newSuggested,
            selected: newSelected,
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    if (listOfItemTobeDisplayed) {
      const newSuggested = listOfItemTobeDisplayed.filter(
        (listItem: any) =>
          !currentStateOfDropdowns.selected.some(
            (item: any) => listItem.value === item.value
          )
      );
      setStateOfDropdowns((state: any) => {
        return {
          ...state,
          suggested: newSuggested.filter((listItem: any) =>
            listItem.label
              .toLowerCase()
              .includes(stateOfDropdowns?.search.toLowerCase())
          ),
        };
      });
    }
  }, [currentStateOfDropdowns?.search, currentStateOfDropdowns?.selected]);

  const handleCheckboxSelection = (item: any) => {
    item.isSelected = true;
    if (listOfItemTobeDisplayed) {
      const suggested = listOfItemTobeDisplayed.filter(
        (value: any) => value.value !== item.value
      );
      suggested.sort((a: any, b: any) => a.value - b.value);
      setStateOfDropdowns((state: any) => {
        return {
          ...state,
          selected: [item],
          suggested: suggested.filter((listItem: any) =>
            listItem.label
              .toLowerCase()
              .includes(stateOfDropdowns?.search.toLowerCase())
          ),
        };
      });
    }
  };

  return (
    <DropdownWrapper ref={dropdownRef} background={background}>
      <DropdownViewable
        className="flex space-between items-center"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>
          <DropdownLabel color={color}>{label}</DropdownLabel>
          <DropdownSelectedItem selectColor={selectColor}>
            <div className="selected-item-label">
              {!!stateOfDropdowns.selected.length
                ? stateOfDropdowns.selected[0]?.label
                : stateOfDropdowns.suggested[0]?.label}
            </div>
          </DropdownSelectedItem>
        </div>
        <img src={arrowDown} alt="down arrow" />
      </DropdownViewable>

      <DropdownListWrapper
        dropDownBgColor={dropDownBgColor}
        //@ts-ignore
        isOpen={isOpen}
      >
        <h3 className="title">Select {label}</h3>
        <div className="search-box">
          <img className="filter-search-icon" src={filterSearch} alt="Search" />
          <DropdownSearch
            value={stateOfDropdowns.search}
            onChange={(e) => {
              setStateOfDropdowns((state: any) => {
                return { ...state, search: e.target.value };
              });
            }}
            placeholder={`Filter by ${label}`}
          />
          <RemoveSearchText
            onClick={() => {
              setStateOfDropdowns((state: any) => {
                return { ...state, search: "" };
              });
            }}
            className={`${!!stateOfDropdowns.search ? "show-cross" : ""}`}
          >
            <IoMdClose className="close-icon" />
          </RemoveSearchText>
        </div>

        <div className="dropdown-list">
          <DisplayDropdownList
            list={stateOfDropdowns.selected}
            handleCheckboxSelection={handleCheckboxSelection}
            setIsOpen={setIsOpen}
          />
          <DisplayDropdownList
            list={stateOfDropdowns.suggested}
            handleCheckboxSelection={handleCheckboxSelection}
            setIsOpen={setIsOpen}
          />
        </div>
      </DropdownListWrapper>
    </DropdownWrapper>
  );
};

export default DropdownWithTemplates;
