import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { appConfig,apiConfig } from "../../config";
import { setIsSignUpToFalse } from "../../helpers";
import {
  ICreateInvitationVariables,
  TCreateInvitationData,
} from "../../interfaces";
import { IInvitationsModalData } from "../../interfaces";
import { Dispatch, SetStateAction } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { history } from "../../utils/history";
const { userUpdationPages, pagesWithCenteredToastContainer } = appConfig;
const { urls: {invitation:invitationApiUrl}} = apiConfig

async function createInvitation(invitationData: IInvitationsModalData[]) {
  try {
    return await axiosInstance
      .post(invitationApiUrl, {
        invitees: invitationData,
      })
      .then((res) => res.data);
  } catch (err) {
    console.log(err);
    toast.error("Oops, something went wrong. Please try again.");
  }
}

interface IUseCreateInvitation {
  setTeamMemberModalState?: Dispatch<SetStateAction<IInvitationsModalData>>;
  setShowInviteModal?: Dispatch<SetStateAction<boolean>>;
  initialTeamMemberModalState?: IInvitationsModalData;
}

// Used in signup user invitations and also in settings section for invitation
function useCreateInvitation({
  setTeamMemberModalState,
  setShowInviteModal,
  initialTeamMemberModalState,
}: IUseCreateInvitation) {
  const queryClient = useQueryClient();
  const location = useLocation();
  const mutationResponse = useMutation<
    TCreateInvitationData,
    Error,
    ICreateInvitationVariables,
    unknown
  >(
    queryKeys.createInvitations,
    ({ invitationData }): Promise<TCreateInvitationData> =>
      createInvitation(invitationData),
    {
      onError: () => {
        toast.error("Oops, something went wrong. Please try again.");
      },
      onSuccess: (data, variables) => {
        // In case of sign_up is true(Invitation on Joining if is an owner)
        if (userUpdationPages.includes(location.pathname)) {
          // Specific to sign up based invitation
          setIsSignUpToFalse();
          history.push("/");
        }
        // Incase of settings page invitation
        if (pagesWithCenteredToastContainer.includes(location.pathname)) {
          const {
            already_invited_email_ids,
            already_joined_email_ids,
            new_invitations,
          } = data;
          const { invitationData } = variables;
          const { first_name } = invitationData[0];
          already_invited_email_ids.length &&
            toast.info(`${first_name} already been invited`);
          already_joined_email_ids.length &&
            toast.info(`${first_name} has already joined`);

          new_invitations?.length &&
            toast.success(
              `Invitation has been sent to ${first_name} successfully`
            );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.invitations]);
        setTeamMemberModalState &&
          initialTeamMemberModalState &&
          setTeamMemberModalState(initialTeamMemberModalState);
        setShowInviteModal && setShowInviteModal(false);
      },
    }
  );

  return { ...mutationResponse };
}

export default useCreateInvitation;
