import { Switch } from "../Switch";
import { cn } from "../lib/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "./Form";

interface IFormSwitchField {
  label: string;
  name: string;
  description?: string;
  required?: boolean;
  form: any;
  [x: string]: any;
}

const SwitchField = ({
  label,
  description,
  required,
  name,
  form,
  ...allprops
}: IFormSwitchField) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-row items-center justify-between rounded-lg border border-solid border-gray-200 p-4 ">
          <div className="space-y-0.5">
            <FormLabel
              className={cn(
                "text-2xl font-normal text-foreground",
                required && "after:content-['_*']"
              )}
            >
              {label}
            </FormLabel>
            {description && (
              <FormDescription className="text-xl font-light text-gray-500">
                {description}
              </FormDescription>
            )}
          </div>
          <FormControl>
            <Switch checked={field.value} onCheckedChange={field.onChange} />
          </FormControl>
        </FormItem>
      )}
    />
  );
};

export default SwitchField;
