import React, { createContext, useState } from "react";

export interface ISettingsContext {
  isAdd: boolean;
  isAddParams: boolean;
  showInputTag: any;
  defaultTabSlugSettings: string;
  solutionParamsData: string;
  viewItem: boolean;
  isActiveTabSlugSetting:string;
  deleteSolutionParams:any;
  deleteRelationshipParams:any;
  relationShipParamsData:string;
  setSolutionParamsData: React.Dispatch<React.SetStateAction<string>>;
  setRelationShipParamsData:React.Dispatch<React.SetStateAction<string>>
  setIsAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddParams: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInputTag: React.Dispatch<React.SetStateAction<any>>;
  setViewItem: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActiveTabSlugSetting: React.Dispatch<React.SetStateAction<string>>;
  setDeleteSolutionParams:React.Dispatch<React.SetStateAction<any>>
  setDeleteRelationshipParams: React.Dispatch<React.SetStateAction<any>>;
}

export const SettingsContext = createContext({
  isAdd: false,
  defaultTabSlugSettings: "solutions_parameters",
} as ISettingsContext);

export const SettingsContextProvider = ({
  children,
  defaultTabSlugSettings,
}: {
  children: React.ReactNode;
  defaultTabSlugSettings: string;
}) => {
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isActiveTabSlugSetting, setIsActiveTabSlugSetting] = useState<string>(
    defaultTabSlugSettings
  );
  const [solutionParamsData,setSolutionParamsData]=useState<string>('')
  const [relationShipParamsData,setRelationShipParamsData]=useState<string>('')
  const [viewItem, setViewItem] = useState<boolean>(true);
  const [isAddParams, setIsAddParams] = useState<boolean>(false);
  const [showInputTag, setShowInputTag] = useState<any>({
    isShow:false,
    isShowUpdate:false,
    errorMessage:"",
    tagWidgetShow:true,
  });
  const [deleteSolutionParams,setDeleteSolutionParams]=useState<any>({
    isDelete: false,
    solutionParamsId: "",
  })
  const [deleteRelationshipParams,setDeleteRelationshipParams] =useState({
    isDelete:false,
    deleteId: ""
  })
  return (
    <SettingsContext.Provider
      value={{
        defaultTabSlugSettings,
        solutionParamsData,
        setSolutionParamsData,
        isActiveTabSlugSetting,
        setIsActiveTabSlugSetting,
        isAdd,
        setIsAdd,
        setViewItem,
        viewItem,
        isAddParams,
        setIsAddParams,
        deleteSolutionParams,
        setDeleteSolutionParams,
        showInputTag,
        setShowInputTag,
        deleteRelationshipParams,
        setDeleteRelationshipParams,
        relationShipParamsData,
        setRelationShipParamsData
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
