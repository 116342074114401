import validator from "validator";
import { fileTypeIconMap } from "../api/constants";
import { getLoggedInUser } from "./signin";

export * from "./auth";
export * from "./dropdown";
export * from "./opportunities";
export * from "./signin";

export const getFileTypeIconFromUrl = (url: string) => {
  const pathname = validator.isURL(url) ? new URL(url).pathname : url;
  const fileTypeMatches = pathname.match(/\.[0-9a-z]+$/i) || [];
  const fileType = fileTypeMatches.length ? fileTypeMatches[0].slice(1) : "";
  const fileTypeIcon = (fileTypeIconMap as any)[fileType];
  return fileTypeIcon || "";
};

export const getFileNameFromUrl = (
  url: string,
  forDisplay: boolean = false
) => {
  if (!url || url.indexOf("/") === -1) return url || "";
  const pathname = validator.isURL(url) ? new URL(url).pathname : url;
  const fileName = pathname.split("/").pop() || "";

  if (fileName.length < 30 || !forDisplay) {
    return fileName;
  } else {
    return `${fileName.slice(0, 13)}...${fileName.slice(-15)}`;
  }
};

export const getAbsoluteFileUrlFromPath = (path: string) => {
  if (!path) return "";
  if (validator.isURL(path) || path.includes("blob")) {
    return path;
  }
  return `${process.env.REACT_APP_API_BASE_URL?.replace(
    "/api/web",
    ""
  )}/${path}`;
};

export const getUserInitialsOfLoggedInUserForThumb = () => {
  const userProfile = getLoggedInUser()?.user.profile;
  const displayName = userProfile?.display_name;
  const firstName = userProfile?.first_name || "";
  const initialsString = displayName
    ?.split(" ")
    .reduce((initialsString, part) => {
      if (!part) return initialsString + part[0];
      return initialsString + part[0];
    }, "");
  return (initialsString || firstName)?.slice(0, 2).toUpperCase();
};
