import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../axios";
import { IMutationState } from "../../interfaces";
import { apiConfig } from "../../config";

interface ICampaignMutationObject {
  id?: number;
  name?: string;
  emails?: string[];
}

const createCampaign = async (postData: ICampaignMutationObject) => {
  const apiEndpoint = `${apiConfig.urls.campaigns}
  `;
  return await axiosInstance.post(apiEndpoint, postData);
};

const updateCampaign = async ({
  id,
  ...updateData
}: ICampaignMutationObject) => {
  const apiEndpoint = `${apiConfig.urls.campaigns + id}/`;
  return await axiosInstance.patch(apiEndpoint, updateData);
};

const useCreateUpdateCampaign = () => {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSettled: (data: any, error: any, variables: any, context: any) => {},
  };
  const mutationFunction = (mutationData: ICampaignMutationObject) => {
    return mutationData.id
      ? updateCampaign(mutationData)
      : createCampaign(mutationData);
  };
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );
  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);
  return { mutate, ...mutationState };
};

export default useCreateUpdateCampaign;
