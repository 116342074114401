import { isNull, noop } from "lodash";
import { useContext } from "react";
import { HiPlusSm } from "react-icons/hi";
import {
  AssetsContext,
  AuthContext,
  SolutionsContext,
} from "../../../../../../../context";
import { SolutionsAssetActionType } from "../../../../../../../interfaces/context/solutionsContext";
import { ISolutionAssetType } from "../../../../../../../interfaces/solution";
import {
  AddFromLibrary,
  CardHeader,
  CreateNewButton,
  HeaderButtonContainer,
  HeaderTitle,
} from "./styled";
import { SolutionDetailsContext } from "../../../../../../../context/SolutionDetailsContext";
import { useTheme } from "@emotion/react";
import { ITheme } from "../../../../../../../interfaces/theme";

interface ISolutionAssetsCardHeader {
  assetType: ISolutionAssetType | null;
  title: string;
  handleTabClick?: Function;
}

const SolutionAssetsCardHeader = ({
  assetType,
  title = "",
  handleTabClick = noop,
}: ISolutionAssetsCardHeader) => {
  const theme: ITheme = useTheme();
  const {
    solutionsAssetDispatch,
    solutionsState: { solutionObject },
  } = useContext(SolutionsContext);
  const { setShowLibraryModal } = useContext(AssetsContext);
  const { authState } = useContext(AuthContext);
  const { solutionAssetTypes } = useContext(SolutionDetailsContext);

  const hasSolutionDoc = !isNull(solutionObject?.solution_doc_html);

  const activeAssetsTypeIndex = solutionAssetTypes?.findIndex(
    (solutionAssetType: ISolutionAssetType) =>
      solutionAssetType.id === assetType?.id
  );

  const isCreatedFromLibrary = !!assetType?.created_from;
  const isShared = authState.isGuestUser;

  return (
    <CardHeader isShared={isShared} className="SolutionAssetCardHeader__header">
      <HeaderTitle
        isShared={isShared}
        className="SolutionAssetCardHeader__title"
        style={
          isShared
            ? assetType?.order === 1
              ? {
                  ...theme?.sharedView?.style?.body?.content?.section?.title,
                  ...theme?.sharedView?.style?.body?.content?.section1?.title,
                }
              : theme?.sharedView?.style?.body?.content?.section?.title
            : {}
        }
      >
        {title || assetType?.name}
      </HeaderTitle>
      {!isShared && (
        <HeaderButtonContainer>
          <CreateNewButton
            onClick={() => {
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_ASSET_TYPE,
                payload: assetType,
              });
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_ASSET_ID,
                payload: "",
              });
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_IS_CREATE_EDIT,
                payload: true,
              });
              solutionsAssetDispatch({
                type: SolutionsAssetActionType.SET_SHOW_ASSET_MODAL,
                payload: true,
              });
              if (activeAssetsTypeIndex > -1)
                handleTabClick(
                  activeAssetsTypeIndex + (hasSolutionDoc ? 1 : 0)
                );
            }}
          >
            <HiPlusSm size={20} />
            <span>Create new</span>
          </CreateNewButton>
          {isCreatedFromLibrary && (
            <AddFromLibrary
              onClick={() => {
                solutionsAssetDispatch({
                  type: SolutionsAssetActionType.SET_ASSET_TYPE,
                  payload: assetType,
                });
                setShowLibraryModal(true);
                if (activeAssetsTypeIndex > -1)
                  handleTabClick(
                    activeAssetsTypeIndex + (hasSolutionDoc ? 1 : 0)
                  );
              }}
            >
              <span>Add from library</span>
            </AddFromLibrary>
          )}
        </HeaderButtonContainer>
      )}
    </CardHeader>
  );
};

export default SolutionAssetsCardHeader;
