import {
  ValidationErrorText,
  InputGroup,
  InputLabel,
} from "../../../../styled";
import { SettingsDropdown } from "../../../Dropdown";
import { appConfig } from "../../../../../config";
import { IOption } from "../../../../../interfaces";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Header,
  Layout,
  GatherUserInfoTitle,
  Main,
  Input,
  Button,
  Step,
  CustomizeAccountForm,
} from "../common";
import jwtDecode from "jwt-decode";
import { useUpdateUserDetails, useGetSettings } from "../../../../../api/hooks";
import { getLoggedInUser } from "../../../../../helpers";
import { ClipLoader } from "react-spinners";
import { ICustomizeYourAccountState } from "../../../../../interfaces/UserInfoLayout";
import {
  getDefaultTimeZone,
  handleChangeUserInfo,
  handleUpdateUserDetails,
} from "../../../../../helpers";

import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import HeaderBand from "../HeaderBand";
import { trackUserAction } from "../../../../../analytics";

const { userUpdationPages } = appConfig;

const GatherUserAccountDetails = () => {
  // Todo: To be cleaned up as data can be taken from loggedInUser stored in localStorage and one being used below
  const token = localStorage.getItem("access");
  const location = useLocation();
  const userInfo: any = token ? jwtDecode(token) : null;
  const userId: string = userInfo ? userInfo.user_id.toString() : ""; //This route will always have userId(only when logged in this route would be opened), but for ts need to keep empty

  const { data: settingsData } = useGetSettings();

  // const { data: timeAndTimeZoneSettingsData } = useGetTimeAndTimezoneSettings();
  // const settings = timeAndTimeZoneSettingsData?.user_settings;
  const initialCustomizeYourAccountState = {
    userTimezone: "",
    first_name: "",
    company: "",
    errors: {
      first_name: "",
      company: "",
    },
  };
  const [customizeYourAccountState, setCustomizeYourAccountState] =
    useState<ICustomizeYourAccountState>(initialCustomizeYourAccountState);

  const loggedInUser = getLoggedInUser();

  const {
    mutate: updateUserDetailsMutation,
    // isSuccess: isUserUpdationSuccessful,
    isLoading: isUserUpdationInProgress,
  } = useUpdateUserDetails({});

  useEffect(() => {
    setCustomizeYourAccountState((state) => ({
      ...state,
      userTimezone: getDefaultTimeZone(settingsData).value,
    }));
  }, [settingsData]);

  useEffect(() => {
    const loggedInUser = getLoggedInUser();
    if (loggedInUser) {
      setCustomizeYourAccountState((state) => ({
        ...state,
        first_name: loggedInUser.user.profile.display_name,
      }));
    }
  }, []);

  const timeZoneList =
    settingsData?.timezones?.map((timeZone) => ({
      label: timeZone.label,
      value: timeZone.value,
      offset: timeZone.offset,
    })) || [];

  const isOwner = !!loggedInUser?.user?.is_owner;
  const { first_name, company } = customizeYourAccountState;
  const isDisabled =
    (!isOwner && !first_name) || (isOwner && (!first_name || !company));

  return (
    <>
      <Layout>
      <HeaderBand />

        <Main>
          <GatherUserInfoTitle>
            Let’s customize your account
          </GatherUserInfoTitle>
          <Step>Step 1 of 2</Step>
          <CustomizeAccountForm
            onSubmit={(e) =>
              handleUpdateUserDetails({
                e,
                isOwner,
                updateUserDetailsMutation,
                setCustomizeYourAccountState,
                customizeYourAccountState,
                userId,
              })
            }
          >
            <InputGroup className="input-group">
              <InputLabel className="input-label">Your Name</InputLabel>
              <Input
                name="first_name"
                placeholder="Your name"
                value={customizeYourAccountState.first_name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeUserInfo(e, setCustomizeYourAccountState)
                }
              />
              {customizeYourAccountState.errors.first_name && (
                <ValidationErrorText>
                  {customizeYourAccountState.errors.first_name}
                </ValidationErrorText>
              )}
            </InputGroup>
            {loggedInUser?.user?.is_owner && (
              <InputGroup className="input-group">
                <InputLabel className="input-label">
                  Company or Team Name
                </InputLabel>
                <Input
                  name="company"
                  placeholder="Name of your company or team"
                  value={customizeYourAccountState.company}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChangeUserInfo(e, setCustomizeYourAccountState)
                  }
                />
                {customizeYourAccountState.errors.company && (
                  <ValidationErrorText>
                    {customizeYourAccountState.errors.company}
                  </ValidationErrorText>
                )}
              </InputGroup>
            )}
            {(settingsData?.user_settings &&
              <InputGroup className="input-group settings-dropdown-input-group">
                <InputLabel className="input-label">
                  Preferred Timezone
                </InputLabel>
                <SettingsDropdown
                  title="timezone"
                  width={38.4}
                  itemsToDisplay={timeZoneList}
                  value={customizeYourAccountState.userTimezone}
                  handleChange={(selected: IOption) => {
                    setCustomizeYourAccountState((state) => ({
                      ...state,
                      userTimezone: selected.value,
                    }));
                  }}
                />
                <Button
                  type="submit"
                  className="customize-account-next-button"
                  style={
                    isDisabled
                      ? {}
                      : {
                          backgroundColor:  "var(--primary-blue)",
                          border: "1px solid  var(--primary-blue)",
                          color: "white",
                        }
                  }
                  onClick={() => {
                    const user = loggedInUser?.user
                    trackUserAction('submit_button',{
                      action: "click",
                      category: "customize_account",
                      label: "next",
                      user: user?.profile?.first_name,
                      userId: user?.id
                      })
                    }
                  }
                >
                  <span style={{ marginRight: "1rem" }}>Next</span>
                  {isUserUpdationInProgress && (
                    <ClipLoader color="white" size={20} />
                  )}
                </Button>
              </InputGroup>
            )}
          </CustomizeAccountForm>
        </Main>
      </Layout>
      {userUpdationPages.includes(location.pathname) && (
        <ToastContainer
          position="top-center"
          autoClose={7000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </>
  );
};

export default GatherUserAccountDetails;
