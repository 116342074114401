import { kebabCase } from "lodash";
import noop from "lodash/noop";
import { TabWrapper, TabEndComponent, TabLabel } from "./styled";

export interface ITab {
  label: string;
  onClick?: (index: any) => void;
  isSelected?: boolean;
  EndComponent?: () => React.ReactNode;
  ExtraComponent?: () => React.ReactNode;
}

const Tab = ({
  label = "",
  isSelected = false,
  onClick = noop,
  EndComponent,
  ExtraComponent,
}: ITab) => {
  if (!label && !ExtraComponent) return <></>;
  return (
    <>
      {label && (
        <TabWrapper
          onClick={onClick}
          className={isSelected ? "active" : ""}
          id={kebabCase(label.toLowerCase())}
        >
          <TabLabel>{label}</TabLabel>
          <TabEndComponent>{EndComponent?.()}</TabEndComponent>
        </TabWrapper>
      )}
      {ExtraComponent?.()}
    </>
  );
};

export default Tab;
