import {
  IIntegrationState,
  IntegrationActionType,
  TIntegrationReducerAction,
} from "../interfaces/context/integrationContext";

export function integrationReducer(
  state: IIntegrationState,
  action: TIntegrationReducerAction
) {
  switch (action.type) {
    case IntegrationActionType.SET_ACTIVE_APP_CARD_DATA:
      return { ...state, appData: action.payload };
    case IntegrationActionType.REMOVE_ACTIVE_APP_CARD_DATA:
      return { ...state, appData: undefined };
    default:
      return { ...state };
  }
}
