import { memo } from "react";
import { FilterTag, IconWrapper, TagHeading } from "../../FilterTags/styled";
import cross from "../../../../../../assets/icons/cross.svg";

interface IRelParamTagData {
  label: string;
  value: any;
}

const RelParamTag = memo<{
  tag: IRelParamTagData;
  onClick: (tag: IRelParamTagData) => void;
}>(({ tag, onClick }) => {
  return (
    <FilterTag
      key={tag.value}
      style={{ backgroundColor: "rgb(241, 245, 249)" }}
    >
      <TagHeading>{tag.label} </TagHeading>
      <IconWrapper>
        <img onClick={() => onClick(tag)} src={cross} alt="X" />
      </IconWrapper>
    </FilterTag>
  );
});

export default RelParamTag;
