import { useField } from "formik";
import { useEffect, useRef, useState } from "react";
import { IRelationshipParamerter } from "../../../../interfaces";
import { Tag } from "../../Reusable/components/Tags";
import { FormErrorText } from "../common";
import { FormTagContainer } from "../styled";
import { IFormFileInputField } from "./interfaces";
import RelParamTagCards from "./RelParamTagCards";
import { RelParamsFieldWrapper } from "./styled";

const FormRelParamField = ({ label, ...allProps }: IFormFileInputField) => {
  const [isFocused, setFocused] = useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);

  const {
    formikField,
    formikMeta,
    formikHelpers,
    required: isRequired,
    ...props
  } = allProps;

  useEffect(() => {
    inputElementRef.current?.addEventListener("focusin", () =>
      setFocused(true)
    );
    inputElementRef.current?.addEventListener("focusout", () =>
      setFocused(false)
    );
  });

  const handleTagClick = (e: MouseEvent, tagId: string | number) => {
    formikHelpers?.setValue(
      formikField?.value.filter(
        (tagObj: IRelationshipParamerter) => tagObj.id !== tagId
      )
    );
  };

  return (
    <RelParamsFieldWrapper isFocused={isFocused} isRequired={isRequired}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <FormTagContainer>
        {formikField?.value?.length ? (
          formikField?.value.map((relParamObj: any) => (
            <Tag
              key={relParamObj.id}
              label={relParamObj.value}
              toolTipText={relParamObj.name}
              onClick={(e: MouseEvent) => handleTagClick(e, relParamObj.id)}
            />
          ))
        ) : (
          <div className="placeholder-text">
            <Tag label="Select appropriate buyer segmentation tags from the options below." />
            <Tag label="Tagging enables pitch components to be auto added when a new pitch with similar buyer segmentation is created." />
          </div>
        )}
      </FormTagContainer>
      <RelParamTagCards
        items={formikField?.value}
        formikHelpers={formikHelpers}
      ></RelParamTagCards>
      {/* <div className="extra-text">(Use comma to separate tags)</div> */}
      <FormErrorText formikMeta={formikMeta}></FormErrorText>
    </RelParamsFieldWrapper>
  );
};

const FormikRelParamField = (props: IFormFileInputField) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormRelParamField
      formikField={field}
      formikMeta={meta}
      formikHelpers={helpers}
      {...props}
    ></FormRelParamField>
  );
};

export { FormRelParamField, FormikRelParamField };
