import { noop } from "lodash";
import {
  TablePropGetter,
  TableProps,
  TableBodyPropGetter,
  TableBodyProps,
  HeaderGroup,
  Row,
} from "react-table";
import { appConfig } from "../../../../../config";
import { IFieldStructure } from "../../../../../interfaces/opportunities";
import { TableWrapper } from "../../../../styled";
import { NotFound } from "../../../Reusable/components";
import { TableHeading } from "./styled";
import { HiChevronUpDown } from "react-icons/hi2";
import { useState } from "react";
const {
  pageSpecificConfigs: {
    allOpportunities: { defaultWidth },
  },
} = appConfig;

interface ITable {
  getTableProps: (propGetter?: TablePropGetter<any> | undefined) => TableProps;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<any> | undefined
  ) => TableBodyProps;
  headerGroups: HeaderGroup<any>[];
  rows: Row<any>[];
  prepareRow: (row: Row<any>) => void;
  tableWidth?: string;
  fields: IFieldStructure[];
  handleTableRowClicked?: (id: any) => void;
  handleColumnSorting?: (columnField: string | undefined) => void;
  activeRowBackgroundColor?: string;
}

const Table = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  tableWidth,
  fields,
  handleTableRowClicked = noop,
  handleColumnSorting = noop,
  activeRowBackgroundColor = "#eff6ff",
}: ITable) => {
  const [activeRowId, setActiveRowId] = useState("");
  const tableWidthFactor = fields.reduce(
    (columnWidthFactorSum: number, field) =>
      columnWidthFactorSum + (field.column_width || 1),
    0
  );
  tableWidth = tableWidth || `${tableWidthFactor * defaultWidth}rem`;

  return (
    <TableWrapper className="table-wrapper hide-scrollbar">
      {!rows.length ? (
        <div style={{ padding: "5rem 0" }}>
          <NotFound descriptionText="No results found" />
        </div>
      ) : (
        <table
          {...getTableProps()}
          style={{
            overflowX: "auto",
            width: tableWidth,
          }}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index) => {
                    return (
                      <TableHeading
                        id={fields[index].field_name + "_" + index}
                        onClick={() => {
                          fields[index].manualSorting &&
                            handleColumnSorting(
                              fields[index].sorting_field_name || ""
                            );
                        }}
                        {...column.getHeaderProps(
                          fields[index].enableSorting &&
                            column.getSortByToggleProps()
                        )}
                        style={{
                          width: `${
                            ((fields[index].column_width || 1) /
                              tableWidthFactor) *
                            100
                          }%`,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {column.render("Header")}
                          {(fields[index].enableSorting ||
                            fields[index].manualSorting) && (
                            <div
                              style={{
                                marginLeft: "2rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <HiChevronUpDown size={16} color={"#A1A1AA"} />
                            </div>
                          )}
                        </div>
                      </TableHeading>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    handleTableRowClicked(row.original);
                    setActiveRowId(row.original.id);
                  }}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        style={{
                          width: `${
                            ((fields[index].column_width || 1) /
                              tableWidthFactor) *
                            100
                          }%`,
                          fontSize: "1.3rem",
                          background:
                            activeRowId === row.original.id
                              ? activeRowBackgroundColor
                              : "",
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </TableWrapper>
  );
};

export default Table;
