import { nanoid } from "nanoid";
import { ChangeEvent, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../../api/axios";
import { getHtmlForEmbed } from "../../../../../../api/richTextEditor";
import { apiConfig } from "../../../../../../config";
import { IDocumentObject } from "../../../../../../interfaces/common";
import EmbedUrlUploadModal from "./EmbedUrlUploadModal";
import {
  getDocumentHtml,
  insertDocumentInEditor,
} from "../../../../../../helpers/richTextEditor1";
import { queryKeys } from "../../../../../../api/constants";

const EmbedUploadPopup = ({
  showModal,
  setShowModal,
  showEmbedUrlModal,
  setShowEmbedUrlModal,
  inputElementRef,
}: any) => {
  // const inputElementRef = useRef<HTMLInputElement>(null);
  const [selectedItems, setSelectedItems] = useState<IDocumentObject[]>([]);
  const queryClient = useQueryClient();

  const uploadDocuments = (files: File[]) => {
    return files.map((file) => {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("is_embed", JSON.stringify(true));
      return axiosInstance
        .post(apiConfig.urls.documents, formData)
        .then((res) => {
          queryClient.setQueryData(
            [queryKeys.documents],
            (oldDocList: IDocumentObject[] | undefined) => [
              ...(oldDocList ? oldDocList : []),
              res.data,
            ]
          );
          return res;
        })
        .catch((err) => {
          toast.error("Oops, something went wrong");
          throw Error(err);
        });
    });
  };

  const handleOnDismiss = (selectedDocumentObjs: Array<string | number>) => {
    setShowModal(false);
  };

  const handleChangeFromModal = async (
    selectedDocumentObjs: IDocumentObject[]
  ) => {
    setShowModal(false);
    await selectedDocumentObjs.forEach((doc) => {
      //@ts-ignore
      tinymce.activeEditor.setProgressState(true);
      let url = `${process.env.REACT_APP_API_BASE_URL}documents/${doc.id}/file/`;
      const isPdf = /^.*\.(pdf|PDF)$/.test(doc?.filename || "");
      const embedContainer = document.createElement("div");
      embedContainer.classList.add("b-embed");
      embedContainer.dataset.documentId = `${doc.id}`;
      if (isPdf) {
        const divId = nanoid();
        embedContainer.id = divId;
        embedContainer.dataset.documentFileName = `${doc.filename}`;
        embedContainer.dataset.isPdf = "true";
        embedContainer.innerHTML = `<iframe title=${doc.filename} style="width:100%; height:80vh" src="${url}"/>`;
        embedContainer.style.width = "100%";
        embedContainer.style.height = "80vh";
        //@ts-ignore
        tinymce.activeEditor.execCommand(
          "InsertHTML",
          false,
          `${embedContainer.outerHTML}<p/>`
        );
        // @ts-ignore
        tinymce.activeEditor.setProgressState(false);
      } else {
        getHtmlForEmbed(url).then((html) => {
          embedContainer.innerHTML = html;
          //@ts-ignore
          tinymce.activeEditor.execCommand(
            "InsertHTML",
            false,
            `${embedContainer.outerHTML}<p/>`
          );
          // @ts-ignore
          tinymce.activeEditor.setProgressState(false);
        });
      }
    });
  };

  const handleUploadClick = (e: MouseEvent) => {
    inputElementRef.current?.click();
  };

  const handleFileInputChange = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    if (!target?.files?.length) {
      return false;
    }

    try {
      //@ts-ignore
      tinymce.activeEditor.setProgressState(true);
      setShowModal(false);
      const newDocuments: IDocumentObject[] = (
        await Promise.all(uploadDocuments(Array.from(target?.files)))
      ).map((res) => res.data);
      await newDocuments.forEach((doc) => {
        insertDocumentInEditor(getDocumentHtml(doc));
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmbedUrlSaveClick = (embedUrl: any) => {
    setShowEmbedUrlModal(false);
    //@ts-ignore
    tinymce.activeEditor.setProgressState(true);
    getHtmlForEmbed(embedUrl).then((html) => {
      const embedContainer = document.createElement("div");
      embedContainer.innerHTML = html;
      embedContainer.classList.add("b-embed");
      embedContainer.style.overflow = "hidden";
      //@ts-ignore
      tinymce.activeEditor.execCommand(
        "InsertHTML",
        false,
        `${embedContainer.outerHTML}<p/>`
      );
      //@ts-ignore
      tinymce.activeEditor.setProgressState(false);
    });
  };

  return (
    <>
      {/* <FileSelectUploadModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        onDismiss={handleOnDismiss}
        onChange={handleChangeFromModal}
        selectedItems={selectedItems}
        onUploadClick={handleUploadClick}
      ></FileSelectUploadModal> */}
      <input
        ref={inputElementRef}
        type="file"
        multiple
        style={{ opacity: "0", width: "0", display: "none" }}
        onChange={handleFileInputChange}
      ></input>
      <EmbedUrlUploadModal
        showEmbedUploadModal={showEmbedUrlModal}
        setShowEmbedUploadModal={setShowEmbedUrlModal}
        onSaveClick={handleEmbedUrlSaveClick}
      />
    </>
  );
};

export default EmbedUploadPopup;
