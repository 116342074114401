import styled from "@emotion/styled";
import ReactTooltip from "react-tooltip";

const Tooltip = styled(ReactTooltip)`
  .type-dark.place-top {
    background: #334155;
    padding: 0.3rem 1rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    opacity: 1;
    ::after {
      border-top-color: #334155;
    }
  }
`;

Tooltip.defaultProps = {
  place: "top",
  effect: "solid",
};

export default Tooltip;
