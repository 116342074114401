import { IAppState, TAppReducerAction } from "../interfaces/context";

export function appReducer(state: IAppState, action: TAppReducerAction) {
  switch (action.type) {
    case "SET_PAGE_TITLE": {
      const pageTitle = action.payload || "";
      return { ...state, pageTitle };
    }
    default:
      return { ...state };
  }
}
