import { cloneDeep } from "lodash";
import { ChangeEvent, Dispatch, SetStateAction, useContext } from "react";
import { appConfig, userActivityEvents } from "../../config";
import { AuthContext } from "../../context";
import { ISettings, IUserAccount, TAuthReducerAction } from "../../interfaces";
import {
  ICustomizeYourAccountState,
  IHandleAddRowFunc,
  IHandleAddTeamMatesFunc,
  IHandleUpdateUserDetailsFunc,
  IInputValue,
} from "../../interfaces/UserInfoLayout";
import { trackUserAction } from "../../analytics";
import { getLoggedInUser } from "../signin";

const { defaultTimezone } = appConfig;

export function getDefaultTimeZone(
  timeAndTimeZoneSettingsData: ISettings | undefined
) {
  const guessedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timezonesList = timeAndTimeZoneSettingsData?.timezones;
  const timezone = timezonesList?.find(
    (timezone) => timezone.value === guessedTimezone
  );

  if (timezone) {
    return timezone;
  }
  const date = new Date();
  const utcOffset = -date.getTimezoneOffset() / 60;

  // Iterate over based on offset and collect the array which matches the offset. If value exists return the first within array
  const timeZonesWithSameOffset = timezonesList?.filter(
    (tz) => tz.offset === utcOffset
  );

  if (timeZonesWithSameOffset?.length) {
    return timeZonesWithSameOffset[0];
  }

  // return default timezone
  return defaultTimezone;
}

export function handleChangeUserInfo(
  e: ChangeEvent<HTMLInputElement>,
  setCustomizeYourAccountState: Dispatch<
    SetStateAction<ICustomizeYourAccountState>
  >
) {
  setCustomizeYourAccountState((state) => ({
    ...state,
    [e.target.name]: e.target.value,
    errors: {
      ...state.errors,
      [e.target.name]: e.target.value
        ? ""
        : `${
            e.target.name === "first_name" ? "Name" : "Company name"
          } is mandatory`,
    },
  }));
}

export function handleUpdateUserDetails({
  e,
  isOwner,
  customizeYourAccountState,
  setCustomizeYourAccountState,
  updateUserDetailsMutation,
  userId,
}: IHandleUpdateUserDetailsFunc) {
  e.preventDefault();
  const { first_name, company, userTimezone } = customizeYourAccountState;
  if (!first_name || (isOwner && !company)) {
    setCustomizeYourAccountState((state) => ({
      ...state,
      errors: isOwner
        ? {
            first_name: !first_name ? "Name is mandatory" : "",
            company: !company ? "Company name is mandatory" : "",
          }
        : {
            first_name: "Name is mandatory",
          },
    }));
    return;
  }
  const userDetails: IUserAccount = {
    user: {
      first_name,
      last_name: "",
      timezone: userTimezone,
    },
    team: company
      ? {
          name: company,
        }
      : undefined,
    is_signup: true,
  };

  delete userDetails.user.last_name;
  !isOwner && delete userDetails.team;
  trackUserAction(userActivityEvents.ONBOARD__UPDATED_ACCOUNT_INFO);
  updateUserDetailsMutation({ userDetails, userId });
}

export function handleAddRow({
  inputValues,
  inputErrors,
  setInputErrors,
  setInputValues,
}: IHandleAddRowFunc) {
  const newRow: IInputValue = {
    first_name: "",
    email_id: "",
  };
  const updatedInputValues = cloneDeep(inputValues);
  updatedInputValues.push(newRow);
  setInputErrors(cloneDeep(inputErrors).concat(newRow));
  setInputValues(updatedInputValues);
}

export function setIsSignUpToFalse() {
  const updatedLoggedInUser = getLoggedInUser();
  if (updatedLoggedInUser) {
    updatedLoggedInUser.is_signup = false;
    localStorage.setItem("loggedInUser", JSON.stringify(updatedLoggedInUser));
  }
}

export function handleAddTeamMates({
  inputValues,
  inputErrors,
  createInvitationMutation,
}: IHandleAddTeamMatesFunc) {
  let rows = cloneDeep(inputValues);
  // Only send rows with filled values
  const finalInputValues: IInputValue[] = rows
    .reduce(
      (final, row) =>
        final.concat({ first_name: row.first_name, email_id: row.email_id }),
      [] as IInputValue[]
    )
    .filter((row) => row.first_name && row.email_id);

  const isValidationFailed = inputErrors.some((row) => {
    const { first_name, email_id } = row;
    return (first_name && !email_id) || (!first_name && email_id);
  });

  if (isValidationFailed || !finalInputValues.length) {
    return;
  }

  createInvitationMutation({ invitationData: finalInputValues });
  trackUserAction(userActivityEvents.ONBOARD__INVITED_USERS, {
    count: finalInputValues,
  });
}

export function logout(authDispatch: Dispatch<TAuthReducerAction>) {
  trackUserAction(userActivityEvents.LOGOUT_CLICKED);
  authDispatch({
    type: "LOGOUT",
  });
  localStorage.clear();
}
