import { APIObjectType } from "../constants";
import { IDocumentObject } from "./common";
import { IRelationshipParamerter } from "./hooks";
import { ISharedViewTheme } from "./theme";

export interface ISolutionObject {
  id: number;
  object: APIObjectType;
  title: string;
  logo?: string;
  AE?: string;
  SE?: string;
  analytics: {
    last_viewed: string;
    view_count: number;
    viewer_count: number;
  };
  is_shared: boolean;
  buyer: string;
  solution_doc: string;
  solution_doc_html: string;
  opportunity: {
    id: number;
    name: string;
  };
  relationship_parameters: IRelationshipParamerter[];
  notes: string;
  proposal_color: string;
}

export interface ISharableResPublicData {
  is_active: boolean;
  is_email_required: boolean;
  logo: string;
  assets?: {
    template: string;
    themes: ISharedViewTheme[];
  };
}

export interface ISolutionSharingObject {
  code: string;
  id?: number;
  is_active: boolean;
  is_email_required: boolean;
}

export interface ISolutionAssetCreateFromObj {
  id?: string | number;
  name?: string;
  relationship_parameters?: IRelationshipParamerter[];
}

export interface ISolutionAssetType {
  id: number;
  key: string;
  name: string;
  order?: number;
  visibility?: string;
  count?: number;
  created_from?: ISolutionAssetCreateFromObj | null;
}

export interface ISolutionAsset {
  id: number;
  asset_type: ISolutionAssetType;
  title: string;
  description: string;
  description_html: string;
  documents: IDocumentObject[];
  order: number;
  visibility: string;
  created_from?: ISolutionAssetCreateFromObj | null;
  is_collapsed: boolean;
}

export interface ISolutionAssetMutationObject {
  id?: number;
  asset_type?: number;
  title?: string;
  description?: string;
  description_html?: string;
  documents?: IDocumentObject[];
  order?: number;
  visibility?: string;
  generic_resource_id?: string | number;
}

export interface ISolutionAssetTypeMutationObject {
  id?: number;
  name?: string;
  order?: number;
  visibility?: string;
}
export interface ISidebar2SubsectionDataObj {
  label: string;
  key: string;
  visibility: string;
  onClick: Function;
  isReorderable?: boolean;
}

export interface ISidebar2DataObj {
  label: string;
  key: string;
  visibility: string;
  onClick: Function;
  subsections?: ISidebar2SubsectionDataObj[];
  isReorderable?: boolean;
  count?: number;
}

export enum Sidebar2ThemeMode {
  LIGHT_MODE = "light_mode",
  DARK_MODE = "dark_mode",
}
