import { createContext, useContext, useEffect, useState } from "react";
import { ISolutionDetailsContext } from "../interfaces/context/solutionDetailsContext";
import useGetSolutionAssetTypes from "../api/hooks/useGetSolutionAssetTypes";
import { SolutionsContext } from "./SolutionsContext";
import { AuthContext } from "./AuthContext";
import { ISolutionAsset, ISolutionAssetType } from "../interfaces/solution";

export const SolutionDetailsContext = createContext(
  {} as ISolutionDetailsContext
);

export const SolutionDetailsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { authState } = useContext(AuthContext);
  const { solutionsState } = useContext(SolutionsContext);
  const { data: solutionAssetTypesData } = useGetSolutionAssetTypes(
    solutionsState.solutionId || ""
  );
  const [solutionAssetTypes, setSolutionAssetTypes] = useState<
    (ISolutionAssetType & { assets: ISolutionAsset[] })[]
  >([]);
  const isShared = authState.isGuestUser;

  useEffect(() => {
    let updatedSolutionAssetTypes = solutionAssetTypesData?.data
      ?.filter((solutionAssetTypeObj) =>
        isShared ? !!solutionAssetTypeObj.count : true
      )
      .map((solutionAssetTypeObj) => {
        const solutionAssets = solutionAssetTypes?.find(
          (type: any) => type.id === solutionAssetTypeObj.id
        );
        const parsedSolutionAssetTypeObj = {
          ...solutionAssetTypeObj,
          assets: solutionAssets?.assets ? solutionAssets.assets : [],
        };
        return parsedSolutionAssetTypeObj;
      });

    setSolutionAssetTypes(updatedSolutionAssetTypes || []);
  }, [solutionAssetTypesData]);

  return (
    <SolutionDetailsContext.Provider
      value={{
        solutionAssetTypes,
        setSolutionAssetTypes,
      }}
    >
      {children}
    </SolutionDetailsContext.Provider>
  );
};
