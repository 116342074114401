import axios from "axios";
import { useEffect, useState } from "react";
import {
  solutionAddEditFormConfig,
  solutionEditFormStructure,
} from "../../../config/solutionConfig";
import { getLoggedInUser } from "../../../helpers";
import { IOpportunityObject } from "../../../interfaces";
import { ISolutionObject } from "../../../interfaces/solution";
import { getImageFileExtensionFromType } from "../../../utils/file";
import axiosInstance from "../../axios";
import useGetOpportunities from "../useGetOpportunities";
import useGetSolution from "../useGetSolution";
import { useLoggedInTeam } from "../useLoggedInTeam";

interface ISolutionFormInitialData {
  opportunityData?: IOpportunityObject;
}

const getLogoFile = async (companyName: string) => {
  try {
    const companyDataRes = await axiosInstance.get(
      `/services/company-data/${companyName}`
    );
    const logoFileRes = await axios({
      method: "get",
      url: companyDataRes.data.logo,
      responseType: "blob",
    });
    return logoFileRes;
  } catch {
    return null;
  }
};

const useAddEditSolutionFormData = (
  editItemId: string,
  initialData: ISolutionFormInitialData = {}
) => {
  const { name: teamName } = useLoggedInTeam();
  const userProfile = getLoggedInUser()?.user.profile;
  const { data: solutionItemData, isLoading } = useGetSolution(editItemId);
  const isEdit = !!editItemId;
  let formData = solutionAddEditFormConfig;
  if (isEdit) {
    const editFormFields = solutionEditFormStructure.flatMap(
      (fieldGroup) => fieldGroup.fields
    );
    formData = {
      ...formData,
      formFields: formData.formFields.filter((fieldObj) =>
        editFormFields.includes(fieldObj.name)
      ),
    };
  }
  const { data: opportunitiesResponse } = useGetOpportunities();
  const [opportunityLogoFile, setOpportunityLogoFile] = useState<File | null>(
    null
  );

  useEffect(() => {
    if (isEdit) return;
    async function getLogo() {
      const response = await getLogoFile(
        initialData?.opportunityData?.primary_account?.name ||
          initialData?.opportunityData?.name ||
          ""
      );
      const logoBlob = response?.data;
      if (!logoBlob || !(logoBlob instanceof Blob))
        return setOpportunityLogoFile(null);
      const fileType = logoBlob.type.split("/")[1];
      const fileExtension = getImageFileExtensionFromType(fileType);
      setOpportunityLogoFile(
        new File(
          [logoBlob],
          `${initialData?.opportunityData?.name || "logo"}.${fileExtension}`,
          {
            type: logoBlob.type,
          }
        )
      );
    }
    if (initialData.opportunityData) {
      getLogo();
    } else {
      setOpportunityLogoFile(null);
    }
  }, [initialData.opportunityData, isEdit]);

  let initialOpportunity: any = null;
  const opportunityFieldOptions = opportunitiesResponse.data.map(
    (opportunityObj: any) => {
      if (opportunityObj.id === Number(initialData.opportunityData?.id))
        initialOpportunity = opportunityObj;
      return {
        value: opportunityObj.id,
        label: opportunityObj.name,
      };
    }
  );
  if (!initialOpportunity && initialData.opportunityData?.name)
    initialOpportunity = initialData.opportunityData;

  const opportunityField = formData.formFields.find(
    (fieldObj) => fieldObj.name === "opportunity"
  );
  if (opportunityField && opportunityField.fieldData) {
    opportunityField.fieldData.options = opportunityFieldOptions;
  }
  const extraData = formData.formFields.reduce(
    (reducedOptionsObj, formFieldObj) => {
      let initialValue: any = "";
      if (formFieldObj.modelField) {
        initialValue =
          solutionItemData &&
          solutionItemData[formFieldObj.modelField as keyof ISolutionObject];
        if (initialValue === null) {
          initialValue = "";
        }
      } else {
        initialValue = formFieldObj.fieldData?.initialValue;
      }
      return {
        initialValues: {
          ...reducedOptionsObj.initialValues,
          [formFieldObj.name]: initialValue,
        },
        validationSchema: {
          ...reducedOptionsObj.validationSchema,
          [formFieldObj.name]: formFieldObj.validation,
        },
      };
    },
    {
      initialValues: {} as any,
      validationSchema: {} as any,
    }
  );
  if (extraData.initialValues.opportunity || initialOpportunity) {
    if (initialOpportunity?.__isNew__) {
      extraData.initialValues.opportunity = {
        __isNew__: true,
        label: initialOpportunity.name,
        value: initialOpportunity.name,
      };
    } else {
      extraData.initialValues.opportunity = {
        label: initialOpportunity
          ? initialOpportunity.name
          : extraData.initialValues.opportunity?.name,
        value: initialOpportunity
          ? initialOpportunity.id
          : extraData.initialValues.opportunity?.id,
      };
    }
  }

  if (!isEdit) {
    const title =
      initialData.opportunityData?.primary_account?.name ||
      initialData.opportunityData?.name ||
      "";
    extraData.initialValues.title = title ? `${teamName} + ${title}` : "";
    extraData.initialValues.AE = initialData?.opportunityData?.AE || "";
    extraData.initialValues.notes = initialData?.opportunityData?.notes || "";
    extraData.initialValues.buyer = initialData?.opportunityData?.buyer || "";
    extraData.initialValues.logo = opportunityLogoFile;
    // Below line is to trigger form initialization when logo field updates
    extraData.initialValues.logoFileName = opportunityLogoFile?.name;
    if (!initialData?.opportunityData?.AE)
      extraData.initialValues.AE = userProfile?.display_name;
  }

  return {
    ...formData,
    ...extraData,
    isEdit,
  };
};

export default useAddEditSolutionFormData;
