import { Suspense, useContext, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { trackUserAction } from "../../../../../analytics";
import { appConfig, userActivityEvents } from "../../../../../config";
import {
  ISettingsContext,
  SettingsContext,
} from "../../../../../context/SettingsContext";
import { IComponentToRender } from "../../../../../interfaces";
import { history } from "../../../../../utils/history";
import FallBack from "../../../Fallback";

import {
  AssetAddButton,
  AssetAddButtonContainer,
  SelectedTabContainer,
  SidebarWithTabsWrapper,
  Tab,
  TabbedSidebar,
  TagIcon,
  TagTitle,
} from "./styled";
import { SolutionParamsSettingsContext } from "../../../../../context/SolutionParamsSettingsContext";

const {
  pageSpecificConfigs: {
    settings: { parentUrl },
  },
} = appConfig;

interface ISidebarWithTabs {
  componentsToRender: IComponentToRender[];
}

const SidebarWithTabsSettings = ({ componentsToRender }: ISidebarWithTabs) => {
  const {
    defaultTabSlugSettings,
    isActiveTabSlugSetting,
    setIsActiveTabSlugSetting,
    setSolutionParamsData,
    isAdd,
    setIsAddParams,
    viewItem,
  } = useContext<ISettingsContext>(SettingsContext);
  const { setActiveTagId } = useContext(SolutionParamsSettingsContext);
  const location = useLocation();
  const queryParams = location.search;
  const assetSidebarRef = useRef<HTMLDivElement>(null);
  const urlParams = new URLSearchParams(queryParams);

  useEffect(() => {
    if (!urlParams.get("tab"))
      setIsActiveTabSlugSetting(defaultTabSlugSettings);
  }, [defaultTabSlugSettings]);

  useEffect(() => {
    if (!isActiveTabSlugSetting)
      setIsActiveTabSlugSetting(defaultTabSlugSettings);
  }, [isActiveTabSlugSetting, defaultTabSlugSettings]);

  // useEffect(() => {
  //   const currentTab = urlParams.get("tab");
  //   if (!!currentTab) {
  //     const componentDataObj = componentsToRender.find(
  //       (componentDataObj) => componentDataObj.slug === currentTab
  //     );
  //     if (componentDataObj) {
  //     } else {
  //       history.replace(parentUrl);
  //     }
  //   }
  // }, [queryParams, componentsToRender]);

  const handleTabClick = (componentDataObj: IComponentToRender) => {
    trackUserAction(userActivityEvents.SETTINGS__CARD_CLICKED, {
      tabClicked: componentDataObj.slug,
    });
    history.push(`/user/settings/${componentDataObj.slug}`);
  };

  return (
    <SidebarWithTabsWrapper sidebarRef={assetSidebarRef}>
      <TabbedSidebar ref={assetSidebarRef}>
        {componentsToRender.map((componentDataObj) => {
          const isSelected = location.pathname.includes(componentDataObj.slug);

          return (
            <Tab
              key={componentDataObj.slug}
              onClick={() => handleTabClick(componentDataObj)}
              isSelected={isSelected}
            >
              <TagIcon>{componentDataObj.titleIcon}</TagIcon>
              <TagTitle>{componentDataObj.title}</TagTitle>
            </Tab>
          );
        })}
      </TabbedSidebar>

      <SelectedTabContainer id="selected-tab-container">
        <Suspense fallback={<FallBack />}>
          {/* {componentToRender} */}
          <Outlet />
          {viewItem && (
            <AssetAddButtonContainer
              hidden={
                isAdd || !location.pathname.match(/relationship-parameters$/)
              }
            >
              <AssetAddButton
                onClick={() => {
                  setIsAddParams(true);
                  setActiveTagId(undefined);
                  setSolutionParamsData("");
                  setTimeout(() => {
                    const tabbedSidebarContainer = document.getElementById(
                      "selected-tab-container"
                    );
                    if (tabbedSidebarContainer)
                      tabbedSidebarContainer.scroll({
                        top: tabbedSidebarContainer.scrollHeight,
                        behavior: "smooth",
                      });
                  });
                }}
                className="add-solution-parameter"
              >
                + Add buyer segmentation
              </AssetAddButton>
            </AssetAddButtonContainer>
          )}
        </Suspense>
      </SelectedTabContainer>
    </SidebarWithTabsWrapper>
  );
};

export default SidebarWithTabsSettings;
