import styled from "@emotion/styled";

export const IntegrationsWrapper = styled.section`
  background: #ffffff;
  box-shadow: 0rem 1.2rem 4rem rgba(0, 0, 0, 0.04);
  border-radius: 0.8rem;
  min-height: 100%;
`;

export const IntegrationsHeader = styled.div`
  font-family: var(--font-lexend);
  padding: 2rem 3rem;
  border-bottom: 1px solid #e2e8f0;
`;
export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  color: #1e293b;
`;
export const HeaderDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 18px;
  color: #4b5563;
  margin-top: 0.6rem;
`;
export const IntegrationsBody = styled.div`
  padding: 2rem 3rem;
`;

export const CardIcon = styled.div`
  height: 6rem;
  width: 6rem;
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
