import appLogo from "../../../../../assets/icons/appLogoLight.png";
import { appConfig } from "../../../../../config";
import { HeaderContainer } from "./styled";

const HeaderBand = () => {
  return (
    <HeaderContainer>
      <img src={appLogo} className="logo" alt={appConfig.appName} />
    </HeaderContainer>
  );
};

export default HeaderBand;
