import styled from "@emotion/styled";
import {
  DropdownListWrapper,
  DropdownSearch,
} from "../DropdownCheckboxedOptions/styled";

export const DropdownOptionsContainer = styled(DropdownListWrapper)`
  width: 100%;
`;
export const DropdownOptionsSearchBox = styled(DropdownSearch)``;
export const DropdownOptionsWrapper = styled.div`
  overflow-y: auto;
  > div:last-of-type {
    margin-top: 0.6rem;
    padding-top: 0.6rem;
    border-top: 1.8px solid rgba(210, 210, 210, 0.5);
    p.dropdown-disabled-sec-title {
      font-size: 1.3rem;
      margin: 0.5rem 0;
      margin-left: 1rem;
      color: gray;
      user-select: none;
    }
  }
`;
