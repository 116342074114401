import React, { useCallback, useContext, useEffect, useState } from "react";
import { useGetAssetMetadata } from "../../../../../../api/hooks";
import useGetGenericResourceType from "../../../../../../api/hooks/useGetGenericResourceTypes";
import {
  ISidebar2DataObj,
  Sidebar2ThemeMode,
} from "../../../../../../interfaces/solution";
import Sidebar2 from "../../../../Reusable/components/Sidebar2";
import { trackUserAction } from "../../../../../../analytics";
import { appConfig, userActivityEvents } from "../../../../../../config";
import {
  AssetsContext,
  IAssetsContext,
} from "../../../../../../context/AssetsContext";
import { history } from "../../../../../../utils/history";
import { useLocation } from "react-router-dom";
import { cloneDeep, kebabCase } from "lodash";
import useCreateUpdateAssetItem from "../../../../../../api/hooks/useCreateUpdateAssetItem";
import { AssetObjectType } from "../../../../../../config/assetsConfig";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../../../../../api/constants";
import { getRelationshipParametersString } from "../../../../../../helpers";
import { ContentAreaContext } from "../../../../../../context/ContentAreaContext";
import useOverviewTabData from "../../../../../../api/hooks/assets/useOverviewTabData";
import useGetAssetCounts from "../../../../../../api/hooks/useGetAssetCount";

const AssetTypeSidebar = () => {
  const location = useLocation();
  const { data: assetMetaData } = useGetAssetMetadata();
  const { data: genericResourceTypeData } = useGetGenericResourceType();
  const { data: assetCount } = useGetAssetCounts();
  const { showOverviewTab } = useOverviewTabData();
  const [sidebarData, setSidebarData] = useState<ISidebar2DataObj[]>([]);
  const [sidebarIndex, setSidebarIndex] = useState(0);
  const [sidebarSubsectionIndex, setSidebarSubsectionIndex] = useState(0);
  const {
    activeTabSlug,
    setActiveTabSlug,
    setIsEdit,
    genericResourceTypes,
    setGenericResourceTypes,
  } = useContext<IAssetsContext>(AssetsContext);
  const { stateOfAllDropdowns, showFilter } = useContext(ContentAreaContext);
  const {
    pageSpecificConfigs: {
      assets: { parentUrl },
    },
  } = appConfig;
  const queryClient = useQueryClient();
  const { mutate } = useCreateUpdateAssetItem({
    object: AssetObjectType.GENERIC_RESOURCE,
  });
  const defaultTabIndex = showOverviewTab ? 0 : sidebarIndex;
  const tabbedSidebarContainer: HTMLElement | null = document.getElementById(
    "tabbed-sidebar-container"
  );
  const sidebarSubsectionTabs = document.getElementsByClassName(
    "sidebar-subsection-tab"
  );

  useEffect(() => {
    setGenericResourceTypes(genericResourceTypeData?.data || []);
  }, [genericResourceTypeData]);

  useEffect(() => {
    setTimeout(() => {
      const tabbedSidebarContainer: HTMLElement | null =
        document.getElementById("tabbed-sidebar-container");
      const sidebarContainer: HTMLElement | null =
        document.querySelector(".tabbed__sidebar");
      if (tabbedSidebarContainer && sidebarContainer) {
        sidebarContainer.style.height = `${
          tabbedSidebarContainer.clientHeight - 24
        }px`;
      }
    }, 200);
  }, [showFilter, stateOfAllDropdowns]);

  useEffect(() => {
    const sidebarData: ISidebar2DataObj[] = showOverviewTab
      ? [
          {
            label: "Sales play",
            key: `${activeTabSlug}`,
            visibility: "visible",
            onClick: handleTabClick,
            subsections: [],
            isReorderable: false,
          },
        ]
      : [];
    assetMetaData?.assets.forEach((assetTypeData) => {
      const genericResourceList = genericResourceTypes.find(
        (genericResourceType) =>
          genericResourceType.id === assetTypeData.type_id
      );
      const subsectionData = genericResourceList?.generic_resources
        .sort(
          (firstSubSection, secondSubSection) =>
            firstSubSection.order - secondSubSection.order
        )
        .map((genericResourceData) => ({
          label: genericResourceData.title,
          key: `${genericResourceData.id}`,
          visibility: "visible",
          onClick: handleSubsectionTabClick,
          isReorderable: true,
        }));
      const count = (assetTypeData.type && assetCount[assetTypeData.type]) || 0;
      sidebarData.push({
        label: assetTypeData.label,
        key: `${assetTypeData.type_id}`,
        visibility: "visible",
        onClick: handleTabClick,
        subsections: subsectionData,
        isReorderable: false,
        count,
      });
    });
    setSidebarData(sidebarData);
  }, [assetMetaData, genericResourceTypes, showOverviewTab]);

  useEffect(() => {
    const tabbedSidebarContainer: HTMLElement | null = document.getElementById(
      "tabbed-sidebar-container"
    );
    tabbedSidebarContainer?.addEventListener(
      "scroll",
      handleSidebarContentScroll
    );
    return () => {
      tabbedSidebarContainer?.removeEventListener(
        "scroll",
        handleSidebarContentScroll
      );
    };
  }, []);

  const checkVisibilityAndAddScrollEvent = (observerElement: HTMLElement) => {
    const options = {
      root: tabbedSidebarContainer,
      threshold: 0.1,
    };
    const callback = (enteries: any, observer: any) => {
      if (enteries[0].isIntersecting) {
        setTimeout(() => {
          tabbedSidebarContainer?.addEventListener(
            "scroll",
            handleSidebarContentScroll
          );
        }, 500);
        observerElement && observer.unobserve(observerElement);
      }
    };
    const observer = new IntersectionObserver(callback, options);
    if (observerElement) observer.observe(observerElement);
  };

  const removeActiveSubsectionTabs = (subsectionId: string = "") => {
    Array.from(sidebarSubsectionTabs).forEach((subsectionTabElement) => {
      if (subsectionTabElement.id !== subsectionId)
        subsectionTabElement.classList.remove("active");
    });
  };

  const handleSidebarContentScroll = useCallback(() => {
    const tabbedSidebarContainer: HTMLElement | null = document.getElementById(
      "tabbed-sidebar-container"
    );
    const sidebarComponentEles = tabbedSidebarContainer?.querySelectorAll(
      ".library-asset-card"
    );
    let nearestBottom = Number.MAX_SAFE_INTEGER;
    let assetId = "";
    if (sidebarComponentEles && tabbedSidebarContainer) {
      Array.from(sidebarComponentEles).forEach((componentEle) => {
        const bottomDistance =
          componentEle.getBoundingClientRect().bottom -
          tabbedSidebarContainer.getBoundingClientRect().top -
          80;
        if (
          bottomDistance < nearestBottom &&
          bottomDistance > bottomDistance * 0.1
        ) {
          nearestBottom = bottomDistance;
          assetId = (componentEle as HTMLElement).dataset.id || "";
        }
      });
      setSidebarSubsectionIndex(-1);
      Array.from(sidebarSubsectionTabs).forEach((tabElement) => {
        if (tabElement.id === assetId) tabElement.classList.add("active");
        else tabElement.classList.remove("active");
      });
    }
  }, []);

  const handleSubsectionContentScroll = (subsectionId: string) => {
    const subsectionContentElement: HTMLElement | null = document.querySelector(
      `[data-id="${subsectionId}"]`
    );
    tabbedSidebarContainer?.removeEventListener(
      "scroll",
      handleSidebarContentScroll
    );
    if (tabbedSidebarContainer && subsectionContentElement) {
      const libraryViewOffset = -(tabbedSidebarContainer?.offsetTop + 20);
      tabbedSidebarContainer.scrollTo({
        top: subsectionContentElement.offsetTop + libraryViewOffset,
        behavior: "smooth",
      });
      checkVisibilityAndAddScrollEvent(subsectionContentElement);
    }
  };

  const handleTabClick = (assetId: string, tabIndex: number) => {
    setSidebarIndex(tabIndex);
    setSidebarSubsectionIndex(0);
    const activeAssetType = assetMetaData?.assets.find(
      (assetType) => assetType.type_id === Number(assetId)
    );
    const isGenericResource = activeAssetType?.object === "generic_resource";
    const assetSlug = isGenericResource
      ? `generics-${kebabCase(activeAssetType.type)}`
      : activeAssetType?.slug;
    tabbedSidebarContainer?.removeEventListener(
      "scroll",
      handleSidebarContentScroll
    );
    if (tabbedSidebarContainer) {
      tabbedSidebarContainer.scroll({
        top: 0,
        behavior: "smooth",
      });
      setTimeout(() => {
        const firstAssetElement =
          document.getElementsByClassName("library-asset-card")[0];
        checkVisibilityAndAddScrollEvent(firstAssetElement as HTMLElement);
      }, 200);
    }
    trackUserAction(userActivityEvents.LIBRARY__TAB_CHANGED, {
      tabClicked: assetSlug,
    });
    if (showOverviewTab && assetId.includes("overview")) {
      // handle sales play tab
      setActiveTabSlug(assetId);
    }
    setActiveTabSlug(assetSlug || "");
    assetSlug !== activeTabSlug && setIsEdit(false);
    location.search && history.replace(parentUrl);
  };

  const handleSubsectionTabClick = (
    subsectionId: string,
    subsectionTabIndex: number
  ) => {
    handleSubsectionContentScroll(subsectionId);
    setSidebarSubsectionIndex(subsectionTabIndex);
  };

  const handleSubsectionTabDragEnd = (
    key: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    tabbedSidebarContainer?.removeEventListener(
      "scroll",
      handleSidebarContentScroll
    );
    const genericResourceId = key;
    let relationshipParameters =
      getRelationshipParametersString(stateOfAllDropdowns);
    const activeSidebarTabData = genericResourceTypes.find(
      (genericResourceTypeObj) => {
        return !!genericResourceTypeObj.generic_resources?.find(
          (genericResourceData) => {
            return genericResourceData.id === Number(genericResourceId);
          }
        );
      }
    );
    let currentGenericResourceTypes = cloneDeep(genericResourceTypes);
    let updatedGenericResourceTypes = cloneDeep(genericResourceTypes);
    let updatedSubsectionData =
      cloneDeep(activeSidebarTabData?.generic_resources) || [];
    const [reOrderedItem] = updatedSubsectionData.splice(sourceIndex, 1);
    updatedSubsectionData.splice(destinationIndex, 0, reOrderedItem);
    updatedSubsectionData = updatedSubsectionData.map(
      (genericResourceTypeObj, index) => ({
        ...genericResourceTypeObj,
        order: index + 1,
      })
    );
    updatedGenericResourceTypes = updatedGenericResourceTypes.map(
      (genericResourceTypeObj) => {
        if (genericResourceTypeObj.id === activeSidebarTabData?.id) {
          return {
            ...genericResourceTypeObj,
            generic_resources: updatedSubsectionData,
          };
        }
        return genericResourceTypeObj;
      }
    );
    setGenericResourceTypes(updatedGenericResourceTypes);
    setSidebarSubsectionIndex(destinationIndex);
    removeActiveSubsectionTabs(genericResourceId);
    setTimeout(() => {
      handleSubsectionContentScroll(genericResourceId);
    }, 200);

    const mutationOption = {
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryKeys.genericResources,
          relationshipParameters,
        ]);
        queryClient.invalidateQueries([queryKeys.genericResourceTypes]);
      },
      onError: () => {
        setGenericResourceTypes(currentGenericResourceTypes);
        removeActiveSubsectionTabs(genericResourceId);
        setSidebarSubsectionIndex(sourceIndex);
        setTimeout(() => {
          handleSubsectionContentScroll(genericResourceId);
        }, 200);
      },
    };
    const mutationData = {
      id: Number(genericResourceId),
      order: destinationIndex + 1,
    };
    mutate(mutationData, mutationOption);
  };

  return (
    <Sidebar2
      theme={Sidebar2ThemeMode.LIGHT_MODE}
      data={sidebarData}
      defaultTabIndex={defaultTabIndex}
      defaultSubsectionTabIndex={sidebarSubsectionIndex}
      onSubsectionTabDragEnd={handleSubsectionTabDragEnd}
      collapsibleTab={true}
    ></Sidebar2>
  );
};

export default AssetTypeSidebar;
