
import React, { createContext, useReducer } from "react";
import {  IAppState,IAppContext } from "../interfaces";
import { appReducer } from "../reducer";

const initialAppState: IAppState = {
  pageTitle: "",
};

export const AppContext = createContext({} as IAppContext);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);

  return (
    <AppContext.Provider value={{ appState, appDispatch }}>
      {children}
    </AppContext.Provider>
  );
};
