import { useRef, useState, memo, useCallback, useEffect, useMemo } from "react";
import { useOnClickOutside } from "../../../../../hooks";
import {
  IDropdownData,
  IDropdownOption,
} from "../../../../../interfaces/dropdown";
import {
  DropdownWrapper,
  DropdownViewable,
  DropdownSelectedItem,
} from "../DropdownCheckboxedOptions/styled";
import { DropdownLabel } from "../../../Templates/components/Solution/CreateNewSolution/styled";
import arrowDown from "../../../../../assets/icons/downArrow.svg";
import filterSearch from "../../../../../assets/icons/filterSearch.svg";
import {
  DropdownOptionsContainer,
  DropdownOptionsSearchBox,
  DropdownOptionsWrapper,
} from "./styled";
import DropdownMultiSelectOptions from "./DropdownMultiSelectOptions";

export interface IDropdownProps {
  data: IDropdownData;
  label?: string;
  width?: string;
  onOptionClick: Function;
  isMultiSelect?: boolean;
}

const DROPDOWN_DISABLED_SECTION_TITLE =
  "Not available based on current selections";

const DropdownMultiSelect = ({
  data,
  label,
  width,
  onOptionClick,
  isMultiSelect = true,
}: IDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dropdownData, setDropdownData] = useState(data);
  const dropdownRef = useRef(null);

  const handleOnClickOutside = () => {
    setIsOpen(false);
    setSearchText("");
  };
  useOnClickOutside(dropdownRef, handleOnClickOutside);

  const handleOptionClick = useCallback(
    (option: IDropdownOption) => {
      const isSelected = !option?.isSelected;
      const updatedOption: IDropdownOption = {
        ...option,
        isSelected: isSelected,
      };
      setDropdownData((prevData) => ({
        ...prevData,
        selectedValues: isSelected
          ? prevData.selectedValues.add(option.value)
          : prevData.selectedValues,
        options: prevData.options.map((option) =>
          option.value === updatedOption.value
            ? updatedOption
            : isMultiSelect
            ? option
            : { ...option, isSelected: false }
        ),
      }));
      onOptionClick(option);
    },
    [onOptionClick]
  );

  useEffect(() => {
    setDropdownData(data);
  }, [data]);

  const optionsToRender = useMemo(() => {
    const options = dropdownData.options;
    return searchText
      ? options.filter((option) => option.label.includes(searchText))
      : options;
  }, [searchText, dropdownData]);

  const selectedOptions = dropdownData.options.filter(
    (option) => option.isSelected && option.isSelectable
  );

  const disabledOptions = optionsToRender.filter(
    (option) => !option.isSelectable
  );

  if (!data) return <></>;
  return (
    <DropdownWrapper width={width} ref={dropdownRef}>
      <DropdownViewable onClick={() => setIsOpen(!isOpen)}>
        <div>
          {label ?? <DropdownLabel>{label}</DropdownLabel>}
          <DropdownSelectedItem className="dropdown-selected-item">
            <DropdownSelectedLabel selectedOptions={selectedOptions} />
          </DropdownSelectedItem>
        </div>
        <img src={arrowDown} alt="V" />
      </DropdownViewable>

      <DropdownOptionsContainer isOpen={isOpen}>
        <h3 className="options-label">Select {data.label || ""}</h3>
        <div className="options-search-box">
          <img src={filterSearch} alt="Search" />
          <DropdownOptionsSearchBox
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={data.label ? `Filter by ${data.label}` : ""}
          />
        </div>
        <DropdownOptionsWrapper>
          <DropdownMultiSelectOptions
            options={optionsToRender.filter((option) => option.isSelectable)}
            onOptionClick={handleOptionClick}
            isMultiSelect={isMultiSelect}
          />
          {disabledOptions.length > 0 && (
            <div>
              <p className="dropdown-disabled-sec-title">
                {DROPDOWN_DISABLED_SECTION_TITLE}
              </p>
              <DropdownMultiSelectOptions
                options={disabledOptions}
                onOptionClick={handleOptionClick}
                isMultiSelect={isMultiSelect}
              />
            </div>
          )}
        </DropdownOptionsWrapper>
      </DropdownOptionsContainer>
    </DropdownWrapper>
  );
};

const DropdownSelectedLabel = memo<{
  selectedOptions: IDropdownOption[];
}>(({ selectedOptions = [] }) => {
  const selectedLength = selectedOptions.length;
  const selectedOptionLabels = selectedOptions.map((option) => option.label);
  return (
    <>
      {selectedLength === 0 ? (
        "Select"
      ) : (
        <div className="selected-item-label">
          {selectedOptionLabels.join(", ")}
        </div>
      )}
    </>
  );
});

export default DropdownMultiSelect;
