import styled from "@emotion/styled";

interface ITagContainer {
  flexDirection?: "column" | "row";
}
const TagContainer = styled.div<ITagContainer>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  gap:1rem;
  flex-wrap:wrap;
`;

export default TagContainer;
