import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import SignIn from "../../../routes/Signin";
import { Email, GoogleAuthenticator, Login } from "../../auth";
import GuestUserAuth from "../../auth/GuestUser/GuestUserAuth";
import { MagicLinkSent } from "../../compositional";
import { useEffect } from "react";
import { appConfig } from "../../../config";

const RootPathNavigator = () => {
  const location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    if (pathname) sessionStorage.setItem("redirectPath", pathname);
  }, [pathname]);
  return <Navigate to="/" replace />;
};

const AuthRoutes = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/login" element={<Login />} />
      <Route path="/shared/:code" element={<GuestUserAuth />} />
      <Route path="/login/magic" element={<Email />} />
      <Route path="/login/google" element={<GoogleAuthenticator />} />
      <Route path="/user/magic-link" element={<MagicLinkSent />} />
      {!localStorage.getItem("access") &&
        !appConfig.publicRoutePaths.includes(pathname) && (
          <Route path="*" element={<RootPathNavigator />} />
        )}
    </Routes>
  );
};

export default AuthRoutes;
