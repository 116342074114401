import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { cloneDeep, isNull } from "lodash";
import { AuthContext, SolutionsContext } from "../../../../../../../context";
import {
  ISidebar2DataObj,
  Sidebar2ThemeMode,
} from "../../../../../../../interfaces/solution";
import Sidebar2 from "../../../../../Reusable/components/Sidebar2";
import useCreateUpdateSolutionAssetItem from "../../../../../../../api/hooks/useCreateUpdateSolutionAssetItem";
import useCreateUpdateSolutionAssetType from "../../../../../../../api/hooks/useCreateUpdateSolutionAssetType";
import { queryKeys } from "../../../../../../../api/constants";
import { useGetSettings } from "../../../../../../../api/hooks";
import { getTitlesFromText } from "../../../../../../../helpers/common";
import { TITLE_HEADING_CONFIG } from "../../../../../../../constants/richTextEditor";
import { SolutionDetailsContext } from "../../../../../../../context/SolutionDetailsContext";

interface ISolutionAssetTypeSidebar {
  theme?: Sidebar2ThemeMode;
}

const SolutionAssetTypeSidebar = ({
  theme = Sidebar2ThemeMode.LIGHT_MODE,
}: ISolutionAssetTypeSidebar) => {
  const { authState } = useContext(AuthContext);
  const {
    solutionsState: { solutionId, solutionObject },
  } = useContext(SolutionsContext);
  const { solutionAssetTypes: types, setSolutionAssetTypes } = useContext(
    SolutionDetailsContext
  );
  const { data: userSettings } = useGetSettings();
  const [sidebarData, setSidebarData] = useState<ISidebar2DataObj[]>([]);
  const [sidebarIndex, setSidebarIndex] = useState(0);
  const [sidebarSubsectionIndex, setSidebarSubsectionIndex] = useState(0);

  const { mutate: mutateSolutionAssetItem } = useCreateUpdateSolutionAssetItem(
    solutionId || ""
  );
  const { mutate: mutateSolutionAssetType } = useCreateUpdateSolutionAssetType(
    solutionId || ""
  );
  const isShared = authState.isGuestUser;
  const queryClient = useQueryClient();
  const solutionDocHtml = solutionObject?.solution_doc_html;
  const isAutoGenerateEnabled =
    userSettings?.solution?.is_auto_generate_enabled;
  const showSolutionDoc =
    solutionDocHtml ||
    solutionDocHtml === "" ||
    (isNull(solutionDocHtml) && isAutoGenerateEnabled);
  const solutionDocTitles = getTitlesFromText(
    solutionDocHtml || "",
    TITLE_HEADING_CONFIG.htmlTag
  );

  let sidebarComponentContainer =
    document.getElementsByClassName("sidebar__selected-tab-content")[0]
      ?.children || [];
  const sidebarTabs = document.getElementsByClassName("sidebar-tab");
  const solutionAssetCardsContainer = document.getElementsByClassName(
    "solution-asset-card"
  );
  const subsectionTabContainer = document.getElementsByClassName("subsection");
  const subsectionTabs = document.getElementsByClassName(
    "sidebar-subsection-tab"
  );
  const mainContainer = document.getElementsByTagName("main")[0];
  const sharedSolutionTopNav = document.getElementById(
    "shared-solution-top-nav"
  ) as HTMLDivElement;
  const sharedSolutionHeader =
    sharedSolutionTopNav?.parentNode as HTMLDivElement;
  const sharedViewOffset =
    (sharedSolutionHeader?.offsetHeight || 0) -
    (sharedSolutionTopNav?.offsetHeight || 0) -
    90;

  useEffect(() => {
    let sidebarData: any = [];
    if (isShared ? solutionDocHtml : showSolutionDoc) {
      sidebarData.push({
        label: solutionDocTitles.length
          ? solutionDocTitles[0]
          : "Executive summary",
        key: "solution-doc",
        onClick: () => {
          mainContainer.scrollTo({
            behavior: "smooth",
            top: 0,
          });
        },
        subsections: [],
      });
    }
    types?.forEach((solutionAssetType) => {
      const subsectionTabsData = solutionAssetType?.assets?.map(
        (subsectionType) => ({
          label: subsectionType.title,
          key: `${subsectionType.id}`,
          visibility: subsectionType.visibility,
          onClick: handleSubsectionTabClick,
          isReorderable: !isShared,
        })
      );
      const tabData = {
        label: solutionAssetType.name,
        key: `${solutionAssetType.id}`,
        visibility: solutionAssetType.visibility,
        onClick: handleTabClick,
        subsections: subsectionTabsData,
        isReorderable: !isShared,
        count: subsectionTabsData.length,
      };
      sidebarData.push(tabData);
    });
    setSidebarData(sidebarData);
  }, [types, showSolutionDoc]);

  useEffect(() => {
    mainContainer.addEventListener("scroll", sidebarContentScroll);
    return () => {
      mainContainer.removeEventListener("scroll", sidebarContentScroll);
    };
  }, [types]);

  const sidebarContentScroll = () => {
    sidebarComponentContainer &&
      Array.from(sidebarComponentContainer).forEach((componentElement) => {
        if (
          (componentElement.getBoundingClientRect().top <= 200 &&
            componentElement.getBoundingClientRect().top >= 60) ||
          (componentElement.getBoundingClientRect().bottom <= 250 &&
            componentElement.getBoundingClientRect().bottom >= 200)
        ) {
          const visibleComponentId = componentElement.id.substring(11);
          setSidebarIndex(-1);
          Array.from(sidebarTabs).forEach((tabElement) => {
            if ((tabElement as HTMLElement).id === visibleComponentId) {
              const subsectionTabs = document.getElementById(
                `subsection-${visibleComponentId}`
              );
              const firstSubsectionTab = subsectionTabs?.getElementsByClassName(
                "sidebar-subsection-tab"
              );
              if (firstSubsectionTab?.length) {
                if (
                  componentElement.getBoundingClientRect().top <= 200 &&
                  componentElement.getBoundingClientRect().top >= 60
                )
                  firstSubsectionTab[0].classList.add("active");
                else if (
                  componentElement.getBoundingClientRect().bottom <= 250 &&
                  componentElement.getBoundingClientRect().bottom >= 200
                )
                  firstSubsectionTab[
                    firstSubsectionTab.length - 1
                  ].classList.add("active");
              } else tabElement.classList.add("active");
            } else {
              tabElement.classList.remove("active");
            }
          });
          Array.from(subsectionTabContainer).forEach((tabElement) => {
            if (
              (tabElement as HTMLElement).id ===
              `subsection-${visibleComponentId}`
            ) {
              (tabElement as HTMLElement).hidden = false;
            } else {
              (tabElement as HTMLElement).hidden = true;
            }
          });
        }
      });
    solutionAssetCardsContainer &&
      Array.from(solutionAssetCardsContainer).forEach((cardElement) => {
        if (
          (cardElement.getBoundingClientRect().top <= 150 &&
            cardElement.getBoundingClientRect().top >= 60) ||
          (cardElement.getBoundingClientRect().bottom <= 250 &&
            cardElement.getBoundingClientRect().bottom >= 200)
        ) {
          const visibleCardId = cardElement.id.substring(13);
          setSidebarSubsectionIndex(-1);
          Array.from(subsectionTabs).forEach((subsectionTabElement) => {
            if ((subsectionTabElement as HTMLElement).id === visibleCardId) {
              subsectionTabElement.classList.add("active");
            } else {
              subsectionTabElement.classList.remove("active");
            }
          });
        }
      });
  };

  const removeActiveTabs = (solutionAssetId: string = "") => {
    Array.from(sidebarTabs).forEach((tabElement) => {
      (tabElement as HTMLElement).id !== solutionAssetId &&
        tabElement.classList.remove("active");
    });
    Array.from(subsectionTabContainer).forEach((tabElement) => {
      if ((tabElement as HTMLElement).id === `subsection-${solutionAssetId}`) {
        const subsectionTabs = tabElement.getElementsByClassName(
          "sidebar-subsection-tab"
        );
        Array.from(subsectionTabs).forEach((subsectionTabElement, index) => {
          if (index === 0) subsectionTabElement.classList.add("active");
          else subsectionTabElement.classList.remove("active");
        });
      }
      if ((tabElement as HTMLElement).id !== `subsection-${solutionAssetId}`)
        (tabElement as HTMLElement).hidden = true;
    });
  };

  const removeActiveSubsectionTabs = (subsectionId: string = "") => {
    Array.from(subsectionTabs).forEach((subsectionTabElement) => {
      if (subsectionTabElement.id !== subsectionId)
        subsectionTabElement.classList.remove("active");
    });
  };

  const sidebarTabScroll = (solutionAssetId: string) => {
    mainContainer.removeEventListener("scroll", sidebarContentScroll);
    const scrollMainContainer = document.getElementsByTagName("main")[0];
    const assetTitleId = `asset-type-${solutionAssetId}`;
    const solutionTopNav = document.getElementById(
      "solution-content-tabs"
    ) as HTMLDivElement;
    const solutionViewOffset = -((solutionTopNav?.offsetHeight || 0) + 10);
    const element = document.getElementById(assetTitleId);
    element &&
      scrollMainContainer.scrollTo({
        behavior: "smooth",
        top:
          element.offsetTop +
          (isShared ? sharedViewOffset : solutionViewOffset),
      });
    const options = {
      root: scrollMainContainer,
      threshold: 0.1,
    };
    const callback = (enteries: any, observer: any) => {
      if (enteries[0].isIntersecting) {
        setTimeout(() => {
          mainContainer.addEventListener("scroll", sidebarContentScroll);
        }, 500);
        element && observer.unobserve(element);
      }
    };
    const observer = new IntersectionObserver(callback, options);
    if (element) observer.observe(element);
  };

  const sidebarSubsectionTabScroll = (subsectionId: string) => {
    mainContainer.removeEventListener("scroll", sidebarContentScroll);
    const scrollMainContainer = document.getElementsByTagName("main")[0];
    const subsectionCardId = `card-content-${subsectionId}`;
    const solutionTopNav = document.getElementById(
      "solution-content-tabs"
    ) as HTMLDivElement;
    const solutionViewOffset = -((solutionTopNav?.offsetHeight || 0) + 10);
    const element = document.getElementById(subsectionCardId);
    element &&
      scrollMainContainer.scrollTo({
        behavior: "smooth",
        top:
          element.offsetTop +
          (isShared ? sharedViewOffset : solutionViewOffset),
      });
    const options = {
      root: scrollMainContainer,
      threshold: 0.1,
    };
    const callback = (enteries: any, observer: any) => {
      if (enteries[0].isIntersecting) {
        setTimeout(() => {
          mainContainer.addEventListener("scroll", sidebarContentScroll);
        }, 500);
        element && observer.unobserve(element);
      }
    };
    const observer = new IntersectionObserver(callback, options);
    if (element) observer.observe(element);
  };

  const handleTabClick = (solutionAssetId: string, tabIndex: number) => {
    sidebarTabScroll(solutionAssetId);
    setSidebarIndex(tabIndex);
    setSidebarSubsectionIndex(0);
  };

  const handleSubsectionTabClick = (
    subsectionId: string,
    subsectionTabIndex: number
  ) => {
    sidebarSubsectionTabScroll(subsectionId);
    setSidebarSubsectionIndex(subsectionTabIndex);
  };

  const handleTabDragEnd = (
    key: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const solutionAssetTypeId = key;
    let updatedSolutionAssetTypesData = cloneDeep(types);
    let currentSolutionAssetTypesData = cloneDeep(types);
    const [reOrderedItem] = updatedSolutionAssetTypesData.splice(
      sourceIndex + (showSolutionDoc ? -1 : 0),
      1
    );
    updatedSolutionAssetTypesData.splice(
      destinationIndex + (showSolutionDoc ? -1 : 0),
      0,
      reOrderedItem
    );
    updatedSolutionAssetTypesData = updatedSolutionAssetTypesData.map(
      (solutionAssetTypeObj, index) => ({
        ...solutionAssetTypeObj,
        order: index + 1,
      })
    );
    setSolutionAssetTypes(updatedSolutionAssetTypesData);
    const updatedSolutionAssetType = sidebarData.find(
      (data) => data?.key === `${solutionAssetTypeId}`
    );
    removeActiveTabs(solutionAssetTypeId);
    removeActiveSubsectionTabs();
    const subsectionFirstTab = document.getElementById(
      `${updatedSolutionAssetType?.subsections?.[0]?.key}`
    );
    if (subsectionFirstTab) subsectionFirstTab.classList.add("active");
    setTimeout(() => {
      sidebarTabScroll(solutionAssetTypeId);
    }, 200);

    const mutationOption = {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries([
          queryKeys.solutionAssetsType,
          { solutionId: `${solutionId}` },
        ]);
      },
      onError: () => {
        setSolutionAssetTypes(currentSolutionAssetTypesData);
        removeActiveTabs(solutionAssetTypeId);
        removeActiveSubsectionTabs();
        const subsectionFirstTab = document.getElementById(
          `${updatedSolutionAssetType?.subsections?.[0]?.key}`
        );
        if (subsectionFirstTab) subsectionFirstTab.classList.add("active");
        setTimeout(() => {
          sidebarTabScroll(solutionAssetTypeId + "");
        }, 200);
      },
    };
    const mutationData = {
      id: Number(solutionAssetTypeId),
      order: Number(destinationIndex) + (showSolutionDoc ? 0 : 1),
    };

    mutateSolutionAssetType(mutationData, mutationOption);
  };

  const handleSubsectionTabDragEnd = (
    key: string,
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const solutionAssetItemId = key;
    const activeSidebarTabData = types.find((solutionAssetTypeObj) => {
      return !!solutionAssetTypeObj.assets?.find((assetData) => {
        return assetData.id === Number(solutionAssetItemId);
      });
    });
    let currentSolutionAssetTypesData = cloneDeep(types);
    let updatedSolutionAssetTypesData = cloneDeep(types);
    let updatedSubsectionData = cloneDeep(activeSidebarTabData?.assets) || [];
    const [reOrderedItem] = updatedSubsectionData.splice(sourceIndex, 1);
    updatedSubsectionData.splice(destinationIndex, 0, reOrderedItem);
    updatedSubsectionData = updatedSubsectionData.map(
      (solutionAssetTypeObj, index) => ({
        ...solutionAssetTypeObj,
        order: index + 1,
      })
    );
    updatedSolutionAssetTypesData = updatedSolutionAssetTypesData.map(
      (solutionAssetTypeObj) => {
        if (solutionAssetTypeObj.id === activeSidebarTabData?.id) {
          return { ...solutionAssetTypeObj, assets: updatedSubsectionData };
        }
        return solutionAssetTypeObj;
      }
    );
    setSolutionAssetTypes(updatedSolutionAssetTypesData);
    removeActiveSubsectionTabs(`${solutionAssetItemId}`);
    setTimeout(() => {
      sidebarSubsectionTabScroll(`${solutionAssetItemId}`);
    }, 200);
    const mutationOption = {
      onSuccess: (data: any) => {
        const solutionAssetTypeId = data?.data?.asset_type?.id;
        queryClient.invalidateQueries([
          queryKeys.solutionAssets,
          { solutionId: `${solutionId}` },
          { type: Number(solutionAssetTypeId) },
        ]);
      },
      onError: () => {
        setSolutionAssetTypes(currentSolutionAssetTypesData);
        removeActiveSubsectionTabs(`${solutionAssetItemId}`);
        setTimeout(() => {
          sidebarSubsectionTabScroll(`${solutionAssetItemId}`);
        }, 200);
      },
    };
    const mutationData = {
      id: Number(solutionAssetItemId),
      order: Number(destinationIndex) + 1,
    };

    mutateSolutionAssetItem(mutationData, mutationOption);
  };

  return (
    <Sidebar2
      theme={theme}
      data={sidebarData}
      defaultTabIndex={sidebarIndex}
      defaultSubsectionTabIndex={sidebarSubsectionIndex}
      onTabDragEnd={handleTabDragEnd}
      onSubsectionTabDragEnd={handleSubsectionTabDragEnd}
    ></Sidebar2>
  );
};

export default SolutionAssetTypeSidebar;
