import React, { useEffect, useState } from "react";
import { Column, useTable } from "react-table";
import { useGetOpportunities } from "../../../api/hooks";
import { COLUMN_ID_PREFIX } from "../../../constants";
import { IFieldStructure } from "../../../interfaces/opportunities";
import RenderColumn from "./components/RenderColumn";
import Table from "./components/Table";
import { AllOpportunitiesWrapper } from "./styled";

const AllOpportunities = () => {
  const { data: allOpportunitiesResponse } = useGetOpportunities();

  const [tableData, setTableData] = useState<any>([]);

  function parseDataForTable(allOpportunitiesResponse: any) {
    /* 
      Structure of data can be anything so based on field_name need to recreate the parsed data
      1. Iterate over the data, and in each iteration check if it's flat key to be accessed from data object
      2. If it's flat create a new object called row and with label as key, set the value accessed from data
      3. Else this is the case where in field name is something like primary_account.name
         3.1 Split the field_name, check the typeof split1, if it's object access it as data[split1][split2]
         3.2 typeof split1 isArray then Iterate over data[split1].map(element => element[split2]).join(",")

      Have recreated the field as field_0, field_1 based on order
    */
    const { fields, data } = allOpportunitiesResponse;

    return data.map((rowData: any) => {
      const parsedRowData = {} as any;
      parsedRowData.id = rowData.id;
      fields.forEach((fieldStructure: IFieldStructure, index: number) => {
        const fieldName = fieldStructure.field_name;
        const recreatedFieldName = COLUMN_ID_PREFIX + index;
        if (fieldName?.includes(".")) {
          const [outerObject, innerFieldToAccess] = fieldName.split(".");
          if (Array.isArray(rowData[outerObject])) {
            parsedRowData[recreatedFieldName] = rowData[outerObject];
          } else {
            parsedRowData[recreatedFieldName] = rowData[outerObject]
              ? rowData[outerObject][innerFieldToAccess]
              : null;
          }
        } else {
          // owner field is allowed to pass thru and enters this else.
          parsedRowData[recreatedFieldName] = rowData[fieldName];
        }
      });
      return parsedRowData;
    });
  }

  useEffect(() => {
    allOpportunitiesResponse &&
      setTableData(parseDataForTable(allOpportunitiesResponse));
  }, [allOpportunitiesResponse]);

  // Had to put any as the data would be dynamic and will change for client to client.
  const columns: readonly Column<any>[] = React.useMemo(() => {
    return allOpportunitiesResponse?.fields?.map(
      (field: IFieldStructure, index: number) => {
        const fieldAccessor = COLUMN_ID_PREFIX + index;
        const { label, render_type } = field;
        let column: Column<any> = {
          Header: label,
          accessor: fieldAccessor,
          Cell: ({ row: { values }, column }) => {
            return (
              <RenderColumn
                key={fieldAccessor}
                cellContent={values[fieldAccessor]}
                fieldRenderType={render_type}
                fieldSpecificData={field}
              />
            );
          },
        };
        return column;
      }
    );
  }, [allOpportunitiesResponse]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  const { fields } = allOpportunitiesResponse;

  return (
    <AllOpportunitiesWrapper>
      {!!tableData?.length && (
        <Table
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          fields={fields}
          tableWidth={fields.reduce(
            (sum: number, field: IFieldStructure) => sum + field.column_width,
            0
          )}
        />
      )}
      <span style={{ fontSize: "1.4rem", marginLeft: "1rem" }}>
        {tableData.length} items
      </span>
    </AllOpportunitiesWrapper>
  );
};

export default AllOpportunities;
