import { Textarea } from "../Textarea";
import { cn } from "../lib/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./Form";

interface IFormTextareaField {
  label: string;
  name: string;
  description?: string;
  required?: boolean;
  form: any;
  [x: string]: any;
}

const TextareaField = ({
  label,
  description,
  required,
  name,
  className,
  form,
  ...allprops
}: IFormTextareaField) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel
            className={cn(
              "text-2xl font-normal text-foreground",
              required && "after:content-['_*']"
            )}
          >
            {label}
          </FormLabel>
          <FormControl>
            <Textarea
              className={cn(
                "resize-none px-4 py-2 text-xl rounded-lg focus-visible:border-purple-600 focus-visible:shadow-purple focus-visible:ring-0",
                className
              )}
              {...field}
              {...allprops}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage className="text-lg font-light" />
        </FormItem>
      )}
    />
  );
};

export default TextareaField;
