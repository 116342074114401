import React, { useContext } from "react";
import styled from "@emotion/styled";
import SolutionAssetsCardList from "./SolutionAssetsCardList";
import { SolutionDetailsContext } from "../../../../../../../context/SolutionDetailsContext";

const SolutionAssetsCardListWrapper = styled.div`
  &.first-child-header {
    .SolutionAssetCardHeader__header {
      background: white;
      margin-bottom: -2rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 1;
      border: 0.5px solid #e2e8f0;
    }
    .SolutionAssetCardHeader__title {
      padding: 2rem;
    }
    .first-solution-asset-card {
      padding-top: 2rem;
    }
  }
`;

const SolutionAssetsCardContainer = ({
  isShared = false,
  handleTabClick,
}: any) => {
  const { solutionAssetTypes } = useContext(SolutionDetailsContext);

  return (
    <>
      {solutionAssetTypes?.map((solutionAssetTypeObj, index: number) => {
        const assetIdName = `asset-type-${solutionAssetTypeObj?.id}`;
        return (
          <SolutionAssetsCardListWrapper
            key={index}
            id={assetIdName}
            className={isShared && index === 0 ? "first-child-header" : ""}
          >
            <SolutionAssetsCardList
              title={solutionAssetTypeObj.name}
              type={solutionAssetTypeObj}
              handleTabClick={handleTabClick}
            />
          </SolutionAssetsCardListWrapper>
        );
      })}
    </>
  );
};

export default SolutionAssetsCardContainer;
