import styled from "@emotion/styled";
import PuffLoader from "react-spinners/PuffLoader";

const FallbackWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
const StatusText = styled.span``;

const FallBack = ({
  fullPage = false,
  style = {},
  statusText = "",
}: {
  fullPage?: boolean;
  style?: object;
  statusText?: string;
}) => {
  return (
    <FallbackWrapper style={{ height: fullPage ? "100vh" : "", ...style }}>
      <PuffLoader color={"#2072D4"} size={80} />
      <StatusText className="text-lg text-slate-600">{statusText}</StatusText>
    </FallbackWrapper>
  );
};

export default FallBack;
