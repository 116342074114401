import {
  ISolutionsAssetState,
  ISolutionsState,
  SolutionsActionType,
  SolutionsAssetActionType,
  TSolutionsAssetReducerAction,
  TSolutionsReducerAction,
} from "../interfaces/context";

export function solutionsReducer(
  state: ISolutionsState,
  action: TSolutionsReducerAction
) {
  switch (action.type) {
    case SolutionsActionType.SET_SOLUTION_ID: {
      const solutionId = action.payload;
      return { ...state, solutionId };
    }
    case SolutionsActionType.SET_SOLUTION_OBJECT: {
      const solutionObject = action.payload;
      return { ...state, solutionObject };
    }
    default:
      return { ...state };
  }
}

export function solutionsAssetReducer(
  state: ISolutionsAssetState,
  action: TSolutionsAssetReducerAction
) {
  switch (action.type) {
    case SolutionsAssetActionType.SET_ASSET_TYPE: {
      const assetType = action.payload;
      return { ...state, assetType };
    }
    case SolutionsAssetActionType.SET_ASSET_ID: {
      const assetId = action.payload;
      return { ...state, assetId };
    }
    case SolutionsAssetActionType.SET_IS_CREATE_EDIT: {
      const isCreateEdit = action.payload;
      return { ...state, isCreateEdit };
    }
    case SolutionsAssetActionType.SET_SHOW_ASSET_MODAL: {
      const showAssetModal = action.payload;
      return { ...state, showAssetModal };
    }
    case SolutionsAssetActionType.SET_IS_DELETE: {
      const isDelete = action.payload;
      return { ...state, isDelete };
    }
    default:
      return { ...state };
  }
}
