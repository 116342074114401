import { Fragment, useContext, useEffect, useState } from "react";
import { cloneDeep, noop } from "lodash";
import { DropResult } from "react-beautiful-dnd";
import {
  SidebarContainer,
  Tab,
  DroppableTabContainer,
  TabDragAndDropWrapper,
  TabLabel,
  DraggableTabWrapper,
  TabCount,
} from "./styled";
import Sidebar2Subsection from "./Sidebar2Subsection";
import {
  ISidebar2DataObj,
  Sidebar2ThemeMode,
} from "../../../../../interfaces/solution";
import { AuthContext } from "../../../../../context";
import dragAndDropIcon from "../../../../../assets/icons/listTag.svg";
import { useTheme } from "@emotion/react";
import { ITheme } from "../../../../../interfaces/theme";

interface ISidebar2 {
  theme?: Sidebar2ThemeMode;
  data: ISidebar2DataObj[];
  defaultTabIndex?: number;
  defaultSubsectionTabIndex?: number;
  onTabDragEnd?: Function;
  onSubsectionTabDragEnd?: Function;
  collapsibleTab?: boolean;
}

const Sidebar2 = ({
  data,
  theme,
  defaultTabIndex = 0,
  defaultSubsectionTabIndex = 0,
  onTabDragEnd = noop,
  onSubsectionTabDragEnd = noop,
  collapsibleTab = false,
}: ISidebar2) => {
  const { authState } = useContext(AuthContext);
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);
  const [sidebarData, setSidebarData] = useState<ISidebar2DataObj[]>(data);
  const styleTheme: ITheme = useTheme();
  const sidebarTabs = document.getElementsByClassName("sidebar-tab");
  const subsectionTabContainer = document.getElementsByClassName("subsection");
  const isShared = authState.isGuestUser;

  useEffect(() => {
    setActiveTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  useEffect(() => {
    setSidebarData(data);
  }, [data]);

  const removeActiveTabs = (solutionAssetId: string = "") => {
    Array.from(sidebarTabs).forEach((tabElement) => {
      (tabElement as HTMLElement).id !== solutionAssetId &&
        tabElement.classList.remove("active");
    });
    Array.from(subsectionTabContainer).forEach((tabElement) => {
      if ((tabElement as HTMLElement).id === `subsection-${solutionAssetId}`) {
        const subsectionTabs = tabElement.getElementsByClassName(
          "sidebar-subsection-tab"
        );
        Array.from(subsectionTabs).forEach((subsectionTabElement, index) => {
          if (index === 0) subsectionTabElement.classList.add("active");
          else subsectionTabElement.classList.remove("active");
        });
      }
      if ((tabElement as HTMLElement).id !== `subsection-${solutionAssetId}`)
        (tabElement as HTMLElement).hidden = true;
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const updatedSidebarData = cloneDeep(sidebarData);
    const [reOrderedItem] = updatedSidebarData.splice(result.source.index, 1);
    if (reOrderedItem && result.destination) {
      updatedSidebarData.splice(result.destination.index, 0, reOrderedItem);
      setSidebarData(updatedSidebarData);
      onTabDragEnd(
        result.draggableId,
        result.source.index,
        result.destination.index
      );
      setActiveTabIndex(result.destination.index);
    }
  };

  const handleTabClick = (tabData: ISidebar2DataObj, index: number) => {
    removeActiveTabs(tabData.key);
    tabData.onClick(tabData.key, index);
    setActiveTabIndex(index);
    if (
      collapsibleTab &&
      activeTabIndex === index &&
      Boolean(tabData.subsections?.length)
    ) {
      const subsectionContainerEle = document.getElementById(
        `subsection-${tabData.key}`
      );
      const tabContainerEle = document.getElementById(`${tabData.key}`);
      if (subsectionContainerEle && tabContainerEle) {
        subsectionContainerEle.hidden = !subsectionContainerEle.hidden;
        tabContainerEle.classList.toggle("active");
      }
    }
  };

  return (
    <SidebarContainer
      className={`tabbed__sidebar ${isShared && "client-view"}`}
      theme={theme}
    >
      <DroppableTabContainer
        onDragEnd={handleDragEnd}
        droppableId={"droppable-solution-asset-type"}
      >
        {sidebarData?.map((tabData, index: number) => (
          <Fragment key={tabData.key}>
            {tabData.key === "solution-doc" ? (
              <Tab
                className={`sidebar-tab ${
                  activeTabIndex === index ? "active" : ""
                }`}
                onClick={() => {
                  tabData.onClick();
                  setActiveTabIndex(index);
                }}
              >
                <TabLabel
                  style={{
                    ...(isShared
                      ? styleTheme?.sharedView?.style?.body?.content?.section?.navTitle
                      : {}),
                    padding: "1.4rem",
                  }}
                  className="sidebar-tab-label"
                >
                  {tabData.label}
                </TabLabel>
              </Tab>
            ) : (
              <DraggableTabWrapper
                key={tabData.key}
                draggableId={tabData.key}
                index={index}
              >
                {(provided) => (
                  <>
                    <Tab
                      theme={theme}
                      id={tabData.key}
                      className={`sidebar-tab ${
                        activeTabIndex === index &&
                        !Boolean(tabData?.subsections?.length)
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleTabClick(tabData, index);
                      }}
                    >
                      <TabDragAndDropWrapper
                        {...provided.dragHandleProps}
                        hidden={!tabData.isReorderable}
                      >
                        <img
                          src={dragAndDropIcon}
                          alt="drag-and-drop-icon"
                        ></img>
                      </TabDragAndDropWrapper>
                      <TabLabel
                        isReorderable={tabData.isReorderable}
                        className="sidebar-tab-label"
                        style={
                          isShared
                            ? styleTheme?.sharedView?.style?.body?.content?.section
                                ?.navTitle
                            : {}
                        }
                      >
                        {tabData.label}
                      </TabLabel>
                      {Boolean(tabData.count) && !isShared && (
                        <TabCount>{tabData.count}</TabCount>
                      )}
                    </Tab>
                    <div
                      hidden={!(activeTabIndex === index)}
                      id={`subsection-${tabData.key}`}
                      className="subsection"
                    >
                      <Sidebar2Subsection
                        theme={theme}
                        data={tabData.subsections || []}
                        onSubsectionTabDragEnd={onSubsectionTabDragEnd}
                        defaultSubsectionTabIndex={defaultSubsectionTabIndex}
                      />
                    </div>
                  </>
                )}
              </DraggableTabWrapper>
            )}
          </Fragment>
        ))}
      </DroppableTabContainer>
    </SidebarContainer>
  );
};

export default Sidebar2;
