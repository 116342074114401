import styled from "@emotion/styled";
import { IoMdRefresh } from "react-icons/io";
import { NotFound } from "../../compositional/Reusable/components";
import { Button } from "../../styled";

export const GENERIC_ERROR_MSG = "Something went wrong! Please try again.";
export const RESOURCE_NOT_FOUND_ERROR_MSG =
  "Oops! We can’t seem to find the page you’re looking for.";
export const RESOURCE_INACTIVE_ERROR_MSG =
  "Access to this page is currently disabled. Please contact the sender to get access.";
export const PREVIEW_RESOURCE_NOT_FOUND_ERROR_MSG =
  "Oops! We can’t seem to find the page you’re looking for.";

const GuestUserErrorWrapper = styled.div`
  text-align: center;
`;

export const RefreshButton = styled(Button)`
  margin-top: 1.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.2rem;
`;

export type TError = null | { code?: ErrorCode; text: string };
export enum ErrorCode {
  BAD_REQUEST = "bad_request",
  NOT_FOUND = "resource_not_found",
  INACTIVE = "resource_inactive",
  NO_PROPOSALS = "no_data",
}

const GuestUserErrorPage = ({
  error,
  showRefreshButton = false,
  ButtonComponent,
}: {
  error?: string | TError;
  showRefreshButton?: boolean;
  ButtonComponent?: () => React.ReactElement;
}) => {
  const guestCode = sessionStorage.getItem("guestCode");
  const isPreview = sessionStorage.getItem("guestIsPreview");
  let sharingLink = `${window.location.origin}/shared/${guestCode}`;
  sharingLink += isPreview ? "?preview=true" : "";
  const errorText = typeof error === "string" ? error : error?.text;
  const errorCode = typeof error === "string" ? null : error?.code;
  return (
    <GuestUserErrorWrapper>
      <NotFound
        descriptionText={errorText || "Resource not found"}
        errorCode={errorCode}
      ></NotFound>
      {showRefreshButton && (
        <RefreshButton onClick={() => window.location.replace(sharingLink)}>
          <IoMdRefresh size={15} color="#fff" />
          <p>Refresh</p>
        </RefreshButton>
      )}
      {ButtonComponent && <ButtonComponent />}
    </GuestUserErrorWrapper>
  );
};

export default GuestUserErrorPage;
