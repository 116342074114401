export enum FormFieldType {
  CURRENCY = "currency",
  CHECKBOX = "checkbox",
  COLOR = "color",
  DATE = "date",
  DATETIME = "date-time",
  EMAIL = "email",
  CSV_FILE_UPLOAD = "csv-file-upload",
  FILE_SELECT_OR_UPLOAD = "file-select-upload",
  FILE_UPLOAD = "file-upload",
  ICON_UPLOAD = "icon-upload",
  NUMBER = "number",
  RADIO = "radio",
  SELECT = "select",
  TAGS = "tags",
  TAGS_WITH_TOGGLE = "tags-with-toggle",
  TEXT = "text",
  TEXT_AREA = "text-area",
  URL = "url",
  TEXT_WITH_TAG = "text-with-tag",
  TOGGLE = "toggle",
}

export enum FileInputType {
  ICON = "icon",
  FILE = "file",
  CSV = "csv",
  SELECT_UPLOAD = "select_upload",
}

export const SUPPORTED_IMAGE_FILETYPES = [
  "blp",
  "bmp",
  "dib",
  "bufr",
  "cur",
  "pcx",
  "dcx",
  "dds",
  "ps",
  "eps",
  "fit",
  "fits",
  "fli",
  "flc",
  "ftc",
  "ftu",
  "gbr",
  "gif",
  "grib",
  "h5",
  "hdf",
  "png",
  "apng",
  "jp2",
  "j2k",
  "jpc",
  "jpf",
  "jpx",
  "j2c",
  "icns",
  "ico",
  "im",
  "iim",
  "tif",
  "tiff",
  "jfif",
  "jpe",
  "jpg",
  "jpeg",
  "mpg",
  "mpeg",
  "mpo",
  "msp",
  "palm",
  "pcd",
  "pdf",
  "pxr",
  "pbm",
  "pgm",
  "ppm",
  "pnm",
  "psd",
  "bw",
  "rgb",
  "rgba",
  "sgi",
  "ras",
  "tga",
  "icb",
  "vda",
  "vst",
  "webp",
  "wmf",
  "emf",
  "xbm",
  "xpm",
];
