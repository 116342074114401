import { useCallback, useState } from "react";
import { WidgetItem } from "../../WidgetToolbar";
import WidgetToolbarForLibraryPopup from "../../WidgetToolbarForLibraryPopup";
import CustomModal from "../CustomModal";
import { HeaderContainer } from "./styled";
import GoogleSlidesEdit from "../../../../RichTextEditor/GoogleDrive/GoogleSlidesEdit";
import { IDocumentObject } from "../../../../../../interfaces";

export interface IGoogleSlidesData {
  documentId: string;
  solutionAssetId?: string;
  solutionAssetTypeId?: string;
  genericResourceId?: string;
  genericResourceTypeId?: string;
  isLoading?: boolean;
}

interface IGoogleSlideEditModal {
  data: IGoogleSlidesData;
  onCancel: Function;
  onSyncClick: Function;
}

const GoogleSlideEditModal = ({
  data,
  onCancel,
  onSyncClick,
}: IGoogleSlideEditModal) => {
  const [documentData, setDocumentData] = useState<IDocumentObject | null>(
    null
  );

  const renderContentCallback = useCallback(() => {
    const handleEditRequestSuccess = (updatedDocData: IDocumentObject) => {
      setDocumentData(updatedDocData);
    };
    return (
      <GoogleSlidesEdit
        data={data}
        onError={onCancel}
        onEditRequestSuccess={handleEditRequestSuccess}
      />
    );
  }, [data]);

  return (
    <div>
      <CustomModal
        width="calc(100% - 10rem)"
        height="calc(100% - 4rem)"
        contentHeight="100%"
        style={{
          margin: "2rem 5rem 2rem 5rem",
          border: "0.5px solid #BFDBFE",
          boxShadow: "0px 6px 30px rgba(37, 99, 235, 0.06)",
          borderRadius: "0.8rem",
        }}
        showModal={true}
        disableFocusLock={true}
        showCancel={false}
        closeModal={() => {}}
        renderHeader={() => {
          return (
            <HeaderContainer>
              <WidgetToolbarForLibraryPopup
                config={[
                  {
                    type: WidgetItem.CANCEL,
                    style: {
                      padding: "0.8rem 1.4rem",
                      fontSize: "1.4rem",
                      fontWeight: "400",
                    },
                    onClick: onCancel,
                  },
                  {
                    type: WidgetItem.SAVE,
                    isLoading: data.isLoading,
                    label: "Update",
                    style: {
                      padding: "0.8rem 1.4rem",
                      fontSize: "1.4rem",
                      fontWeight: "400",
                    },
                    onClick: () => onSyncClick(documentData),
                  },
                ]}
              />
            </HeaderContainer>
          );
        }}
        renderContent={renderContentCallback}
      />
    </div>
  );
};

export default GoogleSlideEditModal;
