import axiosInstance from "../axios";
import {
  IMutationState,
  IRelationshipParamMutationObject,
} from "../../interfaces";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { queryKeys } from "../constants";

async function createRelationshipPram(
  postData: IRelationshipParamMutationObject
) {
  if (postData.name_link && postData.str_value) {
    return await axiosInstance.post(
      `/assets/relationship-parameters/`,
      postData
    );
  }
}
async function updateRelationshipPram(
  postData: IRelationshipParamMutationObject
) {
  return await axiosInstance.patch(
    `/assets/relationship-parameters/${postData.id}/`,
    postData
  );
}

function useCreateUpdateRelationshipParam() {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  const queryClient = useQueryClient();

  const mutationOption = {
    onError: (error: any, data: any, variables: any) => {
      toast.error("Oops, something went wrong");
    },
    onSettled: (data: any) => {
      if (data) {
        if (data.status === 201) {
          toast.success(`Successfully created new tag '${data.data.value}'`);
        } else {
          toast.success(`Successfully updated tag '${data.data.value}'`);
          queryClient.invalidateQueries([
            queryKeys.relationshipParameters,
            { id: data.data.id },
          ]);
        }
        queryClient.invalidateQueries([queryKeys.metaData]);
        queryClient.invalidateQueries([queryKeys.relationshipParameters]);
        queryClient.invalidateQueries([queryKeys.solutionParameterTags]);
      }
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([queryKeys.metaData]);
      queryClient.invalidateQueries([queryKeys.relationshipParameters]);
      queryClient.invalidateQueries([queryKeys.solutionParameterTags]);
    },
  };

  const mutationFunction = (mutationData: IRelationshipParamMutationObject) => {
    return mutationData.id
      ? updateRelationshipPram(mutationData)
      : createRelationshipPram(mutationData);
  };

  const { mutate, mutateAsync, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );

  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);

  return { mutate, mutateAsync, ...mutationState };
}

export default useCreateUpdateRelationshipParam;
