import React, { createContext, useState } from "react";
interface IAssetsTypeContext {
  typeInputValue: string;
  setTypeInputValue: React.Dispatch<React.SetStateAction<string>>;
  assetTypeId: number | undefined;
  setAssetTypeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  deleteStatus: boolean;
  setDeleteStatus: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  borderColor: string;
  setBorderColor: React.Dispatch<React.SetStateAction<string>>;
}
export const AssetsTypeContext = createContext({} as IAssetsTypeContext);

const AssetsTypeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [typeInputValue, setTypeInputValue] = useState<string>("");
  const [assetTypeId, setAssetTypeId] = useState<number | undefined>(0);
  const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("no-error");
  const [borderColor, setBorderColor] = useState<string>("");
  return (
    <AssetsTypeContext.Provider
      value={{
        typeInputValue,
        setTypeInputValue,
        assetTypeId,
        setAssetTypeId,
        deleteStatus,
        setDeleteStatus,
        errorMessage,
        setErrorMessage,
        borderColor,
        setBorderColor,
      }}
    >
      {children}
    </AssetsTypeContext.Provider>
  );
};

export default AssetsTypeContextProvider;
