import { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { ISettings } from "../../interfaces";
import { apiConfig } from "../../config";

const {
  urls: { settings: settingsApiUrl },
} = apiConfig;

async function getSettings(
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    const response = await axiosInstance.get(settingsApiUrl);
    setIsError(false);
    return response.data;
  } catch (err) {
    setIsError(true);
  }
}

const useGetSettings = () => {
  const [isError, setIsError] = useState(false);
  const response = useQuery<ISettings, Error>(
    [queryKeys.settingsV2],
    () => getSettings(setIsError),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 60 * 1000,
    }
  );
  return { ...response, isError };
};

export default useGetSettings;
