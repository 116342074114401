import { AppName, NEW_FIELD_KEY } from "../../constants";

export interface IIntegrationAppData {
  label: string;
  name: AppName;
  description: string;
  url: string;
  is_connected: boolean;
  icon?: React.ReactNode;
  last_sync_at?: string;
}

export interface IAppStatusDropdownData {
  label?: string;
  onDropdownItemClick?: Function;
}

export enum APP_CONNECT_STATUS {
  CONNECT = "not_connected",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  ERROR = "error",
}

export interface IAppsCard {
  title: string;
  appData: IIntegrationAppData[];
  onCardClick: Function;
  CardContentFooter: Function;
}

export interface IStatusButton {
  appData: IIntegrationAppData;
  onDisconnect?: Function;
}

export interface IIntergrationConfigField {
  [NEW_FIELD_KEY]?: boolean;
  id?: string | number;
  name: string;
  label: string;
  type: string;
  is_rel_param: boolean;
  is_write_enabled: boolean;
  is_required?: boolean;
}

export interface IIntergrationHubspotConfigField {
  [NEW_FIELD_KEY]?: boolean;
  id?: string | number;
  app_field_name: string;
  app_field_label: string;
  crm_object_name: string;
  crm_object_label: string;
  crm_field_name: string;
  crm_field_label: string;
  is_rel_param: boolean;
  is_write_enabled: boolean;
  is_required?: boolean;
}

export interface IIntergrationConfigData {
  [x: string]: {
    is_enabled: boolean;
    fields: any[];
  };
}
