import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { history } from "../../utils/history";
import { appConfig } from "../../config";
import { capitalize } from "lodash";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const APP_TEAMID_HEADER_NAME = `${capitalize(appConfig.appName)}-Team-Id`;

const axiosInstance = axios.create({ baseURL });
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.url?.includes("/auth/token")) return config;

    const appTeamId = localStorage.getItem("teamId");
    const isGuest = window.location.pathname.startsWith("/shared");
    const userToken = localStorage.getItem("access");
    const guestToken = sessionStorage.getItem("guestAccess");
    let accessToken = isGuest ? guestToken : userToken;
    if (!accessToken && !userToken && guestToken) accessToken = guestToken;

    if (accessToken && config.headers) {
      config.headers["Content-Type"] = "application/json";
      config.headers["authorization"] = `Bearer ${accessToken}`;
    }
    if (appTeamId && config.headers && !isGuest) {
      config.headers[APP_TEAMID_HEADER_NAME] = appTeamId;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  function (error) {
    const originalRequest = error?.response?.config;

    // Need to ask status code being sent for token expired situation
    if (
      error?.response?.status === 401 &&
      originalRequest.url.includes("/auth/token")
    ) {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      history.push("/");
      return Promise.reject(error);
    }

    // Refreshing token
    const refreshToken = localStorage.getItem("refresh");
    const isGuest = window.location.pathname.startsWith("/shared");
    if (
      refreshToken &&
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      !isGuest
    ) {
      originalRequest._retry = true;
      return axiosInstance
        .post("/auth/token/refresh/", { refresh: refreshToken })
        .then((res) => {
          localStorage.setItem("access", res.data.access);
          localStorage.setItem("refresh", res.data.refresh);

          return axiosInstance(originalRequest);
        });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
