import { IAuthContext, IAuthState } from "../interfaces";
import React, { createContext, useReducer } from "react";
import { authReducer } from "../reducer";

const initialAuthState: IAuthState = {
  isLoggedIn: false,
  userInfo: null,
  isGuestUser: false,
  isPreview: false,
};

export const AuthContext = createContext({} as IAuthContext);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
