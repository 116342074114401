import { useContext, useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import isNull from "lodash/isNull";
import { useQueryClient } from "react-query";
import { ClipLoader, DotLoader } from "react-spinners";
import TimeMe from "timeme.js";
import { queryKeys } from "../../../../../../api/constants";
import useCreateUpdateSolution from "../../../../../../api/hooks/useCreateUpdateSolution";
import useGetSolution from "../../../../../../api/hooks/useGetSolution";
import { SolutionsContext } from "../../../../../../context";
import { updatePageEmbedIframeUrl } from "../../../../../../helpers/updatePageEmbedIframeUrl";
import SanitizedHtmlDiv from "../../../../../styled/SanitizedHtmlDiv";
import Fallback from "../../../../Fallback";
import EditorModal from "../../../../Reusable/components/Modals/EditorModal";
import { generateSolutionDoc } from "../../../../../../api/solutions/generateSolutionDoc";
import {
  SolutionDocumentContainer,
  SolutionDocumentTitle,
  SolutionDocumentWrapper,
  SolutionDocumentHeading,
  SolutionDocGenerationLoaderContainer,
  HeaderButtonContainer,
  EditButton,
} from "./styled";
import { toast } from "react-toastify";
import Typed from "typed.js";
import { sanitizeHtml } from "../../../../../../utils/string";
import { useGetSettings } from "../../../../../../api/hooks";
import { TbPencil } from "react-icons/tb";
import Markdown, {
  MarkDownWrapper,
} from "../../../../Reusable/components/Markdown";

interface ISolutionDocument {
  isSharing: boolean;
}

const SolutionDocument = ({ isSharing }: ISolutionDocument) => {
  const { solutionsState } = useContext(SolutionsContext);
  const { data: solutionObj, isRefetching } = useGetSolution(
    solutionsState.solutionId ? solutionsState.solutionId + "" : ""
  );
  const { data: userSettings } = useGetSettings();
  const [updatedDocument, setUpdatedDocument] = useState(
    solutionObj?.solution_doc_html || solutionObj?.solution_doc
  );
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { mutate } = useCreateUpdateSolution();
  const editorRef = useRef<any>(null);
  const queryClient = useQueryClient();
  const solutionDocumentTextRef = useRef<any>(null);
  const typedRef = useRef<any>(null);
  const isFirstTimeRef = useRef<null | boolean>(true || null);
  const isAutoGenerateEnabled =
    userSettings?.solution?.is_auto_generate_enabled;

  const TextC = ({ text }: { text?: string }) => (
    <Markdown>{text || solutionObj?.solution_doc_html || ""}</Markdown>
  );
  const getMarkdownText = (text?: string) =>
    renderToStaticMarkup(<TextC text={text} />);

  useEffect(() => {
    if (!isSharing) return;
    TimeMe.setIdleDurationInSeconds(5);
    TimeMe.trackTimeOnElement("doc-section");
  }, []);

  useEffect(() => {
    if (editorRef.current) editorRef?.current?.focus();
  }, [editorRef]);

  useEffect(() => {
    async function generateSolutionDocument() {
      if (!solutionObj) return;
      const typewriteSolutionDoc = (text: string) => {
        const options = {
          strings: [
            sanitizeHtml(
              updatePageEmbedIframeUrl({
                html: getMarkdownText(text) || "",
                companyName: solutionObj?.opportunity?.name || "",
                companyLogoUrl: solutionObj?.logo || "",
              })
            ),
          ],
          typeSpeed: 2,
          onComplete: () => {
            isFirstTimeRef.current = false;
            queryClient.removeQueries([
              "solutions",
              { id: `${solutionObj?.id}` },
            ]);
          },
        };
        if (solutionDocumentTextRef.current)
          typedRef.current = new Typed(
            solutionDocumentTextRef.current,
            options
          );
      };

      try {
        const solutionDoc = await generateSolutionDoc(solutionObj?.id);
        setIsGenerating(false);
        typewriteSolutionDoc(solutionDoc);
        setUpdatedDocument(solutionDoc);
      } catch {
        toast.error("Oops! Something went wrong while generating pitch");
        setIsGenerating(false);
      }
    }
    if (solutionObj && !isRefetching && isAutoGenerateEnabled) {
      if (isNull(solutionObj?.solution_doc) && !isGenerating) {
        isFirstTimeRef.current = true;
        setIsGenerating(true);
        generateSolutionDocument();
      } else if (solutionObj?.solution_doc_html) {
        isFirstTimeRef.current = false;
      }
    }

    return () => {
      typedRef.current?.destroy();
    };
  }, [solutionObj]);

  const handleSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    mutate(
      {
        id: solutionObj?.id,
        solution_doc_html: updatedDocument,
        solution_doc: editorRef.current.getContent() || updatedDocument,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            queryKeys.solutions,
            { id: solutionObj?.id },
          ]);
        },
        onSettled: () => {
          setIsSubmitting(false);
          setShowModal(false);
        },
        onError: () => {
          setUpdatedDocument(
            solutionObj?.solution_doc_html || solutionObj?.solution_doc
          );
        },
      }
    );
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };

  const handleEditorOnChange = (newValue: string) => {
    setUpdatedDocument(newValue);
  };

  if (!solutionObj) return <Fallback />;

  return (
    <SolutionDocumentContainer isSharing={isSharing}>
      {!isSharing && (
        <SolutionDocumentHeading>
          <SolutionDocumentTitle>Executive summary</SolutionDocumentTitle>
        </SolutionDocumentHeading>
      )}
      <SolutionDocumentWrapper isSharing={isSharing}>
        {!isGenerating ? (
          <>
            {!isSharing && (
              <HeaderButtonContainer>
                <EditButton
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <TbPencil size={18} />
                  <span>Edit</span>
                </EditButton>
              </HeaderButtonContainer>
            )}
            <MarkDownWrapper>
              <SanitizedHtmlDiv
                value={
                  solutionObj?.solution_doc_html &&
                  updatePageEmbedIframeUrl({
                    html: getMarkdownText() || "",
                    companyName: solutionObj?.opportunity?.name || "",
                    companyLogoUrl: solutionObj?.logo || "",
                  })
                }
              >
                {isFirstTimeRef.current && !solutionObj?.solution_doc_html && (
                  <span ref={solutionDocumentTextRef}></span>
                )}
              </SanitizedHtmlDiv>
            </MarkDownWrapper>
          </>
        ) : (
          <SolutionDocGenerationLoader />
        )}
      </SolutionDocumentWrapper>
      <EditorModal
        showModal={showModal}
        onSave={handleSaveClick}
        onCancel={handleCancelClick}
        isLoading={isSubmitting}
        onClose={() => {}}
        editorRef={editorRef}
        onChange={handleEditorOnChange}
        text={updatedDocument}
      />
    </SolutionDocumentContainer>
  );
};

export default SolutionDocument;

const loaderTexts = [
  "Thank you for all the input. I’ll need just a few seconds..",
  "Gathering data from company website for additional context..",
  "Analyzing the collected information..",
  "Generating pitch sections..",
  "Adding the finishing touches..",
];

export const SolutionDocGenerationLoader = () => {
  const [textIndex, setTextIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) =>
        prevIndex + 1 === loaderTexts.length ? prevIndex : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return (
    <SolutionDocGenerationLoaderContainer>
      <DotLoader color={"#A8AEF4"} size={45} />
      {loaderTexts[textIndex]}
    </SolutionDocGenerationLoaderContainer>
  );
};
