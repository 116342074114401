// TODO-dar: To be refactored
export const addPdfIFrame = function (
  url: string,
  filename: string = "file.pdf",
  divId = ""
) {
  // @ts-ignore
  var adobeDCView = new AdobeDC.View({
    clientId: process.env.REACT_APP_ADOBE_PDF_EMBED_API_KEY,
    divId: divId,
  });
  adobeDCView.previewFile(
    {
      content: {
        location: {
          url,
        },
      },
      metaData: { fileName: filename },
    },
    { embedMode: "IN_LINE", showDownloadPDF: false, showPrintPDF: false }
  );
};
