
const Privacy = () => {
    return (
      
          <iframe src={`https://docs.google.com/document/d/1HGtIW38YlHxbmHTS22YpRt6m2wuylsljl_SoE1yJhnw/pub?embedded=true`} 
          title="Vink Privacy"
          style={{
              width: "100%",
              height: "100vh",
              border: "none",
            }}/>
    )
  }
  
  export default Privacy