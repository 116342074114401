import React, { useState } from "react";
import CustomModal from "../CustomModal";
import { ClipLoader } from "react-spinners";

import {
  ButtonWrapper,
  CancelButton,
  EmbedUrlInput,
  EmbedUrlLabel,
  EmbedBody,
  SaveButton,
  EmbedHeader,
  EmbedHeaderTitle,
  EmbedCloseButton,
  EmbedUrlSubText,
} from "./styled";
import { IoMdClose } from "react-icons/io";

const EmbedUrlUploadModal = ({
  showEmbedUploadModal,
  setShowEmbedUploadModal,
  onSaveClick,
  isLoading,
}: any) => {
  const [embedUrl, setEmbedUrl] = useState("");
  return (
    <div>
      <CustomModal
        width="45rem"
        style={{
          marginTop: "20%",
          border: "0.5px solid rgba(148, 163, 184, 0.5)",
          borderRadius: "12px",
        }}
        showModal={showEmbedUploadModal}
        showFooter={true}
        showCancel={false}
        closeModal={() => {
          setShowEmbedUploadModal(false);
        }}
        renderContent={() => (
          <>
            <EmbedHeader>
              <EmbedHeaderTitle>Embed from URL</EmbedHeaderTitle>
              <EmbedCloseButton
                onClick={() => {
                  setShowEmbedUploadModal(false);
                  setEmbedUrl("");
                }}
              >
                <IoMdClose className="close-icon" />
              </EmbedCloseButton>
            </EmbedHeader>
            <EmbedBody>
              <EmbedUrlInput
                id="embed-url-input"
                autoFocus
                type="text"
                placeholder="Enter the embed source URL"
                value={embedUrl}
                onChange={(e) => {
                  setEmbedUrl(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSaveClick(embedUrl);
                    setEmbedUrl("");
                  }
                }}
              ></EmbedUrlInput>
              <EmbedUrlSubText>
                Supports Youtube, Loom, Vimeo, Google slides, Google docs, and
                Google sheets. All other links will get converted to bookmarks.
              </EmbedUrlSubText>
            </EmbedBody>
          </>
        )}
        renderAction={() => (
          <ButtonWrapper>
            <CancelButton
              onClick={() => {
                setShowEmbedUploadModal(false);
                setEmbedUrl("");
              }}
            >
              Cancel
            </CancelButton>
            <SaveButton
              onClick={() => {
                onSaveClick(embedUrl);
                setEmbedUrl("");
              }}
            >
              {isLoading ? <ClipLoader size={18} color="white" /> : "Save"}
            </SaveButton>
          </ButtonWrapper>
        )}
      />
    </div>
  );
};

export default EmbedUrlUploadModal;
