import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import { CustomRouter } from "./components/hoc/CustomRouter";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { history as customHistory } from "./utils/history";
import { AuthContextProvider } from "./context";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AppContextProvider } from "./context/AppContext";

// process.env.NODE_ENV === "production" &&
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend lowering this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <CustomRouter history={customHistory}>
      <AuthContextProvider>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </AuthContextProvider>
    </CustomRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
