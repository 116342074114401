import { DialogOverlay } from "@reach/dialog";
import { isEqual, noop } from "lodash";
import React, { useEffect, useState } from "react";
import useGetAssetDocuments from "../../../../api/hooks/useGetAssetDocuments";
import { getFileNameFromUrl } from "../../../../helpers";
import { IDocumentObject } from "../../../../interfaces";
import SearchBox from "../../Reusable/components/SearchBox";
import ThumbnailCard from "../../Reusable/components/Thumbnails/ThumbnailCard";
import {
  ChooseFileArea,
  CloseModalButton,
  DialogContent,
  FilesContainer,
  FilesContainerWrapper,
  FileSelectModalFooter,
  FileSelectUploadWrapper,
  FileUploadArea,
  SelectOrUploadContent,
} from "./styled";

const parseDocuments = (documents: IDocumentObject[]) => {
  return documents.map((documentObj) => ({
    ...documentObj,
    filename: getFileNameFromUrl(documentObj.url),
  }));
};

interface IFileSelectUploadModal {
  isOpen: boolean;
  selectedItems: Array<IDocumentObject | File>;
  onChange?: Function;
  onUploadClick?: Function;
  onDismiss?: Function;
  setIsOpen?: Function;
}

export const FileSelectUploadModal = ({
  isOpen = false,
  selectedItems = [],
  onChange,
  onUploadClick,
  onDismiss,
  setIsOpen = noop,
}: IFileSelectUploadModal) => {
  const selectedDocObjs = selectedItems.filter(
    (item) => !(item instanceof File)
  ) as IDocumentObject[];

  const { data: documents = [], isLoading, isError } = useGetAssetDocuments();

  const [searchText, setSearchText] = useState("");
  const [selectedFiles, setSelectedFiles] =
    useState<IDocumentObject[]>(selectedDocObjs);
  const [itemsToRender, setItemsToRender] = useState<IDocumentObject[]>([]);

  const selectedFileIds = selectedFiles.map(
    (documentObj: IDocumentObject) => documentObj.id
  );

  useEffect(() => {
    if (!isEqual(selectedFiles, selectedDocObjs))
      onChange && onChange(selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    if (!isEqual(selectedFiles, selectedDocObjs))
      setSelectedFiles(selectedDocObjs);
  }, [selectedItems]);

  useEffect(() => {
    let cardsToRender = selectedFiles.concat(
      parseDocuments(documents).filter(
        (documentObj) => !selectedFileIds.includes(documentObj.id)
      )
    );
    setItemsToRender(cardsToRender);
    return () => {
      setItemsToRender([]);
    };
  }, [documents, searchText]);

  let cardsToRender = searchText
    ? itemsToRender.filter((documentObj: IDocumentObject) => {
        return `${documentObj.filename} ${documentObj.description}`.includes(
          searchText
        );
      })
    : itemsToRender;

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleThumbCardClick = (
    e: MouseEvent,
    documentObj: IDocumentObject
  ) => {
    e.stopPropagation();
    if (e.ctrlKey && selectedFileIds.includes(documentObj.id)) {
      setSelectedFiles(
        selectedFiles.filter((docObj) => docObj.id !== documentObj.id)
      );
    } else {
      const newSelectedFiles = [];
      if (e.ctrlKey) newSelectedFiles.push(...selectedFiles);
      setSelectedFiles([...newSelectedFiles, documentObj]);
    }
  };

  const handleUploadClick = () => {
    onUploadClick && onUploadClick();
  };

  return (
    <FileSelectUploadWrapper>
      <DialogOverlay
        isOpen={isOpen}
        onDismiss={() => onDismiss && onDismiss(selectedFiles)}
        style={{ zIndex: "1400" }}
      >
        <DialogContent aria-label="File Upload">
          <SelectOrUploadContent>
            <FileUploadArea>
              <div className="upload_btn" onClick={handleUploadClick}>
                Upload from computer
              </div>
              <p className="extra-text">
                Select multiple file by holding on
                <br /> “Command” or “Ctrl”
              </p>
            </FileUploadArea>
            <ChooseFileArea>
              <p className="title">Choose from documents</p>
              <SearchBox onChange={handleSearchTextChange}></SearchBox>
              <FilesContainerWrapper>
                <FilesContainer onClick={() => setSelectedFiles([])}>
                  {cardsToRender.map((documentObj: IDocumentObject) => {
                    return (
                      <ThumbnailCard
                        key={documentObj.id}
                        className={
                          selectedFileIds.includes(documentObj.id)
                            ? "thumb-card selected"
                            : "thumb-card"
                        }
                        label={documentObj.filename}
                        description={documentObj.description}
                        thumbUrl={documentObj.thumbnail_url}
                        onClick={(e: MouseEvent) =>
                          handleThumbCardClick(e, documentObj)
                        }
                      ></ThumbnailCard>
                    );
                  })}
                </FilesContainer>
              </FilesContainerWrapper>
              <FileSelectModalFooter>
                <CloseModalButton onClick={() => setIsOpen(false)}>
                  Done
                </CloseModalButton>
              </FileSelectModalFooter>
            </ChooseFileArea>
          </SelectOrUploadContent>
        </DialogContent>
      </DialogOverlay>
    </FileSelectUploadWrapper>
  );
};

export default FileSelectUploadModal;
