import styled from "@emotion/styled";
import { TagContainer } from "../Reusable/components/Tags";

interface IFormFieldWrapper {
  isFocused?: boolean;
  isRequired?: boolean;
}

export const FormFieldWrapper = styled.div<IFormFieldWrapper>`
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 1.2rem;
  row-gap: 0.8rem;
  color: #0f172a;
  font-style: normal;
  font-family: var(--font-lexend);
  border: ${(props) =>
    props.isFocused ? "0.1rem solid #2563eb" : "0.05rem solid #cbd5e1"};
  box-shadow: ${(props) =>
    props.isFocused ? "0 0 0 0.4rem rgba(37, 99, 235, 0.1)" : "none"};
  border-radius: ${(props) => (props.isFocused ? "1.2rem" : "0.8rem")};

  input {
    font-family: var(--font-lexend);
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 150%;
    color: #0f172a;
    border: 0;
  }

  /* When start typing */
  input:focus {
    font-size: 1.4rem;
    color: #0f172a;
    outline: none;
  }

  input::placeholder {
    color: #a1a1aa;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
  }

  label {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    ::after {
      content: ${(props) => (props.isRequired ? "' *'" : "''")};
    }
  }

  #error-text {
    color: var(--danger);
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.5rem;
    ::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const FormTagContainer = styled(TagContainer)`
  .Tag__content {
    background: #f8fafc;
    border-radius: 0.6rem;
    padding: 0.6rem 1rem;
    font-family: var(--font-lexend);
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.4rem;
    cursor: pointer;
    .Tag__label::after {
      content: "x";
      font-size: 1.3rem;
      font-weight: 400;
      padding-left: 1.1rem;
    }
  }
`;
