import { useContext } from "react";
import { ContentAreaContext } from "../../../context";
import { IRelationshipParamerter } from "../../../interfaces";
import useGetRelationshipParameters from "../useGetRelationshipParameters";

interface IUseOverviewTabData {
  isLoading: boolean;
  showOverviewTab: boolean;
  selectedRelParamsData: IRelationshipParamerter[];
}

const useOverviewTabData = (): IUseOverviewTabData => {
  const { selectedRelParamsIds } = useContext(ContentAreaContext);
  const { data: getRelationshipParamsRes, isLoading } =
    useGetRelationshipParameters();
  const selectedRelParamsData =
    getRelationshipParamsRes?.data?.filter(
      (rpObject) =>
        selectedRelParamsIds.includes(rpObject.id.toString()) &&
        (rpObject.description_html || rpObject.description)
    ) || [];
  const showOverviewTab = selectedRelParamsData.length > 0;
  return { isLoading, showOverviewTab, selectedRelParamsData };
};

export default useOverviewTabData;
