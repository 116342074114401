import { useEffect, useRef, useState } from "react";
import { FieldInputProps, FieldMetaProps, useField } from "formik";
import { IoClose } from "react-icons/io5";
import validator from "validator";
import { FormErrorText } from "../common";
import { TextFieldWrapper } from "./styled";
import { cn } from "../../../../ui/lib/utils";
import { InputTag } from "../../../../ui/InputTag";

interface IFormTextFieldWithTag {
  label: string;
  name: string;
  formikField?: FieldInputProps<any>;
  formikMeta?: FieldMetaProps<any>;
  className?: string;
  focusedClassName?: string;
  [x: string]: any;
}

const FormTextFieldWithTag = ({
  label,
  className,
  focusedClassName,
  ...allProps
}: IFormTextFieldWithTag) => {
  const [isFocused, setFocused] = useState(false);
  const [inputState, setInputState] = useState("");
  const inputElementRef = useRef<HTMLInputElement>(null);

  const {
    formikField,
    formikHelpers,
    formikMeta,
    required: isRequired,
    ...props
  } = allProps;

  useEffect(() => {
    inputElementRef.current?.addEventListener("focusin", () =>
      setFocused(true)
    );
    inputElementRef.current?.addEventListener("focusout", () =>
      setFocused(false)
    );
  });

  const handleRemoveTag = (index: number) => {
    formikHelpers?.setValue({
      ...formikField?.value,
      tags: formikField?.value?.tags?.filter(
        (tagObj: any, i: number) => index !== i
      ),
    });
  };

  return (
    <TextFieldWrapper
      className={cn(className, isFocused ? focusedClassName : "")}
      isFocused={isFocused}
      isRequired={label ? isRequired : false}
    >
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <InputTagList
        tagList={formikField?.value?.tags}
        handleRemoveTag={handleRemoveTag}
        className="flex gap-2 flex-wrap"
      />
      <input
        ref={inputElementRef}
        type="text"
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.key === ",") {
            e.preventDefault();
            const initialValue = formikField?.value;
            if (inputState.trim()) {
              initialValue.inputValue = "";
              const emailsArr = inputState.split(",");
              emailsArr.map((email) => {
                if (email.trim())
                  initialValue.tags.push({ email: email.trim() });
              });
              formikHelpers?.setValue(initialValue);
            }
            setInputState("");
          }
        }}
        {...formikField}
        {...{ value: inputState || "" }}
        {...{
          onChange: (e: any) => {
            setInputState(e.target.value.trim());
            const initialValue = formikField?.value;
            if (e.target.value.trim()) {
              initialValue.inputValue = e.target.value.trim();
            } else initialValue.inputValue = "";
            formikHelpers?.setValue(initialValue);
          },
        }}
        {...props}
      ></input>
      <FormErrorText formikMeta={formikMeta}></FormErrorText>
    </TextFieldWrapper>
  );
};

const FormikTextFieldWithTag = (props: IFormTextFieldWithTag) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormTextFieldWithTag
      formikField={field}
      formikMeta={meta}
      formikHelpers={helpers}
      {...props}
    ></FormTextFieldWithTag>
  );
};

export interface IInputTagList {
  tagList: any[];
  handleRemoveTag: (index: number) => void;
  className?: string;
}

const InputTagList = ({
  tagList,
  handleRemoveTag,
  className,
}: IInputTagList) => {
  if (!tagList.length) return <></>;
  return (
    <div className={className}>
      {tagList?.map((item: any, index: number) => {
        const emailId = item?.email || item?.Email;
        return (
          <InputTag
            variant={validator.isEmail(emailId) ? "default" : "destructive"}
            onClick={(e) => {
              handleRemoveTag(index);
            }}
          >
            {emailId}
            <IoClose size={16} />
          </InputTag>
        );
      })}
    </div>
  );
};

export { FormTextFieldWithTag, FormikTextFieldWithTag };
