import { capitalize } from "lodash";
import { Fragment, MouseEvent, useContext, useEffect, useState } from "react";
import TimeMe from "timeme.js";
import { trackUserAction } from "../../../../../../../analytics";
import { userActivityEvents } from "../../../../../../../config";
import { AuthContext, SolutionsContext } from "../../../../../../../context";
import {
  IDocumentObject,
  IRelationshipParamerter,
  SolutionsAssetActionType,
} from "../../../../../../../interfaces";
import SanitizedHtmlDiv from "../../../../../../styled/SanitizedHtmlDiv";
import { NotFound } from "../../../../../Reusable/components";
import { ViewFileButton } from "../../../../../Reusable/components/Buttons";
import { DocumentViewerModal } from "../../../../../Reusable/components/Modals";
import {
  WidgetToolbar,
  WidgetItem,
} from "../../../../../Reusable/components/WidgetToolbar";
import { NoContentWrapper } from "../..";
import {
  Card,
  CardContentWrapper,
  CardContent,
  Description,
  DocumentsContainer,
  SolutionAssetsCardsContainer,
  Title,
  TitleContainer,
  TagAndButtonContainer,
  WidgetToolbarContainer,
  WidgetTooltip,
} from "./styled";
import {
  getAbsoluteFileUrlFromPath,
  getFileNameFromUrl,
} from "../../../../../../../helpers";
import useHtmlRenderers from "../../../../../../../hooks/useHtmlRenderers";
import { Tag } from "../../../../../Reusable/components/Tags";
import { ISolutionAsset } from "../../../../../../../interfaces/solution";
import { useTheme } from "@emotion/react";
import useCreateUpdateSolutionAssetItem from "../../../../../../../api/hooks/useCreateUpdateSolutionAssetItem";
import { ITheme } from "../../../../../../../interfaces/theme";

interface ISolutionAssetsCards {
  Header?: false | (() => React.ReactNode);
  title?: string;
  data: ISolutionAsset[];
}

interface ISolutionAssetsCard {
  data: ISolutionAsset;
}

const SolutionAssetsCards = ({ Header, title, data }: ISolutionAssetsCards) => {
  const { authState } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const isShared = authState.isGuestUser;

  useEffect(() => {
    if (isShared) return;
    if (data.length) {
      data.map((item: any) => {
        const widgetToolbarList: NodeListOf<HTMLDivElement> | null =
          document.querySelectorAll(`#widget-${item.id}`);

        Array.from(widgetToolbarList).forEach((widgetToolbar) => {
          if (widgetToolbar) {
            const cardContentElement = document.getElementById(
              `card-content-${item.id}`
            );
            const solutionContentTabs = document.getElementById(
              "solution-content-tabs"
            );

            const mainContainer = document.getElementsByTagName("main")[0];
            mainContainer.addEventListener("scroll", () => {
              if (
                cardContentElement &&
                solutionContentTabs &&
                cardContentElement.getBoundingClientRect().top -
                  solutionContentTabs.clientHeight <
                  0 &&
                cardContentElement.getBoundingClientRect().top -
                  solutionContentTabs.clientHeight -
                  20 >
                  -cardContentElement.offsetHeight
              ) {
                widgetToolbar.style.position = "absolute";
                widgetToolbar.style.top = "5rem";
                widgetToolbar.style.right = widgetToolbar.dataset.for?.includes(
                  "widget-tooltip"
                )
                  ? "7.9rem"
                  : "13rem";
                widgetToolbar.style.zIndex = "21";
              } else {
                widgetToolbar.style.position = "static";
                widgetToolbar.style.zIndex = "1";
              }
            });
          }
        });
      });
    }
  }, [data]);

  const getDocuments = (dataItem: any) => {
    return dataItem.documents
      .filter((documentObj: IDocumentObject) => documentObj.url)
      .map((documentObj: IDocumentObject) => {
        return (
          <ViewFileButton
            showToolTip={true}
            hideButtonLabel={false}
            key={documentObj.id}
            filePath={documentObj.url}
            onClick={() => {
              setFileUrl(getAbsoluteFileUrlFromPath(documentObj.url));
              setShowModal(true);
              trackUserAction(
                userActivityEvents.LIBRARY__DOCUMENT_BUTTON_CLICKED,
                {
                  fileName: getFileNameFromUrl(documentObj.url),
                  assetName: dataItem.title,
                  assetType: dataItem.object,
                }
              );
            }}
          ></ViewFileButton>
        );
      });
  };

  return (
    <>
      <SolutionAssetsCardsContainer isShared={isShared}>
        {Header && Header()}
        {data.length ? (
          data.map((item, i: number) => (
            <SolutionAssetsCard data={item} key={item.order} />
          ))
        ) : (
          <Card>
            <NoContentWrapper style={{ paddingBottom: "4rem" }}>
              <NotFound
                descriptionText={`"${capitalize(
                  title
                )}" not added. Empty "${capitalize(
                  title
                )}" section will not show in the shared buyer view.`}
              />
            </NoContentWrapper>
          </Card>
        )}
      </SolutionAssetsCardsContainer>
      <DocumentViewerModal
        fileUrl={fileUrl}
        isOpen={showModal}
        onDismissal={() => setShowModal(false)}
      ></DocumentViewerModal>
    </>
  );
};

export default SolutionAssetsCards;

const SolutionAssetsCard = ({ data: item }: ISolutionAssetsCard) => {
  const [isCollapsed, setIsCollapsed] = useState(item.is_collapsed);
  const { authState } = useContext(AuthContext);
  const { solutionsState, solutionsAssetDispatch } =
    useContext(SolutionsContext);
  const { mutate } = useCreateUpdateSolutionAssetItem(
    solutionsState.solutionId || ""
  );
  const theme: ITheme = useTheme();
  const isShared = authState.isGuestUser;
  const tooltipText =
    "Note: Expanding or collapsing a sub-section here will update the live shared view as well";

  // Use Adobe PDF Viewer to render pdfs
  useHtmlRenderers();

  useEffect(() => {
    if (!isShared) return;
    TimeMe.trackTimeOnElement(`${item.asset_type.id}-${item.id}`);
  }, []);

  const getTags = (relationshipParameters: IRelationshipParamerter[]) =>
    relationshipParameters.map(
      (rpObject: IRelationshipParamerter, i: number) => (
        <Tag
          key={rpObject.id}
          label={rpObject.value}
          toolTipText={rpObject.name}
        />
      )
    );

  const handleAccordionClick = () => {
    setIsCollapsed((preState) => !preState);
    if (isShared) return;
    const scrollMainContainer = document.getElementsByTagName("main")[0];
    const subsectionCardId = `card-content-${item.id}`;
    const solutionTopNav = document.getElementById(
      "solution-content-tabs"
    ) as HTMLDivElement;
    const solutionViewOffset = -((solutionTopNav?.offsetHeight || 0) + 42);
    const element = document.getElementById(subsectionCardId);
    const mutationOptions = {
      onError: () => {
        setIsCollapsed((preState) => !preState);
      },
    };
    const mutationData = {
      id: Number(item.id),
      is_collapsed: Boolean(!isCollapsed),
    };
    if (!isCollapsed && element)
      scrollMainContainer.scrollTo({
        behavior: "smooth",
        top: element.offsetTop + solutionViewOffset,
      });
    mutate(mutationData, mutationOptions);
  };

  return (
    <Card
      isShared={isShared}
      id={`${item.asset_type.id}-${item.id}`}
      className={
        item.order === 1 && isShared ? "first-solution-asset-card" : ""
      }
    >
      <CardContentWrapper
        id={`card-content-${item.id}`}
        className="solution-asset-card"
      >
        <TitleContainer>
          <Title
            style={
              isShared
                ? theme?.sharedView?.style?.body?.content?.subSection?.title
                : {}
            }
          >
            {item.title || item.description}
          </Title>
          <WidgetToolbarContainer>
            {!isShared && (
              <WidgetToolbar
                id={`widget-${item.id}`}
                data-id={item.id}
                style={{
                  alignSelf: "flex-start",
                  borderRadius: "0.8rem",
                  padding: "0 0.3rem",
                }}
                config={[
                  {
                    type: WidgetItem.LIBRARY_AND_SOLUTION_EDIT,
                    style: {
                      fontSize: "1.8rem",
                      padding: "0.7rem 0.9rem",
                    },
                    onClick: (e: MouseEvent) => {
                      e.stopPropagation();
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_ASSET_TYPE,
                        payload: item.asset_type,
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_ASSET_ID,
                        payload: item.id,
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_IS_CREATE_EDIT,
                        payload: true,
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_SHOW_ASSET_MODAL,
                        payload: true,
                      });
                    },
                  },
                  {
                    type: WidgetItem.DIVIDER,
                    style: { fontSize: "1.8rem" },
                    onClick: () => {},
                  },
                  {
                    type: WidgetItem.DELETE,
                    style: {
                      fontSize: "1.8rem",
                      padding: "0.7rem 0.9rem",
                    },
                    onClick: (e: MouseEvent) => {
                      e.stopPropagation();
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_ASSET_TYPE,
                        payload: item.asset_type,
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_ASSET_ID,
                        payload: item.id,
                      });
                      solutionsAssetDispatch({
                        type: SolutionsAssetActionType.SET_IS_DELETE,
                        payload: true,
                      });
                    },
                  },
                ]}
              />
            )}
            <WidgetToolbar
              id={`widget-${item.id}`}
              data-tip
              data-for={`widget-tooltip-${item.id}`}
              data-id={item.id}
              style={{
                alignSelf: "flex-start",
                borderRadius: "0.8rem",
                padding: "0 0.3rem",
              }}
              config={[
                {
                  type: WidgetItem.ACCORDION_BUTTON,
                  className: isCollapsed ? "collapsed" : "",
                  style: {
                    display: "flex",
                    fontSize: "2rem",
                    padding: "0.7rem 0.7rem",
                  },
                  onClick: handleAccordionClick,
                },
              ]}
            >
              {!isShared && !isCollapsed && (
                <WidgetTooltip id={`widget-tooltip-${item.id}`}>
                  {tooltipText}
                </WidgetTooltip>
              )}
            </WidgetToolbar>
          </WidgetToolbarContainer>
        </TitleContainer>
        <CardContent>
          <div
            hidden={isCollapsed}
            style={{
              width: `100%`,
              paddingTop: "2rem",
            }}
          >
            <Description
              style={
                isShared
                  ? theme?.sharedView?.style?.body?.content?.subSection?.body?.style
                  : {}
              }
              className="card-content-description"
              contentStyle={
                theme?.sharedView?.style?.body?.content?.subSection?.body?.content
              }
            >
              <SanitizedHtmlDiv value={item.description}></SanitizedHtmlDiv>
            </Description>
            {!isShared &&
              !!item.created_from?.relationship_parameters?.length && (
                <TagAndButtonContainer>
                  {!isShared &&
                    !!item.created_from?.relationship_parameters?.length &&
                    getTags(item.created_from.relationship_parameters)}
                </TagAndButtonContainer>
              )}
          </div>
        </CardContent>
      </CardContentWrapper>
    </Card>
  );
};
