import React from "react";
import { AuthContext } from "../../../../context";
import { IoMdRefresh } from "react-icons/io";
import { Button, ErrorContent, ErrorWrapper, Text } from "./styled";

class ContentAreaErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  static getDerivedStateFromError(error: any) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  componentDidCatch(error: any, info: any) {
    // Log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <ErrorWrapper>
          <ErrorContent>
            <Text>There has been a timeout.</Text>
            <Text>Please click the button below to refresh</Text>
            {/* <Button
              to={`/view/my-board?type=myBoard&id=${authState.userInfo?.userId}`}
            >
              My Board
            </Button> */}
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              <IoMdRefresh color="#fff" />
              <span className="gap">Refresh</span>
            </Button>
          </ErrorContent>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

export default ContentAreaErrorBoundary;
