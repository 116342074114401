import styled from "@emotion/styled";
import Droppable, { Draggable } from "../DragAndDrop";
import { Sidebar2ThemeMode } from "../../../../../interfaces/solution";

interface ISubsectionTabLabel {
  theme?: Sidebar2ThemeMode;
  isReorderable?: boolean;
}

interface ITabLabel {
  isReorderable?: boolean;
}

export const SidebarContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 2rem 1.2rem 1.2rem;
  background: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "#111827" : "#FFFFFF"};
  border: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "" : "1px solid #CBD5E1"};
  border-radius: 1.2rem;
  height: calc(100vh - 7rem);
  min-width: 24.4rem;
  width: 24.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  overflow-y: auto;
`;

export const Tab = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.2rem;
  border-radius: 1.2rem;
  color: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "#94a3b8" : "#0F172A"};
  font-size: 1.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) =>
      theme === Sidebar2ThemeMode.DARK_MODE
        ? "rgba(255, 255, 255, 0.1)"
        : "#f8f8f8"};
  }
  &.active {
    ${({ theme }) =>
      theme === Sidebar2ThemeMode.DARK_MODE
        ? `background: #2563eb; color: #e2e8f0;`
        : `background: var(--selected-tab); .sidebar-tab-label {
          color: #2563eb;
        }`};
  }
`;

export const TabDragAndDropWrapper = styled.div`
  padding: 1.4rem 1rem;
`;

export const TabLabel = styled.div<ITabLabel>`
  ${({ isReorderable }) =>
    isReorderable ? `padding: 1.4rem 0rem; ` : `padding: 1.4rem;`}
  width: 16rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
`;

export const TabCount = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: right;
  color: #2563eb;
  margin-right: 1.6rem;
  margin-left: auto;
`;

export const TabButtonWrapper = styled.div`
  padding: 1.4rem 0.4rem;
  padding-right: 1.4rem;
`;

export const SidebarSubsectionContainer = styled.div`
  padding-left: 2.6rem;
  width: 100%;
  overflow: hidden;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
`;

export const SubsectionTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem;
  border-radius: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "" : "1.2rem"};
  background: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "rgba(30, 58, 138, 0.1)" : ""};
  border-left: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "1px solid #334155" : ""};
  color: ${({ theme }) =>
    theme === Sidebar2ThemeMode.DARK_MODE ? "#9ca3af" : "#64748B"};
  &.active {
    ${({ theme }) =>
      theme === Sidebar2ThemeMode.DARK_MODE
        ? ""
        : `background: var(--selected-tab); color: #1E293B; .light-mode-tab-label {
        color: #2563eb; 
      }`};
  }
  cursor: pointer;
`;

export const SubsectionTabLabel = styled.div<ISubsectionTabLabel>`
  ${({ isReorderable }) =>
    isReorderable ? `padding: 1.4rem 0rem; ` : `padding: 1.4rem;`}
  width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding-right: 1.8rem;
  &.active {
    color: ${({ theme }) =>
      theme === Sidebar2ThemeMode.DARK_MODE ? "#f1f5f9" : ""};
  }
`;

export const DroppableTabContainer = styled(Droppable)`
  width: 100%;
`;

export const DraggableTabWrapper = styled(Draggable)``;
