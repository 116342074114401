import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from "formik";
import { useEffect, useRef, useState } from "react";
import { components, OptionProps, Props } from "react-select";
import { default as SelectType } from "react-select/dist/declarations/src/Select";
import { FormErrorText } from "../common";
import {
  Select,
  CreatableSelect,
  SelectFieldWrapper,
  CreateNewOptionWrapper,
} from "./styled";
import { cn } from "../../../../ui/lib/utils";

interface IFormSelectField {
  label: string;
  name: string;
  isCreatable: boolean;
  formikField?: FieldInputProps<any>;
  formikMeta?: FieldMetaProps<any>;
  formikHelpers?: FieldHelperProps<any>;
  className?: string;
  focusedClassName?: string;
  [x: string]: any;
}

const CustomOption = (props: OptionProps, createNewLabel: string) => {
  const optionData = props.data as any;

  if (optionData?.__isNew__) {
    return (
      <CreateNewOptionWrapper>
        <components.Option {...props}>
          <div id="create-btn">
            + {createNewLabel} <span>{optionData.value}</span>
          </div>
        </components.Option>
      </CreateNewOptionWrapper>
    );
  }
  return <components.Option {...props} />;
};

const FormSelectField = ({
  label,
  isCreatable,
  children,
  className,
  focusedClassName,
  ...allProps
}: IFormSelectField) => {
  const [isFocused, setFocused] = useState(false);
  const reactSelectRef = useRef<SelectType>(null);

  const {
    formikField,
    formikMeta,
    formikHelpers,
    createNewLabel,
    required: isRequired,
    ...props
  } = allProps;

  useEffect(() => {
    reactSelectRef.current?.controlRef?.addEventListener("focusin", () =>
      setFocused(true)
    );
    reactSelectRef.current?.controlRef?.addEventListener("focusout", () =>
      setFocused(false)
    );
  });

  const reactSelectProps: Props | IFormSelectField = {
    ref: reactSelectRef,
    options: props.options,
    components: {
      Option: (props) => CustomOption(props, createNewLabel),
    },
    formatCreateLabel: () => null,
    classNamePrefix: "Select",
    isSearchable: true,
    isClearable: true,
    isFocused: isFocused,
    ...formikField,
    ...props,
    value: formikField?.value || "",
    onChange: (value: any) => {
      formikHelpers?.setValue(value);
      props.onChange && props.onChange(value);
    },
  };

  return (
    <>
      <SelectFieldWrapper
        className={cn(className, isFocused ? focusedClassName : "")}
        isFocused={isFocused}
        isRequired={label ? isRequired : false}
      >
        {label && <label htmlFor={props.id || props.name}>{label}</label>}
        {isCreatable ? (
          <CreatableSelect {...reactSelectProps}></CreatableSelect>
        ) : (
          <Select {...reactSelectProps}></Select>
        )}
        <FormErrorText formikMeta={formikMeta}></FormErrorText>
      </SelectFieldWrapper>
    </>
  );
};

const FormikSelectField = (props: IFormSelectField) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormSelectField
      formikField={field}
      formikMeta={meta}
      formikHelpers={helpers}
      {...props}
    ></FormSelectField>
  );
};

export { FormSelectField, FormikSelectField };
