import axiosInstance from "../axios";
import { IMutationState } from "../../interfaces";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { apiConfig } from "../../config";
import { toast } from "react-toastify";

const deleteSolutionAssetItem = async (
  solutionId: string | number | any,
  id: string | number
) => {
  if (!solutionId || !id) throw Error;
  const apiEndpoint = `${apiConfig.urls.solutions}${solutionId}${apiConfig.urls.assets}${id}/`;
  return await axiosInstance.delete(apiEndpoint);
};

export interface ISolutionAssetTypeObj {
  solutionId: string | number | any;
}

function useDeleteSolutionAssetItem(
  solutionAssetTypeObj: ISolutionAssetTypeObj
) {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSuccess: (data: any, variables: any, context: any) => {
      toast.success(`Successfully deleted`);
    },
  };

  const mutationFunction = (assetItemData: any) => {
    return deleteSolutionAssetItem(
      solutionAssetTypeObj.solutionId,
      assetItemData.id
    );
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );

  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);

  return { mutate, ...mutationState };
}

export default useDeleteSolutionAssetItem;
