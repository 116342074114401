export function getInitials(personName: string) {
  if (typeof personName === "undefined") return "";
  const personNameArray = personName?.split(" ");

  const [firstName, lastName] = personNameArray;
  if (personNameArray.length === 1) {
    return firstName[0].toUpperCase();
  } else {
    return firstName[0].toUpperCase() + lastName[0].toUpperCase();
  }
}
