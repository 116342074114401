import DOMPurify from "dompurify";
import moment from "moment";

export function singular(word: string, amount?: number): string {
  if (amount !== undefined && amount !== 1) {
    return word;
  }
  const singular: { [key: string]: string } = {
    "(quiz)zes$": "$1",
    "(matr)ices$": "$1ix",
    "(vert|ind)ices$": "$1ex",
    "^(ox)en$": "$1",
    "(alias)es$": "$1",
    "(octop|vir)i$": "$1us",
    "(cris|ax|test)es$": "$1is",
    "(shoe)s$": "$1",
    "(o)es$": "$1",
    "(bus)es$": "$1",
    "([m|l])ice$": "$1ouse",
    "(x|ch|ss|sh)es$": "$1",
    "(m)ovies$": "$1ovie",
    "(s)eries$": "$1eries",
    "([^aeiouy]|qu)ies$": "$1y",
    "([lr])ves$": "$1f",
    "(tive)s$": "$1",
    "(hive)s$": "$1",
    "(li|wi|kni)ves$": "$1fe",
    "(shea|loa|lea|thie)ves$": "$1f",
    "(^analy)ses$": "$1sis",
    "((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$": "$1$2sis",
    "([ti])a$": "$1um",
    "(n)ews$": "$1ews",
    "(h|bl)ouses$": "$1ouse",
    "(corpse)s$": "$1",
    "(us)es$": "$1",
    s$: "",
  };
  const irregular: { [key: string]: string } = {
    move: "moves",
    foot: "feet",
    goose: "geese",
    sex: "sexes",
    child: "children",
    man: "men",
    tooth: "teeth",
    person: "people",
  };
  const uncountable: string[] = [
    "sheep",
    "fish",
    "deer",
    "moose",
    "series",
    "species",
    "money",
    "rice",
    "information",
    "equipment",
    "bison",
    "cod",
    "offspring",
    "pike",
    "salmon",
    "shrimp",
    "swine",
    "trout",
    "aircraft",
    "hovercraft",
    "spacecraft",
    "sugar",
    "tuna",
    "you",
    "wood",
  ];
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word;
  }
  // check for irregular forms
  for (const w in irregular) {
    const pattern = new RegExp(`${irregular[w]}$`, "i");
    const replace = w;
    if (pattern.test(word)) {
      return word.replace(pattern, replace);
    }
  }

  // check for matches using regular expressions
  for (const reg in singular) {
    const pattern = new RegExp(reg, "i");
    if (pattern.test(word)) {
      return word.replace(pattern, singular[reg]);
    }
  }
  return word;
}

export const dateToTimeIntervalFromNow = (datetimeStr: string) => {
  if (!datetimeStr) return;
  const newDate: any = new Date(datetimeStr);
  return moment(newDate).fromNow();
};

export const getObjectValueFromDotString = (
  nestedObj: object,
  dotNotationStr: string
): any => {
  if (!dotNotationStr) return undefined;
  const propertyNameList = dotNotationStr.split(".");
  const getValue = (obj: object, propertyNameArr: Array<string>): any => {
    const value = obj[propertyNameArr[0] as keyof typeof obj];
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      if (propertyNameArr.length > 1) {
        return getValue(value, propertyNameArr.slice(1));
      }
    }
    return propertyNameArr.length === 1 ? value : undefined;
  };
  return getValue(nestedObj, propertyNameList);
};

export const sanitizeHtml = (htmlText: string) => {
  return DOMPurify.sanitize(htmlText || "", {
    ADD_TAGS: ["iframe", "object"],
    ADD_ATTR: [
      "allow",
      "allowfullscreen",
      "frameborder",
      "scrolling",
      "style",
      "target",
      "data",
    ],
  });
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};