import { useEffect, useState } from "react";
import { IMutationState } from "../../interfaces";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { ISolutionAssetTypeMutationObject } from "../../interfaces/solution";
import { apiConfig } from "../../config";
import axiosInstance from "../axios";

const createSolutionAssetType = async (
  solutionId: string | number,
  postData: ISolutionAssetTypeMutationObject
) => {
  const apiEndpoint = `${
    apiConfig.urls.solutions + solutionId + apiConfig.urls.assets
  }types/
  `;
  return await axiosInstance.post(apiEndpoint, postData);
};

const updateSolutionAssetType = async (
  solutionId: string | number,
  { id, ...updateData }: ISolutionAssetTypeMutationObject
) => {
  const apiEndpoint = `${
    apiConfig.urls.solutions + solutionId + apiConfig.urls.assets
  }types/${id}/
  `;
  return await axiosInstance.patch(apiEndpoint, updateData);
};

const useCreateUpdateSolutionAssetType = (solutionId: string | number) => {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSettled: (data: any, error: any, variables: any, context: any) => {},
  };
  const mutationFunction = (mutationData: ISolutionAssetTypeMutationObject) => {
    return mutationData.id
      ? updateSolutionAssetType(solutionId, mutationData)
      : createSolutionAssetType(solutionId, mutationData);
  };
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );
  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);
  return { mutate, ...mutationState };
};

export default useCreateUpdateSolutionAssetType;
