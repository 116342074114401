import { DownloadWrapper } from "./styled";
import axios from "axios";
import fileDownload from "js-file-download";
import { HiOutlineDownload } from "react-icons/hi";
interface IDownload {
  fileUrl: string | null;
}

const Download = ({ fileUrl }: IDownload) => {
  const axiosInstance = axios.create({
    baseURL: fileUrl!,
  });
  const handleDownload = (filename: string) => {
    axiosInstance.get("/", { responseType: "blob" }).then((res) => {
      fileDownload(res.data, filename);
    });
  };
  return (
    <DownloadWrapper
      onClick={() => handleDownload(`${fileUrl?.split("/").pop()}`)}
    >
      <HiOutlineDownload className="download-icon" size="1.8rem" />
      Download
    </DownloadWrapper>
  );
};

export default Download;
