import { useMemo } from "react";
import useCreateUpdateRelationshipParam from "../../../../api/hooks/useCreateUpdateRelationshipParam";
import useGetAllSolutionParamTags from "../../../../api/hooks/useGetAllSolutionParamTags";
import {
  IRelationshipParamerter,
  IRelationshipParameterTag,
} from "../../../../interfaces";
import FallBack from "../../Fallback";
import { SearchSelectCard } from "../../Reusable/components";
import {
  IRelParamTagCardsContainerProps,
  IItemsObj,
  ISelectedRemovedObj,
} from "./interfaces";
import { RelParamTagCardsContainer } from "./styled";

interface IRelParamsObject {
  relParamTagsArray: IRelParamTag[];
  allRelParamsArray: IRelationshipParamerter[];
}

interface IRelParamTag {
  id: number;
  title: string;
  items: {
    label: string;
    value: string;
    isChecked: boolean;
  }[];
}

export const RelParamTagCards = ({
  items = [],
  formikHelpers,
}: IRelParamTagCardsContainerProps) => {
  const { data: relParamTagsData, isLoading } = useGetAllSolutionParamTags();
  const { mutateAsync, ...mutationState } = useCreateUpdateRelationshipParam();

  const selectedRelParamIds = items.map((relParamObj) => relParamObj.id);

  const { relParamTagsArray, allRelParamsArray }: any = useMemo(
    () =>
      relParamTagsData?.data?.reduce(
        (
          relParamsObject: IRelParamsObject,
          relParamTagObj: IRelationshipParameterTag
        ) => {
          relParamsObject.relParamTagsArray.push({
            id: relParamTagObj.id,
            title: relParamTagObj.tag_name,
            items: relParamTagObj.relationship_parameters.map(
              (relParamObj) => ({
                label: relParamObj.value,
                value: String(relParamObj.id),
                isChecked: selectedRelParamIds.includes(relParamObj.id),
              })
            ),
          });
          relParamsObject.allRelParamsArray =
            relParamsObject.allRelParamsArray.concat(
              relParamTagObj.relationship_parameters
            );
          return relParamsObject;
        },
        { relParamTagsArray: [], allRelParamsArray: [] }
      ),
    [relParamTagsData, items]
  );
  if (isLoading || !relParamTagsData) return <FallBack />;

  const handleStateChange = (newRelTagsState: IItemsObj[]) => {
    const { selected, removed } = newRelTagsState.reduce(
      (selectedRemovedObj: ISelectedRemovedObj, tagObj) => {
        if (tagObj.isChecked) {
          selectedRemovedObj.selected.push(tagObj.value);
        } else {
          selectedRemovedObj.removed.push(tagObj.value);
        }
        return selectedRemovedObj;
      },
      {
        selected: [],
        removed: [],
      }
    );
    const initialItemIds = items.map((itemObj) => String(itemObj.id));

    const selectedRelParamObjects = allRelParamsArray?.filter(
      (relParamObj: IRelationshipParamerter) =>
        [...initialItemIds, ...selected].includes(String(relParamObj.id)) &&
        !removed.includes(String(relParamObj.id))
    );
    formikHelpers?.setValue(selectedRelParamObjects);
  };

  const handleRelationshipParamCreation = (
    tagId: number,
    newTagName: string
  ) => {
    const relParamTagObj = relParamTagsData.data.find(
      (relParamObj: IRelationshipParameterTag) => relParamObj.id === tagId
    );
    if (relParamTagObj && newTagName)
      return mutateAsync({
        name: relParamTagObj?.tag_name,
        name_link: relParamTagObj?.id,
        data_type: "str_value",
        str_value: newTagName,
      });
    throw Error;
  };

  const handleRelParamCreationSuccess = (
    newRelParamObj: IRelationshipParamerter
  ) => {
    formikHelpers?.setValue([...items, newRelParamObj]);
  };

  return (
    <RelParamTagCardsContainer>
      {relParamTagsArray?.map((relParamTag: IRelParamTag) => (
        <SearchSelectCard
          key={relParamTag.id}
          title={relParamTag.title}
          items={relParamTag.items}
          onChange={handleStateChange}
          onCreateTagSubmit={(newTagName: string) =>
            handleRelationshipParamCreation(relParamTag.id, newTagName)
          }
          onTagCreationSuccess={handleRelParamCreationSuccess}
        ></SearchSelectCard>
      ))}
    </RelParamTagCardsContainer>
  );
};

export default RelParamTagCards;
