import { Suspense, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { trackUserAction } from "../../../../../analytics";
import useGetAssetCounts from "../../../../../api/hooks/useGetAssetCount";
import { appConfig, userActivityEvents } from "../../../../../config";
import { AssetObjectType } from "../../../../../config/assetsConfig";
import { AssetsContext, IAssetsContext } from "../../../../../context";
import { IComponentToRender } from "../../../../../interfaces";
import { history } from "../../../../../utils/history";
import { singular } from "../../../../../utils/string";
import FallBack from "../../../Fallback";

import {
  SelectedTabContainer,
  SidebarWithTabsWrapper,
  Tab,
  TabbedSidebar,
  TagCount,
  TagTitle,
} from "./styled";
import AssetTypeSidebar from "../../../Templates/components/Assets/AssetTypeSidebar";

const {
  pageSpecificConfigs: {
    assets: { parentUrl },
  },
} = appConfig;

interface ISidebarWithTabs {
  componentsToRender: IComponentToRender[];
}

const SidebarWithTabs = ({ componentsToRender }: ISidebarWithTabs) => {
  const {
    defaultTabSlug,
    activeTabSlug,
    isEdit,
    setActiveTabSlug,
    setIsEdit,
    setEditItemId,
    setShowLibraryModal,
    setActiveItemType,
  } = useContext<IAssetsContext>(AssetsContext);
  const location = useLocation();
  const queryParams = location.search;
  const assetSidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // When defaultTabSlug is updated from the provider,
    // set the updated value as the activeTabSlug if a tab was
    // not explicitly passed in URL params
    const urlParams = new URLSearchParams(queryParams);
    if (!urlParams.get("tab")) setActiveTabSlug(defaultTabSlug);
  }, [defaultTabSlug]);

  useEffect(() => {
    // If no activeTabSlug is set, set the defaultValue as the
    // activeTabSlug
    if (!activeTabSlug) setActiveTabSlug(defaultTabSlug);
  }, [activeTabSlug]);

  useEffect(() => {
    const urlParams = new URLSearchParams(queryParams);
    const currentTab = urlParams.get("tab");
    const isEditValue =
      urlParams.get("edit") === "true" || urlParams.get("create") === "true";
    const editItemId = urlParams.get("id");
    if (editItemId) {
      setEditItemId(editItemId);
    }
    if (!!currentTab) {
      const componentDataObj = componentsToRender.find(
        (componentDataObj) => componentDataObj.slug === currentTab
      );
      if (componentDataObj) {
        setActiveTabSlug(componentDataObj?.slug);
      } else {
        history.replace(parentUrl);
      }
    }
    setIsEdit(isEditValue);
  }, [queryParams]);

  const handleTabClick = (componentDataObj: IComponentToRender) => {
    const tabbedSidebarContainer = document.getElementById(
      "tabbed-sidebar-container"
    );
    if (tabbedSidebarContainer)
      tabbedSidebarContainer.scroll({
        top: 0,
        behavior: "smooth",
      });
    trackUserAction(userActivityEvents.LIBRARY__TAB_CHANGED, {
      tabClicked: componentDataObj.slug,
    });
    setActiveTabSlug(componentDataObj.slug);
    componentDataObj.slug !== activeTabSlug && setIsEdit(false);
    location.search && history.replace(parentUrl);
  };

  const componentDataObj = componentsToRender.find(
    (componentDataObj) => componentDataObj.slug === activeTabSlug
  );
  const componentToRender =
    // isEdit && componentDataObj?.addEditForm
    // ? componentDataObj.addEditForm()
    componentDataObj?.componentToRender();
  return (
    <SidebarWithTabsWrapper sidebarRef={assetSidebarRef}>
      <Suspense fallback={<></>}>
        <AssetTypeSidebar></AssetTypeSidebar>
      </Suspense>
      {/* <TabbedSidebar ref={assetSidebarRef}>
        {componentsToRender
          .filter((componentDataObj) => !componentDataObj.hidden)
          .map((componentDataObj) => {
            const assetSlug = componentDataObj.slug;
            const isSelected = assetSlug === activeTabSlug;
            const assetCountKey =
              componentDataObj.object === AssetObjectType.GENERIC_RESOURCE
                ? componentDataObj.type
                : componentDataObj.object;

            return (
              <Tab
                key={componentDataObj.slug}
                onClick={() => handleTabClick(componentDataObj)}
                isSelected={isSelected}
              >
                <TagTitle>{componentDataObj.title}</TagTitle>
                <TagCount>
                  <Suspense fallback={<></>}>
                    {assetCountKey && (
                      <AssetCount assetCountKey={assetCountKey} />
                    )}
                  </Suspense>
                </TagCount>
              </Tab>
            );
          })}
      </TabbedSidebar> */}
      <SelectedTabContainer>
        <Suspense fallback={<FallBack />}>{componentToRender}</Suspense>
      </SelectedTabContainer>
    </SidebarWithTabsWrapper>
  );
};

export default SidebarWithTabs;

// TODO: To be refactored later
const AssetCount = ({ assetCountKey }: { assetCountKey: string }) => {
  const { data: assetCount } = useGetAssetCounts();
  return (
    <>
      {!!(assetCountKey && assetCount[assetCountKey]) && (
        <span>{assetCount[assetCountKey]}</span>
      )}
    </>
  );
};
