import styled from "@emotion/styled";
import { useContext } from "react";
import { ContentAreaContext } from "../../../../../context";
import { getRelationshipParametersString } from "../../../../../helpers";
import filterUp from "../../../../../assets/icons/filterUp.svg";

const ToggleButton = styled.div`
  width: 10.4rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.05rem solid #cbd5e1;
  border-radius: 0.6rem;
  cursor: pointer;
  .icon {
    width: 1.5rem;
    height: 1rem;
  }
  .label {
    font-family: var(--font-lexend);
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: #0f172a;
    margin-left: 1.2rem;
  }
`;

const FilterCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  line-height: 1.2rem;
  padding: 0.2rem 0;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  background: #dc2626;
`;

const FilterToggleButton = () => {
  const { stateOfAllDropdowns, showFilter, setShowFilter } =
    useContext(ContentAreaContext);

  const selectedRelationshipParamIdString =
    getRelationshipParametersString(stateOfAllDropdowns);

  const filterCount = selectedRelationshipParamIdString
    .split(",")
    .filter(Boolean).length;

  return (
    <ToggleButton
      className="Filter__toggle-button"
      onClick={() => setShowFilter(!showFilter)}
    >
      {filterCount ? (
        <FilterCount>{filterCount}</FilterCount>
      ) : (
        <img className="icon" src={filterUp} alt="Toggle icon" />
      )}
      <span className="label">Filters</span>
    </ToggleButton>
  );
};

export default FilterToggleButton;
