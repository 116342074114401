import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import "github-markdown-css/github-markdown-light.css";
import styled from "@emotion/styled";

const MarkdownContainer = styled.div`
  &.markdown-body table td:last-child {
    border-width: 1px;
  }
  &.markdown-body ul,
  ol {
    list-style: revert;
  }
`;

export const MarkDownWrapper = styled.div`
  .markdown-body {
    font-family: var(--font-lexend) !important;
    color: #1f2937;
    font-size: 1.3rem;
    line-height: 1.5;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 2rem;
    }
    h1 {
      font-size: 2.2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.3rem;
    }
    table td {
      max-width: 40rem;
    }
  }
`;

interface IMarkdown {
  children: string;
}

const Markdown = ({ children }: IMarkdown) => {
  return (
    <MarkdownContainer className="markdown-body">
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkGemoji]}
        rehypePlugins={[rehypeRaw]}
        children={children}
        components={{
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter
                {...rest}
                PreTag="div"
                children={String(children).replace(/\n$/, "")}
                language={match[1]}
                style={oneLight}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      />
    </MarkdownContainer>
  );
};

export default Markdown;
