export enum IconName {
  NAME = "Name",
  EMAIL = "Email",
  JOB_TITLE = "Job title",
  LINKEDIN_URL = "Linkedin",
  PHONE = "Phone",
  LOCATION = "Location",
  TEAM = "Team",
  ABOUT = "About",
  WEBSITE_URL = "Website",
  INDUSTRY = "Industry",
  ICP_MATCH = "ICP match",
  ROLE = "Role",
  REGION = "Region",
  COUNTRY = "Country",
  STATUS = "Status",
  LEAD_SOURCE = "LeadSource",
  OWNER = "Owner",
  LINK = "link",
  QUALIFICATION_SCORE = "Qualification Score",
  LAST_VIEWED = "Last viewed",
  COMPANY = "Company",
}

export enum IconImportPath {
  FI = "react-icons/fi",
  FA = "react-icons/fa",
  FA6 = "react-icons/fa6",
  GR = "react-icons/gr",
  BS = "react-icons/bs",
  RI = "react-icons/ri",
  TB = "react-icons/tb",
  IO = "react-icons/io",
  IO5 = "react-icons/io5",
  MD = "react-icons/md",
}

export enum CampaignUserCardType {
  BUYER_BASIC_DETAILS = "buyer_basic_details",
  BUYER_CRM_DATA = "buyer_crm_lead_data",
  BUYER_CUSTOM_QUESTIONS = "buyer_custom_questions",
  COMPANY_BASIC_DETAILS = "company_basic_details",
  PRIOR_INTERACTIONS = "prior_interactions",
  KEY_STAKEHOLDERS = "key_stakeholders",
  SIZE_INDICATORS = "size_indicators",
  MARKETING_DATA = "marketing_data",
  TECH_STACK = "tech_stack",
  ADR = "adr_data",
  COMPANY_CUSTOM_QUESTIONS = "company_custom_questions",
  COMPANY_CUSTOM_DATA = "company_custom_data",
  TALKING_POINTS = "talking_points",
  KEY_CHALLENGES = "key_challenges",
  PERSONALIZED_EMAIL = "personalized_email",
  PERSONALIZED_LANDING_PAGE = "personalized_landing_page",
}

export const DEFAULT_CAMPAIGN_EMAIL_LIMIT = 100;

export const CAMPAIGN_CSV_UPLOAD_MESSAGE = `The email column header name in your CSV should be Email. Also, your current plan allows addition of upto ${
  Number(process.env.REACT_APP_CAMPAIGN_EMAIL_LIMIT) ||
  DEFAULT_CAMPAIGN_EMAIL_LIMIT
} emails at a time via the CSV upload.`;

export const SKELETON_WIDTH_SIZE_CLASSES = [
  "w-[40%]",
  "w-[35%]",
  "w-[30%]",
  "w-[25%]",
];

export const CAMPAIGN_USER_DETAILS_REFETCH_INTERVAL_IN_MS = 6000;
