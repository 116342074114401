import React from "react";
import { FiTrash2 } from "react-icons/fi";
import CustomModal from "../CustomModal";

import {
  ButtonWrapper,
  CancelButton,
  DeleteButton,
  DeleteContainer,
  DeleteIconContainer,
  MessageWrapper,
} from "./styled";

interface IDeleteModal {
  handleDelete: Function;
  handleCancel: Function;
  showDeleteModal: boolean;
  deleteMessage: string;
  width?: string;
  height?: string;
}

const DeleteModal = ({
  handleDelete,
  handleCancel,
  showDeleteModal,
  deleteMessage,
  width = "52rem",
  height = "23.5rem",
}: IDeleteModal) => {
  return (
    <div>
      <CustomModal
        width={width}
        height={height}
        style={{
          marginTop: "20%",
          border: "0.5px solid rgba(148, 163, 184, 0.5)",
          borderRadius: "12px",
        }}
        showModal={showDeleteModal}
        showFooter={true}
        showCancel={false}
        closeModal={() => handleCancel()}
        renderContent={() => (
          <DeleteContainer>
            <DeleteIconContainer>
              <FiTrash2 />
            </DeleteIconContainer>
            <MessageWrapper>{deleteMessage}</MessageWrapper>
          </DeleteContainer>
        )}
        renderAction={() => (
          <ButtonWrapper>
            <DeleteButton onClick={() => handleDelete()}>
              Yes, delete
            </DeleteButton>
            <CancelButton onClick={() => handleCancel()}>
              No, cancel
            </CancelButton>
          </ButtonWrapper>
        )}
      />
    </div>
  );
};

export default DeleteModal;
