import { MouseEvent, useState } from "react";
import { NotFound } from "..";
import { trackUserAction } from "../../../../../analytics";
import { userActivityEvents } from "../../../../../config";
import {
  getAbsoluteFileUrlFromPath,
  getFileNameFromUrl,
} from "../../../../../helpers";
import { IDocumentObject } from "../../../../../interfaces";
import { ViewFileButton } from "../Buttons";
import HorizontalCards from "../HorizontalCards";
import { DocumentViewerModal } from "../Modals";
import {
  VerticalCardsContainer,
  VerticalCardsHeader,
  HorizontalCardsContainer,
  HorizontalCardWrapper,
  VerticalCardsHeaderTitle,
} from "./styled";
import useHtmlRenderers from "../../../../../hooks/useHtmlRenderers";
import Card from "./Card";
import { ITag } from "../Tags/Tag";

export interface ICardItem {
  id: number;
  title: string;
  description: string;
  tags?: ITag[];
  thumbnailUrl?: string;
  ThumbnailComponent?: () => React.ReactNode;
}

interface IVerticalCards {
  title?: string;
  type?: string;
  data: ICardItem[];
  onCardClick?: Function;
  WidgetToolbar?: (props?: object) => React.ReactNode;
  HeaderExtraComponent?: false | (() => React.ReactNode);
  hideToolbarOnBlur?: boolean;
  hideTags?: boolean;
}

const VerticalCards = ({
  title,
  type,
  data,
  onCardClick,
  WidgetToolbar,
  HeaderExtraComponent,
  hideToolbarOnBlur = true,
  hideTags = false,
}: IVerticalCards) => {
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  // Use Adobe PDF Viewer to render pdfs
  useHtmlRenderers();

  const getDocuments = (dataItem: any) => {
    return dataItem.documents
      .filter((documentObj: IDocumentObject) => documentObj.url)
      .map((documentObj: IDocumentObject) => {
        return (
          <ViewFileButton
            showToolTip={true}
            hideButtonLabel={false}
            key={documentObj.id}
            filePath={documentObj.url}
            onClick={() => {
              setFileUrl(getAbsoluteFileUrlFromPath(documentObj.url));
              setShowModal(true);
              trackUserAction(
                userActivityEvents.LIBRARY__DOCUMENT_BUTTON_CLICKED,
                {
                  fileName: getFileNameFromUrl(documentObj.url),
                  assetName: dataItem.title,
                  assetType: dataItem.object,
                }
              );
            }}
          ></ViewFileButton>
        );
      });
  };

  const handleCardClick = (e: MouseEvent, item: any) => {
    trackUserAction(userActivityEvents.LIBRARY__LIST_ITEM_CLICKED, {
      id: item.id,
      title: item.title,
      type: title,
    });
    onCardClick && onCardClick(e, item.id);
  };

  return (
    <>
      <div>
        {data.length ? (
          // title?.toLowerCase().includes("deck") ? (
          // <HorizontalCardsContainer>
          //   {title && <VerticalCardsHeader>{title}</VerticalCardsHeader>}
          //   <HorizontalCardWrapper>
          //     {data.map((item: any, i: number) => (
          //       <HorizontalCards
          //         key={item.id}
          //         item={item}
          //         title={title}
          //         onCardClick={onCardClick}
          //         getDocuments={getDocuments}
          //         getTags={getTags}
          //       />
          //     ))}
          //   </HorizontalCardWrapper>
          // </HorizontalCardsContainer>
          // ) :
          <VerticalCardsContainer>
            {title && (
              <VerticalCardsHeader data-type={type}>
                <VerticalCardsHeaderTitle>{title}</VerticalCardsHeaderTitle>
                {HeaderExtraComponent && HeaderExtraComponent()}
              </VerticalCardsHeader>
            )}
            {data.map((item, i: number) => (
              <Card
                key={item.id}
                item={item}
                onCardClick={handleCardClick}
                WidgetToolbar={WidgetToolbar}
              />
            ))}
          </VerticalCardsContainer>
        ) : (
          <NotFound descriptionText="No items to show" />
        )}
      </div>

      <DocumentViewerModal
        fileUrl={fileUrl}
        isOpen={showModal}
        onDismissal={() => setShowModal(false)}
      ></DocumentViewerModal>
    </>
  );
};

export default VerticalCards;
