import styled from "@emotion/styled";
import { InputLabel, ValidationErrorText } from "../../../../styled";
import { FormEvent, useEffect, useState } from "react";
import {
  GatherUserInfoTitle,
  Header,
  Input,
  Layout,
  Main,
  Step,
  Button,
} from "../common";
import { cloneDeep } from "lodash";
import { useCreateInvitation } from "../../../../../api/hooks";
import validator from "validator";
import { history } from "../../../../../utils/history";
import {
  handleAddRow,
  handleAddTeamMates,
  setIsSignUpToFalse,
} from "../../../../../helpers";
import { ClipLoader } from "react-spinners";
import { IInputValue } from "../../../../../interfaces";
import { appConfig, userActivityEvents } from "../../../../../config";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getLoggedInUser } from "../../../../../helpers";
import HeaderBand from "../HeaderBand";
import { trackUserAction } from "../../../../../analytics";
const { userUpdationPages } = appConfig;

const CustomInviteUserForm = styled.div`
  width: 62rem;
  .button {
    border: 0;
    background: transparent;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
  }
  .add-another-button {
    color: var(--primary-blue);
    margin-bottom: 4rem;
  }
  .add-teammates-button {
    width: 37.4rem;
    padding: 1.1rem;
    font-size: 1.6rem;
    cursor: pointer;
    color: #3d3d3d;
  }
  .skip-button {
    color: #808080;
    margin-left: 6.2rem;
  }
`;

const Row = styled.div`
  justify-content: space-between;
  margin-bottom: 2.8rem;
`;

const RowLeft = styled.div`
  width: 24.4rem;
`;

const RowRight = styled.div`
  width: 35.6rem;
`;

const InviteMembersOnSignup = () => {
  const location = useLocation();
  const {
    mutate: createInvitationMutation,
    isSuccess: isInvitationSuccessful,
    isLoading: isCreateInvitationInProgress,
  } = useCreateInvitation({});

  const initialInputValues: IInputValue[] = [
    {
      first_name: "",
      email_id: "",
    },
    {
      first_name: "",
      email_id: "",
    },
  ];
  const [inputValues, setInputValues] =
    useState<IInputValue[]>(initialInputValues);
  // Validations
  const [inputErrors, setInputErrors] = useState<IInputValue[]>(
    cloneDeep(initialInputValues)
  );

  useEffect(() => {
    const loggedInUser = getLoggedInUser();
    if (loggedInUser) {
      loggedInUser.is_signup = false;
      localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
    }
  }, []);

  useEffect(() => {
    // Modify validation errors on change of inputvalues
    const updatedValidationErrors: IInputValue[] = inputValues.map((row) => {
      if (!row.first_name && !row.email_id) {
        return { first_name: "", email_id: "" };
      } else if (row.first_name && !row.email_id) {
        return { first_name: "", email_id: "Enter email id" };
      } else if (row.first_name && validator.isEmail(row.email_id)) {
        return { first_name: "", email_id: "" };
      } else if (row.first_name && !validator.isEmail(row.email_id)) {
        return { first_name: "", email_id: "Enter valid email id" };
      } else if (!row.first_name && !validator.isEmail(row.email_id)) {
        return {
          first_name: "Enter the name",
          email_id: "Enter valid email id",
        };
      } else if (!row.first_name && validator.isEmail(row.email_id)) {
        return { first_name: "Enter the name", email_id: "" };
      } else {
        return { first_name: "", email_id: "" };
      }
    });
    setInputErrors(updatedValidationErrors);
  }, [inputValues]);

  // useEffect(() => {
  //   if (isInvitationSuccessful) {
  //     // Need to decide in case if they are already joined emails what to display
  //     setIsSignUpToFalse();
  //     history.push("/");
  //   }
  // }, [isInvitationSuccessful]);

  function handleInputChange({
    e,
    index,
    type,
  }: {
    e: FormEvent<HTMLInputElement>;
    index: number;
    type: "first_name" | "email_id";
  }) {
    const updatedInputValues = cloneDeep(inputValues);
    updatedInputValues[index] = {
      ...updatedInputValues[index],
      [type]: e.currentTarget.value,
    };

    setInputValues(updatedInputValues);
  }

  const isEmpty = inputValues.every((row) => !row.first_name && !row.email_id);
  const filteredInputValues = inputValues.filter((row) => {
    const { first_name, email_id } = row;
    return (
      (first_name && !validator.isEmail(email_id)) ||
      (!first_name && validator.isEmail(email_id)) ||
      (!first_name && email_id)
    );
  });
  const isDisabled = isEmpty || !!filteredInputValues.length;

  return (
    <>
      <Layout>
        <HeaderBand/>
        <Main>
          <GatherUserInfoTitle>Add teammates</GatherUserInfoTitle>
          <Step>Step 2 of 2</Step>
          <CustomInviteUserForm>
            {inputValues.map((row, index) => {
              return (
                <Row key={index} className="flex">
                  <RowLeft>
                    <InputLabel>Name</InputLabel>
                    <Input
                      onChange={(e) =>
                        handleInputChange({ e, index, type: "first_name" })
                      }
                      value={row.first_name}
                    />
                    {inputErrors[index].first_name && (
                      <ValidationErrorText>
                        {inputErrors[index].first_name}
                      </ValidationErrorText>
                    )}
                  </RowLeft>
                  <RowRight>
                    <InputLabel>Work Email</InputLabel>
                    <Input
                      onChange={(e) =>
                        handleInputChange({ e, index, type: "email_id" })
                      }
                      value={row.email_id}
                    />
                    {inputErrors[index].email_id && (
                      <ValidationErrorText>
                        {inputErrors[index].email_id}
                      </ValidationErrorText>
                    )}
                  </RowRight>
                </Row>
              );
            })}
            <button
              onClick={(e) =>
                handleAddRow({
                  inputValues,
                  inputErrors,
                  setInputErrors,
                  setInputValues,
                })
              }
              className="add-another-button button"
            >
              + Add another
            </button>
            <div className="flex">
              <Button
                onClick={(e) =>
                  handleAddTeamMates({
                    inputErrors,
                    inputValues,
                    createInvitationMutation,
                  })
                }
                className="add-teammates-button"
                style={
                  !isDisabled
                    ? {
                        background: "var(--primary-blue)",
                        border: "1px solid var(--primary-blue)",
                        color: "white",
                      }
                    : {}
                }
              >
                <span style={{ marginRight: "1rem" }}>Add teammates</span>
                {isCreateInvitationInProgress && (
                  <ClipLoader color="white" size={20} />
                )}
              </Button>
              <button
                onClick={() => {
                  setIsSignUpToFalse();
                  trackUserAction(userActivityEvents.ONBOARD__SKIPPED_INVITE_SCREEN)
                  history.push("/");
                }}
                className="skip-button button"
              >
                Skip this step
              </button>
            </div>
          </CustomInviteUserForm>
        </Main>
      </Layout>
      {userUpdationPages.includes(location.pathname) && (
        <ToastContainer
          position="top-center"
          autoClose={7000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      )}
    </>
  );
};

export default InviteMembersOnSignup;
