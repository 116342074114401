import DOMPurify from "dompurify";
import { TITLE_HEADING_CONFIG } from "../constants/richTextEditor";
import { THTMLHeadTag } from "../interfaces";

export const categorizeByFieldType = (mutationData: any) => {
  // Categorize fields by file fields, vink document fields, and others
  return Object.entries(mutationData).reduce(
    (fileFieldsAndOtherFieldsObj, [key, value]) => {
      if (key === "documents" && Array.isArray(value)) {
        const documentIdArr: number[] = [];
        const filesToUpload: File[] = value.filter((documentIdOrFile) => {
          if (documentIdOrFile instanceof File) {
            return true;
          } else {
            documentIdArr.push(documentIdOrFile);
            return false;
          }
        });
        fileFieldsAndOtherFieldsObj["documentFiles"] = filesToUpload;
        fileFieldsAndOtherFieldsObj["others"]["documents"] = documentIdArr;
      } else {
        const valueType = value instanceof File ? "files" : "others";
        fileFieldsAndOtherFieldsObj[valueType][key] = value;
      }
      return fileFieldsAndOtherFieldsObj;
    },
    {
      files: {} as any,
      others: {} as any,
      documentFiles: [] as File[],
    }
  );
};

export const getTitlesFromText = (text: string, tagName: THTMLHeadTag) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = DOMPurify.sanitize(text);
  const titles = Array.from(tempDiv.getElementsByTagName(tagName)).map(
    (element) => {
      return element.innerText;
    }
  );
  return titles.filter((titleText) => titleText);
};

export const getFirstEmbedEleFromDescription = (description: string) => {
  const parser = new DOMParser();
  const tempHTMLEle = parser.parseFromString("<div></div>", "text/html");
  if (!tempHTMLEle.firstElementChild) return;
  tempHTMLEle.firstElementChild.innerHTML = description;
  const firstEmbed = tempHTMLEle.querySelector(
    `.b-embed,[data-document-id],.tiny-pageembed`
  );
  if (firstEmbed) tempHTMLEle.firstElementChild.replaceChildren(firstEmbed);
  else return;
  return tempHTMLEle.firstElementChild.innerHTML;
};
