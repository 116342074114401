
const Term = () => {
  return (
    
        <iframe src={`https://docs.google.com/document/d/1Nt6mnuz5p7MBADqMXmx2z-UnGc4u_TZOyGN8EAa8064/pub?embedded=true`} 
        title="Vink Terms"
        style={{
            width: "100%",
            height: "100vh",
            border: "none",
            textAlign:'center'
          }}/>
  )
}

export default Term