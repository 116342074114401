import { useContext, useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@emotion/react";
import { useParams } from "react-router-dom";
import TimeMe from "timeme.js";
import {
  AssetsContextProvider,
  AuthContext,
  SolutionsContext,
} from "../../../context";
import { SolutionsActionType } from "../../../interfaces/context/solutionsContext";
import SharedSolutionHeader from "./SharedSolutionHeader";
import {
  SharedSolutionBodyWrapper,
  SharedSolutionBodyContainer,
  SharedSolutionBodyFooter,
  LeftSideFooter,
  RightSideFooter,
  AppLink,
  SharedSolutionContainer,
} from "./styled";
import { useLoggedInTeam } from "../../../api/hooks/useLoggedInTeam";
import useGetSolutions from "../../../api/hooks/useGetSolutions";
import GuestUserErrorPage, {
  ErrorCode,
  PREVIEW_RESOURCE_NOT_FOUND_ERROR_MSG,
  RefreshButton,
  RESOURCE_NOT_FOUND_ERROR_MSG,
} from "../../../components/auth/GuestUser/GuestUserErrorPage";
import { appConfig } from "../../../config";
import { FallBack } from "../../../components";
import SharedSolutionDetailsContent from "./SharedSolutionDetailsContent";
import { SolutionDetailsContextProvider } from "../../../context/SolutionDetailsContext";
import useGetSharedResourcePublicData from "../../../api/hooks/useGetShareableResPublicData";
import { ITheme } from "../../../interfaces/theme";
import useUpdateAnalyticsLogs from "../../../api/hooks/useUpdateAnalyticsLogs";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_BUSINESS_WEBSITE_URL = `${appConfig.websiteUrl}/?utm_source=proposal`;

const SharedSolutionDetails = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const { authState } = useContext(AuthContext);
  const { solutionsDispatch } = useContext(SolutionsContext);
  const loggedInTeamDetails = useLoggedInTeam();
  const code = params.code || sessionStorage.getItem("guestCode");
  const { data: sharedResPublicData } = useGetSharedResourcePublicData(code!);

  const theme: ITheme = useMemo(() => {
    const themes = sharedResPublicData?.assets?.themes || []
    return {
      sharedView: themes.find(theme => theme.is_default) || themes.length? themes[0] : undefined,
    };
  }, []);
  const fonts = theme?.sharedView?.imports?.fonts || [];

  const { data: solutions, isLoading } = useGetSolutions();
  const { mutate } = useUpdateAnalyticsLogs();

  useEffect(() => {
    queryClient.invalidateQueries();
  }, []);

  useEffect(() => {
    if (!authState.isGuestUser || authState.isPreview) return;

    TimeMe.initialize({
      currentPageName: "shared-page",
      idleTimeoutInSeconds: 5,
      trackWhenUserGoesIdle: true,
    });

    const main: HTMLElement = document.getElementsByTagName("main")[0];
    //handle the case of scroll on a page will trigger the timer
    main?.addEventListener("scroll", () => {
      TimeMe.userActivityDetected();
    });

    TimeMe.callWhenUserLeaves(function () {
      let timeSpentOnPage = {};
      const timeSpentForAllSectionsArr = TimeMe.getTimeOnAllPagesInSeconds();
      timeSpentOnPage = timeSpentForAllSectionsArr.reduce(
        (
          pageTimeSpentData: any,
          sectionTimeSpent: { pageName: string; timeOnPage: number }
        ) => {
          if (sectionTimeSpent.pageName === "shared-page")
            pageTimeSpentData["page"] = Math.ceil(sectionTimeSpent.timeOnPage);
          else if (sectionTimeSpent.pageName === "doc-section")
            pageTimeSpentData["solution_doc"] = Math.ceil(
              sectionTimeSpent.timeOnPage
            );
          else if (sectionTimeSpent.pageName.includes("asset-type")) {
            const assetTypeId = sectionTimeSpent.pageName.slice(
              "asset-type-".length
            );
            const solutionAssetTypeTime =
              pageTimeSpentData["solution_asset_types"]?.[assetTypeId] || {};
            pageTimeSpentData["solution_asset_types"] = {
              ...pageTimeSpentData["solution_asset_types"],
              [assetTypeId]: {
                ...solutionAssetTypeTime,
                total_time: Math.ceil(sectionTimeSpent.timeOnPage),
              },
            };
          } else {
            const [assetTypeId, assetItemId] =
              sectionTimeSpent.pageName.split("-");
            if (pageTimeSpentData["solution_asset_types"]?.[assetTypeId]) {
              const solutionAssetsTime =
                pageTimeSpentData["solution_asset_types"]?.[assetTypeId]
                  ?.solution_assets || {};
              pageTimeSpentData["solution_asset_types"][assetTypeId] = {
                ...pageTimeSpentData["solution_asset_types"][assetTypeId],
                solution_assets: {
                  ...solutionAssetsTime,
                  [assetItemId]: Math.ceil(sectionTimeSpent.timeOnPage),
                },
              };
            } else {
              pageTimeSpentData["solution_asset_types"] = {
                ...(pageTimeSpentData?.["solution_asset_types"] || {}),
                [assetTypeId]: {
                  solution_assets: {
                    [assetItemId]: Math.ceil(sectionTimeSpent.timeOnPage),
                  },
                },
              };
            }
          }
          return pageTimeSpentData;
        },
        {}
      );
      mutate({ type: "time_spent", payload: timeSpentOnPage });
    });
  }, []);

  useEffect(() => {
    if (solutions?.data.length) {
      solutionsDispatch({
        type: SolutionsActionType.SET_SOLUTION_ID,
        payload: solutions.data[0].id,
      });
    }

    return () =>
      solutionsDispatch({
        type: SolutionsActionType.SET_SOLUTION_ID,
        payload: "",
      });
  }, [solutions]);

  if (!authState.isGuestUser || isLoading) return <FallBack />;

  if (!solutions?.data.length) {
    const errorText = authState.isPreview
      ? PREVIEW_RESOURCE_NOT_FOUND_ERROR_MSG
      : RESOURCE_NOT_FOUND_ERROR_MSG;
    return (
      <GuestUserErrorPage
        error={{
          text: errorText,
          code: !authState.isPreview ? ErrorCode.NO_PROPOSALS : undefined,
        }}
        ButtonComponent={
          authState.isPreview
            ? () => (
                <RefreshButton
                  onClick={() =>
                    window.location.replace(
                      BASE_URL +
                        appConfig.pageSpecificConfigs.solution.parentUrl
                    )
                  }
                >
                  <span>Go to home</span>
                </RefreshButton>
              )
            : undefined
        }
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <SolutionDetailsContextProvider>
        <AssetsContextProvider>
          <Helmet>
            {fonts.map((fontLink: string, index: number) => (
              <link href={fontLink} rel="stylesheet" key={index} />
            ))}
          </Helmet>
          <SharedSolutionContainer style={theme?.sharedView?.style?.body?.style}>
            <SharedSolutionHeader />
            <SharedSolutionBodyWrapper>
              <SharedSolutionBodyContainer>
                <SharedSolutionDetailsContent />
                <SharedSolutionBodyFooter
                  style={theme?.sharedView?.style?.body?.content?.footer?.body}
                >
                  <LeftSideFooter>
                    {loggedInTeamDetails?.name && (
                      <p>Confidential: {loggedInTeamDetails?.name}</p>
                    )}
                  </LeftSideFooter>
                  <RightSideFooter>
                    <p>Powered by&nbsp;</p>
                    <AppLink
                      onClick={() => {
                        window.open(APP_BUSINESS_WEBSITE_URL, "_blank");
                      }}
                    >
                      {appConfig.appName}
                    </AppLink>
                  </RightSideFooter>
                </SharedSolutionBodyFooter>
              </SharedSolutionBodyContainer>
            </SharedSolutionBodyWrapper>
          </SharedSolutionContainer>
        </AssetsContextProvider>
      </SolutionDetailsContextProvider>
    </ThemeProvider>
  );
};

export default SharedSolutionDetails;
