import { Slider } from "../Slider";
import { cn } from "../lib/utils";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./Form";

interface IFormNumberField {
  label: string;
  name: string;
  description?: string;
  required?: boolean;
  form: any;
  [x: string]: any;
}

const NumberField = ({
  label,
  description,
  required,
  name,
  form,
  ...allprops
}: IFormNumberField) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <div className="flex items-center justify-between">
            <FormLabel
              htmlFor={name}
              className={cn(
                "text-2xl font-normal text-foreground",
                required && "after:content-['_*']"
              )}
            >
              {label}
            </FormLabel>
            <span className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-xl text-muted-foreground hover:border-border">
              {field.value}
            </span>
          </div>
          <FormControl>
            <div className="grid gap-4">
              <Slider
                id={name}
                max={allprops.maxLength}
                defaultValue={[field.value]}
                step={allprops.step}
                onValueChange={field.onChange}
                className="[&_[role=slider]]:h-4 [&_[role=slider]]:w-4"
                aria-label={allprops.ariaLabel}
              />
            </div>
          </FormControl>
          <FormMessage className="text-lg font-light" />
        </FormItem>
      )}
    />
  );
};

export default NumberField;
