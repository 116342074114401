import {
  IDropdownCollectionItem,
  IStateOfAllDropdowns,
} from "../../interfaces/dropdown";

export function getRelationshipParametersString(
  stateOfAllDropdowns: IStateOfAllDropdowns | undefined
) {
  let relParamString =
    stateOfAllDropdowns && Object.keys(stateOfAllDropdowns)
      ? Object.keys(stateOfAllDropdowns).reduce(
          (joinedRelParam, dropdownKey) => {
            const selectedIndexForDropdownKey = stateOfAllDropdowns[
              dropdownKey
            ].selected
              .map((item) => item.value)
              .join(",");
            joinedRelParam =
              joinedRelParam +
              (selectedIndexForDropdownKey.length
                ? `${selectedIndexForDropdownKey},`
                : selectedIndexForDropdownKey);
            return joinedRelParam;
          },
          ""
        )
      : "";

  relParamString = relParamString.endsWith(",")
    ? relParamString.slice(0, -1)
    : relParamString;

  return relParamString;
}

export const getStateOfAllDropdownFromRelIds = (
  relParameterIds: number[],
  assetsMetadata: any
) => {
  const stateOfAllDropdowns = {} as IStateOfAllDropdowns;
  assetsMetadata?.filters?.relationship_parameters.forEach(
    (relParameterFilterObj: { data: any[] }, index: string | number) => {
      const individualDropdownState = relParameterFilterObj.data.reduce(
        (stateOfIndividualDropdown, listItem) => {
          const isSelected = relParameterIds?.includes(listItem.id);
          const stateObj = {
            label: listItem.value,
            value: listItem.id,
            isSelected,
          };
          const state = isSelected ? "selected" : "suggested";
          stateOfIndividualDropdown[state].push(stateObj);
          return stateOfIndividualDropdown;
        },
        {
          search: "",
          selected: [],
          suggested: [],
        }
      );
      stateOfAllDropdowns[index] = individualDropdownState;
    }
  );
  return stateOfAllDropdowns;
};
