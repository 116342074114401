import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "./lib/utils";

const inputTagVariants = cva(
  "flex items-center gap-1 rounded-lg border border-solid text-xl font-medium font-lexend cursor-pointer",
  {
    variants: {
      variant: {
        default: "bg-purple-50 text-purple-600 border-purple-300",
        destructive: "bg-red-50 text-red-600 border-red-300",
      },
      size: {
        default: "h-9 px-3 py-2",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface InputTagProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof inputTagVariants> {}

const InputTag = ({
  className,
  children,
  variant,
  size,
  ...props
}: InputTagProps) => {
  return (
    <div
      className={cn(inputTagVariants({ variant, size, className }))}
      {...props}
    >
      {children}
    </div>
  );
};

export { InputTag, inputTagVariants };
