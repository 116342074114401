export const tinymcePluginArray = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "image",
  "charmap",
  "preview",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "code",
  "help",
  "wordcount",
  "save",
  "insertdatetime",
  "pageembed",
  "export",
  "powerpaste",
  "checklist",
  "pagebreak",
];

export const editorElementStyle = {
  image: {
    maxWidth: "97%",
  },
  pdf: {
    maxWidth: "97%",
  },
  other: {
    maxWidth: "97%",
  },
};
