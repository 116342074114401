import { Dispatch } from "react";
import { IIntegrationAppData } from "../integration";

export enum IntegrationActionType {
  SET_ACTIVE_APP_CARD_DATA = "SET_ACTIVE_APP_CARD_DATA",
  REMOVE_ACTIVE_APP_CARD_DATA = "REMOVE_ACTIVE_APP_CARD_DATA",
}

type TSetAppData = {
  type: IntegrationActionType.SET_ACTIVE_APP_CARD_DATA;
  payload: IIntegrationAppData | undefined;
};

type TRemoveAppData = {
  type: IntegrationActionType.REMOVE_ACTIVE_APP_CARD_DATA;
};

export type TIntegrationReducerAction = TSetAppData | TRemoveAppData;

export interface IIntegrationState {
  appData?: IIntegrationAppData;
}

export interface IIntegrationContext {
  integrationState: IIntegrationState;
  integrationDispatch: Dispatch<TIntegrationReducerAction>;
}
