import axiosInstance from "../axios";
import { IDocumentObject, IMutationState } from "../../interfaces";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { apiConfig } from "../../config";
import { toast } from "react-toastify";
import { ISolutionAssetMutationObject } from "../../interfaces/solution";
import { queryKeys } from "../constants";

const categorizeByFiles = (mutationData: ISolutionAssetMutationObject) => {
  return Object.entries(mutationData).reduce(
    (fileFieldsAndOtherFieldsObj, [key, value]) => {
      if (key === "documents" && Array.isArray(value)) {
        const documentIdArr: number[] = [];
        const filesToUpload: File[] = value.filter((documentIdOrFile) => {
          if (documentIdOrFile instanceof File) {
            return true;
          } else {
            documentIdArr.push(documentIdOrFile);
            return false;
          }
        });
        fileFieldsAndOtherFieldsObj["documentFiles"] = filesToUpload;
        fileFieldsAndOtherFieldsObj["others"]["documents"] = documentIdArr;
      } else {
        const valueType = value instanceof File ? "files" : "others";
        fileFieldsAndOtherFieldsObj[valueType][key] = value;
      }
      return fileFieldsAndOtherFieldsObj;
    },
    {
      files: {} as any,
      others: {} as any,
      documentFiles: [] as File[],
    }
  );
};

const uploadDocuments = (files: File[]) => {
  return files.map((file) => {
    const formData = new FormData();
    formData.append("document", file);
    return axiosInstance.post("/documents/", formData);
  });
};

const updateSolutionAssetItemFiles = async (url: string, filesObj: object) => {
  const formData = new FormData();
  Object.entries(filesObj).forEach(([key, file]) => formData.append(key, file));
  return await axiosInstance.patch(url, formData);
};

const updateSolutionAssetItem = async (
  solutionId: string | number,
  { id, ...updateData }: ISolutionAssetMutationObject
) => {
  if (!solutionId) throw Error;
  const apiEndpoint = `${apiConfig.urls.solutions}${solutionId}${apiConfig.urls.assets}${id}/`;
  const { files, others, documentFiles } = categorizeByFiles(updateData);
  const newDocuments: IDocumentObject[] = (
    await Promise.all(uploadDocuments(documentFiles))
  ).map((res) => res.data);
  const newDocumentIdArr = newDocuments.map((documentObj) => documentObj.id);
  const data = {
    ...others,
    documents: updateData.documents
      ? [...others.documents, ...newDocumentIdArr]
      : undefined,
  };
  if (Object.keys(files).length)
    await updateSolutionAssetItemFiles(apiEndpoint, files);
  return await axiosInstance.patch(apiEndpoint, data);
};

const createSolutionAssetItem = async (
  solutionId: string | number,
  postData: ISolutionAssetMutationObject
) => {
  if (!solutionId) throw Error;
  const apiEndpoint = `${apiConfig.urls.solutions}${solutionId}${apiConfig.urls.assets}`;
  const { files, others, documentFiles } = categorizeByFiles(postData);
  const newDocuments: IDocumentObject[] = documentFiles?.length
    ? (await Promise.all(uploadDocuments(documentFiles))).map((res) => res.data)
    : [];
  const newDocumentIdArr = newDocuments.map((documentObj) => documentObj.id);
  const data = {
    ...others,
    documents: postData.documents
      ? [...others.documents, ...newDocumentIdArr]
      : undefined,
  };
  const newAssetItem = await axiosInstance.post(apiEndpoint, data);
  if (Object.keys(files).length)
    await updateSolutionAssetItemFiles(
      `${apiEndpoint}${newAssetItem.data.id}/`,
      files
    );
  return newAssetItem;
};

function useCreateUpdateSolutionAssetItem(solutionId: string | number) {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  const queryClient = useQueryClient();

  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSettled: (data: any, error: any, variables: any, context: any) => {
      const assetTypeId = data?.data?.asset_type?.id;
      const queryKeyArr = [queryKeys.solutionAssets, { solutionId }] as any[];
      if (assetTypeId) queryKeyArr.push({ type: assetTypeId });
      queryClient.invalidateQueries(queryKeyArr);
      queryClient.invalidateQueries([queryKeys.solutionAssetsType]);
    },
  };

  const mutationFunction = (mutationData: ISolutionAssetMutationObject) => {
    return mutationData.id
      ? updateSolutionAssetItem(solutionId, mutationData)
      : createSolutionAssetItem(solutionId, mutationData);
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );

  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isLoading, isSuccess, isError]);

  return { mutate, ...mutationState };
}

export default useCreateUpdateSolutionAssetItem;
