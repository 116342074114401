import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useGetAssetMetadata } from "../../../../../api/hooks";
import { appConfig } from "../../../../../config";
import { ContentAreaContext } from "../../../../../context";
import { getRelationshipParametersString } from "../../../../../helpers";
import { IStateOfAllDropdowns } from "../../../../../interfaces/dropdown";
import { history } from "../../../../../utils/history";
import { ClearAllContainer } from "./styled";

const ClearAllFilter = () => {
  const location = useLocation();
  const { setStateOfAllDropdowns, stateOfAllDropdowns } =
    useContext(ContentAreaContext);
  const { data: assetsMetadata } = useGetAssetMetadata();
  const {
    pageSpecificConfigs: {
      assets: { parentUrl },
    },
  } = appConfig;

  const urlParams = new URLSearchParams(location.search);

  const validPageToShowFilter =
    location.pathname === parentUrl || location.pathname === `${parentUrl}/`;
  const relationshipParams =
    getRelationshipParametersString(stateOfAllDropdowns);

  return validPageToShowFilter && relationshipParams ? (
    <ClearAllContainer
      onClick={() => {
        const key = urlParams.get("tab");
        const initialStateOfAllDropdowns = {} as IStateOfAllDropdowns;
        assetsMetadata?.filters?.relationship_parameters.forEach(
          (parameter: { data: any[] }, index: string | number) => {
            initialStateOfAllDropdowns[index] = {
              search: "",
              selected: [],
              suggested: parameter.data.map(
                (listItem: { value: any; id: any }) => ({
                  label: listItem.value,
                  value: listItem.id,
                  isSelected: false,
                })
              ),
            };
          }
        );
        setStateOfAllDropdowns(initialStateOfAllDropdowns);
        history.push(location.pathname);
      }}
      className="clear flex"
    >
      <span>Clear all filters</span>
    </ClearAllContainer>
  ) : (
    <></>
  );
};

export default ClearAllFilter;
