import noop from "lodash/noop";
import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import ClipLoader from "react-spinners/ClipLoader";
import { trackUserAction } from "../../../../../analytics";
import { userActivityEvents } from "../../../../../config";
import {
  CreateNewTagButton,
  CreateNewTagContainer,
  CreateNewTagInputContainer,
  CreateNewTagSubmit,
  CreateNewTagTextBox,
  SearchSelectCardWrapper,
  SearchSelectCheckboxContainer,
  SearchSelectHeaderContainer,
  SearchSelectHeadSearchWrapper,
  SearchSelectOptionContentWrapper,
  SearchSelectOptionWrapper,
  SearchSelectSearchBar,
} from "./styled";

interface IItemsObj {
  label: string;
  value: string;
  isChecked?: boolean;
}
interface ISearchSelectCardProps {
  title: string;
  resetLabel?: string;
  inputPlaceholder?: string;
  items?: IItemsObj[];
  onChange?: Function;
  onCreateTagSubmit?: Function;
  onTagCreationSuccess?: Function;
}

export const SearchSelectCard = ({
  title,
  items = [],
  resetLabel = "Clear all",
  inputPlaceholder = "Search",
  onChange = noop,
  onCreateTagSubmit = noop,
  onTagCreationSuccess = noop,
}: ISearchSelectCardProps) => {
  const [itemsState, setItemsState] = useState<IItemsObj[]>(items);
  const [searchText, setSearchText] = useState("");
  const [itemCount, setItemCount] = useState(items.length);
  const [showCreateOption, setShowCreateOption] = useState<boolean>(false);
  const [newTagName, setNewTagName] = useState("");
  const [isCreatingNewTag, setIsCreatingNewTag] = useState<boolean>(false);
  const checkBoxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(itemsState)) {
      setItemsState(
        items.filter((itemObj) =>
          itemObj.label.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      if (checkBoxContainerRef.current && items.length === itemCount + 1) {
        setTimeout(() => {
          if (checkBoxContainerRef.current)
            checkBoxContainerRef.current.scrollTop =
              checkBoxContainerRef.current?.scrollHeight;
        }, 200);
      }
    }
    setItemCount(items.length);
  }, [items]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = e.target.value;

    const updatedItemsArr = newSearchText
      ? items.filter((itemObj) =>
          itemObj.label.toLowerCase().includes(newSearchText.toLowerCase())
        )
      : items;
    setSearchText(newSearchText);
    setItemsState(updatedItemsArr);
  };

  const updateItemsState = (
    itemValue: string,
    isChecked: boolean | undefined
  ) => {
    const updatedItemsArr = items.map((itemObj) => {
      if (itemObj.value === itemValue) {
        itemObj.isChecked = Boolean(isChecked);
      }
      return itemObj;
    });
    onChange(updatedItemsArr);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    updateItemsState(value, checked);
  };

  const handleClearClick = () => {
    setSearchText("");
    const updatedItemsArr = items.map((itemObj) => {
      return { ...itemObj, isChecked: false };
    });
    onChange(updatedItemsArr);
  };

  const handleItemClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    if (!(e.currentTarget instanceof HTMLElement)) {
      return;
    }
    const inputElements = e.currentTarget.getElementsByTagName("input");
    if (inputElements.length) {
      const inputElement: HTMLInputElement = inputElements[0];
      const itemValue = inputElement.value;
      if (itemValue)
        updateItemsState(itemValue, !Boolean(inputElement.checked));
    }
  };

  const createNewTagHandler = async () => {
    setSearchText("");
    setIsCreatingNewTag(true);
    try {
      var newTag = await onCreateTagSubmit(newTagName);
      setShowCreateOption(false);
      trackUserAction(
        userActivityEvents.ASSETADDEDITFORM__CREATE_NEW_TAG_CLICKED,
        { newTagName }
      );
      setNewTagName("");
    } catch (error) {
    } finally {
      setIsCreatingNewTag(false);
    }
    if (newTag?.data) onTagCreationSuccess(newTag.data);
  };

  return (
    <SearchSelectCardWrapper>
      <SearchSelectHeadSearchWrapper>
        <SearchSelectHeaderContainer>
          <p className="SearchSelect__title">{title}</p>
          <p className="SearchSelect__reset" onClick={handleClearClick}>
            {resetLabel}
          </p>
        </SearchSelectHeaderContainer>
        <SearchSelectSearchBar>
          <FiSearch className="SearchSelect__search-icon"></FiSearch>
          <input
            className={"SearchSelect__search-input"}
            value={searchText}
            type={"text"}
            placeholder={inputPlaceholder}
            onChange={handleSearchTextChange}
          ></input>
        </SearchSelectSearchBar>
      </SearchSelectHeadSearchWrapper>
      <SearchSelectCheckboxContainer ref={checkBoxContainerRef}>
        {itemsState.map((item, i) => {
          return (
            <SearchSelectOptionWrapper
              key={item.value}
              className="SearchSelect__option"
              data-item-value={item.value}
              onClick={handleItemClick}
            >
              <SearchSelectOptionContentWrapper>
                <label
                  className="SearchSelect__option-label"
                  htmlFor={item.value}
                >
                  {item.label}
                  <input
                    id={`check-${item.value}`}
                    name={item.value}
                    value={item.value || ""}
                    type="checkbox"
                    checked={!!item.isChecked}
                    onChange={handleCheckboxChange}
                  ></input>
                  <FaCheck className="SearchSelect__checkmark"></FaCheck>
                </label>
              </SearchSelectOptionContentWrapper>
            </SearchSelectOptionWrapper>
          );
        })}
      </SearchSelectCheckboxContainer>
      <CreateNewTagContainer>
        {!showCreateOption ? (
          <CreateNewTagButton onClick={() => setShowCreateOption(true)}>
            + Create new tag
          </CreateNewTagButton>
        ) : (
          <CreateNewTagInputContainer>
            <CreateNewTagTextBox
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
            ></CreateNewTagTextBox>
            <CreateNewTagSubmit
              onClick={(e) => {
                e.preventDefault();
                if (!newTagName) setShowCreateOption(false);
                createNewTagHandler();
              }}
            >
              {!isCreatingNewTag ? (
                <FaCheck />
              ) : (
                <ClipLoader color="grey" size={14} />
              )}
            </CreateNewTagSubmit>
          </CreateNewTagInputContainer>
        )}
      </CreateNewTagContainer>
    </SearchSelectCardWrapper>
  );
};

export default SearchSelectCard;
