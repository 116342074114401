import styled from "@emotion/styled";
import { ReactNode } from "react";
import tick from "../../../../../assets/icons/tick.svg";
import warning from "../../../../../assets/icons/warning.svg";

interface IMessage {
  background: string;
}

const Message = styled.div`
  background: ${({ background }: IMessage) => background};
  padding: 1rem 10.2rem 1rem 1.2rem;
  display: inline-flex;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  font-weight: 500;
  align-items: center;
  .message-content {
    margin-left: 1rem;
  }
`;

const bannerBackgrounds = {
  success: "#D3F4D4",
  danger: "#FFD9D9",
  update: "#E7E7E7",
};

const bannerIcon = {
  success: tick,
  danger: warning,
  update: warning,
};

interface IMessageBanner {
  type?: keyof typeof bannerBackgrounds;
  customIcon?: ReactNode;
  altText: string;
  messageContent: string;
}

function MessageBanner({
  type = "success",
  altText,
  customIcon,
  messageContent,
}: IMessageBanner) {
  return (
    <Message background={bannerBackgrounds[type]}>
      {customIcon ? customIcon : <img src={bannerIcon[type]} alt={altText} />}
      <span className="message-content">{messageContent}</span>
    </Message>
  );
}

export default MessageBanner;
