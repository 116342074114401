import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { IAutoCompleteSearchResponse } from "../../interfaces/hooks";
import { useQuery } from "react-query";
interface IUseGetAutoCompleteSearch {
  searchText: string;
  filters: object;
}

interface IAutoCompleteSearchAPIFilters {
  exclude_objects?: string[];
  solution?: number | string;
}

async function getAutoCompleteSearchResults(
  searchText: string,
  filters: IAutoCompleteSearchAPIFilters
) {
  const searchParams = new URLSearchParams(filters as any);
  const response = await axiosInstance.get(
    `/search/?q=${searchText}&${searchParams.toString()}`
  );
  return response.data;
}

const useGetAutoCompleteSearch = ({
  searchText,
  filters,
}: IUseGetAutoCompleteSearch) => {
  const autocompleteSearchResponse = useQuery<
    IAutoCompleteSearchResponse,
    Error
  >(
    [queryKeys.autoCompleteSearch, searchText, filters],
    () => getAutoCompleteSearchResults(searchText, filters),
    {
      enabled: !!searchText.length,
      suspense: false,
    }
  );

  return autocompleteSearchResponse;
};

export default useGetAutoCompleteSearch;
