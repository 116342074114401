import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { history } from "../../../utils/history";
import axiosInstance from "../../../api/axios";
import { AuthContext } from "../../../context";
import { storeUserInfoAndAuthenticationDetails } from "../../../helpers";
import { appConfig, userActivityEvents } from "../../../config";
import { identifyUser, trackUserAction } from "../../../analytics";
import { SIGNIN_METHODS } from "../../../api/constants";
import {
  ErrorContent,
  ErrorWrapper,
  Text,
} from "../../compositional/ErrorBoundary/ContentAreaErrorBoundary/styled";
import HeaderBand from "../../compositional/UserInfoLayout/components/HeaderBand";
import { GatherUserInfoTitle as Title } from "../../compositional/UserInfoLayout/components/common";
import { FallBack } from "../../compositional";

const {
  urls: { signin: signinUrl },
  emailIds: { support: supportEmail },
} = appConfig;

interface IRedirectAuthState {
  code: string;
  state: string;
}

const Authenticator = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { authDispatch } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const code = urlParams.get("code");
  const state = urlParams.get("state");

  useEffect(() => {
    if (code) {
      const parsedParams = {
        code,
        state,
      } as unknown as IRedirectAuthState;
      if (!parsedParams.code) return history.push(signinUrl);

      axiosInstance
        .post("/auth/google/login/", parsedParams)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            const user = res.data.user;

            // Analytics
            identifyUser(user, res.data.team.id);

            storeUserInfoAndAuthenticationDetails(
              authDispatch,
              res.data,
              () => {
                localStorage.setItem("loggedInUser", JSON.stringify(res.data));
                trackUserAction(userActivityEvents.SIGNIN__FLOW_COMPLETED, {
                  method: SIGNIN_METHODS.GOOGLE,
                });
                // history.push(workspaceSelectionUrl);
                history.push("/");
              }
            );
          } else {
            history.push("/");
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setErrorMessage("UserNotFound");
          } else {
            setErrorMessage(err.response.data);
            setTimeout(() => {
              history.push("/");
            }, 3000);
            toast.error(err?.response?.data?.error?.details);
          }
          trackUserAction(userActivityEvents.SIGNIN__ERROR, {
            error:
              err.response.status === 404 ? "UserNotFound" : err.response.data,
          });
        });
    }
  }, [code, state]);

  if (!errorMessage) return <FallBack fullPage></FallBack>;

  return (
    <>
      <HeaderBand />
      {errorMessage && (
        <ErrorWrapper style={{ marginTop: "14.3rem" }}>
          <ErrorContent style={{ textAlign: "center" }}>
            <Title style={{ lineHeight: "150%" }}>
              {errorMessage === "UserNotFound" ? (
                `Your ${appConfig.appName} account is not setup yet!`
              ) : (
                <span>
                  Oops.. Something went wrong!
                  <br /> Redirecting..
                </span>
              )}
            </Title>
            <Text>
              {errorMessage === "UserNotFound" && (
                <span>
                  But don’t worry. You just need to contact your company admin
                  or drop an email to{" "}
                  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>, and we
                  will get you setup asap.
                </span>
              )}
            </Text>
          </ErrorContent>
        </ErrorWrapper>
      )}
    </>
  );
};

export default Authenticator;
