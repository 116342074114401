import styled from "@emotion/styled";

export const ClearAllContainer= styled.div`
justify-content:center;
color: #dc2626;
font-size: 1.4rem;
margin-top: 2rem;
cursor: pointer;
.clear{
    line-height: 2.56rem;
    font-weight: 400;
    cursor: pointer;
    width: 9rem;
}
}`