import React, { createContext, useState } from "react";

interface ISolutionParamsSettingsContext {
  activeTagId: number | undefined;
  setActiveTagId: React.Dispatch<React.SetStateAction<number | undefined>>;
  activeRelParamsId: number | undefined;
  setActiveRelParamsId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  activeAddNewTagId: number | undefined;
  setActiveAddNewTagId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

export const SolutionParamsSettingsContext = createContext(
  {} as ISolutionParamsSettingsContext
);

export const SolutionParamsSettingsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeTagId, setActiveTagId] = useState<number | undefined>();
  const [activeRelParamsId, setActiveRelParamsId] = useState<
    number | undefined
  >();
  const [activeAddNewTagId, setActiveAddNewTagId] = useState<
    number | undefined
  >();
  return (
    <SolutionParamsSettingsContext.Provider
      value={{
        activeTagId,
        setActiveTagId,
        activeRelParamsId,
        setActiveRelParamsId,
        activeAddNewTagId,
        setActiveAddNewTagId,
      }}
    >
      {children}
    </SolutionParamsSettingsContext.Provider>
  );
};
