import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import useGetAllSolutionParamTags from "../../../../../../api/hooks/useGetAllSolutionParamTags";
import {
  IRelationshipParamerter,
  IRelationshipParameterTag,
} from "../../../../../../interfaces";
import {
  IDropdownData,
  IDropdownOption,
} from "../../../../../../interfaces/dropdown";
import Dropdown from "../../../../Dropdown/components/DropdownMultiSelect";
import {
  FilterDropDowns,
  DropdownContainer,
  DropdownHeaderContainer,
  ErrorText,
} from "./styled";
import { useGetSettings } from "../../../../../../api/hooks";
import RelParamTags from "./RelParamTags";

const ERROR_TEXT_REQUIRED_FIELD = "This is required";

type TId = number | string;
type TDropdownRelParam = IRelationshipParamerter & {
  isSelectable?: boolean;
  isSelected?: boolean;
};
type TDropdownRelParamTag = IRelationshipParameterTag & {
  relationship_parameters: TDropdownRelParam[];
  selected: TId[];
  otherIds: TId[];
};
type IRelParamDropdownData = IDropdownData & {
  isRequired?: boolean;
  selectedValues: Set<number>;
  type: "str" | "select" | "multiselect";
};

export type TRelParamRenderType = "all" | "required" | "optional";

type TRelParamDropdownsProps = {
  onChange: (selectedIds: number[]) => void;
  initSelectedIds?: number[];
  renderType?: TRelParamRenderType;
};

type TRelParamDropdownsHandle = {
  validateFields: () => number[];
};

const multiSelectTypes = ["str", "multiselect"];

const RelParamDropdowns: React.ForwardRefRenderFunction<
  TRelParamDropdownsHandle,
  TRelParamDropdownsProps
> = ({ initSelectedIds, onChange, renderType = "all" }, forwardedRef) => {
  useImperativeHandle(forwardedRef, () => ({
    validateFields,
  }));

  const { data: teamSettings } = useGetSettings();
  const { data: tagsResponse } = useGetAllSolutionParamTags();
  const [tagsObj, setTagsObj] = useState<{
    [x: TDropdownRelParamTag["id"]]: TDropdownRelParamTag;
  }>({});

  const [allGenericResIds, setAllGenericResIds] = useState<number[]>([]);
  const [errorTagIds, setErrorTagIds] = useState<number[]>([]);
  // const [availIds, setAvailIds] = useState<number[]>([]);

  const tagsData = useMemo(() => {
    let tagsDataArr = tagsResponse?.data ?? [];
    const requiredTagIds = teamSettings?.solution?.required_tag_ids || [];
    if (requiredTagIds.length)
      tagsDataArr = tagsDataArr.filter((tag) =>
        renderType === "all"
          ? true
          : renderType === "required"
          ? requiredTagIds.includes(tag.id)
          : !requiredTagIds.includes(tag.id)
      );
    return tagsDataArr;
  }, [
    renderType,
    tagsResponse?.data,
    teamSettings?.solution?.required_tag_ids,
  ]);

  useEffect(() => {
    const allGrIdArr = Array.from(
      new Set(
        tagsData.flatMap((tag) =>
          tag.relationship_parameters.flatMap((rp) => rp.generic_resources)
        )
      )
    );
    setAllGenericResIds(allGrIdArr.sort((a, b) => a - b));
    // setAvailIds(allGrIdArr.sort((a, b) => a - b));
    const defaultRelParamIds: number[] = [];
    const tagsDataObj = tagsData.reduce((a, tag) => {
      const isMultiSelect = multiSelectTypes.includes(tag.type);
      const hasSelection = tag.relationship_parameters.some((rp) =>
        initSelectedIds?.includes(rp.id)
      );
      const relParamIds = tag.relationship_parameters.map((rp) => rp.id);
      const firstInitSelectedId = initSelectedIds?.find((id) =>
        relParamIds.includes(id)
      );
      const firstDefaultRelParam = tag.relationship_parameters.find(
        (rp) => rp.is_default
      );
      return {
        ...a,
        [tag.id]: {
          ...tag,
          selected: [],
          otherIds: [],
          availIds: [],
          relationship_parameters: tag.relationship_parameters.map((rp) => {
            let isSelected = Boolean(
              hasSelection
                ? firstInitSelectedId === rp.id
                : firstDefaultRelParam?.id === rp.id
            );
            if (isMultiSelect) {
              isSelected = Boolean(
                hasSelection ? initSelectedIds?.includes(rp.id) : rp.is_default
              );
            }
            if (isSelected) defaultRelParamIds.push(rp.id);
            return {
              ...rp,
              isSelectable: rp.generic_resources.length > 0,
              isSelected,
            };
          }),
        },
      };
    }, {});

    setTagsObj(tagsDataObj);
    if (defaultRelParamIds.length) {
      onChange?.(defaultRelParamIds);
    }
  }, [tagsResponse?.data, initSelectedIds]);

  const dropdownData = useMemo<Map<number, IRelParamDropdownData>>(() => {
    const dropdownDataMap = new Map();
    if (!Object.values(tagsObj).length) return dropdownDataMap;
    tagsData.forEach((tagObj) => {
      const tag = tagsObj[tagObj.id];
      const dropdownOptions: IDropdownOption[] =
        tag.relationship_parameters.map((rp: TDropdownRelParam) => {
          return {
            label: rp.value,
            value: rp.id,
            isSelectable: rp.isSelectable,
            isSelected: rp.isSelected,
          };
        });
      // .sort((a, b) => (a.isSelectable ? -1 : 1));
      dropdownDataMap.set(tag.id, {
        label: tag.tag_name,
        key: tag.id,
        type: tag.type,
        options: dropdownOptions,
        isRequired: Boolean(
          teamSettings?.solution?.required_tag_ids?.includes(tag.id)
        ),
        selectedValues: new Set(
          dropdownOptions
            .filter((option) => option.isSelected)
            .map((option) => option.value) || []
        ),
      });
    });
    return dropdownDataMap;
  }, [tagsData, tagsObj, teamSettings?.solution?.required_tag_ids]);

  const handleRpClick = useCallback(
    (tagId: IRelationshipParamerter["id"], rpId: TId) => {
      const tagObj = tagsObj[tagId];
      const isMultiSelect = multiSelectTypes.includes(tagObj.type);
      const rpObjIndex = tagObj.relationship_parameters.findIndex(
        (rp) => rp.id === rpId
      );
      const isSelected = !Boolean(
        tagObj.relationship_parameters[rpObjIndex].isSelected
      );
      if (isMultiSelect)
        tagObj.relationship_parameters[rpObjIndex].isSelected = isSelected;
      else {
        tagObj.relationship_parameters.forEach(
          (rp, index) =>
            (tagObj.relationship_parameters[index].isSelected =
              index === rpObjIndex ? isSelected : false)
        );
      }

      // 1. Create selected and otherIds for the RelParamTag
      //      Selected : Generic resource ids of all the selected rel_params
      //      OtherIds : Generic resource ids of the remaining rel_params in the tag
      const selectedIdSet: Set<TId> = new Set();
      const otherIdsInTagSet: Set<TId> = new Set();

      // Loop through the rel_params of the tag
      tagObj.relationship_parameters.forEach((rp: TDropdownRelParam) => {
        rp.generic_resources.forEach((id) => {
          if (rp.isSelected && rp.isSelectable) {
            selectedIdSet.add(id);
          } else {
            otherIdsInTagSet.add(id);
          }
        });
      });
      const selectedIdArr = Array.from(selectedIdSet);
      let otherIdArr: TId[] = [];
      if (selectedIdSet.size) {
        // Add otherIds only if there is some selection in the tag
        otherIdArr = Array.from(otherIdsInTagSet).filter(
          (id) => !selectedIdArr.includes(id)
        );
      }

      tagObj.selected = selectedIdArr;
      tagObj.otherIds = otherIdArr;

      // Update the tagsObj
      // 1. Generate the filtered list of available generic resources
      //    availableIds = allGenericResourceIds - Union(otherIds of all the tags before the active/current)
      let updatedTagsObj = { ...tagsObj, [tagId]: tagObj };

      let tagsArr = Object.values(updatedTagsObj);
      const activeTagIdIndex = tagsArr.findIndex((tag) => tag.id === tagId);
      let allOtherIds = tagsArr.reduce((otherIdsUnion, tag, index) => {
        if (index <= activeTagIdIndex)
          otherIdsUnion = otherIdsUnion.concat(tag.otherIds);
        return otherIdsUnion;
      }, [] as TId[]);

      let availableIds = allGenericResIds.filter(
        (gr_id) => !allOtherIds.includes(gr_id)
      );

      // Update the tags that comes after the active/current tag
      // Selected: Filter out any ids which is not in new availableIds
      // OtherIds:
      updatedTagsObj = tagsArr.reduce((updatedTagsObject, tag, index) => {
        // Update the tags after the current active tag
        if (index > activeTagIdIndex) {
          const selectedIdSet: Set<number> = new Set();
          const otherIdsInTagSet: Set<number> = new Set();

          // Loop through the rel_params of the tag
          const rel_params = tag.relationship_parameters.map(
            (rp: TDropdownRelParam) => {
              const isSelectable = rp.generic_resources.some((gs_id) =>
                availableIds.includes(gs_id)
              );
              rp.generic_resources.forEach((id) => {
                if (rp.isSelected && isSelectable) {
                  selectedIdSet.add(id);
                } else {
                  otherIdsInTagSet.add(id);
                }
              });
              return { ...rp, isSelectable };
            }
          );
          const newSelected = Array.from(selectedIdSet);
          let otherIdArr: number[] = [];
          if (selectedIdSet.size) {
            // Add otherIds only if there is some selection in the tag
            otherIdArr = Array.from(otherIdsInTagSet).filter(
              (id) => !newSelected.includes(id)
            );
            // Filter out the new otherIds from available Ids
            availableIds = availableIds.filter(
              (id) => !otherIdArr.includes(id)
            );
          }

          // Update tagObj
          updatedTagsObj[tag.id] = {
            ...tag,
            selected: newSelected.sort((a, b) => a - b),
            otherIds: otherIdArr.sort((a, b) => a - b),
            relationship_parameters: rel_params,
          };
        }

        return updatedTagsObject;
      }, updatedTagsObj);

      const selectedRelParamIds = Object.values(updatedTagsObj).flatMap(
        (tagObj) =>
          tagObj.relationship_parameters
            .filter((rp: TDropdownRelParam) => rp.isSelected && rp.isSelectable)
            .flatMap((rp) => rp.id)
      );
      onChange?.(selectedRelParamIds);
      // setAvailIds(availableIds.sort((a, b) => a - b));
      setTagsObj(updatedTagsObj);
    },
    [tagsObj, allGenericResIds, onChange]
  );

  const handleOptionClick = (tagId: TId, option: IDropdownOption) => {
    if (errorTagIds.includes(Number(tagId)))
      setErrorTagIds((prevIds) => prevIds.filter((id) => id !== tagId));
    handleRpClick(Number(tagId), option.value);
  };

  const validateFields = () => {
    const errorTagIds = Object.values(tagsObj).reduce((errorIds, tag) => {
      const selectedRelParms = tag.relationship_parameters.filter(
        (rp: TDropdownRelParam) => rp.isSelectable && rp.isSelected
      );
      const isRequiredTag = teamSettings?.solution?.required_tag_ids?.includes(
        tag.id
      );
      if (!selectedRelParms.length && isRequiredTag) errorIds.push(tag.id);
      return errorIds;
    }, [] as number[]);

    if (errorTagIds.length) {
      setErrorTagIds(errorTagIds);
      const errorDropdown = document.querySelector(
        `[data-tag-id="${errorTagIds[0]}"]`
      );
      errorDropdown?.scrollIntoView({
        behavior: "smooth",
      });
    }
    return errorTagIds;
  };

  return (
    <FilterDropDowns>
      {dropdownData.size > 0 &&
        Array.from(dropdownData.values()).map((dropdownDataObj) => {
          const { key, label, type } = dropdownDataObj;
          const isMultiSelect = multiSelectTypes.includes(type);
          return (
            <DropdownContainer key={key} data-tag-id={dropdownDataObj.key}>
              <DropdownHeaderContainer>
                <RelParamTags
                  label={label}
                  tagList={dropdownDataObj.options.filter(
                    (option) => option.isSelected && option.isSelectable
                  )}
                  onTagClick={(option) => handleOptionClick(key, option)}
                ></RelParamTags>
              </DropdownHeaderContainer>
              <Dropdown
                data={dropdownDataObj}
                onOptionClick={(option: IDropdownOption) =>
                  handleOptionClick(dropdownDataObj.key, option)
                }
                isMultiSelect={isMultiSelect}
              ></Dropdown>
              <ErrorText hidden={!errorTagIds.includes(Number(key))}>
                * {ERROR_TEXT_REQUIRED_FIELD}
              </ErrorText>
            </DropdownContainer>
          );
        })}
    </FilterDropDowns>
  );
};

export default forwardRef(RelParamDropdowns);
