import styled from "@emotion/styled";

interface IDropdownWrapper {
  width: string;
}
interface IBgcolor {
  background: string;
}
interface ILabelColor {
  color: string;
}
interface IBorderColor {
  borderColor: string;
}
interface IDropdownSelectedItem {
  selectColor: string;
}
interface IDropdownList {
  dropDownBgColor: any;
}
//@ts-ignore
export const DropdownWrapper = styled.div`
  position: relative;
  background: ${({ background }: IBgcolor) => background};
  border: 1px solid #cbd5e1;
  border-radius: 0.8rem;
`;

export const DropdownViewable = styled.div`
  padding: 1rem 1.4rem;
  user-select: none;
  cursor: pointer;
  .selected-item-label {
    max-width: 50rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .more {
    color: #2563eb;
    font-size: 1rem;
    margin-left: 1rem;
    white-space: nowrap;
  }
`;

export const DropdownLabel = styled.h3`
  font-size: 1.2rem;
  color: ${({ color }: ILabelColor) => color};
  font-family: var(--font-lexend);
  margin-bottom: 0.6rem;
`;

export const DropdownSelectedItem = styled.h2`
  min-height: 1.4rem;
  font-family: var(--font-lexend);
  font-size: 1.4rem;
  color: ${({ selectColor }: IDropdownSelectedItem) => selectColor};
  line-height: 1.75rem;
`;

export const DropdownSearch = styled.input`
  display: inline-block;
  width: 100%;
  border: 0;
  box-sizing: border-box;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 150%;
  font-family: var(--font-lexend);
  color: #64748b;
  outline: 0;
  ::placeholder {
    color: #cfcfcf;
  }
`;

interface IDropdownListWrapper {
  isOpen: boolean;
}
//@ts-ignore
export const DropdownListWrapper = styled.div`
  position: absolute;
  background: ${({ dropDownBgColor }: IDropdownList) => dropDownBgColor};
  border-radius: 0.4rem;
  height: 29rem;
  z-index: 10;
  width: 44rem;
  left: 0;
  top: 100%;
  margin-top: 0.2rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  opacity: ${({ isOpen }: IDropdownListWrapper) => (isOpen ? 1 : 0)};
  display: ${({ isOpen }: IDropdownListWrapper) => (isOpen ? "flex" : "none")};
  border: 1px solid #dae1e9;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.08);
  padding: 1.2rem;
  flex-direction: column;
  .title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
  }
  .dropdown-list {
    overflow-y: auto;
  }
  .search-box {
    display: flex;
    width: 100%;
    border: 1px solid #cbd5e1;
    border-radius: 0.6rem;
    padding: 0.8rem;
    margin-bottom: 1.2rem;
    gap: 0.8rem;
    .show-cross {
      opacity: 1;
    }
  }
`;

// DropdownList
export const DropdownList = styled.ul`
  font-size: 1.2rem;
  font-weight: 300;
`;

export const DropdownListItem = styled.li`
  cursor: pointer;
  &:hover {
    background: #f8faff;
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  padding: 0.6rem 0.8rem;
  align-items: center;
`;

export const ListItemCheckbox = styled.input`
  margin-right: 1rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 0;
  display: none;
`;

export const ListItemLabel = styled.div`
  color: #1b1b1b;
  width: 100%;
`;
