import { FieldInputProps, FieldMetaProps, useField } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { IoImage } from "react-icons/io5";
import { FaFileCsv } from "react-icons/fa";
import { FileInputType } from "../../../../constants";
import {
  getAbsoluteFileUrlFromPath,
  getFileNameFromUrl,
} from "../../../../helpers";
import { IDocumentObject } from "../../../../interfaces";
import { Tag } from "../../Reusable/components/Tags";
import { FormErrorText } from "../common";
import { FileSelectUploadModal } from "../FileSelectUploadModal";
import { FormTagContainer } from "../styled";
import {
  BrowseCSVButton,
  BrowseFileButton,
  FileInputFieldContentWrapper,
  FileInputFieldWrapper,
  IconContainer,
  IconFieldWrapper,
  RemoveFileButton,
} from "./styled";
import { FiTrash2 } from "react-icons/fi";
import { TbPencil } from "react-icons/tb";

interface IFormFileInputField {
  label: string;
  name: string;
  formikField?: FieldInputProps<any>;
  formikMeta?: FieldMetaProps<any>;
  type?: FileInputType;
  [x: string]: any;
}

const FormFileInputField = ({
  type,
  label,
  browseFileButtonLabel,
  changeFileButtonLabel,
  removeFileButtonLabel,
  ...allProps
}: IFormFileInputField) => {
  const [isFocused, setFocused] = useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);
  const [showModal, setShowModal] = useState(false);

  const {
    formikField,
    formikMeta,
    formikHelpers,
    required: isRequired,
    fieldType,
    ...props
  } = allProps;
  const isIcon = type === FileInputType.ICON;
  const isSelectUpload = type === FileInputType.SELECT_UPLOAD;
  const isCSV = type === FileInputType.CSV;

  const formikFieldValue = formikField?.value || (isSelectUpload ? [] : "");

  useEffect(() => {
    inputElementRef.current?.addEventListener("focusin", () =>
      setFocused(true)
    );
    inputElementRef.current?.addEventListener("focusout", () =>
      setFocused(false)
    );
  });

  const handleOnDismiss = (selectedDocumentObjs: Array<string | number>) => {
    setShowModal(false);
    formikHelpers?.setValue(
      selectedDocumentObjs.concat(
        formikFieldValue.filter(
          (fileOrDocObj: any) => fileOrDocObj instanceof File
        )
      )
    );
  };

  const handleChangeFromModal = (selectedDocumentObjs: IDocumentObject[]) => {
    formikHelpers?.setValue(
      selectedDocumentObjs.concat(
        formikFieldValue.filter(
          (fileOrDocObj: any) => fileOrDocObj instanceof File
        )
      )
    );
  };

  const handleTagClick = (
    e: MouseEvent,
    documentId: string | number,
    uploadedFileName: string
  ) => {
    const updatedValues = formikFieldValue.filter(
      (fileOrDocumentObj: IDocumentObject | File) => {
        const isFile = fileOrDocumentObj instanceof File;
        if (
          (isFile && fileOrDocumentObj.name === uploadedFileName) ||
          (!isFile && fileOrDocumentObj.id === documentId)
        ) {
          return false;
        }
        return true;
      }
    );
    formikHelpers?.setValue(updatedValues);
  };

  const handleUploadClick = (e: MouseEvent) => {
    inputElementRef.current?.click();
  };

  const handleFileInputChanage = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    if (!target?.files?.length) {
      return false;
    }

    try {
      formikHelpers.setValue(Array.from(target.files).concat(formikFieldValue));
      setShowModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getTags = (
    value: IDocumentObject | File | (IDocumentObject | File)[]
  ) => {
    if (!value) return <></>;
    const isValuesArr = Array.isArray(value);
    const valueArr: (IDocumentObject | File)[] = isValuesArr ? value : [value];
    return valueArr.map((item: any) => {
      const isUploadedFile = item instanceof File;
      return (
        <Tag
          key={isUploadedFile ? item.name : item.id}
          label={
            isUploadedFile ? item.name : getFileNameFromUrl(item.url, true)
          }
          onClick={
            isSelectUpload
              ? (e: MouseEvent) => {
                  handleTagClick(e, item.id, item.name);
                }
              : isCSV
              ? (e: MouseEvent) => {
                  e.preventDefault();
                  formikHelpers.setValue("");
                }
              : undefined
          }
        />
      );
    });
  };

  const isCompact = fieldType === "compact";

  if (isCSV) {
    return (
      <FileInputFieldWrapper
        isFocused={isFocused}
        type={type}
        isRequired={isRequired}
      >
        <label>{label}</label>
        <FileInputFieldContentWrapper>
          <IconFieldWrapper className={fieldType ?? ""}>
            <IconContainer showBackground={false}>
              <FaFileCsv
                size={"4rem"}
                className="csv-icon"
                color={formikFieldValue ? "#16A34A" : "#3b82f6"}
              />
            </IconContainer>
            <label
              htmlFor={props.id || props.name}
              className="csv-upload-label"
            >
              {formikFieldValue ? (
                <FormTagContainer>{getTags(formikFieldValue)}</FormTagContainer>
              ) : (
                <BrowseCSVButton>{browseFileButtonLabel}</BrowseCSVButton>
              )}
              <input
                ref={inputElementRef}
                accept={isCSV ? ".csv" : undefined}
                type="file"
                {...{ ...formikField, value: undefined }}
                onChange={(e) =>
                  e.target.files?.length &&
                  formikHelpers.setValue(e.target.files[0])
                }
                {...props}
              ></input>
            </label>
          </IconFieldWrapper>
        </FileInputFieldContentWrapper>
        <FormErrorText formikMeta={formikMeta}></FormErrorText>
      </FileInputFieldWrapper>
    );
  }

  return (
    <FileInputFieldWrapper
      isFocused={isFocused}
      type={type}
      isRequired={isRequired}
    >
      {!isIcon && (
        <>
          <label>{label}</label>
          <FormTagContainer>{getTags(formikFieldValue)}</FormTagContainer>
        </>
      )}
      <FileInputFieldContentWrapper>
        {isSelectUpload ? (
          <>
            <BrowseFileButton tabIndex={0} onClick={() => setShowModal(true)}>
              {browseFileButtonLabel || "Click to choose file"}
            </BrowseFileButton>
            <FileSelectUploadModal
              isOpen={showModal}
              setIsOpen={setShowModal}
              onDismiss={handleOnDismiss}
              onChange={handleChangeFromModal}
              selectedItems={formikFieldValue}
              onUploadClick={handleUploadClick}
            ></FileSelectUploadModal>
            <input
              ref={inputElementRef}
              type="file"
              multiple
              {...{ ...formikField, value: undefined }}
              {...props}
              onChange={handleFileInputChanage}
            ></input>
          </>
        ) : (
          <IconFieldWrapper className={fieldType ?? ""}>
            {isIcon ? (
              <IconContainer showBackground={!Boolean(formikFieldValue)}>
                {formikFieldValue ? (
                  <img
                    src={
                      formikFieldValue instanceof File
                        ? window.URL.createObjectURL(formikFieldValue)
                        : getAbsoluteFileUrlFromPath(formikFieldValue) || ""
                    }
                    alt="Icon"
                    className="icon"
                  />
                ) : (
                  <IoImage size={"2rem"} className="image-icon"></IoImage>
                )}
              </IconContainer>
            ) : null}
            <label htmlFor={props.id || props.name}>
              <BrowseFileButton tabIndex={0}>
                {formikFieldValue ? (
                  <>
                    {isCompact && !changeFileButtonLabel ? (
                      <TbPencil size={"1.4rem"} />
                    ) : (
                      changeFileButtonLabel || "Choose different"
                    )}
                  </>
                ) : (
                  browseFileButtonLabel || "Click to choose file"
                )}
              </BrowseFileButton>
              {formikFieldValue && (
                <RemoveFileButton
                  className="remove-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    formikHelpers.setValue("");
                  }}
                >
                  {isCompact ? (
                    <FiTrash2 size={"1.4rem"} />
                  ) : (
                    removeFileButtonLabel || "Remove"
                  )}
                </RemoveFileButton>
              )}
              <input
                ref={inputElementRef}
                accept={isIcon ? ".jpeg, .jpg, .png, .bmp, .ico" : undefined}
                type="file"
                {...{ ...formikField, value: undefined }}
                onChange={(e) =>
                  e.target.files?.length &&
                  formikHelpers.setValue(e.target.files[0])
                }
                {...props}
              ></input>
            </label>
          </IconFieldWrapper>
        )}
      </FileInputFieldContentWrapper>
      <FormErrorText formikMeta={formikMeta}></FormErrorText>
    </FileInputFieldWrapper>
  );
};

const FormikFileInputField = ({ type, ...props }: IFormFileInputField) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormFileInputField
      formikField={field}
      formikMeta={meta}
      formikHelpers={helpers}
      type={type}
      {...props}
    ></FormFileInputField>
  );
};

export { FormFileInputField, FormikFileInputField };
