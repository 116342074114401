import { useState } from "react";
import axiosInstance from "../axios";
import { appConfig } from "../../config";
import { getLoggedInUser } from "../../helpers";
import { IUpdateUserVariables, IUserAccount } from "../../interfaces";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { history } from "../../utils/history";
const {
  landingPage,
  urls: { inviteUsers: inviteUsersUrl },
} = appConfig;

async function updateUserDetails(
  userId: string,
  userDetails: IUserAccount,
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    await axiosInstance.patch(`/users/${userId}`, userDetails);
  } catch (err) {
    console.log(err);
    toast.error("Oops, something went wrong. Please try again.");
    setIsError(true);
  }
}

interface IUseUpdateUserDetails {
  callback?: Function;
}

export const useUpdateUserDetails = ({ callback }: IUseUpdateUserDetails) => {
  const [isError, setIsError] = useState(false);
  const updatedLoggedInuser = getLoggedInUser();

  const mutationResponse = useMutation<
    void,
    Error,
    IUpdateUserVariables,
    unknown
  >(
    ({ userDetails, userId }: IUpdateUserVariables) =>
      updateUserDetails(userId, userDetails, setIsError),
    {
      onError: () => {
        toast.error("Oops, something went wrong. Please try again.");
      },
      onSuccess: () => {
        if (updatedLoggedInuser) {
          if (updatedLoggedInuser.user.is_owner) {
            history.push(inviteUsersUrl);
          } else {
            updatedLoggedInuser.is_signup = false;
            localStorage.setItem(
              "loggedInUser",
              JSON.stringify(updatedLoggedInuser)
            );
            history.push(landingPage.apply(appConfig));
          }
        }
      },
    }
  );

  return {
    ...mutationResponse,
    isError: isError ? isError : mutationResponse.isError,
  };
};
