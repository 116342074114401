import { useGetAssetMetadata } from "..";
import { IStateOfAllDropdowns } from "../../../interfaces/dropdown";

interface IResponseObject {
  isLoading: boolean;
  data?: IStateOfAllDropdowns;
}
const useFilterDropdownInitialState = (): IResponseObject => {
  const { data: assetsMetadata, isLoading, isFetching } = useGetAssetMetadata();
  let responseObj: IResponseObject = {
    isLoading: true,
  };
  if (isLoading || isFetching) return responseObj;

  const initialStateOfAllDropdowns = {} as IStateOfAllDropdowns;
  assetsMetadata?.filters?.relationship_parameters.forEach(
    (parameter: { data: any[] }, index: string | number) => {
      initialStateOfAllDropdowns[index] = {
        search: "",
        selected: [],
        suggested: parameter.data.map((listItem: { value: any; id: any }) => ({
          label: listItem.value,
          value: listItem.id,
          isSelected: false,
        })),
      };
    }
  );
  responseObj = { isLoading: false, data: initialStateOfAllDropdowns };
  return responseObj;
};

export default useFilterDropdownInitialState;
