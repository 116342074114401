import * as Yup from "yup";
import { FormFieldType, SUPPORTED_IMAGE_FILETYPES } from "../constants";
import { IFormField } from "../interfaces";
import { campaignSearchItems } from "./campaignConfig";

export const librarySearchHiddenItems = ["solution", ...campaignSearchItems];

export enum AssetObjectType {
  BATTLE_CARD = "battle_card",
  CASE_STUDY = "case_study",
  DISCOVERY_QUESTION = "discovery_question",
  OBJECTION = "objection",
  GENERIC_RESOURCE = "generic_resource",
}

type IAssetAddEditFormFieldProps = IFormField;
export interface IAssetAddEditFormConfigObj {
  title?: string;
  editTitle?: string;
  object?: AssetObjectType;
  name?: string;
  formFields: IAssetAddEditFormFieldProps[];
}

type IAssetAddEditFormConfig = {
  [assetObjectType in AssetObjectType]?: IAssetAddEditFormConfigObj;
};

export const assetAddEditFormConfig: IAssetAddEditFormConfig = {
  [AssetObjectType.DISCOVERY_QUESTION]: {
    title: "New discovery question",
    editTitle: "Edit discovery question",
    name: "Discovery question",
    object: AssetObjectType.DISCOVERY_QUESTION,
    formFields: [
      {
        label: "Question category",
        type: FormFieldType.SELECT,
        name: "category",
        modelField: "category",
        fieldData: {
          initialValue: "",
          placeholder: "Write category name to get options",
          isCreatable: true,
          createNewLabel: "Create new category",
          options: [],
          selected: [],
        },
      },
      {
        label: "Question",
        type: FormFieldType.TEXT_AREA,
        name: "question",
        modelField: "question",
        isRichText: false,
        validation: Yup.string().required("Question is required"),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Sales play",
        type: FormFieldType.TEXT_AREA,
        name: "guidance",
        modelField: "guidance",
        isRichText: false,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Buyer segmentation",
        type: FormFieldType.TAGS,
        name: "relationship_parameters",
        modelField: "relationship_parameters",
        fieldData: {
          initialValue: [],
          placeholder: "Add new Tag",
        },
      },
    ],
  },
  [AssetObjectType.BATTLE_CARD]: {
    title: "New battle card",
    editTitle: "Edit battle card",
    name: "Battle card",
    object: AssetObjectType.BATTLE_CARD,
    formFields: [
      {
        label: "",
        type: FormFieldType.ICON_UPLOAD,
        name: "icon_url",
        modelField: "icon_url",
        writeField: "icon",
        fieldData: {
          initialValue: "",
          browseFileButtonLabel: "Choose an icon",
          changeFileButtonLabel: "Choose different",
          removeFileButtonLabel: "Remove",
        },
        validation: Yup.mixed().test(
          "isSupportedImageFileType",
          "This image type is not supported. Please add a valid .png, .jpg, .bmp, or .ico image.",
          (value, context) => {
            const getExtension = (name: string) => {
              return name.substring(name.lastIndexOf(".") + 1);
            };
            if (!value) return true;
            return SUPPORTED_IMAGE_FILETYPES.includes(
              getExtension(typeof value === "string" ? value : value.name)
            );
          }
        ),
      },
      {
        label: "Competitor Name",
        type: FormFieldType.TEXT,
        name: "competitor_name",
        modelField: "competitor_name",
        validation: Yup.string()
          .max(100)
          .required("Competitor name is required"),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Competitor details",
        type: FormFieldType.TEXT_AREA,
        name: "description_html",
        modelField: "description_html",
        alternateModelField: "description",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Areas where competitor wins",
        type: FormFieldType.TEXT_AREA,
        name: "competitor_wins_html",
        modelField: "competitor_wins_html",
        alternateModelField: "competitor_wins",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Areas where we win",
        type: FormFieldType.TEXT_AREA,
        name: "product_wins_html",
        modelField: "product_wins_html",
        alternateModelField: "product_wins",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Potential landmines to lay",
        type: FormFieldType.TEXT_AREA,
        name: "strategy_html",
        modelField: "strategy_html",
        alternateModelField: "strategy",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Upload documents as attachment",
        type: FormFieldType.FILE_SELECT_OR_UPLOAD,
        name: "documents",
        modelField: "documents",
        fieldData: {
          initialValue: [],
          browseFileButtonLabel: "Click to choose file",
          changeFileButtonLabel: "Choose different",
          removeFileButtonLabel: "Remove",
        },
      },
      {
        label: "Buyer segmentation",
        type: FormFieldType.TAGS,
        name: "relationship_parameters",
        modelField: "relationship_parameters",
        fieldData: {
          initialValue: [],
          placeholder: "Add solution parameter",
        },
      },
    ],
  },
  [AssetObjectType.CASE_STUDY]: {
    title: "New Case Study",
    editTitle: "Edit Case Study",
    name: "Case Study",
    object: AssetObjectType.CASE_STUDY,
    formFields: [
      {
        label: "Title",
        type: FormFieldType.TEXT,
        name: "title",
        modelField: "title",
        validation: Yup.string().max(255).required("Title is required"),
        fieldData: {
          initialValue: "",
          placeholder: "Enter title",
        },
      },
      {
        label: "Summary",
        type: FormFieldType.TEXT_AREA,
        name: "description",
        modelField: "description",
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Problem Statement",
        type: FormFieldType.TEXT_AREA,
        name: "problem_statement_html",
        modelField: "problem_statement_html",
        alternateModelField: "problem_statement",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Solution",
        type: FormFieldType.TEXT_AREA,
        name: "solution_html",
        modelField: "solution_html",
        alternateModelField: "solution",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Result",
        type: FormFieldType.TEXT_AREA,
        name: "result_html",
        modelField: "result_html",
        alternateModelField: "result",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Upload documents as attachment",
        type: FormFieldType.FILE_SELECT_OR_UPLOAD,
        name: "documents",
        modelField: "documents",
        fieldData: {
          initialValue: [],
          browseFileButtonLabel: "Click to choose file",
          changeFileButtonLabel: "Choose different",
          removeFileButtonLabel: "Remove",
        },
      },
      {
        label: "Buyer segmentation",
        type: FormFieldType.TAGS,
        name: "relationship_parameters",
        modelField: "relationship_parameters",
        fieldData: {
          initialValue: [],
          placeholder: "Add new Tag",
        },
      },
    ],
  },
  [AssetObjectType.GENERIC_RESOURCE]: {
    title: "New",
    editTitle: "Edit",
    name: "Resource",
    object: AssetObjectType.GENERIC_RESOURCE,
    formFields: [
      {
        label: "Title",
        type: FormFieldType.TEXT,
        name: "title",
        modelField: "title",
        validation: Yup.string().required("Title is required"),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Description",
        type: FormFieldType.TEXT_AREA,
        name: "description_html",
        modelField: "description_html",
        alternateModelField: "description",
        isRichText: true,
        validation: Yup.string().nullable(),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      // {
      //   label: "Upload documents as attachment",
      //   type: FormFieldType.FILE_SELECT_OR_UPLOAD,
      //   name: "documents",
      //   modelField: "documents",
      //   fieldData: {
      //     initialValue: [],
      //     browseFileButtonLabel: "Click to choose file",
      //     changeFileButtonLabel: "Choose different",
      //     removeFileButtonLabel: "Remove",
      //   },
      // },
      {
        label: "Buyer segmentation",
        type: FormFieldType.TAGS,
        name: "relationship_parameters",
        modelField: "relationship_parameters",
        fieldData: {
          initialValue: [],
          placeholder: "Add new tag",
        },
      },
    ],
  },
  [AssetObjectType.OBJECTION]: {
    title: "New Objection Handling",
    editTitle: "Edit Objection Handling",
    name: "Objection Handling",
    object: AssetObjectType.OBJECTION,
    formFields: [
      {
        label: "Objection",
        type: FormFieldType.TEXT,
        name: "objection",
        modelField: "objection",
        validation: Yup.string().max(512).required("Objection is required"),
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Response",
        type: FormFieldType.TEXT_AREA,
        name: "response",
        modelField: "response",
        fieldData: {
          initialValue: "",
          placeholder: "Enter text here",
        },
      },
      {
        label: "Upload documents as attachment",
        type: FormFieldType.FILE_SELECT_OR_UPLOAD,
        name: "documents",
        modelField: "documents",
        fieldData: {
          initialValue: [],
          browseFileButtonLabel: "Click to choose file",
          changeFileButtonLabel: "Choose different",
          removeFileButtonLabel: "Remove",
        },
      },
      {
        label: "Buyer segmentation",
        type: FormFieldType.TAGS,
        name: "relationship_parameters",
        modelField: "relationship_parameters",
        fieldData: {
          initialValue: [],
          placeholder: "Add new Tag",
        },
      },
    ],
  },
};
