import { createContext, useReducer } from "react";
import {
  IIntegrationContext,
  IIntegrationState,
} from "../interfaces/context/integrationContext";
import { integrationReducer } from "../reducer/integrationReducer";

const initialIntegrationState: IIntegrationState = {
  appData: undefined,
};

export const IntegrationContext = createContext({} as IIntegrationContext);

export const IntegrationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [integrationState, integrationDispatch] = useReducer(
    integrationReducer,
    initialIntegrationState
  );

  return (
    <IntegrationContext.Provider
      value={{
        integrationState,
        integrationDispatch,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};
