import styled from "@emotion/styled";

import chevronDown from "./chevron--down.svg";
import chevronUp from "./chevron--up.svg";
import React from "react";

interface ISharedSolutionHeaderBody {
  backgroundColor?: string;
}
interface ISolutionSharingContainer {
  isOpen?: boolean;
}

export const SharedSolutionContainer = styled.div`
  /* font-family: var(--font-lexend); */
  * {
    font-family: ${({ style }: any) => style.fontFamily} !important;
  }
  .container {
    width: 100%;
    padding-right: var(--bs-gutter-x, 20px);
    padding-left: var(--bs-gutter-x, 20px);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1320px;
    }
  }

  @media (min-width: 1580px) {
    .container {
      max-width: 1522px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1579.98px) {
    .container {
      width: calc(100% - 20px);
      max-width: 1400px;
    }
  }
`;

export const SharedSolutionBodyWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 960px) {
    .tabbed__sidebar.client-view {
      display: none;
    }
    .sidebar__selected-tab-content.client-view {
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 1rem;
      border-radius: 0;
    }
  }
`;

export const SharedSolutionBodyFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-right: 2rem;
  img {
    height: 2.5rem;
    cursor: pointer;
  }
  p {
    font-size: 1.3rem;
    font-weight: 350;
    color: #000000;
    user-select: none;
  }
`;

export const LeftSideFooter = styled.div`
  margin-left: 27rem;
  @media screen and (max-width: 960px) {
    margin-left: 1rem;
  }
`;

export const RightSideFooter = styled.div`
  display: flex;
  align-items: center;
`;

export const AppLink = styled.a`
  font-size: 1.3rem;
  font-weight: 350;
  color: rgb(37, 99, 235);
  cursor: pointer;
`;

interface ISharedSolutionBodyContainer {
  navItemActiveStyle?: object;
}

export const SharedSolutionBodyContainer = styled.div<ISharedSolutionBodyContainer>`
  margin: 0 4.5rem;
  /* margin-top: -8rem; */
  @media screen and (max-width: 960px) {
    margin: 0;
    top: 0;
  }
  #sidebar__tabs {
    border: none;
    background: transparent;
    box-shadow: none;
    margin-bottom: 0.5rem;
  }
  .tabbed__sidebar {
    background: none;
    border: none;
    /* box-shadow: 0 0.4rem 0.7rem rgba(0, 0, 0, 0.15); */
    border-radius: 1.2rem;
    min-height: 90vh;
    margin: 1rem 0;
    margin-left: 10px;
    /* border: 1px solid green; */
    div {
      font-size: 14px;
      line-height: 24px;
      background: transparent;
      padding: 0;
    }
    .subsection {
    }
    .sidebar-tab {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      color: #555;
    }
    .sidebar-tab-label {
      /* border: 1px solid blue; */
      color: #555;
      padding: 0 !important;
      ::after {
        content: url("${chevronDown}");
        /* filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%); */
        display: inline-block;
        width: 16px;
        position: relative;
        top: 3px;
        left: 3px; 
      }
      :hover {
        color: #555555;
      }
      &.active {
        color: black;
        ::after {
          content: url("${chevronUp}");
          display: inline-block;
          width: 16px;
          position: relative;
          top: 3px;
          left: 3px;
        }
      }
    }
    .sidebar-subsection-tab {
      /* border: 1px solid magenta; */
      padding-left: 16px;
      border-left: 1px solid #ddd;
      border-radius: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-weight: 500;
      color: #555;

      &.active {
        .sidebar-subsection-tab-label {
          color: ${({ navItemActiveStyle }: any) =>
            navItemActiveStyle?.color || "black"} !important;
          font-weight: 500;
          border-left: 1px solid
            ${({ navItemActiveStyle }: any) =>
              navItemActiveStyle?.color || "black"};
          padding-left: 16px;
          margin-left: -1px;
        }
        padding-left: 0;
      }
    }
  }

  .sidebar__selected-tab-content {
    border-radius: 24px;
    min-height: 80rem;
    margin: 1rem;
    margin-left: 1rem;
    margin-bottom: 0;
    * {
      line-height: 160%;
    }
    padding: 0;
    .SolutionAssetCardHeader__header {
    }
    .SolutionAssetCardHeader__title {
      color: black !important;
    }
  }
  .each-tab-content {
    margin-bottom: 1.5rem;
    background: white;
    border: 1px solid #e2e8f0;
    /* box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem; */
    border-radius: 1.2rem;
    padding: 0 1.8rem;
    min-height: 25rem;
  }
`;

/* Header */
interface ISharedSolutionHeaderContainer {
  background?: React.CSSProperties["background"];
}

export const SharedSolutionHeaderContainer = styled.div<ISharedSolutionHeaderContainer>`
  /* min-height: 560px; */
  /* background: #f4f4f4; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
                to right, 
                rgba(33, 49, 70, 0.99),
                rgba(33, 49, 70, 0.9),
                rgba(33, 49, 70, 0.7),
                rgba(33, 49, 70, 0.1),
                rgba(33, 49, 70, 0.1),
                rgba(33, 49, 70, 0.1)
            ), 
    ${({ background }) => background};        
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

/* Header NavBar */

export const SharedSolutionNavBar = styled.div`
  background: none;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25); */
  z-index: 100;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 0.7rem;
  // width: 147px;
`;

export const ShareButtonWrapper = styled.div`
  position: relative;
`;
export const ShareButton = styled.div`
  background: ${({ theme }: any) => theme.colors.button.background};
  min-height: 40px;
  background-color: #ddd;
  border-radius: 0.6rem;
  padding: 0rem 16px;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
    line-height: 2rem;
    font-weight: 500;
    text-align: center;
    color: #f8fafc;
    color: black;
  }
  :hover {
    background-color: #d7981b;
  }
`;
export const EditButton = styled(ShareButton)`
  font-size: 1.4rem;
  background: #ffffff;
  border-radius: 0.8rem;
  color: #334155;
  gap: 0.2rem;
  padding-left: 14px;
  span {
    color: #334155;
  }
  :hover {
    background-color: #d7d7d7;
  }
`;

export const CopyButton = styled(ShareButton)`
`;

export const LinkcopiedMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* font-family: var(--font-lexend); */
  font-size: 1rem;
  font-weight: 400;
  color: #16a34a;
  padding-top: 0.2rem;
  span {
    margin-left: 0.1rem;
  }
`;

export const SolutionSharingBody = styled.div`
  padding: 2rem;
`;

export const SolutionSharingContainer = styled.div<ISolutionSharingContainer>`
  position: absolute;
  top: 100%;
  right: 0;
  width: 34.9rem;
  border: 1px solid #cbd5e1;
  border-radius: 0.9rem;
  background-color: #ffffff;
  box-shadow: 0rem 1.2rem 4rem rgba(0, 0, 0, 0.2);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  margin-top: 0.8rem;
  z-index: 10;
  @media screen and (max-width: 960px) {
    &.client-view {
      width: 34.5rem;
    }
  }
`;

export const SharingLinkContainer = styled.div`
  width: 30.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0.8rem;
  padding: 0.6rem;
  padding-left: 1.2rem;
  p {
    max-width: 20.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* font-family: var(--font-lexend); */
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
  }
  @media screen and (max-width: 960px) {
    &.client-view {
      width: 30.5rem;
      p {
        max-width: 20rem;
      }
    }
  }
`;

/* Header Body */

export const SharedSolutionHeaderBody = styled.div<ISharedSolutionHeaderBody>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 120px;
  margin-bottom: 120px;
`;

export const SolutionTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 48px !important;
  line-height: 1.15 !important;
  color: #ffffff;
  padding: 0 0 0 20px;
  max-width: 600px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 960px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

export const SolutionLogoContainer = styled.div`
  min-width: 6rem;
  background: #ffffff;
  border-radius: 0.7rem;
  padding: 0.5rem 0;
  margin-right: 2rem;
  align-items: center;
  height: 6rem;
  img {
    height: 100%;
    border-radius: 0.5rem;
  }
  @media screen and (max-width: 960px) {
    min-width: 6rem;
    padding: 0.25rem;
    height: 6rem;
  }
`;

export const SidebarWithTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  font-size: 1.4rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border: 0.5px solid #e2e8f0;
  border-radius: 1.2rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  min-height: 80rem;
`;

export const SidebarWithTabsBody = styled.div`
  display: flex;
`;

export const SelectedTabContent = styled.div`
  width: 100%;
  overflow-y: auto;
`;
