export const ROW_ID_PREFIX = "row_";
export const COLUMN_ID_PREFIX = "field_";
export const NEW_FIELD_KEY = "__new__";
export const OBJECT_ROOT_KEY = "__root__";

export const booleanDropDownOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const readWriteDropDownOptions = [
  {
    label: "Read and Write",
    value: true,
  },
  {
    label: "Read only",
    value: false,
  },
];
