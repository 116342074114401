import { useQuery } from "react-query";
import { apiConfig } from "../../config";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { ISharableResPublicData } from "../../interfaces/solution";

const getShareableResPublicData = async (code: string) => {
  if (!code) return;
  const response = await axiosInstance.get(
    `${apiConfig.urls.shareableResPublicData}?code=${code}`
  );
  return response.data;
};

const useGetShareableResPublicData = (code: string) => {
  const response = useQuery<ISharableResPublicData>(
    [queryKeys.shareableResPublicData],
    () => getShareableResPublicData(code),
    {
      enabled: !!code,
      refetchOnWindowFocus: false,
      staleTime: 2 * 60 * 1000,
    }
  );
  return { ...response };
};

export default useGetShareableResPublicData;
