import React, { useCallback, useRef } from "react";
import { MouseEvent, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import useAddEditSolutionFormData from "../../../../../../api/hooks/solutions/useAddEditSolutionFormData";
import { AppContext } from "../../../../../../context/AppContext";
import { Header } from "../../../../Reusable/components";
import { object as YupObject } from "yup";
import {
  AccordionButton,
  CancelFormButton,
  FormFieldContainer,
  FormFieldWrapper,
  FormSectionTitle,
  SaveFormButton,
  SolutionAddEditFooter,
  SolutionAddEditFormBody,
  SolutionAddEditFormContainer,
} from "./styled";
import { useGetSettings } from "../../../../../../api/hooks";
import { IItemDropdown } from "../../../../../../interfaces/dropdown";
import useCreateUpdateSolution from "../../../../../../api/hooks/useCreateUpdateSolution";
import { useCreateOpportunities } from "../../../../../../api/hooks/useGetOpportunities";
import useGetSolution from "../../../../../../api/hooks/useGetSolution";
import { ClipLoader } from "react-spinners";
import { fieldMapper } from "../../../../../../config/formConfig";
import { history } from "../../../../../../utils/history";
import useGetSolutionTemplates from "../../../../../../api/hooks/useGetSolutionTemplates";
import useGetOpportunity from "../../../../../../api/hooks/useGetOpportunity";
import DropdownWithTemplates from "../../../../Dropdown/components/DropdownTemplate";
import {
  solutionAddEditFormConfig,
  solutionAddEditFormStructure,
  SOLUTION_CREATE_FORM_HIDDEN_FIELDS,
  IFormFieldStructure,
  solutionEditFormStructure,
  solutionCreateFormVisibleFields,
  solutionEditFormVisibleFields,
} from "../../../../../../config/solutionConfig";
import { IRelationshipParamerter } from "../../../../../../interfaces";
import RelParamDropdowns from "./RelParamDropdowns";
import { MdKeyboardArrowDown } from "react-icons/md";
import { trackUserAction } from "../../../../../../analytics";
import { userActivityEvents } from "../../../../../../analytics/userActivityEvents";

const FormField = (formFieldObj: any) => {
  const propsObj: any = {
    key: formFieldObj.type + formFieldObj.name,
    id: formFieldObj.name,
    label: formFieldObj.label,
    name: formFieldObj.name,
    required: Boolean(formFieldObj?.validation?.exclusiveTests?.required),
    ...formFieldObj.fieldData,
  };
  delete propsObj.initialValue;

  return fieldMapper[formFieldObj.type]?.(propsObj);
};

type RelParmFieldHandle = React.ElementRef<typeof RelParamDropdowns>;
interface ICreateNewSolutionProps {
  solutionId?: string;
  showHeader?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

const CreateNewSolution = ({
  solutionId,
  showHeader = true,
  onSubmit,
  onCancel,
}: ICreateNewSolutionProps) => {
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [opportunityId, setOpportunityId] = useState(
    queryParams.get("opportunity")
  );
  const { appDispatch } = useContext(AppContext);
  const { data: opportunityData } = useGetOpportunity(opportunityId);
  const [intialFormData, setIntialFormData] = useState<any>({
    opportunityData,
  });
  const formData = useAddEditSolutionFormData(
    params.id || solutionId || "",
    intialFormData
  );
  const { data: userSettings } = useGetSettings();
  const { data: editSolutionInitialValue } = useGetSolution(params.id || "");
  const { data: solutionTemplates } = useGetSolutionTemplates();
  const [selectedRelParamIds, setSelectedRelParamIds] = useState<{
    required?: number[];
    optional?: number[];
  }>({});
  const { mutate } = useCreateUpdateSolution();
  const { mutateAsync: createOpportunityMutationFunction } =
    useCreateOpportunities();
  const [showAllFields, setShowAllFields] = useState(false);
  const requiredRelParamFieldRef = useRef<RelParmFieldHandle>(null);
  const optionalRelParamFieldRef = useRef<RelParmFieldHandle>(null);
  const isEdit = formData.isEdit;
  const solutionFormVisibleFields = isEdit
    ? solutionEditFormVisibleFields
    : solutionCreateFormVisibleFields;

  const listOfDropdownForTemplates = React.useMemo(() => {
    let templateDropdownOptions: any[] = userSettings?.solution
      ?.is_auto_generate_enabled
      ? [
          {
            value: null,
            label: "Auto generate",
            isSelected: true,
          },
        ]
      : [];
    solutionTemplates.data.forEach((eachTemplate: any) => {
      const data = {
        value: eachTemplate?.id,
        isSelected: eachTemplate?.is_default,
        label: eachTemplate?.name,
      };
      templateDropdownOptions.push(data);
    }, []);
    return templateDropdownOptions;
  }, [solutionTemplates]);

  const [stateOfDropdownTemplates, setStateOfDropdownTemplates] =
    useState<IItemDropdown>({
      search: "",
      selected: [],
      suggested: [],
    });

  useEffect(() => {
    appDispatch({
      type: "SET_PAGE_TITLE",
      payload: formData.isEdit ? formData.editTitle : formData.title,
    });
  }, [appDispatch, formData.editTitle, formData.isEdit, formData.title]);

  const initRelParamIds = useMemo(() => {
    const relParameterIdArr: number[] = [];
    if (isEdit) {
      relParameterIdArr.push(
        ...(editSolutionInitialValue?.relationship_parameters.map(
          (item: IRelationshipParamerter) => item.id
        ) || [])
      );
    } else if (opportunityData) {
      const custAccount = opportunityData?.primary_account;
      relParameterIdArr.push(...(custAccount?.relationship_parameters || []));
      relParameterIdArr.push(
        ...(opportunityData?.relationship_parameters.map(
          (item: IRelationshipParamerter) => item.id
        ) || [])
      );
    }
    return relParameterIdArr;
  }, [opportunityData]);

  useEffect(() => {
    if (opportunityData) setIntialFormData({ opportunityData });
  }, [opportunityData]);

  const handleFormSubmit = useCallback(
    (formData: any, { setSubmitting }: any) => {
      const mutationFunction = (mutationData: any) => {
        mutate(mutationData, {
          onSettled: () => {
            setSubmitting(false);
          },
          onSuccess: (data: { data: { id: any; title: string } }) => {
            trackUserAction(
              userActivityEvents.PITCH_INTERNAL__CREATED_VIA_BUTTON,
              {
                solutionId: data.data.id,
                title: data.data.title,
              }
            );
            onSubmit
              ? onSubmit()
              : history.push(`/user/solutions/${data.data.id}?tab=solution`, {
                  showAction: isEdit ? false : true,
                });
          },
        });
      };
      const values = { ...formData };
      if (params.id) {
        values["id"] = params.id;
      } else if (!!stateOfDropdownTemplates.selected.length) {
        values["solution_template"] =
          stateOfDropdownTemplates.selected[0].value;
      }
      const requiredTagErrorTagIds =
        requiredRelParamFieldRef.current?.validateFields() || [];
      const optionalTagErrorTagIds =
        optionalRelParamFieldRef.current?.validateFields() || [];
      const errorTagIds = requiredTagErrorTagIds?.concat(
        optionalTagErrorTagIds
      );

      if (errorTagIds?.length) {
        setSubmitting(false);
        return;
      }
      if (!isEdit && errorTagIds)
        values["relationship_parameters"] =
          Object.values(selectedRelParamIds).flat();

      if (values["opportunity"]?.__isNew__) {
        const data = { name: values["opportunity"].value };
        createOpportunityMutationFunction(data, {
          onSuccess: (data) => {
            values.opportunity = data.id;
            mutationFunction(values);
          },
          onError: () => {
            delete values.opportunity;
            setSubmitting(false);
          },
        });
      } else {
        values["opportunity"] = values["opportunity"]?.value;
        mutationFunction(values);
      }
    },
    [
      createOpportunityMutationFunction,
      isEdit,
      mutate,
      params.id,
      selectedRelParamIds,
      stateOfDropdownTemplates.selected,
    ]
  );

  const handleRelParamSelectionChange = useCallback(
    (renderType: "required" | "optional", selectedIds) =>
      setSelectedRelParamIds((prevValues) => ({
        ...prevValues,
        [renderType]: selectedIds,
      })),
    []
  );
  const handleFormCancel = (e: MouseEvent, touched: FormikTouched<any>) => {
    e.preventDefault();
    let confirmedToLeaveForm = true;
    // if (Object.keys(touched).length) {
    //   confirmedToLeaveForm = window.confirm(
    //     "You have unsaved changes. Are you sure you want to leave this page?"
    //   );
    // }
    if (!confirmedToLeaveForm) return;
    onCancel ? onCancel() : history.back();
  };

  const scrollToTouchedErrorField = (errors: FormikErrors<any>) => {
    const touchedErrorField = Object.entries(errors).find(
      ([fieldName, error]) => error
    );
    if (touchedErrorField) {
      const fieldElement =
        touchedErrorField[0] && document.getElementById(touchedErrorField[0]);
      if (fieldElement) {
        setShowAllFields(true);
        fieldElement.scrollIntoView({
          block: "center",
          behavior: "smooth",
          inline: "nearest",
        });
      }
    }
  };

  const formFieldnameFieldMapping = useMemo(
    () =>
      solutionAddEditFormConfig.formFields.reduce(
        (fieldNameFieldObjMapping, fieldObj) => {
          if (
            formData.isEdit ||
            (!formData.isEdit &&
              !SOLUTION_CREATE_FORM_HIDDEN_FIELDS.includes(fieldObj.name))
          ) {
            const isOpportunityCreatable =
              !!userSettings?.opportunity?.is_create_enabled;
            if (fieldObj.name === "opportunity" && fieldObj.fieldData)
              fieldObj.fieldData.isCreatable = isOpportunityCreatable;
            fieldNameFieldObjMapping[fieldObj.name] = fieldObj;
          }
          return fieldNameFieldObjMapping;
        },
        {} as { [x: string]: any }
      ),
    [userSettings, formData.isEdit]
  );

  const getFieldToRender = useCallback(
    (fieldName: string, relParamRenderType?: "required" | "optional") => {
      switch (fieldName) {
        case "template": {
          return (
            <DropdownWithTemplates
              stateOfDropdowns={stateOfDropdownTemplates}
              setStateOfDropdowns={setStateOfDropdownTemplates}
              listOfItemTobeDisplayed={listOfDropdownForTemplates}
              label={"Exec summary templates"}
              background={"rgba(255, 255, 255, 0.14)"}
              color="#A1A1AA"
              selectColor="#0F172A"
              dropDownBgColor="var(--white)"
            />
          );
        }
        case "rel_params":
          return (
            <RelParamDropdowns
              ref={
                relParamRenderType === "required"
                  ? requiredRelParamFieldRef
                  : optionalRelParamFieldRef
              }
              initSelectedIds={initRelParamIds}
              onChange={(selectedIds) =>
                relParamRenderType &&
                handleRelParamSelectionChange(relParamRenderType, selectedIds)
              }
              renderType={relParamRenderType || "all"}
            ></RelParamDropdowns>
          );
        default:
          const formFieldObject = formFieldnameFieldMapping[fieldName];
          if (!formFieldObject) return <></>;
          if (fieldName === "opportunity" && !formData.isEdit)
            formFieldObject.fieldData.onChange = (valueObj: {
              value: any;
              __isNew__: boolean;
            }) => {
              if (!valueObj) {
                setOpportunityId(null);
                setIntialFormData({});
                return;
              }
              const { value, __isNew__ } = valueObj;
              // This is to pre-select the relationship params for the
              //  selected opportunity in 'create' flow.
              if (__isNew__) {
                setIntialFormData({
                  opportunityData: {
                    __isNew__,
                    name: value,
                  },
                });
              } else {
                setOpportunityId(value);
              }
            };

          return FormField(formFieldObject);
      }
    },
    [
      formData.isEdit,
      formFieldnameFieldMapping,
      handleRelParamSelectionChange,
      initRelParamIds,
      listOfDropdownForTemplates,
      stateOfDropdownTemplates,
    ]
  );

  const getFieldGroups = useCallback(() => {
    const visibleFieldStructure = (
      isEdit ? solutionEditFormStructure : solutionAddEditFormStructure
    ).reduce((fieldGroupsToRender, fieldGroup, index) => {
      fieldGroupsToRender.push({
        ...fieldGroup,
        fields: fieldGroup.fields.filter((fieldName) =>
          solutionFormVisibleFields.includes(fieldName)
        ),
      });
      return fieldGroupsToRender;
    }, [] as IFormFieldStructure[]);
    const hiddenFieldStructure = (
      isEdit ? solutionEditFormStructure : solutionAddEditFormStructure
    ).reduce((fieldGroupsToRender, fieldGroup, index) => {
      fieldGroupsToRender.push({
        ...fieldGroup,
        fields: fieldGroup.fields.filter(
          (fieldName) =>
            fieldName === "rel_params" ||
            !solutionFormVisibleFields.includes(fieldName)
        ),
      });
      return fieldGroupsToRender;
    }, [] as IFormFieldStructure[]);

    const getFields = (
      fieldStructure: IFormFieldStructure[],
      isHiddenFields?: boolean
    ) => {
      return fieldStructure.reduce((fieldGroupsToRender, fieldGroup, index) => {
        const fieldsToInclude = ["rel_params"];
        if (solutionTemplates?.data.length) fieldsToInclude.push("template");
        let hasVisibleFields = false;
        const fields = fieldGroup.fields.filter((fieldName) => {
          if (solutionFormVisibleFields.includes(fieldName))
            hasVisibleFields = true;
          return (
            fieldsToInclude.includes(fieldName) ||
            formFieldnameFieldMapping.hasOwnProperty(fieldName)
          );
        });

        if (fields.length) {
          fieldGroupsToRender.push(
            <section
              key={`${isHiddenFields && "h-"}${fieldGroup.title || index}`}
              style={{
                marginTop:
                  !hasVisibleFields && !showAllFields ? "-3rem" : undefined,
              }}
              hidden={!hasVisibleFields}
            >
              {fieldGroup.title && (
                <FormSectionTitle>{fieldGroup.title}</FormSectionTitle>
              )}
              <FormFieldContainer
                className={fieldGroup.fieldContainerClass || ""}
              >
                {fields.map((fieldName, index) => {
                  const isRelParamField = fieldName === "rel_params";
                  const opportunityClassName =
                    fieldName === "opportunity" ? "opportunity-field" : "";
                  return (
                    <FormFieldWrapper
                      key={index}
                      className={`${
                        fieldGroup.fieldWrapperClass || ""
                      } ${opportunityClassName}`}
                      hidden={
                        showAllFields
                          ? false
                          : isRelParamField && isHiddenFields
                          ? true
                          : !solutionFormVisibleFields.includes(fieldName)
                      }
                    >
                      {getFieldToRender(
                        fieldName,
                        isHiddenFields ? "optional" : "required"
                      )}
                    </FormFieldWrapper>
                  );
                })}
              </FormFieldContainer>
            </section>
          );
        }
        return fieldGroupsToRender;
      }, [] as React.ReactNode[]);
    };
    const visibleFieldsToRender = getFields(visibleFieldStructure);
    const hiddenFieldsToRender = getFields(hiddenFieldStructure, true);
    const itemsToRender = [...visibleFieldsToRender];
    if (hiddenFieldsToRender.length)
      itemsToRender.push(
        <AccordionButton
          key={"btn"}
          className={showAllFields ? "open" : ""}
          onClick={() => setShowAllFields((prevValue) => !prevValue)}
        >
          <span>Advanced options</span>
          <MdKeyboardArrowDown size={26} color="#2563eb"></MdKeyboardArrowDown>
        </AccordionButton>
      );
    return itemsToRender.concat(hiddenFieldsToRender);
  }, [
    isEdit,
    showAllFields,
    solutionTemplates?.data.length,
    formFieldnameFieldMapping,
    getFieldToRender,
  ]);

  return (
    <SolutionAddEditFormContainer
      className="SolutionForm__container"
      style={showAllFields ? { justifyContent: "center", paddingTop: 0 } : {}}
    >
      {showHeader && <Header showSearchBar={false}></Header>}
      <Formik
        initialValues={{ ...formData.initialValues }}
        enableReinitialize={true}
        validationSchema={YupObject(formData.validationSchema)}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
                setTimeout(() => {
                  scrollToTouchedErrorField(formik.errors);
                }, 1);
              }}
            >
              <SolutionAddEditFormBody className="SolutionForm__body">
                {getFieldGroups()}
                <SolutionAddEditFooter
                  style={{ marginTop: showAllFields ? undefined : 0 }}
                >
                  <CancelFormButton
                    type="button"
                    onClick={(e) => handleFormCancel(e, formik.touched)}
                  >
                    <span>Cancel</span>
                  </CancelFormButton>
                  <SaveFormButton
                    type={"submit"}
                    disabled={formik.isSubmitting}
                  >
                    <span>{formData.isEdit ? "Save" : "Generate"}</span>
                    {formik.isSubmitting ? (
                      <ClipLoader color="white" size={16}></ClipLoader>
                    ) : (
                      <></>
                    )}
                  </SaveFormButton>
                </SolutionAddEditFooter>
              </SolutionAddEditFormBody>
            </Form>
          );
        }}
      </Formik>
    </SolutionAddEditFormContainer>
  );
};

export default CreateNewSolution;
