import styled from "@emotion/styled";

export const TableHeading = styled.th`
  padding: 1.2rem 2.8rem;
  font-family: var(--font-lexend);
  font-style: Normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #475569 !important;
  opacity: 1;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
`;
