import { getFileNameFromUrl } from "../../../../../../helpers";
import CustomModal from "../CustomModal";
import Widget from "../Widget";
import { DocumentIframeWrapper } from "./styled";

interface IDocumentViewerModal {
  fileUrl: string | null;
  isOpen: boolean;
  onDismissal: Function;
}

const DocumentViewerModal = ({
  fileUrl,
  isOpen,
  onDismissal,
}: IDocumentViewerModal) => {
  return (
    <CustomModal
      width="80vw"
      height="80vh"
      showModal={isOpen}
      closeModal={onDismissal}
      renderContent={() => (
        <DocumentIframeWrapper>
          {fileUrl &&
            (/^.*\.(jpg|JPG|jpeg|png|PNG|pdf|PDF)$/.test(
              getFileNameFromUrl(fileUrl)
            ) ? (
              <iframe
                title="PDF/JPG viewer"
                style={{
                  height: "80vh",
                }}
                width="100%"
                src={fileUrl}
              />
            ) : (
              <iframe
                title="Slides/Docx Viewer"
                width="100%"
                style={{
                  height: "80vh",
                }}
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                  fileUrl
                )}`}
              />
            ))}
          <Widget fileUrl={fileUrl} />
        </DocumentIframeWrapper>
      )}
    />
  );
};

export default DocumentViewerModal;
