import { useQuery } from "react-query";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getSolutionTemplates = async () => {
  const response = await axiosInstance.get("/solutions/templates/");
  return response.data;
};

const useGetSolutionTemplates = () => {
  const response = useQuery(
    [queryKeys.templates],
    () => getSolutionTemplates(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetSolutionTemplates;
