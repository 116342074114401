import styled from "@emotion/styled";

export const WidgetContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  background: #ffffff;
  border: 0.05rem solid #cbd5e199;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.04);
  border-radius: 0.6rem;
  /* overflow-y: hidden; */
  white-space: nowrap;
`;

const WidgetButton = styled.div`
  color: #475569;
  height: max-content;
  font-size: 2rem;
  padding: 0.8rem 1rem;
  cursor: pointer;
  :hover {
    background-color: #eff6ff66;
  }
`;

export const EditWidget = styled(WidgetButton)``;

export const SolutionEditWidget = styled(WidgetButton)`
  display: flex;
  align-items: center;
  font-weight: 400;
`;

export const AddToSolutionWidget = styled(WidgetButton)`
  display: flex;
  align-items: center;
`;
export const DeleteWidget = styled(WidgetButton)`
  color: #dc2626;
`;
export const AddGuidance = styled(WidgetButton)``;
export const HideAndShow = styled(WidgetButton)``;
export const AccordionButton = styled(WidgetButton)`
  svg {
    transform: rotate(-180deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.collapsed {
    svg {
      transform: rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`;
export const RemoveFromSolution = styled(WidgetButton)`
  display: flex;
  align-items: center;
  color: #dc2626;
  font-size: 1.4rem;
  padding: 0.6rem 1rem;
  line-height: 150%;
  column-gap: 0.5rem;
`;

export const Divider = styled(WidgetButton)`
  padding: 0 0.3rem;
  cursor: text;
  .vertical-divider {
    width: 0.1rem;
    height: 1.8rem;
    background-color: #cbd5e1;
    border-radius: 0.2rem;
  }
`;
