import { kebabCase } from "lodash";
import { Suspense, useContext, useEffect, useMemo } from "react";
import { PageSpecificLayout1 } from "../../../components/compositional/PageSpecificLayout";
import SidebarWithTabs1, {
  ITabAndComponent,
} from "../../../components/compositional/PageSpecificLayout/components/SidebarWithTabs1";
import SolutionAssetsCardContainer from "../../../components/compositional/Templates/components/Solution/SolutionAssets/SolutionAssetsCardContainer";
import SolutionAssetsList from "../../../components/compositional/Templates/components/Assets/solutionAssetsList";
import SolutionDocument from "../../../components/compositional/Templates/components/Solution/SolutionDocument";
import { TITLE_HEADING_CONFIG } from "../../../constants/richTextEditor";
import { SolutionsContext } from "../../../context";
import { getTitlesFromText } from "../../../helpers/common";
import { SolutionsAssetActionType } from "../../../interfaces";
import {
  ISolutionAssetType,
  Sidebar2ThemeMode,
} from "../../../interfaces/solution";
import SolutionDocumentTitleTabs from "../../Solutions/SolutionDetails/SolutionDetailsContent/SolutionDocumentTitleTabs";
import { SolutionContentContainer } from "../../Solutions/SolutionDetails/SolutionDetailsContent/styled";
import { SelectedTabContent, SidebarWithTabsBody } from "./styled";
import SolutionAssetTypeSidebar from "../../../components/compositional/Templates/components/Solution/SolutionAssets/SolutionAssetTypeSidebar";
import { FallBack } from "../../../components/compositional";
import { SolutionDetailsContext } from "../../../context/SolutionDetailsContext";

const SharedSolutionDetailsContentThree = () => {
  const { solutionsState, solutionsAssetDispatch } =
    useContext(SolutionsContext);
  const { solutionAssetTypes } = useContext(SolutionDetailsContext);
  const solutionAssetTypesData = solutionAssetTypes;
  const solutionDocHtml = solutionsState.solutionObject?.solution_doc_html;
  const getComponentsToRender = (
    solutionAssetTypesData: ISolutionAssetType[]
  ) => {
    let componentsToRender: {
      tab: {
        label: string;
        isSelected: boolean;
        onClick: (index: number) => void;
        EndComponent?: () => JSX.Element;
        ExtraComponent?: () => JSX.Element;
      };
      component: () => {};
      hidden?: boolean;
    }[] = [];

    if (solutionDocHtml) {
      const solutionDocTitles = getTitlesFromText(
        solutionDocHtml || "",
        TITLE_HEADING_CONFIG.htmlTag
      );
      componentsToRender.push({
        tab: {
          label: solutionDocTitles.length ? "" : "Executive summary",
          isSelected: false,
          onClick: (index: any) => handleTabClick(index),
          ExtraComponent: () => (
            <SolutionDocumentTitleTabs
              onTabClick={(titleText: any) => handleTabClick(0)}
            />
          ),
        },
        component: () => <SolutionDocument isSharing={true} />,
      });
    }

    solutionAssetTypesData
      ?.filter((solutionAssetTypeObj) => {
        return !!solutionAssetTypeObj.count;
      })
      .forEach((solutionAssetTypeObj) => {
        let componentToRender = () => (
          <SolutionAssetsList
            title={solutionAssetTypeObj.name}
            type={solutionAssetTypeObj}
            isShared={true}
          />
        );

        const data = {
          tab: {
            label: solutionAssetTypeObj.name,
            isSelected: false,
            onClick: (index: any) => handleTabClick(index),
          },
          component: componentToRender,
        };

        componentsToRender.push(data);
      });
    return { componentsToRender };
  };

  const tabData = useMemo<ITabAndComponent[]>(() => {
    if (solutionAssetTypesData)
      return (
        getComponentsToRender(solutionAssetTypesData).componentsToRender || []
      );
    return [];
  }, [solutionAssetTypesData, solutionsState.solutionObject]);

  const handleTabClick = (index: any) => {
    if (index)
      solutionsAssetDispatch({
        type: SolutionsAssetActionType.SET_ASSET_TYPE,
        payload: solutionAssetTypesData?.[index - 1],
      });
    const sidebarTabsContainer =
      document.getElementsByClassName("tabbed__sidebar")[0]?.children || [];
    Array.from(sidebarTabsContainer).forEach((tabElement) => {
      tabElement.classList.remove("active");
    });
    const scrollContainerMain = document.getElementsByTagName("main")[0];
    const solutionDocHtml = solutionsState.solutionObject?.solution_doc_html;
    const solutionDocTitles = getTitlesFromText(
      solutionDocHtml || "",
      TITLE_HEADING_CONFIG.htmlTag
    );
    if (index === 0) {
      if (solutionDocTitles.length > 0) return;
      scrollContainerMain.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
    const assetLabel = tabData && tabData[index]?.tab?.label;
    const assetTitleId = `asset-title-${kebabCase(assetLabel)}`;
    const sharedSolutionTopNav = document.getElementById(
      "shared-solution-top-nav"
    ) as HTMLDivElement;
    const sharedSolutionHeader =
      sharedSolutionTopNav?.parentNode as HTMLDivElement;
    const sharedViewOffset =
      (sharedSolutionHeader?.offsetHeight || 0) -
      (sharedSolutionTopNav?.offsetHeight || 0) -
      80;
    const element = document.getElementById(assetTitleId);
    element &&
      scrollContainerMain.scrollTo({
        behavior: "smooth",
        top: element.offsetTop + sharedViewOffset,
      });
  };

  useEffect(() => {
    let sidebarComponentscontainer =
      document.getElementsByClassName("sidebar__selected-tab-content")[0]
        ?.children || [];
    const sidebarTabsContainer =
      document.getElementsByClassName("tabbed__sidebar")[0]?.children || [];
    const mainContainer = document.getElementsByTagName("main")[0];
    mainContainer.addEventListener("scroll", () => {
      sidebarComponentscontainer &&
        Array.from(sidebarComponentscontainer).forEach((componentElement) => {
          if (
            (componentElement.getBoundingClientRect().top <= 200 &&
              componentElement.getBoundingClientRect().top >= 60) ||
            (componentElement.getBoundingClientRect().bottom <= 250 &&
              componentElement.getBoundingClientRect().bottom >= 200)
          ) {
            const visibleComponentName = componentElement.id
              .substring(12)
              .toLowerCase();
            Array.from(sidebarTabsContainer).forEach((tabElement) => {
              if ((tabElement as HTMLElement).id === visibleComponentName) {
                tabElement.classList.add("active");
              } else {
                tabElement.classList.remove("active");
              }
            });
          }
        });
    });
  }, [tabData]);

  useEffect(() => {
    const mainContainer = document.getElementsByTagName("main")[0];

    const scrollListenerForNav = () => {
      const sidebarSubsection = document.querySelector(
        ".sidebar-subsection-tab.active"
      );
      if (sidebarSubsection) {
        const section =
          sidebarSubsection.parentElement?.parentElement?.parentElement
            ?.parentElement?.previousElementSibling;
        const tabLabel = section?.querySelector(".sidebar-tab-label");
        const allTabs = document.getElementsByClassName("sidebar-tab-label");
        Array.from(allTabs).forEach((element) => {
          if (element === tabLabel) {
            tabLabel.classList.add("active");
          } else {
            element.classList.remove("active");
          }
        });
      }
    };
    mainContainer.addEventListener("scroll", scrollListenerForNav);

    mainContainer.addEventListener("scroll", () => {
      const sidebarTabsContainer = document.getElementsByClassName(
        "tabbed__sidebar"
      )[0] as HTMLDivElement;
      const sharedSolutionNavEle = document.getElementById(
        "shared-solution-top-nav"
      );
      const tabContentContainer = document.getElementsByClassName(
        "sidebar__selected-tab-content"
      )[0] as HTMLDivElement;
      const mediaQueryList = window.matchMedia("(min-width: 1080px)");
      if (mediaQueryList.matches) {
        const isIntersecting =
          tabContentContainer.getBoundingClientRect().top <=
          (sharedSolutionNavEle?.offsetHeight || 0);

        if (isIntersecting) {
          tabContentContainer.style.marginLeft = `${
            sidebarTabsContainer.offsetWidth + 20
          }px`;
          sidebarTabsContainer.style.position = "fixed";
          sidebarTabsContainer.style.top = `${sharedSolutionNavEle?.offsetHeight}px`;
          sidebarTabsContainer.style.maxHeight = `${
            window.innerHeight - (sharedSolutionNavEle?.offsetHeight || 0)
          }px`;
        } else {
          tabContentContainer.style.marginLeft = "10px";
          sidebarTabsContainer.style.position = "static";
          sidebarTabsContainer.style.top = "0px";
          sidebarTabsContainer.style.maxHeight = "unset";
        }
      }
    });
    setTimeout(() => {
      const sidebarSectionTabs = document.getElementsByClassName("sidebar-tab");
      Array.from(sidebarSectionTabs).forEach((sidebarEle) => {
        sidebarEle?.addEventListener("click", (e) => {
          mainContainer.removeEventListener("scroll", scrollListenerForNav);
          Array.from(sidebarSectionTabs).forEach((sidebarTabEle) => {
            const sidebarTabLabel =
              sidebarTabEle.querySelector(".sidebar-tab-label");
            if (
              sidebarTabEle?.nextElementSibling &&
              sidebarTabEle.nextElementSibling.className.includes("subsection")
            ) {
              const subsectionEle =
                sidebarTabEle?.nextElementSibling as HTMLElement;
              const isHidden =
                sidebarTabEle === sidebarEle ? !subsectionEle.hidden : true;
              subsectionEle.hidden = isHidden;
              if (isHidden) {
                sidebarTabLabel?.classList.remove("active");
              } else {
                sidebarTabLabel?.classList.add("active");
              }
            }
          });
          setTimeout(() => {
            mainContainer.addEventListener("scroll", scrollListenerForNav);
          }, 1000);
        });
      });
    }, 500);
  }, []);

  return (
    <PageSpecificLayout1>
      <SolutionContentContainer>
        <SidebarWithTabsBody>
          <SolutionAssetTypeSidebar theme={Sidebar2ThemeMode.LIGHT_MODE} />
          <SelectedTabContent
            className={`sidebar__selected-tab-content client-view
          `}
          >
            <Suspense fallback={<FallBack />}>
              {solutionDocHtml && (
                <div className={"each-tab-content"} id="doc-section">
                  <SolutionDocument isSharing={true} />
                </div>
              )}
              <SolutionAssetsCardContainer
                isShared={true}
                handleTabClick={handleTabClick}
              />
            </Suspense>
          </SelectedTabContent>
        </SidebarWithTabsBody>
      </SolutionContentContainer>
    </PageSpecificLayout1>
  );
};

export default SharedSolutionDetailsContentThree;
