import React, { createContext, useEffect, useState } from "react";
import { IAssetTypeLink, IGenericResourceType } from "../interfaces";

export interface IAssetsContext {
  isEdit: boolean;
  isDelete: boolean;
  defaultTabSlug: string;
  activeTabSlug: string;
  editItemId: string;
  activeItemId: string;
  showLibraryModal: boolean;
  activeItemType: IAssetTypeLink;
  genericResourceTypes: IGenericResourceType[];
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTabSlug: React.Dispatch<React.SetStateAction<string>>;
  setEditItemId: React.Dispatch<React.SetStateAction<string>>;
  setActiveItemId: React.Dispatch<React.SetStateAction<string>>;
  setActiveItemType: React.Dispatch<React.SetStateAction<IAssetTypeLink>>;
  setDefaultTabSlug: React.Dispatch<React.SetStateAction<string>>;
  setShowLibraryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setGenericResourceTypes: React.Dispatch<
    React.SetStateAction<IGenericResourceType[]>
  >;
}

export const initialItemType: IAssetTypeLink = {
  id: undefined,
  name: "",
  key: "",
};

export const AssetsContext = createContext({
  isEdit: false,
} as IAssetsContext);

export const AssetsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [defaultTabSlug, setDefaultTabSlug] = useState<string>("");
  const [activeTabSlug, setActiveTabSlug] = useState<string>("");
  const [editItemId, setEditItemId] = useState<string>("");
  const [activeItemId, setActiveItemId] = useState<string>("");
  const [activeItemType, setActiveItemType] =
    useState<IAssetTypeLink>(initialItemType);
  const [showLibraryModal, setShowLibraryModal] = useState<boolean>(false);
  const [genericResourceTypes, setGenericResourceTypes] = useState<
    IGenericResourceType[]
  >([]);

  useEffect(() => {
    if (!activeTabSlug) {
      setActiveTabSlug(defaultTabSlug);
    }
  }, [defaultTabSlug]);

  return (
    <AssetsContext.Provider
      value={{
        isEdit,
        isDelete,
        setIsDelete,
        setIsEdit,
        defaultTabSlug,
        setDefaultTabSlug,
        activeTabSlug,
        setActiveTabSlug,
        editItemId,
        setEditItemId,
        activeItemId,
        setActiveItemId,
        showLibraryModal,
        setShowLibraryModal,
        activeItemType,
        setActiveItemType,
        genericResourceTypes,
        setGenericResourceTypes,
      }}
    >
      {children}
    </AssetsContext.Provider>
  );
};
