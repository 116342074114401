import styled from "@emotion/styled";
import ReactTooltip from "react-tooltip";

interface IStatusContainer {
  background: string;
  color: string;
}

export const Tooltip = styled(ReactTooltip)`
  &.type-dark.place-top,
  &.type-dark.place-bottom,
  &.type-dark.place-right,
  &.type-dark.place-left {
    background: #ffffff;
    border-radius: 6px;
    padding: 0.8rem 0.6rem;
    padding-left: 1.2rem;
    font-family: var(--font-lexend);
    font-size: 1.2rem;
    line-height: 2rem;
    color: #0f172a;
    opacity: 1;
    max-width: 20rem;
    z-index: 10;
    border: 0.5px solid #e2e8f0;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  }
  &.type-dark.place-top {
    &::after,
    &::before {
      border-top-color: #ffffff;
    }
  }
  &.type-dark.place-bottom {
    &::after,
    &::before {
      border-bottom-color: #ffffff;
    }
  }
  &.type-dark.place-left {
    &::after,
    &::before {
      border-left-color: #ffffff;
    }
  }
  &.type-dark.place-right {
    &::after,
    &::before {
      border-right-color: #ffffff;
    }
  }
`;

Tooltip.defaultProps = {
  place: "bottom",
  effect: "solid",
};

export const PersonIconSquare = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: grey;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 10px;
`;

export const CellWrapper = styled.div`
  padding: 1.6rem 2.8rem;
  font-size: 1.4rem;
  line-height: 1.85rem;
  color: #4b5563;
  overflow-wrap: anywhere;
  .long-text {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .text-bold {
    font-weight: 600;
  }
`;
export const CommaSeperatedContainer = styled.div`
  display: -webkit-box;
  flex-direction: row;
  width: 22rem;
`;

export interface ICommaSeperatedWrapper {
  keys?: string;
}

export const CommaSeperatedKey = styled.div<ICommaSeperatedWrapper>`
  margin-right: 0.3rem;
  width: ${(props) => (props.keys === "team" ? "auto" : "10rem")};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const CommaSeperatedValue = styled.div`
  color: #4b5563;
  font-weight: 500;
`;

export const StatusContainer = styled.div<IStatusContainer>`
  align-self: center;
  width: fit-content;
  padding: 0.1rem 0.6rem;
  gap: 0.4rem;
  border-radius: 0.5rem;
  font-family: var(--font-lexend);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.85rem;
  text-align: center;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
`;
