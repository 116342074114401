import kebabCase from "lodash/kebabCase";
import { createContext, useEffect, useReducer, useState } from "react";
import useGetSolution from "../api/hooks/useGetSolution";
import { TITLE_HEADING_CONFIG } from "../constants/richTextEditor";
import {
  ISolutionsAssetState,
  ISolutionsContext,
  ISolutionsState,
  SolutionsActionType,
  initialShareableResObj,
} from "../interfaces";
import {
  solutionsAssetReducer,
  solutionsReducer,
} from "../reducer/solutionsReducer";
import { ISolutionSharingObject } from "../interfaces/solution";

const initialSolutionsState: ISolutionsState = {
  solutionId: undefined,
  solutionObject: undefined,
};

const initialSolutionsAssetState: ISolutionsAssetState = {
  assetType: null,
  assetId: "",
  showAssetModal: false,
  isCreateEdit: false,
  isDelete: false,
};

export const SolutionsContext = createContext({} as ISolutionsContext);

export const SolutionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [solutionsState, solutionsDispatch] = useReducer(
    solutionsReducer,
    initialSolutionsState
  );
  const { data } = useGetSolution(
    solutionsState.solutionId ? solutionsState.solutionId + "" : ""
  );
  const [solutionSearchText, setSolutionSearchText] = useState("");
  const [solutionsAssetState, solutionsAssetDispatch] = useReducer(
    solutionsAssetReducer,
    initialSolutionsAssetState
  );
  const [shareableResObj, setShareableResObj] =
    useState<ISolutionSharingObject>(initialShareableResObj);

  useEffect(() => {
    if (solutionsState.solutionId && data) {
      const solutionDocHtml = data?.solution_doc_html;
      if (solutionDocHtml) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = solutionDocHtml;
        Array.from(
          tempDiv.getElementsByTagName(TITLE_HEADING_CONFIG.htmlTag)
        ).forEach((element) => {
          element.id = `title-${kebabCase(element.innerText)}`;
        });
        data.solution_doc_html = tempDiv.innerHTML;
      }
      solutionsDispatch({
        type: SolutionsActionType.SET_SOLUTION_OBJECT,
        payload: data,
      });
    }
  }, [solutionsState.solutionId, data]);

  return (
    <SolutionsContext.Provider
      value={{
        solutionsState,
        solutionsDispatch,
        solutionSearchText,
        setSolutionSearchText,
        solutionsAssetState,
        solutionsAssetDispatch,
        shareableResObj,
        setShareableResObj,
      }}
    >
      {children}
    </SolutionsContext.Provider>
  );
};
