interface IIdentifyUser {
  id: string;
  profile: {
    first_name: string;
    last_name: string;
    email: string;
  };
}

export const identifyUser = (user: IIdentifyUser, teamId?: string) => {
  window.posthog.identify(user.id, {
    name: user.profile.first_name + " " + user.profile.last_name,
    email: user.profile.email,
    userId: user.id,
    teamId: teamId,
  });
};

export const trackUserAction = (event: string, properties?: any) => {
  window.posthog.capture(event, properties);
};
