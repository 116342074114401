import styled from "@emotion/styled";

interface ISolutionDocumentContainer {
  isSharing: boolean;
}

interface ISolutionDocumentWrapper {
  isSharing: boolean;
}

export const SolutionDocumentContainer = styled.div<ISolutionDocumentContainer>`
  margin: ${({ isSharing }) =>
    isSharing ? "2.8rem 1.8rem" : "2rem 2rem 5rem 2rem"};
  box-shadow: ${({ isSharing }) =>
    isSharing ? "none" : "0 2rem 25rem rgba(0, 0, 0, 0.06)"};
  border-radius: ${({ isSharing }) => (isSharing ? "none" : "1.2rem")};
  overflow: hidden;
`;

export const SolutionDocumentHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-lexend);
  background: rgba(30, 58, 138, 0.03);
  border-radius: 1.2rem;
  gap: 1.6rem;
  border: 1px solid #cbd5e1;
  margin-bottom: 1rem;
  box-shadow: 0 1rem 10rem rgba(0, 0, 0, 0.06);
`;

export const SolutionDocumentWrapper = styled.div<ISolutionDocumentWrapper>`
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  font-family: var(--font-clarity-city) !important;
  font-weight: revert;
  font-size: 1.4rem;
  line-height: 2rem;
  color: black;
  ${({ isSharing }) =>
    isSharing
      ? ""
      : `padding: 2rem 1.6rem 2rem 3rem;
      border: 1px solid #cbd5e1;
      overflow: hidden;
  border-radius: 1.2rem;
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.06);`}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-lexend) !important;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1rem;
    /* color: #334155; */
  }

  strong {
    font-family: var(--font-lexend);
  }
  @media screen and (max-width: 960px) {
    div.b-embed {
      max-width: 100%;
    }
    img {
      max-width: 100%;
    }
  }
`;

export const SolutionDocumentTitle = styled.div`
  padding: 2rem;
  font-family: var(--font-lexend);
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  display: flex;
  align-items: center;
  color: #1e3a8a;
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
`;

export const EditButton = styled.div`
  position: absolute;
  top: -0.6rem;
  right: 0;
  padding: 0.8rem;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 0.8rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #334155;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
  span {
    text-align: center;
    margin-top: 0.1rem;
  }
`;

export const SolutionDocumentBody = styled.div`
  min-height: 42rem;
  .editor {
    padding: 1.2rem;
    padding-top: 0;
    border: 1px solid #cbd5e1;
    border-radius: 0.8rem;
    min-height: inherit;
    overflow-y: auto;
  }
`;

export const SolutionDocumentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e2f8f0;
  background: linear-gradient(
    360deg,
    #ffffff 0%,
    #ffffff 75.52%,
    rgba(255, 255, 255, 0) 97.69%
  );
  margin-top: 1.1rem;
  padding: 1.2rem;
  gap: 1.6rem;
`;

export const CancelButton = styled.button`
  font-family: var(--font-lexend);
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  padding: 1.2rem 2rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #334155;
`;

export const SaveButton = styled(CancelButton)`
  display: flex;
  align-items: center;
  background: #2563eb;
  border: none;
  color: #f8fafc;
  gap: 0.5rem;
  :disabled {
    background-color: var(--border-gray);
  }
`;

export const SolutionDocGenerationLoaderContainer = styled.div`
  font-family: var(--font-lexend);
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 5rem 2rem;
`;
