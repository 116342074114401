import { Input } from "../Input";
import { cn } from "../lib/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./Form";

interface IFormTextField {
  label: string;
  name: string;
  description?: string;
  required?: boolean;
  form: any;
  [x: string]: any;
}

const TextField = ({
  label,
  description,
  required,
  name,
  form,
  ...allProps
}: IFormTextField) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="space-y-3">
          <FormLabel
            className={cn(
              "text-2xl font-normal text-foreground",
              required && "after:content-['_*']"
            )}
          >
            {label}
          </FormLabel>
          <FormControl>
            <Input
              {...allProps}
              {...field}
              className="h-14 px-4 py-2 text-xl focus-visible:border-purple-600 focus-visible:shadow-purple focus-visible:ring-0"
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage className="text-lg font-light" />
        </FormItem>
      )}
    />
  );
};

export default TextField;
