import { THTMLHeadTag } from "../interfaces";

export const EMBED_OPTIONS = [
  {
    label: "Dismiss",
    value: "dismiss",
  },
  {
    label: "Bookmark",
    value: "bookmark",
  },
  {
    label: "Embed",
    value: "embed",
  },
];

export const TITLE_HEADING_CONFIG: {
  htmlTag: THTMLHeadTag;
  value: 1 | 2 | 3 | 4 | 5 | 6;
} = {
  htmlTag: "h2",
  value: 2,
};
