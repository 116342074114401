import styled from "@emotion/styled";

export const SearchMainContentWrapper = styled.div`
  font-family: "Lexend";
  width: 30rem;
  height: 3.5rem;
  box-sizing: border-box;
  border: 0.5px solid rgba(203, 213, 225, 1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #cbd5e1;
`;

export const SearchInputIconWrapper = styled.div`
  display: flex;
  align-items: center;
  .show-cross {
    opacity: 1;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 1.7rem;
  height: 1.7rem;
  margin-top: 0.2rem;
`;

export const SearchText = styled.input`
  width: 22rem;
  border: none;
  height: 2.375rem;
  margin-left: 0.8rem;
  justify-content: center;
  text-align: start;
  font-family: var(--font-lexend);
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 400;
  padding: 0.3rem 0 0.3rem 0.5rem;
  color: #475569;
  :focus {
    outline: none !important;
  }
`;

export const RemoveSearchText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  .close-icon {
    color: #858585;
    transition: color, transform 100ms ease-in-out;
    font-size: 1.8rem;
    &:hover {
      color: #505050;
      transform: scale(1.2);
    }
  }
`;

export const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: relative;
`;

export const SearchDropdownWrapper = styled.div``;
