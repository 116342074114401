import { sanitizeHtml } from "../../../utils/string";
import { HtmlDiv } from "./styled";

interface ISanitizedHtmlDiv {
  value?: string | null;
  className?: string;
  children?: React.ReactNode;
}

const SanitizedHtmlDiv = ({
  value = "",
  className,
  children,
}: ISanitizedHtmlDiv) => {
  return children ? (
    <HtmlDiv className={className}>{children}</HtmlDiv>
  ) : (
    <HtmlDiv
      className={className}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(value || ""),
      }}
    ></HtmlDiv>
  );
};

export default SanitizedHtmlDiv;
