import {
  IAuthState,
  IAuthUser,
  TAuthReducerAction,
} from "../interfaces/context";

export function authReducer(state: IAuthState, action: TAuthReducerAction) {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        isLoggedIn: true,
        isGuestUser: !!(action.payload as { isGuestUser: boolean }).isGuestUser,
        isPreview: !!(action.payload as { isGuestUser: boolean }).isGuestUser
          ? (action.payload as { isPreview: boolean }).isPreview
          : false,
        userInfo: (action.payload as { userInfo: IAuthUser }).userInfo
          ? (action.payload as { userInfo: IAuthUser }).userInfo
          : state.userInfo,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
      };
    }
    default:
      return { ...state };
  }
}
