import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PuffLoader } from "react-spinners";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPDFViewer {
  file: string | null;
}

const PDFViewer = ({ file }: IPDFViewer) => {
    const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: {numPages: number | null}) {
    setNumPages(numPages);
  }

  return (
    <Document
      file={file}
      loading={() => <div style={{height: "100%"}} className="flex justify-center align-center" ><PuffLoader color={"#2072D4"} size={80} /></div>  }
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}
    </Document>
  );
};

export default PDFViewer;
