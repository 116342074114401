import { IDropdownOption } from "../../../../../../interfaces/dropdown";
import {
  FilterTagContainer as FilterTagsContainer,
  FilterTagWrapper as FilterTagsWrapper,
} from "../../../../Reusable/components/FilterTags/styled";
import { RelParamTag } from "../../../../Reusable/components/Tags";
import { DropdownLabel } from "./styled";

const RelParamTags = ({
  label,
  tagList,
  onTagClick,
}: {
  label: string;
  tagList: IDropdownOption[];
  onTagClick: (option: IDropdownOption) => void;
}) => {
  return (
    <FilterTagsContainer style={{ margin: 0, paddingLeft: 0 }}>
      <FilterTagsWrapper>
        {label && <DropdownLabel>{label}</DropdownLabel>}
        {tagList.map((tag) => {
          return (
            <RelParamTag
              key={tag.value}
              tag={tag}
              onClick={onTagClick}
            ></RelParamTag>
          );
        })}
      </FilterTagsWrapper>
    </FilterTagsContainer>
  );
};

export default RelParamTags;
