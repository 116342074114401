import notFound from "../../../../../assets/icons/notFound.svg";
import { NotFoundWrapper } from "./styled";

interface INotFound {
  descriptionText: string;
  errorCode?: any;
}

const NotFound = ({ descriptionText, errorCode }: INotFound) => {
  return (
    <NotFoundWrapper>
      <div className="flex justify-center not-found-icon-container">
        <img src={notFound} alt="Not found" />
      </div>
      <p className="text-center description-text">{descriptionText}</p>
      {errorCode && <p className="text-center error-code">[code: {errorCode}]</p>}
    </NotFoundWrapper>
  );
};

export default NotFound;
