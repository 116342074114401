import styled from "@emotion/styled";

export const DownloadWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    .download-icon{
        margin-right: 0.5rem;
    }
`;