import styled from "@emotion/styled";

export const SolutionContentTabs = styled.div`
  .sticky {
    position: fixed;
    top: 0;
    background: #ffffff;
    z-index: 20;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
  }
`;

export const SolutionContentContainer = styled.div``;

export const SolutionContentTabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
`;

export const SolutionContentTabsContainer = styled.div`
  display: flex;
  .active {
    color: #2563eb;
  }
`;

interface ISolutionContentTab {
  isActive?: boolean;
}

export const SolutionContentTab = styled.div<ISolutionContentTab>`
  border-bottom: ${(props) => (props.isActive ? "2px solid #2563eb" : "")};
  padding: 1.6rem 10rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: ${(props) => (props.isActive ? "#2563eb" : "#64748b")};
  cursor: pointer;
  :hover {
    border-bottom: ${(props) => (props.isActive ? "" : "2px solid #2563eb55")};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const SharingButtonContainer = styled.div`
  position: relative;
  margin-right: 1.6rem;
  display: flex;
  gap: 1rem;
`;

export const SharingButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}: any) => theme.colors.button.background};
  border-radius: 0.6rem;
  padding: 0.6rem 1.6rem;
  font-family: var(--font-lexend);
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #f8fafc;
  user-select: none;
  cursor: pointer;
  span {
    margin-right: 1rem;
  }
`;

export const PreviewButton = styled(SharingButton)`
  background: #ffffff;
  border: 1px solid #cbd5e1;
  color: #334155;
  gap: 0.5rem;
  span {
    margin-right: 0;
  }
`;

export const CreateAssetBtn = styled.a`
  background: #2563eb;
  font-size: 1.3rem;
  color: #f8fafc;
  font-weight: 400;
  font-family: var(--font-lexend);
  margin-left: 2rem;
  padding: 1.2rem 1.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
`;

export const AddAssetsButton = styled(CreateAssetBtn)`
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 0.8rem;
  margin-left: 1rem;
  color: #475569;
  span {
    margin: 0;
  }
`;

export const BreakLine = styled.hr`
  width: 85%;
  border: none;
  border-top: 1px solid #e2e8f0;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const LibraryPopupHeader = styled.div`
  border: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem 1.6rem;
  cursor: pointer;
  span {
    font-family: var(--font-lexend);
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 2rem;
    color: #2563eb;
  }
`;

export const SidebarWithTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--white);
  font-size: 1.4rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.04);
  border: 0.5px solid #e2e8f0;
  border-radius: 1.2rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  min-height: 80rem;
`;

export const SidebarWithTabsBody = styled.div`
  display: flex;
`;

export const SelectedTabContent = styled.div`
  width: 100%;
  background: #fff;
  overflow-y: auto;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding-bottom: 3rem;
`;
