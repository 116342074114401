import { nanoid } from "nanoid";
import Tooltip from "../Tooltips";
import {
  ThumbnailCardWrapper,
  ThumbnailDescription,
  ThumbnailImage,
  ThumbnailImageWrapper,
  ThumbnailLabel,
  ThumbnailLabelContainer,
} from "./styled";

interface IThumbnailCardProps {
  label?: string;
  description?: string;
  thumbUrl?: string;
  onClick?: Function;
  className?: string;
}
const ThumbnailCard = ({
  label = "",
  thumbUrl = "",
  description = "",
  onClick,
  className = "",
}: IThumbnailCardProps) => {
  const tooltipId = nanoid();
  return (
    <>
      <ThumbnailCardWrapper
        onClick={(e) => onClick && onClick(e)}
        className={className}
        data-tip
        data-for={tooltipId}
      >
        <ThumbnailImageWrapper>
          <ThumbnailImage src={thumbUrl} alt="Thumbnail image"></ThumbnailImage>
        </ThumbnailImageWrapper>
        {(label || description) && (
          <ThumbnailLabelContainer>
            <ThumbnailLabel>
              {label.length < 30
                ? label
                : `${label.slice(0, 13)}...${label.slice(-15)}`}
            </ThumbnailLabel>
            <ThumbnailDescription>{description}</ThumbnailDescription>
          </ThumbnailLabelContainer>
        )}
      </ThumbnailCardWrapper>
      {/* <Tooltip id={tooltipId}>{label}</Tooltip> */}
    </>
  );
};

export default ThumbnailCard;
