import React from "react";
import { AuthContext } from "../../../../context";
import { Button, ErrorContent, ErrorWrapper, Text } from "./styled";
import { FiLogOut } from "react-icons/fi";
import GuestUserErrorPage, {
  GENERIC_ERROR_MSG,
} from "../../../auth/GuestUser/GuestUserErrorPage";

class AppErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  static getDerivedStateFromError(error: any) {
    return { error: error };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
  }

  render() {
    const isSharedPath = window.location.pathname.startsWith("/shared/");
    if (this.state.error) {
      return isSharedPath ? (
        <ErrorContent>
          <GuestUserErrorPage error={GENERIC_ERROR_MSG} showRefreshButton />
        </ErrorContent>
      ) : (
        <ErrorWrapper>
          <ErrorContent>
            <div>
              <Text>Oops, something went wrong.</Text>
              <Text>Please try logging in again.</Text>
            </div>
            <Button
              onClick={() => {
                window.location.replace("/logout");
              }}
            >
              <FiLogOut />
              <span>Logout</span>
            </Button>
          </ErrorContent>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundary;
