import { useQuery } from "react-query";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

export const getTeams = async () => {
  const response = await axiosInstance.get("/teams/");
  return response.data;
};

const useGetTeams = () => {
  const response = useQuery([queryKeys.teams], () => getTeams(), {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
  });
  return response;
};

export default useGetTeams;
