import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getOpportunities = async () => {
  const response = await axiosInstance.get("/opportunities/");
  return response.data;
};

const createOpportunity = async (postData: any) => {
  const response = await axiosInstance.post("/opportunities/", postData);
  return response.data;
};

const useGetOpportunities = () => {
  const response = useQuery(
    [queryKeys.opportunities],
    () => getOpportunities(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export const useCreateOpportunities = () => {
  const mutationFunction = (mutationData: any) => {
    return createOpportunity(mutationData);
  };

  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSuccess: () => {},
  };

  return useMutation(mutationFunction, mutationOption);
};

export default useGetOpportunities;
