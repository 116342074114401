import { useQuery } from "react-query";
import { getLoggedInUser } from "../../helpers";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

export const getUser = async (id: string) => {
  if (!id) return;
  const response = await axiosInstance.get(`/users/${id}`);
  return response.data;
};

const useGetUser = (id?: string) => {
  if (!id) id = getLoggedInUser()?.user.id;
  const response = useQuery<any, Error>(
    [queryKeys.solutions, { id: id }],
    () => id && getUser(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );
  return { ...response };
};

export default useGetUser;
