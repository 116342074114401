import { useQuery } from "react-query";
import { apiConfig } from "../../config";
import { IDocumentObject } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getAssetDocuments = async () => {
  const response = await axiosInstance.get(apiConfig.urls.documents);
  return response.data;
};

const useGetAssetDocuments = () => {
  const response = useQuery<IDocumentObject[]>(
    [queryKeys.documents],
    () => getAssetDocuments(),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );
  return { ...response };
};

export default useGetAssetDocuments;
