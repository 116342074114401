import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

let prevPath: null | string = null;

// todo: tried using various interfaces for location, but couldn't resolve the issue, hence using "any"
history.listen((location: any) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
  }
});
