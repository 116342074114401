import _ from "lodash";
import React, { createContext, useEffect, useState } from "react";
import useFilterDropdownInitialState from "../api/hooks/filter/useFilterDropdownInitialState";
import { IStateOfAllDropdowns } from "../interfaces/dropdown";

interface IContentAreaContext {
  showFilter: boolean;
  stateOfAllDropdowns: IStateOfAllDropdowns | undefined;
  deleteState: any;
  selectedRelParamsIds: (string | number)[];
  setStateOfAllDropdowns: React.Dispatch<
    React.SetStateAction<IStateOfAllDropdowns | undefined>
  >;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteState: React.Dispatch<React.SetStateAction<any>>;
  setSelectedRelParamsIds: React.Dispatch<
    React.SetStateAction<(string | number)[]>
  >;
}

export const ContentAreaContext = createContext({} as IContentAreaContext);

export const ContentAreaContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showFilter, setShowFilter] = useState(true);
  const { data: filterDropdownInitialState } = useFilterDropdownInitialState();
  const [stateOfAllDropdowns, setStateOfAllDropdowns] = useState<
    IStateOfAllDropdowns | undefined
  >(filterDropdownInitialState);
  const [deleteState, setDeleteState] = useState({
    isDelete: false,
    assetItemId: "",
    assetSlug: null,
    onSuccess: () => {},
  });
  const [selectedRelParamsIds, setSelectedRelParamsIds] = useState<
    (string | number)[]
  >([]);

  useEffect(() => {
    // This updates the stateOfAllDropdowns when the Relationship param gets updated
    let shouldUpdateDropdownState =
      Object.values(stateOfAllDropdowns || {}).length !==
      Object.values(filterDropdownInitialState || {}).length;

    if (!shouldUpdateDropdownState)
      shouldUpdateDropdownState = Object.values(stateOfAllDropdowns || {}).some(
        ({ selected, suggested }, i) => {
          if (
            filterDropdownInitialState &&
            filterDropdownInitialState[i] &&
            !stateOfAllDropdowns?.[i].search
          ) {
            const allItems = selected
              .concat(suggested)
              .sort(
                (option1: any, option2: any) => option1.value - option2.value
              )
              .map(({ label, value }) => ({
                label,
                value,
              }));
            const { selected: initSelected, suggested: initSuggested } =
              filterDropdownInitialState[i];
            const allInitItems = initSelected
              .concat(initSuggested)
              .sort(
                (option1: any, option2: any) => option1.value - option2.value
              )
              .map(({ label, value }) => ({
                label,
                value,
              }));
            return !_.isEqual(allItems, allInitItems);
          }
          return false;
        }
      );
    if (shouldUpdateDropdownState) {
      setStateOfAllDropdowns(filterDropdownInitialState);
    }
  }, [filterDropdownInitialState]);

  return (
    <ContentAreaContext.Provider
      value={{
        stateOfAllDropdowns,
        showFilter,
        setStateOfAllDropdowns,
        setShowFilter,
        deleteState,
        setDeleteState,
        selectedRelParamsIds,
        setSelectedRelParamsIds,
      }}
    >
      {children}
    </ContentAreaContext.Provider>
  );
};
