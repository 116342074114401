import { useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../../context";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

interface IGetSolutionsAPIFilters {
  users?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
}
interface IUseGetSolutions {
  filters?: IGetSolutionsAPIFilters;
}

const getSolutions = async (filters?: IGetSolutionsAPIFilters) => {
  const searchParams = new URLSearchParams(filters as any);
  const response = await axiosInstance.get(
    `/solutions/?${searchParams.toString()}`
  );
  return response.data;
};

const useGetSolutions = (props?: IUseGetSolutions) => {
  const filters = props?.filters;
  const { authState } = useContext(AuthContext);
  const response = useQuery(
    filters ? [queryKeys.solutions, filters] : [queryKeys.solutions],
    () => getSolutions(filters),
    {
      refetchOnWindowFocus: authState.isGuestUser ? false : true,
    }
  );
  return { ...response };
};

export default useGetSolutions;
