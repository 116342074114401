import { useQuery } from "react-query";
import { ISolutionObject } from "../../interfaces/solution";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getSolution = async (id: string) => {
  if (!id) return;
  const response = await axiosInstance.get(`/solutions/${id}/`);
  return response.data;
};

const useGetSolution = (id: string) => {
  const response = useQuery<ISolutionObject, Error>(
    [queryKeys.solutions, { id: id }],
    () => getSolution(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetSolution;
