export enum APIObjectType {
  SOLUTION = "solution",
}

export enum AppName {
  HUBSPOT = "hubspot",
  SALESFORCE = "salesforce",
}

export const A4_PAGE_DIMENSION = {
  width: 595, //in pixels
  height: 820, //in pixels
};

export const GUPSHUP_TEAM_IDENTIFIER = "gupshup";

export const SOLUTION_LIST_PAGE_SIZE = 20;

export const CAMPAIGN_LIST_PAGE_SIZE = 20;

export const CAMPAIGN_USER_LIST_PAGE_SIZE = 25;

export const PAGINATION_PAGE_LIMIT = 10;

export const TIME_ANALYTICS_PROGRESS_BAR_LENGTH = 120;

export const CAMPAIGN_CSV_ALERT_MESSAGE =
  "The download is not ready yet. It will be ready once the campaign processing gets over.";
