import styled from "@emotion/styled";

export const DropdownListWrapper = styled.div`
  position: absolute;
  z-index: 11;
  width: 30rem;
  .search-dropdown-list {
    background: #ffffff;
    box-shadow: 0px 12px 30px rgba(71, 85, 105, 0.12);
    border-radius: 0px 0px 5px 5px;
  }
  .hide-dropdown-list {
    display: none;
  }
`;

export const DropdownListContainer = styled.ul`
  font-family: var(--font-clarity-city);
  background: #fff;
  max-height: 374px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.04);
  font-size: 1.4rem;
`;

export const DropdownListItems = styled.li``;

export const DropdownGroupWrapper = styled.div`
  margin: 1.2rem 0;
`;

export const DropdownGroupName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1rem;
  color: #312e81;
  padding-left: 2rem;
`;

export const DropdownItemsContainer = styled.div`
  padding-top: 1.2rem;
`;

export const DropdownListItem = styled.div`
  display: flex;
  align-items: center;
  color: #bfbfbf;
  border-left: 3px solid transparent;
  cursor: pointer;
  padding: 0.8rem 2rem;
  padding-right: 1.2rem;
  justify-content: space-between;
  &:hover {
    background-color: #f1f6ff;
    border-left: 0.25rem solid #287ada;
  }
`;
export const DropdownListItemLabel = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #64748b;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SearchListHead = styled.div`
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #64748b;
  padding: 1.2rem;
`;

export const SearchListSubHead = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
`;
export const DropdownItemArrow = styled.div`
  img {
    width: 1rem;
    height: 1rem;
  }
`;
export const BreakLine = styled.hr`
  border: none;
  border-top: 0.05rem solid #e2e8f0;
`;
export const SearchDropdownListMain = styled.li`
  font-size: 1.4rem;
  border-left: 3px solid white;
  .list-item {
    padding: 0.7rem 1.1rem 0 0;
    display: flex;
    align-items: center;
    color: #bfbfbf;
    justify-content: space-between;
  }
  .list-item-fixed {
    font-weight: 700;
  }
  .icon {
    display: block;
  }
  .icon > svg {
    width: 1.8rem;
    height: 1.8rem;
  }
  .search-name {
    color: #64748b;
    margin: 0 1.5rem;
    font-size: 1.2rem;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
  }
  .search-type {
  }
`;
export const SearchDropdownListContainer = styled.ul`
  background: #fff;
  max-height: 374px;
  overflow-y: auto;
  border: 1px solid #e4e4e4;
  font-size: 1.4rem;
`;
