import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import { FallBack } from "../../compositional";
import { history } from "../../../utils/history";

/*
    1. From hubspot user is redirected to this path
        /oauth/hubspot either with some code or some error
    2. 
        2.1 If we receive code POST /auth/hubspot/install/ is called and we send the code
            2.1.1 Gets executed successfully and we receive status 200  
                Redirect towards the settings page to import tab with some message and also activate button
                as Sync with HubSpot
            2.1.2 Failure of api due to some reason
                Redirect towards the settings page to import tab with error message and button should still 
                show connect with HubSpot
        2.2 If we fail to receive code -  we receive some error
            2.2.1 Redirect to the settings page to import tab with some error message.

    3. Now on import_tab there can be either "Connect with HubSpot" or "Sync with HubSpot"
        3.1 GET /accounts/hubspot on click of Sync with HubSpot
            

    Redirection URL signature 
    /user/settings/?tab=import_data&type=<success | error>&message=<Custom message>
*/

// Consumes the code param from url
function HubSpotOauth() {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      axiosInstance
        .post("/auth/hubspot/install/", { code })
        .then((res) => {
          if (res.status === 200) {
            history.replace(
              `/user/settings/integrations/hubspot?tab=import_data&type=success&message=Click on Sync with HubSpot button to import data from hubspot`
            );
          } else {
            const error = res.data.error;
            history.replace(
              `/user/settings/integrations/hubspot?tab=import_data&type=error&message=${error?.details}`
            );
          }
        })
        .catch((err) => {
          history.replace(
            `/user/settings/integrations/hubspot?tab=import_data&type=error&message=server_error`
          );
        });
    } else {
      // If there isn't a code it implies either the user is trying to come on this route or
      //   He has been redirected from HubSpot oauth to this place with some error.
      history.replace(
        `/user/settings/integrations/hubspot?tab=import_data&type=error&message=authentication_error`
      );
    }
  }, []);

  return <FallBack />;
}

export default HubSpotOauth;
