import { useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import moment from "moment";
import { appConfig } from "../../../../../config";
import { getInitials } from "../../../../../helpers";
import { IFieldStructure } from "../../../../../interfaces/opportunities";
import {
  CellWrapper,
  CommaSeperatedContainer,
  CommaSeperatedKey,
  CommaSeperatedValue,
  PersonIconSquare,
  StatusContainer,
  Tooltip,
} from "./styled";

const {
  formats: { cellDateFormat, cellDateTimeFormat },
} = appConfig;

interface IRenderColumn {
  fieldRenderType: string;
  cellContent: any;
  fieldSpecificData: IFieldStructure;
}
interface ICell {
  children?: React.ReactNode;
  tooltipContent?: any;
  extraData?: {
    enableTooltip?: "always" | "overflow";
  };
  style?: Object;
  contentClassName?: string;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
}

const date2str = (dates: any) => {
  if (!dates) return;
  const newDate: any = new Date(dates);
  return moment(newDate).fromNow();
};

export const Cell = ({
  children,
  tooltipContent,
  extraData,
  style = {},
  contentClassName,
  ...props
}: ICell) => {
  const tooltipIdRef = useRef<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  if (tooltipIdRef.current === null) {
    tooltipIdRef.current = nanoid();
  }
  const tooltipId = tooltipIdRef.current;
  const enableTooltip = extraData?.enableTooltip;
  const [showTooltip, setShowTooltip] = useState(
    enableTooltip ? enableTooltip === "always" : Boolean(tooltipContent)
  );

  useEffect(() => {
    if (showTooltip == false && extraData?.enableTooltip === "overflow") {
      const longTextElement =
        contentRef.current?.getElementsByClassName("long-text")?.[0] ||
        contentRef.current;
      if (
        longTextElement &&
        longTextElement.clientHeight < longTextElement.scrollHeight
      )
        setShowTooltip(true);
    }
  }, [tooltipContent]);

  return (
    <CellWrapper style={style} {...props}>
      <div
        data-tip
        data-for={tooltipId}
        ref={contentRef}
        className={contentClassName}
      >
        {children}
      </div>
      {showTooltip && <Tooltip id={tooltipId}>{tooltipContent}</Tooltip>}
    </CellWrapper>
  );
};

const RenderColumn = ({
  fieldRenderType,
  cellContent,
  fieldSpecificData,
}: IRenderColumn) => {
  const emptyCell = <Cell />;

  switch (fieldRenderType) {
    case "text": {
      return cellContent ? <Cell>{cellContent}</Cell> : emptyCell;
    }
    case "long_text": {
      return cellContent ? (
        <Cell
          extraData={fieldSpecificData.cellProperties}
          tooltipContent={cellContent}
          contentClassName="long-text"
        >
          {cellContent}
        </Cell>
      ) : (
        emptyCell
      );
    }
    case "text_bold": {
      return cellContent ? (
        <Cell contentClassName="text-bold">{cellContent}</Cell>
      ) : (
        emptyCell
      );
    }
    case "date": {
      const date =
        typeof cellContent === "string" &&
        moment(cellContent).format(cellDateFormat);
      return date ? <Cell>{date}</Cell> : emptyCell;
    }
    case "datetime": {
      const date =
        typeof cellContent === "string" &&
        moment(cellContent).format(cellDateTimeFormat);
      return date ? <Cell>{date}</Cell> : emptyCell;
    }
    case "user_thumb": {
      const { display_name } = cellContent as any;
      return display_name ? (
        <Cell>
          <div className="flex align-center">
            <PersonIconSquare>{getInitials(display_name)}</PersonIconSquare>
            <span>{display_name}</span>
          </div>
        </Cell>
      ) : (
        emptyCell
      );
    }
    case "comma_seperated_text": {
      // const accessor = fieldSpecificData.field_name.split(".")[1];
      const cellContentList = cellContent as unknown[];
      return cellContentList ? (
        <Cell
          extraData={fieldSpecificData.cellProperties}
          tooltipContent={cellContent}
        >
          <div className="long-text">
            {cellContentList
              // .map((cellContentElement: any) => cellContentElement[accessor])
              .join(", ")}
          </div>
        </Cell>
      ) : (
        emptyCell
      );
    }
    case "time_interval": {
      return cellContent ? (
        <>
          <Cell>
            <div className="long-text">{date2str(cellContent)}</div>
          </Cell>
        </>
      ) : (
        emptyCell
      );
    }
    case "comma_seprated_text_map": {
      return cellContent ? (
        <Cell>
          {Object.entries(cellContent).map(
            ([key, value]: [string, any], index) => {
              return (
                <div key={index}>
                  {value[0] && (
                    <CommaSeperatedContainer>
                      <CommaSeperatedKey
                        keys={
                          key.includes("Buying team") ||
                          key?.includes("Deal team") ||
                          key?.includes("SE")
                            ? "team"
                            : ""
                        }
                      >
                        {key}:&nbsp;
                      </CommaSeperatedKey>
                      <CommaSeperatedValue className="text-bold">
                        {value?.map(
                          (valueName: any, index: any) =>
                            valueName !== "null" &&
                            valueName !== "" &&
                            `${valueName}${
                              valueName === value[value.length - 1] ? "" : ","
                            } `
                        )}
                      </CommaSeperatedValue>
                    </CommaSeperatedContainer>
                  )}
                </div>
              );
            }
          )}
        </Cell>
      ) : (
        emptyCell
      );
    }
    case "status": {
      return (
        <Cell>
          <StatusContainer
            background={cellContent.background}
            color={cellContent.color}
          >
            {cellContent?.content ? cellContent.content : "-"}
          </StatusContainer>
        </Cell>
      );
    }
    default: {
      return cellContent ? <Cell>{cellContent.toString()}</Cell> : emptyCell;
    }
  }
};

export default RenderColumn;
