import { DialogOverlay, DialogContent } from "@reach/dialog";
import styled from "@emotion/styled";

export const DialogOverlayWrapper = styled(DialogOverlay)``;

export const DialogContentWrapper = styled(DialogContent)`
  width: 44rem;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  padding: 0;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div``;

export const ContentMain = styled.div`
  #sidebar__tabs {
    border: none;
    margin: 0;
  }
  overflow: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const Cancel = styled.button`
  padding: 0.8rem 2rem;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  background: #ffffff;
  color: #454545;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;
