// Flow for user joining through click on JOIN in workspace

import axiosInstance from "../../../api/axios";
import { AuthContext } from "../../../context";
import { clearUpAndGoToHome } from "../../../helpers";
import { ILoggedInUserResponse } from "../../../interfaces";
import jwtDecode from "jwt-decode";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../../utils/history";
import { appConfig, userActivityEvents } from "../../../config";
import { trackUserAction } from "../../../analytics";

const {
  urls: { gatherUserAccountDetails: gatherUserAccountDetailsUrl },
} = appConfig;

const SignedInUserJoin = () => {
  const { authDispatch } = useContext(AuthContext);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  useEffect(() => {
    const code = urlParams.get("code");
    if (!code) return history.push(gatherUserAccountDetailsUrl);
    if (!code) return history.push("/");
    axiosInstance
      .post("/teams/join/", {
        code,
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200 || res.status === 201) {
          const loggedInUser = data as unknown as ILoggedInUserResponse;
          trackUserAction(userActivityEvents.SIGNIN__JOINED_WORKSPACE, {
            team_id: loggedInUser.team.id,
            team_name: loggedInUser.team.name,
          });
          localStorage.setItem("access", data.access);
          localStorage.setItem("refresh", data.refresh);
          localStorage.setItem("teamId", data.team.id);

          const userInfo: any = jwtDecode(data.access);
          localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));

          authDispatch({
            type: "SET_USER",
            payload: {
              userInfo: {
                userId: userInfo.user_id,
                email: userInfo.email,
                display_name: userInfo.display_name,
              },
            },
          });
          history.push(
            loggedInUser.is_signup ? gatherUserAccountDetailsUrl : "/"
          );
        } else {
          clearUpAndGoToHome();
        }
      })
      .catch((err) => {
        console.log(err);
        clearUpAndGoToHome();
      });
  }, []);

  return <></>;
};

export default SignedInUserJoin;
