import axios from "axios";

const IFRAMELY_API_KEY = process.env.REACT_APP_IFRAMELY_APP_ID;

export const getHtmlForEmbed = async (
  url: string,
  isBookmark: boolean = false
) => {
  if (!(IFRAMELY_API_KEY && url)) return "";
  const baseUrl = "https://iframe.ly/api/oembed?";
  const searchParams = new URLSearchParams({
    api_key: IFRAMELY_API_KEY,
    omit_script: "1",
    url: url,
  });

  if (isBookmark) searchParams.set("iframe", "card-small");

  const iframelyUrl = baseUrl + searchParams.toString();
  const response = await axios.get(iframelyUrl);
  const html = response.data.html || "";
  return html;
};
