import axiosInstance from "../axios";

const FILE_SERVER_BASE_URL = process.env.REACT_APP_FILE_SERVER_BASE_URL;

export const generateSolutionDoc = async (solutionId: string | number) => {
  if (!solutionId) return Promise.reject();
  const url = `solutions/${solutionId}/doc/generate/`;
  const response = await axiosInstance({
    method: "post",
    url,
    baseURL: FILE_SERVER_BASE_URL,
  });
  return response.data;
};
