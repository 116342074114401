import { noop, kebabCase } from "lodash";
import React, { useContext, useEffect } from "react";
import { AuthContext, SolutionsContext } from "../../../../context";
import Tab from "../../../../components/compositional/PageSpecificLayout/components/SidebarWithTabs1/Tab";
import { BreakLine } from "./styled";
import { getTitlesFromText } from "../../../../helpers/common";
import { TITLE_HEADING_CONFIG } from "../../../../constants/richTextEditor";

const SolutionDocumentTitleTabs = ({
  onTabClick = noop,
}: {
  onTabClick?: Function;
}) => {
  const { authState } = useContext(AuthContext);
  const { solutionsState } = useContext(SolutionsContext);
  const solutionDocHtml = solutionsState.solutionObject?.solution_doc_html;
  const titles = getTitlesFromText(
    solutionDocHtml || "",
    TITLE_HEADING_CONFIG.htmlTag
  );

  const handleTabClick = (titleText: string) => {
    onTabClick(titleText);
    const sharedSolutionTopNav = document.getElementById(
      "shared-solution-top-nav"
    ) as HTMLDivElement;
    const sharedSolutionHeader =
      sharedSolutionTopNav?.parentNode as HTMLDivElement;
    const sharedViewOffset = authState.isGuestUser
      ? (sharedSolutionHeader?.offsetHeight || 0) -
        (sharedSolutionTopNav?.offsetHeight || 0) -
        80
      : 0;

    const EXTRA_OFFSET_TOP = 24;
    const titleId = `title-${kebabCase(titleText)}`;
    const element = document.getElementById(titleId);
    const scrollContainerMain = document.getElementsByTagName("main")[0];
    const tabsContainerHeight =
      document.getElementById("solution-content-tabs")?.offsetHeight || 0;
    if (element) {
      scrollContainerMain.scrollTo({
        behavior: "smooth",
        top:
          element.offsetTop -
          tabsContainerHeight -
          EXTRA_OFFSET_TOP +
          sharedViewOffset,
      });
    } else {
      setTimeout(() => {
        const element = document.getElementById(titleId);
        element &&
          scrollContainerMain.scrollTo({
            behavior: "smooth",
            top:
              element.offsetTop -
              tabsContainerHeight -
              EXTRA_OFFSET_TOP +
              sharedViewOffset,
          });
      }, 200);
    }
  };

  useEffect(() => {
    // if (!authState.isGuestUser) return;
    const mainContainer = document.getElementsByTagName("main")[0];
    mainContainer.addEventListener("scroll", () => {
      titles.forEach((titleText) => {
        const docTitleElement = document.getElementById(
          `title-${kebabCase(titleText.toLowerCase())}`
        );
        if (
          docTitleElement &&
          docTitleElement?.getBoundingClientRect().top <= 200 &&
          docTitleElement?.getBoundingClientRect().top >= 60
        ) {
          const sidebarTabsContainer =
            document.getElementsByClassName("tabbed__sidebar")[0]?.children ||
            [];
          Array.from(sidebarTabsContainer).forEach((tabElement) => {
            if (
              (tabElement as HTMLElement).id ===
              kebabCase(docTitleElement.innerText.toLowerCase())
            ) {
              tabElement.classList.add("active");
            } else {
              tabElement.classList.remove("active");
            }
          });
        }
      });
    });
  }, [titles]);

  return (
    <>
      {titles.map((titleText, index) => (
        <Tab
          key={index}
          label={titleText}
          onClick={() => handleTabClick(titleText)}
        ></Tab>
      ))}
      <BreakLine></BreakLine>
    </>
  );
};

export default SolutionDocumentTitleTabs;
