import styled from "@emotion/styled";
import { nanoid } from "nanoid";
import Tooltip from "../../Tooltips";

interface ITagContentProps {
  visibility?: "visible" | "hidden";
}

const TagContent = styled.div<ITagContentProps>`
  background-color: #f1f5f9;
  color: #475569;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 5px;
  font-family: var(--font-clarity-city);
  line-height: 2rem;
  visibility: ${(props) => props.visibility || "visible"};
`;

export interface ITag {
  label: string;
  toolTipText?: string;
  onClick?: Function;
  visibility?: "visible" | "hidden";
}

const Tag = ({ label, toolTipText, onClick, visibility = "visible" }: ITag) => {
  const tooltipId = nanoid();
  return (
    <>
      <TagContent
        data-tip
        data-for={tooltipId}
        className="Tag__content"
        visibility={visibility}
        onClick={(e) => onClick && onClick(e)}
      >
        <span className="Tag__label">{label}</span>
      </TagContent>
      {toolTipText ? (
        <Tooltip id={tooltipId} className="Tag__tooltip">
          {toolTipText}
        </Tooltip>
      ) : null}
    </>
  );
};

export default Tag;
