import { useContext, useEffect } from "react";
import { history } from "../../../utils/history";
import { appConfig, userActivityEvents } from "../../../config";
import salesforceIcon from "../../../assets/icons/salesforce.svg";
import hubspotIcon from "../../../assets/icons/hubspot.svg";
import useGetIntegrationApps from "../../../api/hooks/useGetIntegrationApps";
import AppsCard, { StatusButton } from "../Reusable/components/AppsCard";
import { IntegrationContext } from "../../../context/integrationContext";
import { IntegrationActionType } from "../../../interfaces/context/integrationContext";
import { IIntegrationAppData } from "../../../interfaces/integration";
import {
  CardIcon,
  HeaderDescription,
  HeaderTitle,
  IntegrationsBody,
  IntegrationsHeader,
  IntegrationsWrapper,
} from "./styled";
import { AppName } from "../../../constants";
import { useQueryClient } from "react-query";
import { trackUserAction } from "../../../analytics";

export const CardIconMapper: { [name_key: string]: React.ReactNode } = {
  [AppName.HUBSPOT]: (
    <CardIcon style={{ backgroundColor: "#FEF2F2" }}>
      <img src={hubspotIcon} alt="hubspot icon" className="icon"></img>
    </CardIcon>
  ),
  [AppName.SALESFORCE]: (
    <CardIcon style={{ backgroundColor: "#F0F9FF" }}>
      <img src={salesforceIcon} alt="salesforce icon"></img>
    </CardIcon>
  ),
};

const Integrations = () => {
  const queryClient = useQueryClient();
  const { integrationDispatch } = useContext(IntegrationContext);
  const { data } = useGetIntegrationApps({ filters: { active: true } });
  const appDataArr = (data?.map((appData) => ({
    ...appData,
    icon: CardIconMapper[appData.name],
  })) || []) as IIntegrationAppData[];

  useEffect(() => {
    trackUserAction(userActivityEvents.SETTINGS__INTEGRATIONS_VIEWED);
  }, []);

  const handleOnAppDisconnection = () => {
    integrationDispatch({
      type: IntegrationActionType.REMOVE_ACTIVE_APP_CARD_DATA,
    });
    queryClient.invalidateQueries("apps");
  };

  return (
    <IntegrationsWrapper>
      <IntegrationsHeader>
        <HeaderTitle>Integrations</HeaderTitle>
        <HeaderDescription>
          Integrate {appConfig.appName} with your favorite tools to supercharge
          your workflows
        </HeaderDescription>
      </IntegrationsHeader>
      <IntegrationsBody>
        <AppsCard
          title=""
          appData={appDataArr}
          onCardClick={(appData: IIntegrationAppData) => {
            if (appData.is_connected) {
              history.push(
                `${appConfig.pageSpecificConfigs.settings.pathUrl.integrations}/${appData.name}`
              );
              integrationDispatch({
                type: IntegrationActionType.SET_ACTIVE_APP_CARD_DATA,
                payload: appData,
              });
            } else {
              window.location.href = appData.url;
              trackUserAction(
                appData.name.includes(AppName.SALESFORCE)
                  ? userActivityEvents.SETTINGS__SALESFORCE_CONNECT_CLICKED
                  : userActivityEvents.SETTINGS__HUBSPOT_CONNECT_CLICKED
              );
            }
          }}
          CardContentFooter={(appData: IIntegrationAppData) => (
            <StatusButton
              appData={appData}
              onDisconnect={handleOnAppDisconnection}
            ></StatusButton>
          )}
        ></AppsCard>
      </IntegrationsBody>
    </IntegrationsWrapper>
  );
};

export default Integrations;
