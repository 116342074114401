import { nanoid } from "nanoid";
import { getHtmlForEmbed } from "../api/richTextEditor";
import { IDocumentObject } from "../interfaces";
import { editorElementStyle } from "../config/editorConfig";

interface IDocumentExtraData {
  solutionAssetId?: string;
  solutionAssetTypeId?: string;
  genericResourceId?: string;
  genericResourceTypeId?: string;
}

const getEditButton = (buttonText: string, className: string) => {
  const editButtonElement = document.createElement("div");
  editButtonElement.contentEditable = "false";
  editButtonElement.classList.add(className);
  editButtonElement.style.cursor = "pointer"; //Adding the cursor: pointer here as inline css because tiny editor add cursor: default to all non editable element
  editButtonElement.innerText = buttonText;
  return editButtonElement;
};

export const getDocumentHtml = (
  documentData: IDocumentObject,
  extraData?: IDocumentExtraData
) => {
  //@ts-ignore
  tinymce.activeEditor.setProgressState(true);
  const url = `${process.env.REACT_APP_API_BASE_URL}documents/${documentData.id}/file/`;
  const isPdf = /^.*\.(pdf|PDF)$/.test(documentData?.filename || "");
  const isImage = /^.*\.(jpg|JPG|png|PNG|jpeg|JPEG|gif)$/.test(
    documentData?.filename || ""
  );
  const isSvg = /^.*\.(svg|svgz)$/.test(documentData?.filename || "");
  const isGoogleDoc = documentData.created_from?.includes("gdrive");
  const embedContainer = document.createElement("div");
  embedContainer.classList.add("b-embed");
  embedContainer.dataset.documentId = `${documentData.id}`;
  embedContainer.dataset.documentFileName = `${documentData.filename}`;
  if (isPdf) {
    const divId = nanoid();
    embedContainer.id = divId;
    embedContainer.dataset.isPdf = "true";
    embedContainer.innerHTML = `<iframe title=${documentData.filename} style="width:100%; height:80vh" src="${url}"></iframe>`;
    if (isGoogleDoc) {
      const editButton = getEditButton(
        "Edit in Google Slides",
        "google-slides-edit-button"
      );
      extraData &&
        Object.entries(extraData).forEach((dataAttri) => {
          const key = dataAttri[0],
            value = dataAttri[1];
          editButton.dataset[key] = value;
        });
      editButton.dataset.hasRedirectUrl = `false`;
      embedContainer.style.position = "relative";
      embedContainer.appendChild(editButton);
    }
    embedContainer.style.width = "100%";
    embedContainer.style.height = "80vh";
    embedContainer.style.overflow = "hidden";
    return embedContainer;
  } else if (isImage || isSvg) {
    embedContainer.classList.remove("b-embed");
    embedContainer.style.maxWidth = editorElementStyle.image.maxWidth;
    embedContainer.innerHTML = `<img src="${url}" alt=${documentData.filename}/>`;
    if (isSvg) embedContainer.dataset.isSvg = "true";
    return embedContainer;
  } else {
    getHtmlForEmbed(url).then((html) => {
      embedContainer.innerHTML = html;
      return embedContainer;
    });
  }
  return embedContainer;
};

export const insertDocumentInEditor = (html: HTMLElement) => {
  //@ts-ignore
  tinymce.activeEditor.execCommand(
    "InsertHTML",
    false,
    `${html.outerHTML}<p/>`
  );
  // @ts-ignore
  tinymce.activeEditor.setProgressState(false);
};

export const getDocumentIdsArray = (html: string): number[] => {
  const documentIds: number[] = [];
  const editorValueWrapperEle = document.createElement("div");
  editorValueWrapperEle.innerHTML = html;
  Array.from(editorValueWrapperEle.getElementsByClassName("b-embed")).forEach(
    (embedElement) => {
      const documentId = Number(
        (embedElement as HTMLElement)?.dataset?.documentId
      );
      if (documentId) documentIds.push(documentId);
    }
  );
  return documentIds;
};
