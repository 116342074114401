import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../../../../api/constants";
import useCreateUpdateSolutionAssetItem from "../../../../../api/hooks/useCreateUpdateSolutionAssetItem";
import useDeleteSolutionAssetItem from "../../../../../api/hooks/useDeleteSolutionAssetItem";
import { solutionSidebarHiddenAssets } from "../../../../../config/solutionConfig";
import { SolutionsContext, ContentAreaContext } from "../../../../../context";
import { getRelationshipParametersString } from "../../../../../helpers";
import { SolutionsAssetActionType } from "../../../../../interfaces/context/solutionsContext";
import SolutionAddedRemoveButton from "../Buttons/SolutionAddedRemoveButton";
import { WidgetToolbar, WidgetItem } from "../WidgetToolbar";

interface ISolutionSearchWidgetToolbar {
  assetItem: any;
}

const SolutionSearchWidgetToolbar = ({
  assetItem,
}: ISolutionSearchWidgetToolbar) => {
  const [assetItemData, setAssetItemData] = useState<any>();
  const { solutionsState, solutionSearchText } = useContext(SolutionsContext);
  const { stateOfAllDropdowns } = useContext(ContentAreaContext);
  const queryClient = useQueryClient();
  const isAddedToSolution = assetItemData?.solution_assets?.length;

  let extraSolutionSearchObject: string[] = [];
  extraSolutionSearchObject = extraSolutionSearchObject.concat([
    ...solutionSidebarHiddenAssets,
    "solution",
    "relationship_parameter",
  ]);

  useEffect(() => {
    setAssetItemData(assetItem);
  }, [assetItem]);

  const { mutate, isLoading: isCreateUpdateSolutionLoading } =
    useCreateUpdateSolutionAssetItem(solutionsState?.solutionId || "");
  const {
    mutate: deleteSolutionAssetMutation,
    isLoading: isDeleteSolutionAssetLoading,
  } = useDeleteSolutionAssetItem({ solutionId: solutionsState?.solutionId });

  const handleRemoveAssetFromSolutionClick = (assetItem: any) => {
    const solutionAssetId = assetItem?.solution_assets?.[0];

    let relationshipParameters = "";
    relationshipParameters =
      getRelationshipParametersString(stateOfAllDropdowns);

    const mutationData = {
      id: solutionAssetId,
    };

    const mutationOption = {
      onSuccess: () => {
        setAssetItemData((prevAssetItem: any) => {
          return { ...prevAssetItem, solution_assets: [] };
        });
        queryClient.invalidateQueries([queryKeys.solutionAssetsType]);
        queryClient.invalidateQueries([queryKeys.solutionAssets, {solutionId : solutionsState.solutionId}]);
        queryClient.invalidateQueries(
          [assetItem.slug, relationshipParameters, { solutions: [] }],
          {},
          { cancelRefetch: true } // when there is multiple invalidation occur at a same time the last one will be executed
        );
        queryClient.invalidateQueries(
          [
            queryKeys.autoCompleteSearch,
            solutionSearchText,
            {
              exclude_objects: extraSolutionSearchObject,
              solution: solutionsState.solutionId,
            },
          ],
          {},
          { cancelRefetch: true }
        );
      },
    };

    deleteSolutionAssetMutation(mutationData, mutationOption);
  };

  const handleAddAssetToSolutionClick = (assetItem: any) => {
    let relationshipParameters = "";
    relationshipParameters =
      getRelationshipParametersString(stateOfAllDropdowns);

    const solutionId = +(solutionsState.solutionId || "");
    if (!solutionId) return;
    return mutate(
      {
        generic_resource_id: assetItem.id,
      },
      {
        onSuccess: (data: any) => {
          setAssetItemData((prevAssetItem: any) => {
            return { ...prevAssetItem, solution_assets: [data?.data?.id] };
          });
          queryClient.invalidateQueries(
            [assetItem.slug, relationshipParameters, { solutions: [] }],
            {},
            { cancelRefetch: true } // when there is multiple invalidation occur at a same time the last one will be executed
          );
          queryClient.invalidateQueries(
            [
              queryKeys.autoCompleteSearch,
              solutionSearchText,
              {
                exclude_objects: extraSolutionSearchObject,
                solution: solutionsState.solutionId,
              },
            ],
            {},
            { cancelRefetch: true }
          );
        },
      }
    );
  };

  if (isAddedToSolution)
    return (
      <SolutionAddedRemoveButton
        {...assetItem}
        isLoading={isDeleteSolutionAssetLoading}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          handleRemoveAssetFromSolutionClick(assetItem);
        }}
      />
    );

  return (
    <WidgetToolbar
      className="widget-toolbar"
      config={[
        {
          type: WidgetItem.ADD_TO_SOLUTION,
          isLoading: isCreateUpdateSolutionLoading,
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            handleAddAssetToSolutionClick(assetItem);
          },
          label: "",
        },
      ]}
    ></WidgetToolbar>
  );
};

export default SolutionSearchWidgetToolbar;
