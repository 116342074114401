import styled from "@emotion/styled";

export const CaseStudyWrapper = styled.div``;

export const VerticalCardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 3rem 2rem 2rem;
`;

export const VerticalCardsHeaderTitle = styled.div`
  font-family: var(--font-lexend);
  line-height: 3rem;
  font-size: 2rem;
  font-weight: 600;
  color: #1e3a8a;
  padding: 0.2rem 0;
`;

export const VerticalCardsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;
export const HorizontalCardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 3rem 2rem 2rem;
`;
export const Card = styled.div`
  padding: 1.2rem 2.7rem 1.6rem 1.6rem;
  border-bottom: 1px solid #e2e8f0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-lexend);
  &:hover {
    background: #f8fafc;
    transition-delay: all 200ms ease-in-out;
  }
`;
export const HorizontalCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const CardSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 32.7rem;
  height: 36.7rem;
  left: 0px;
  top: 0px;
  padding: 1.2rem 1.2rem 1.6rem;
  border: 1px solid #f1f5f9;
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-lexend);
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  padding-right: 2rem;
  line-height: 2rem;
  font-weight: 300;
  display: flex;
`;

export const CardRightArrow = styled.div``;

export const Title = styled.div`
  color: black;
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 2.4rem;
  display: flex;
  justify-content: space-between;
`;

export const Description = styled.div`
  div[data-ephox-embed-iri] {
    max-width: 30% !important;
    margin-bottom: 1rem;
    padding-bottom: 16.87% !important; //{30 * (9/16)} to maintain aspect ratio of 16:9
  }
  .tiny-pageembed--21by9,
  .tiny-pageembed--16by9,
  .tiny-pageembed--4by3,
  .tiny-pageembed--1by1 {
    width: 30% !important;
  }
  .tiny-pageembed--21by9 {
    padding-top: calc(42.857143% / 3) !important;
  }

  .tiny-pageembed--16by9 {
    padding-top: calc(56.25% / 3) !important;
  }

  .tiny-pageembed--4by3 {
    padding-top: calc(75% / 3) !important;
  }

  .tiny-pageembed--1by1 {
    padding-top: calc(100% / 3) !important;
  }
  .b-embed {
    max-width: 30% !important;
    pointer-events: none;
    img {
      max-width: 100% !important;
    }
  }
  img,
  object {
    max-width: 30% !important;
    height: auto;
    pointer-events: none;
  }
  max-height: 16rem;
  /* color: #64748b; */
  color: black;
  font-size: 1.3rem;
  p {
    margin: 0 !important;
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const TagAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const DocumentsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const TagsContainer = styled(DocumentsContainer)``;

export const Occurences = styled.div`
  color: #1d4ed8;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 5rem;
`;

export const ThumbnailBody = styled.div`
  width: 30.3rem;
  height: 18.7rem;
  left: 1.2rem;
  top: 1.2rem;
  background: #e2e8f0;
`;

export const ExtraTag = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1rem;
  gap: 10px;
  width: 3.6rem;
  height: 3.2rem;
  background: #475569;
  border-radius: 6px;
  margin-left: auto;
  color: #fff;
  position: relative;
`;

export const TagList = styled.ul`
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  width: 14.4rem;
  max-height: 13.2rem;
  margin-top: 11rem;
`;

export const TagListing = styled.li`
  display: none;
  background: #ffffff;
  border: 0.5px solid #cbd5e1;
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

export const TagHeading = styled.div`
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #64748b;
  padding: 1.2rem;
`;

export const CardFooter = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  /* column-gap: 1rem; */
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const ThumbnailContainer = styled.div`
  width: 40%;
  max-height: 15rem;
  overflow: hidden;
  margin-right: 1.5rem;
  border-radius: 0.4rem;
  border: 1px solid #e2e8f0;
  background: #ffffff;
  img,
  object,
  .b-embed {
    max-width: 100%;
    pointer-events: none;
  }

  .tiny-pageembed--21by9,
  .tiny-pageembed--16by9,
  .tiny-pageembed--4by3,
  .tiny-pageembed--1by1 {
    padding-top: 100%;
    pointer-events: none;
  }
`;
