import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetAssetMetadata } from "../../../../../api/hooks";
import useFilterDropdownInitialState from "../../../../../api/hooks/filter/useFilterDropdownInitialState";
import { ContentAreaContext } from "../../../../../context";
import { getStateOfAllDropdownFromRelIds } from "../../../../../helpers";
import {
  IDropdownCollectionItem,
  IStateOfAllDropdowns,
} from "../../../../../interfaces/dropdown";
import { DropdownWithCheckbox } from "../../../Dropdown";
import { FilterDropdownListContainerWrapper } from "./styled";

interface IFilterDropdownListContainer {
  style?: React.CSSProperties;
}

const FilterDropdownListContainer = ({
  style = {},
}: IFilterDropdownListContainer) => {
  const { stateOfAllDropdowns, setStateOfAllDropdowns, showFilter } =
    useContext(ContentAreaContext);

  const { data: filterDropdownInitialState } = useFilterDropdownInitialState();
  const { data: assetsMetadata } = useGetAssetMetadata();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const filterIdsFromUrl = urlParams.get("rel");

  useEffect(() => {
    const parsedFilterIds = filterIdsFromUrl
      ?.split(",")
      .map((filterId) => +filterId);
    let finalStateOfFilterDropdowns =
      stateOfAllDropdowns || filterDropdownInitialState;
    if (parsedFilterIds && assetsMetadata) {
      finalStateOfFilterDropdowns = getStateOfAllDropdownFromRelIds(
        parsedFilterIds,
        assetsMetadata
      );
    }
    setStateOfAllDropdowns(finalStateOfFilterDropdowns);
  }, [assetsMetadata, filterIdsFromUrl]);

  if (!showFilter) return <></>;

  return (
    <FilterDropdownListContainerWrapper id="filterShow" style={style}>
      <div style={{ display: "none" }} className="flex space-between">
        <h4 className="filter-title">Filter</h4>
      </div>
      <div className="flex space-between">
        <div className="flex">
          {stateOfAllDropdowns &&
            Object.keys(stateOfAllDropdowns).length &&
            assetsMetadata?.filters?.relationship_parameters?.map(
              (parameter: { data: any; label: any }, index: number) => {
                const { data, label } = parameter;
                const listOfItemsToBeDisplayed: IDropdownCollectionItem[] =
                  data.map((listItem: { value: any; id: any }) => ({
                    label: listItem.value,
                    value: listItem.id,
                    isSelected: false,
                  }));

                return (
                  <DropdownWithCheckbox
                    key={index}
                    dropDownBgColor="var(--white)"
                    color="#94A3B8"
                    selectColor="#1E293B"
                    background="var(--dropdown-background)"
                    label={label}
                    listOfItemsToBeDisplayed={listOfItemsToBeDisplayed}
                    currentDropdownIndex={index}
                    stateOfAllDropdowns={
                      stateOfAllDropdowns as IStateOfAllDropdowns
                    }
                    setStateOfAllDropdowns={setStateOfAllDropdowns}
                  />
                );
              }
            )}
        </div>
      </div>
    </FilterDropdownListContainerWrapper>
  );
};

export default FilterDropdownListContainer;
