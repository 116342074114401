import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import {
  AssetsContext,
  ContentAreaContext,
  SolutionsContext,
} from "../../context";
import { getRelationshipParametersString } from "../../helpers";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getAssetCount = async (params: any, filters: any) => {
  const url = `/assets/count/?relationship_parameters=${params}&solutions=${
    filters?.solutions?.length ? filters.solutions[0] : ""
  }`;
  const response = await axiosInstance.get(url);
  return response.data;
};

const useGetAssetCounts = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isProposalTab = searchParams.get("tab") === "solution";
  const { showLibraryModal } = useContext(AssetsContext);

  const { solutionsState } = useContext(SolutionsContext);
  const { stateOfAllDropdowns } = useContext(ContentAreaContext);
  let relationshipParameters =
    isProposalTab && !showLibraryModal
      ? ""
      : getRelationshipParametersString(stateOfAllDropdowns);
  const filters =
    solutionsState.solutionId && isProposalTab && !showLibraryModal
      ? [{ solutions: [solutionsState.solutionId] }]
      : [];

  const response = useQuery(
    [queryKeys.assetcount, relationshipParameters, ...filters],
    () =>
      getAssetCount(relationshipParameters, filters.length ? filters[0] : {}),
    {
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetAssetCounts;
