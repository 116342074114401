import { cloneDeep, noop } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import TimeMe from "timeme.js";
import useGetSolutionAssets from "../../../../../../../api/hooks/useGetSolutionAssets";
import { AuthContext, SolutionsContext } from "../../../../../../../context";
import { updatePageEmbedIframeUrl } from "../../../../../../../helpers/updatePageEmbedIframeUrl";
import { ISolutionsState } from "../../../../../../../interfaces";
import {
  ISolutionAsset,
  ISolutionAssetType,
} from "../../../../../../../interfaces/solution";
import FallBack from "../../../../../Fallback";
import SolutionAssetsCardHeader from "../SolutionAssetsCards/SolutionAssetsCardHeader";
import { SolutionDetailsContext } from "../../../../../../../context/SolutionDetailsContext";
import { replaceSvgImgWithObject } from "../../../../../../../hooks/useReplaceSvgImgWithObject";
import SolutionAssetsCards from "../SolutionAssetsCards";

interface ISolutionAssetsCardListProps {
  title?: string;
  type: ISolutionAssetType | null;
  handleTabClick?: Function;
}

const parseSolutionAssetsData = (
  solutionAssetsListData: ISolutionAsset[],
  solutionsState: ISolutionsState
) => {
  return solutionAssetsListData.map((solutionAsset: ISolutionAsset) => {
    let description_html = replaceSvgImgWithObject({
      htmlStr: solutionAsset?.description_html,
    });
    if (solutionsState?.solutionId) {
      description_html = updatePageEmbedIframeUrl({
        html: description_html || solutionAsset.description,
        companyName: solutionsState.solutionObject?.opportunity?.name || "",
        companyLogoUrl: solutionsState.solutionObject?.logo || "",
      });
    }
    solutionAsset.description = description_html || solutionAsset.description;
    return solutionAsset;
  });
};

const SolutionAssetsCardList = ({
  title,
  type,
  handleTabClick = noop,
}: ISolutionAssetsCardListProps) => {
  const { solutionsState } = useContext(SolutionsContext);
  const { authState } = useContext(AuthContext);
  const { solutionAssetTypes, setSolutionAssetTypes } = useContext(
    SolutionDetailsContext
  );

  let { data, isLoading } = useGetSolutionAssets({
    solutionId: solutionsState.solutionId || "",
    filters: { type: type?.id },
  });
  const solutionAssetsData = data?.data;
  const isShared = authState.isGuestUser;

  useEffect(() => {
    if (!isShared) return;
    TimeMe.trackTimeOnElement(`asset-type-${type?.id}`);
  }, []);

  useEffect(() => {
    setSolutionAssetTypes((state) => {
      let updatedSolutionAssetTypes = cloneDeep(state);
      updatedSolutionAssetTypes = updatedSolutionAssetTypes.map(
        (solutionAssetType: any) => {
          if (solutionAssetType.id === type?.id)
            return { ...solutionAssetType, assets: solutionAssetsData };
          else return solutionAssetType;
        }
      );
      return updatedSolutionAssetTypes;
    });
  }, [solutionAssetsData]);

  const parsedSolutionAssetsData: any = useMemo(() => {
    const solutionAssetType = solutionAssetTypes.find(
      (solutionAssetTypeObj) => solutionAssetTypeObj.id === type?.id
    );
    const updatedSolutionAssetsData = solutionAssetType?.assets || [];

    return parseSolutionAssetsData(updatedSolutionAssetsData, solutionsState);
  }, [solutionAssetTypes]);

  if (isLoading) return <FallBack />;

  // if (isShared)
  //   return <SharedVerticalCards data={parsedSolutionAssetsData} title={title} />;

  return (
    <SolutionAssetsCards
      title={title}
      data={parsedSolutionAssetsData}
      Header={() => (
        <SolutionAssetsCardHeader
          assetType={type}
          title={title || ""}
          handleTabClick={handleTabClick}
        />
      )}
    />
  );
};

export default SolutionAssetsCardList;
