import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 0;
  padding-top: 2rem;
  margin-bottom: 1rem;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 3.2rem;
`;

export const Title = styled.h2`
  font-family: var(--font-lexend);
  font-size: 2rem;
  font-weight: 600;
  line-height: 3rem;
  color: #1e3a8a;
`;

export const Button = styled.div`
  padding: 1rem 1.6rem;
  background: ${({ theme }: any) => theme.colors.button.background};
  border-radius: 0.6rem;
  cursor: pointer;
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: center;
  color: #f8fafc;
`;

export const HeaderRightSideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;
