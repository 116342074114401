import { useQuery } from "react-query";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { ISolutionAsset } from "../../interfaces/solution";

interface IGetSolutionAssetsRes {
  has_more: boolean;
  count: number;
  data: ISolutionAsset[];
}
interface ISolutionAssetsAPIFilters {
  type?: string | number;
}

interface IUseGetSolutionAssets {
  solutionId: string | number;
  filters?: object;
}

const getSolutionAssets = async (
  solutionId: string | number,
  filters: ISolutionAssetsAPIFilters
) => {
  if (!solutionId) return;
  const searchParams = new URLSearchParams(filters as any);
  const response = await axiosInstance.get(
    `/solutions/${solutionId}/assets/?${searchParams.toString()}`
  );
  return response.data;
};

const useGetSolutionAssets = ({
  solutionId,
  filters = {},
}: IUseGetSolutionAssets) => {
  const response = useQuery<IGetSolutionAssetsRes, Error>(
    [queryKeys.solutionAssets, { solutionId }, filters],
    () => getSolutionAssets(solutionId, filters),
    {
      enabled: !!solutionId,
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetSolutionAssets;
