import { FormFieldType, SUPPORTED_IMAGE_FILETYPES } from "../constants";
import * as Yup from "yup";
import { AssetObjectType } from "./assetsConfig";
import { noop } from "lodash";
import { IFormField, ISelectOption } from "../interfaces/form";
import { campaignSearchItems } from "./campaignConfig";

interface ISolutionAddEditFormConfigObj {
  title?: string;
  editTitle?: string;
  object?: AssetObjectType;
  name?: string;
  formFields: IFormField[];
}

export const solutionCreateFormVisibleFields = [
  "logo",
  "opportunity",
  "rel_params",
];
export const solutionEditFormVisibleFields = ["logo", "title"];

export const solutionSidebarHiddenAssets = [
  AssetObjectType.DISCOVERY_QUESTION,
  AssetObjectType.OBJECTION,
];

export const libraryModalSearchHiddenItems = [
  ...solutionSidebarHiddenAssets,
  "solution",
  "relationship_parameter",
  ...campaignSearchItems,
];

export const solutionSearchHiddenItems = [
  ...solutionSidebarHiddenAssets,
  AssetObjectType.GENERIC_RESOURCE,
  "relationship_parameter",
  ...campaignSearchItems,
];

export interface IFormFieldStructure {
  title?: string;
  fields: string[];
  fieldContainerClass?: string;
  fieldWrapperClass?: string;
}

export const solutionAddEditFormStructure: IFormFieldStructure[] = [
  {
    fieldContainerClass: "flex logo-opportunity",
    fields: ["logo", "opportunity"],
  },
  {
    fields: ["rel_params", "notes"],
  },
  {
    fields: ["title", "template"],
  },
  {
    fields: ["AE", "buyer"],
    fieldContainerClass: "buyer-deal-team",
    fieldWrapperClass: "each-deal-team",
  },
];

export const solutionEditFormStructure: IFormFieldStructure[] = [
  {
    fieldContainerClass: "flex logo-opportunity",
    fields: ["logo", "title"],
  },
];

export const SOLUTION_CREATE_FORM_HIDDEN_FIELDS: string[] = [];

export const solutionAddEditFormConfig: ISolutionAddEditFormConfigObj = {
  title: "Create new",
  editTitle: "Edit pitch",
  formFields: [
    {
      label: "",
      type: FormFieldType.ICON_UPLOAD,
      name: "logo",
      modelField: "logo",
      writeField: "logo",
      fieldData: {
        initialValue: "",
        fieldType: "compact",
        browseFileButtonLabel: "Add logo",
        changeFileButtonLabel: "",
        removeFileButtonLabel: "",
      },
      validation: Yup.mixed().test(
        "isSupportedImageFileType",
        "This image type is not supported. Please add a valid .png, .jpg, .bmp, or .ico image.",
        (value, context) => {
          const getExtension = (name: string) => {
            return name.substring(name.lastIndexOf(".") + 1);
          };
          if (!value || typeof value === "string") return true;
          return SUPPORTED_IMAGE_FILETYPES.includes(getExtension(value.name));
        }
      ),
    },
    {
      label: "Select Opportunity",
      type: FormFieldType.SELECT,
      name: "opportunity",
      modelField: "opportunity",
      validation: Yup.object({
        label: Yup.string().max(100).required("Opportunity name is required"),
      })
        .required("Opportunity is required")
        .nullable(),
      fieldData: {
        initialValue: "",
        placeholder: "Write opportunity name to get options",
        isCreatable: true,
        createNewLabel: "Create new opportunity",
        options: [],
        selected: [],
        onChange: noop,
      },
    },
    {
      label: "What we heard",
      type: FormFieldType.TEXT_AREA,
      name: "notes",
      modelField: "notes",
      validation: Yup.string().nullable(),
      fieldData: {
        initialValue: "",
        placeholder: "Enter text here",
      },
    },
    {
      label: "Pitch title",
      type: FormFieldType.TEXT,
      name: "title",
      modelField: "title",
      validation: Yup.string().max(255).required("Pitch title is required"),
      fieldData: {
        initialValue: "",
        placeholder: "Enter text here",
      },
    },
    {
      label: "Deal team",
      type: FormFieldType.TEXT,
      name: "AE",
      modelField: "AE",
      validation: Yup.string().max(100),
      fieldData: {
        initialValue: "",
        placeholder: "Enter text here",
      },
    },
    {
      label: "Buying team",
      type: FormFieldType.TEXT,
      name: "buyer",
      modelField: "buyer",
      validation: Yup.string().max(100),
      fieldData: {
        initialValue: "",
        placeholder: "Enter text here",
      },
    },
  ],
};

interface ISolutionAssetAddEditFormFieldProps {
  label: string;
  type: FormFieldType;
  name: string;
  modelField?: string;
  alternateModelField?: string;
  writeField?: string;
  validation?: any;
  isRichText?: boolean;
  fieldData?: {
    initialValue: any;
    placeholder?: string;
    options?: ISelectOption[];
    isCreatable?: boolean;
    createNewLabel?: string;
    selected?: ISelectOption[];
    browseFileButtonLabel?: string; // File input 'Browse' button name
    changeFileButtonLabel?: string; // File input 'Choose different' button name
    removeFileButtonLabel?: string; // File input 'Remove' button name
  };
}

export interface ISolutionAssetAddEditFormConfig {
  title?: string;
  editTitle?: string;
  name?: string;
  formFields: ISolutionAssetAddEditFormFieldProps[];
}

export const solutionAssetAddEditFormConfig: ISolutionAssetAddEditFormConfig = {
  formFields: [
    {
      label: "Title",
      type: FormFieldType.TEXT,
      name: "title",
      modelField: "title",
      validation: Yup.string().required("Title is required"),
      fieldData: {
        initialValue: "",
        placeholder: "Enter text here",
      },
    },
    {
      label: "Description",
      type: FormFieldType.TEXT_AREA,
      name: "description_html",
      modelField: "description_html",
      alternateModelField: "description",
      isRichText: true,
      validation: Yup.string().nullable(),
      fieldData: {
        initialValue: "",
        placeholder: "Enter text here",
      },
    },
    // {
    //   label: "Upload documents as attachment",
    //   type: FormFieldType.FILE_SELECT_OR_UPLOAD,
    //   name: "documents",
    //   modelField: "documents",
    //   fieldData: {
    //     initialValue: [],
    //     browseFileButtonLabel: "Click to choose file",
    //     changeFileButtonLabel: "Choose different",
    //     removeFileButtonLabel: "Remove",
    //   },
    // },
    // {
    //   label: "Add to library as well",
    //   type: FormFieldType.TAGS_WITH_TOGGLE,
    //   name: "relationship_parameters",
    //   modelField: "relationship_parameters",
    //   fieldData: {
    //     initialValue: [],
    //   },
    // },
  ],
};
