import styled from "@emotion/styled";

export const Header = styled.header`
  display: flex;
  justify-content: center;
  margin-top: 5.3rem;
  .brand {
    width: 17.7rem;
    height: 6.7rem;
  }
`;

export const Layout = styled.div`
  .input-group {
    margin-bottom: 1.8rem;
  }
  .input-label {
    margin-left: 0.5rem;
  }
  .customize-account-next-button {
    margin-top: 2.4rem;
    cursor: pointer;
    font-size: 1.6rem;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GatherUserInfoTitle = styled.h2`
  font-size: 3.9rem;
  font-weight: 800;
  margin-top: 4.5rem;
`;

export const Input = styled.input`
  width: 100%;
  display: inline-block;
  padding: 1.1rem 1.8rem;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  font-size: 1.6rem;
`;

export const Button = styled.button`
  width: 100%;
  background: #cccccc;
  border-radius: 7px;
  padding: 1.1rem;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Step = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  color: #808080;
  margin: 1.8rem 0 3.6rem;
`;

export const CustomizeAccountForm = styled.form`
  width: 38.4rem;
  .settings-dropdown-input-group {
    width: 38.4rem;
    font-size: 1.6rem;
    color: #808080;
  }
  .display {
    padding: 1.1rem 2rem;
    border: 1px solid #a6a6a6;
  }
`;
