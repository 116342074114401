import { useContext } from "react";
import appLogo from "../../../assets/icons/appLogo.png";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "../../../utils/history";
import axiosInstance from "../../../api/axios";
import { AuthContext } from "../../../context";
import {
  Header,
  ButtonWrapper,
  Content,
  ErrorText,
  Input,
  Title,
} from "./styled";

import { identifyUser, trackUserAction } from "../../../analytics";
import { storeUserInfoAndAuthenticationDetails } from "../../../helpers";
import { appConfig, userActivityEvents } from "../../../config";

const Login = () => {
  const { authDispatch } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      const credentials = values;
      axiosInstance
        .post("/auth/token/", { ...credentials })
        .then((res) => {
          const tokenObj = res.data;
          localStorage.setItem("access", tokenObj.access);
          localStorage.setItem("refresh", tokenObj.refresh);
          localStorage.setItem("teamId", tokenObj.team.id);

          identifyUser(res.data.user, res.data.team.id);
          storeUserInfoAndAuthenticationDetails(authDispatch, res.data, () => {
            localStorage.setItem("loggedInUser", JSON.stringify(res.data));
            history.push("/");
          });
        })
        .catch((err) => {
          trackUserAction(userActivityEvents.SIGNIN__ERROR, { error: err });
          console.error(err);
          toast.error("Credentials are invalid");
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
          history.push("/");
        });
    },
  });

  return (
    <>
      <Header>
        <img src={appLogo} className="brand" alt={appConfig.appName} />
      </Header>
      <Content>
        <Title>Login</Title>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="username"
            placeholder="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <ErrorText>{formik.errors.username}</ErrorText>
          ) : null}
          <Input
            id="password"
            type="password"
            placeholder="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <ErrorText>{formik.errors.password}</ErrorText>
          ) : null}
          <ButtonWrapper>
            <button type="submit" className="button">
              Submit
            </button>
            <button
              className="button"
              type="button"
              onClick={() => formik.resetForm()}
            >
              Reset
            </button>
          </ButtonWrapper>
        </form>
      </Content>
    </>
  );
};

export default Login;
