import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, GatherUserInfoTitle as Title } from "../common";
import HeaderBand from "../HeaderBand";

const Content = styled.div`
  margin-top: 14.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title {
    margin: 0 0 1.8rem;
  }
  .text {
    font-size: 1.8rem;
    line-height: 150%;
  }
`;

const MagicLinkSent = () => {
  const location = useLocation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const {
      // @ts-ignore
      state: { email },
    } = location;
    if (email) {
      setEmail(email);
    }
  }, []);

  return (
    <Layout>
      <HeaderBand />
      <Content>
        <Title className="title">Check your email for the magic link</Title>
        <p className="text">
          We’ve sent an email with a magic link to {email}. Click on it to get
          started.
        </p>
      </Content>
    </Layout>
  );
};

export default MagicLinkSent;
