import styled from "@emotion/styled";

export const AccordionHeader = styled.div`
  font-family: var(--font-lexend);
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
  color: #1e293b;
  padding: 2rem 0rem 2rem 0rem;
`;

export const AccordionContainer = styled.div`
  padding: 1rem;
  font-family: var(--font-lexend);
  .accordion__item {
    padding: 1.5rem 3rem 1rem 1rem;
    border: #f3f6fa solid 1px;
    border-radius: 5px;
    margin-bottom: 1rem;
    line-height: 2rem;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      background: #f1f5f955;
      transition-delay: all 200ms ease-in-out;
    }
  }

  .accordion-button {
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .accordion-panel {
    color: #64748b;
    font-size: 1.3rem;
    line-height: 2rem;
    padding-right: 2rem;
    font-weight: 300;
  }

  .right_arrow {
    padding: 0.3rem 0rem 0rem 1rem;
  }
`;

export const DownArrow = styled.img<{ isExpanded: boolean }>`
  transform: ${(props) => (props.isExpanded ? "none" : "rotate(-90deg)")};
`;

export const AccordionTitleText = styled.div`
  color: #475569;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 500;
`;

export const TagAndButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.6rem;
`;

export const AccordionTagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const DocumentsContainer = styled(AccordionTagsContainer)``;

export const AccordionFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
