import { useQuery } from "react-query";
import { IAssetsMetadata } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getAllMetadata = async () => {
  const response = await axiosInstance.get("/assets/metadata/");
  return response.data;
};

const useGetAssetMetadata = () => {
  const response = useQuery<IAssetsMetadata>(
    [queryKeys.metaData],
    () => getAllMetadata(),
    {
      refetchOnWindowFocus: false,
      staleTime: 30 * 60 * 1000,
    }
  );
  return { ...response };
};

export default useGetAssetMetadata;
