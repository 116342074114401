import { useRef, useState } from "react";
import { cloneDeep, noop } from "lodash";
import arrowDown from "../../../../../assets/icons/downArrow.svg";
import filterSearch from "../../../../../assets/icons/filterSearch.svg";
import {
  DropdownLabel,
  DropdownListWrapper,
  DropdownSelectedItem,
  DropdownViewable,
  DropdownWrapper,
  DropdownList,
  DropdownListItem,
  ListItemWrapper,
  ListItemCheckbox,
  ListItemLabel,
  DropdownSearch,
} from "./styled";
import { useOnClickOutside } from "../../../../../hooks";
import {
  IDropdownCollectionItem,
  IStateOfAllDropdowns,
} from "../../../../../interfaces/dropdown";
import { trackUserAction } from "../../../../../analytics";
import { userActivityEvents } from "../../../../../config";

interface IDisplayDropdownList {
  list: IDropdownCollectionItem[];
  handleCheckboxSelection: Function;
}

function DisplayDropdownList({
  list,
  handleCheckboxSelection,
}: IDisplayDropdownList) {
  return (
    <DropdownList>
      {list?.map((item, i) => {
        return (
          <DropdownListItem
            key={i}
            onClick={() => handleCheckboxSelection(item)}
            className={item?.isSelectable === false ? "disabled" : ""}
          >
            <ListItemWrapper>
              <ListItemCheckbox
                type="checkbox"
                checked={item?.isSelected}
                onChange={noop}
              />
              <ListItemLabel>{item.label}</ListItemLabel>
            </ListItemWrapper>
          </DropdownListItem>
        );
      })}
    </DropdownList>
  );
}

interface IDropdownWithCheckbox {
  listOfItemsToBeDisplayed: IDropdownCollectionItem[] | undefined;
  currentDropdownIndex: number;
  stateOfAllDropdowns: IStateOfAllDropdowns;
  setStateOfAllDropdowns: React.Dispatch<
    React.SetStateAction<IStateOfAllDropdowns | undefined>
  >;
  label: string;
  width?: string; //in rem
  handleChange?: Function;
  background: any;
  color: string;
  selectColor: string;
  dropDownBgColor: any;
  borderColor?: string;
  initialDropDownValue?: boolean;
  showLabel?: boolean;
}

const DropdownWithCheckbox = ({
  listOfItemsToBeDisplayed,
  currentDropdownIndex,
  stateOfAllDropdowns,
  setStateOfAllDropdowns,
  label,
  width = "20%",
  handleChange,
  background,
  color,
  selectColor,
  dropDownBgColor,
  borderColor,
  initialDropDownValue = false,
  showLabel = true,
}: IDropdownWithCheckbox) => {
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));
  const stateOfCurrentDropdown = stateOfAllDropdowns[currentDropdownIndex];

  const [isOpen, setIsOpen] = useState(false);

  const listOfSelected = stateOfCurrentDropdown?.selected || [];
  const currentDropdownSelectedLength = listOfSelected.length;
  const displaySelectedItem = !!currentDropdownSelectedLength ? (
    currentDropdownSelectedLength === 1 ? (
      <div className="selected-item-label">{listOfSelected[0].label}</div>
    ) : (
      <div>
        <span>
          {listOfSelected[0].label.slice(0, 14)}
          {listOfSelected[0].label.length > 14 && "..."}
        </span>
        <span className="more">+{currentDropdownSelectedLength - 1} more</span>{" "}
      </div>
    )
  ) : initialDropDownValue ? (
    "Select option"
  ) : (
    "All"
  );

  function handleCheckboxSelection(item: IDropdownCollectionItem) {
    setStateOfAllDropdowns((state) => {
      // @ts-ignore
      const currentDropdownState = state[currentDropdownIndex];
      let newSelected = cloneDeep(currentDropdownState.selected);
      let newSuggested = cloneDeep(currentDropdownState.suggested);
      if (item.isSelected) {
        const indexOfItemClicked = newSelected.findIndex(
          (listItem) => listItem.value === item.value
        );
        const itemToRemove = newSelected[indexOfItemClicked];
        newSelected.splice(indexOfItemClicked, 1);
        newSuggested.push({ ...itemToRemove, isSelected: false });
      } else {
        const indexOfItemClicked = newSuggested.findIndex(
          (listItem) => listItem.value === item.value
        );
        let itemClicked = newSuggested[indexOfItemClicked];
        newSuggested.splice(indexOfItemClicked, 1);
        newSelected.push({
          ...itemClicked,
          isSelected: true,
        });
      }
      newSuggested.sort((a: any, b: any) => a.value - b.value);
      const selectedOptions = Object.values({
        ...state,
        [currentDropdownIndex]: {
          ...currentDropdownState,
          selected: newSelected,
          suggested: newSuggested,
        },
      })
        .map((dropdownObj: any) => dropdownObj.selected)
        .flat();
      trackUserAction(userActivityEvents.LIBRARY__FILTERS_APPLIED, {
        appliedFilters: selectedOptions,
      });
      // Callback for selected values
      handleChange && handleChange(newSelected);
      return {
        ...state,
        [currentDropdownIndex]: {
          ...currentDropdownState,
          selected: newSelected,
          suggested: newSuggested,
        },
      };
    });
  }

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (listOfItemsToBeDisplayed) {
      const newSuggested = listOfItemsToBeDisplayed.filter(
        (listItem) =>
          !stateOfCurrentDropdown?.selected.some(
            (item) => listItem.value === item.value
          )
      );
      setStateOfAllDropdowns((state) => {
        return {
          ...state,
          [currentDropdownIndex]: {
            //@ts-ignore
            ...state[currentDropdownIndex],
            search: e.target.value,
            suggested: newSuggested.filter((listItem) =>
              listItem.label
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
            ),
          },
        };
      });
    }
  };

  return (
    <DropdownWrapper
      background={background}
      //@ts-ignore
      width={width}
      ref={dropdownRef}
      borderColor={borderColor}
    >
      <DropdownViewable
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>
          {showLabel && <DropdownLabel color={color}>{label}</DropdownLabel>}
          <DropdownSelectedItem
            selectColor={selectColor}
            className="dropdown-selected-item"
          >
            {displaySelectedItem}
          </DropdownSelectedItem>
        </div>
        <img src={arrowDown} alt="down arrow" />
      </DropdownViewable>

      <DropdownListWrapper
        dropDownBgColor={dropDownBgColor}
        //@ts-ignore
        isOpen={isOpen}
      >
        <h3 className="options-label">Select {label}</h3>
        <div className="options-search-box">
          <img className="filter-search-icon" src={filterSearch} alt="Search" />
          <DropdownSearch
            value={stateOfCurrentDropdown?.search}
            onChange={handleSearchTextChange}
            placeholder={`Filter by ${label}`}
          />
        </div>

        <div className="options">
          <DisplayDropdownList
            list={stateOfCurrentDropdown?.selected}
            handleCheckboxSelection={handleCheckboxSelection}
          />
          <DisplayDropdownList
            list={stateOfCurrentDropdown?.suggested}
            handleCheckboxSelection={handleCheckboxSelection}
          />
        </div>
      </DropdownListWrapper>
    </DropdownWrapper>
  );
};

export default DropdownWithCheckbox;
