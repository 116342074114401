import { useContext, useEffect, useState } from "react";
import { cloneDeep, noop } from "lodash";
import { DropResult } from "react-beautiful-dnd";
import {
  SubsectionTab,
  SidebarSubsectionContainer,
  SubsectionTabLabel,
  TabDragAndDropWrapper,
} from "./styled";
import Droppable, { Draggable } from "../DragAndDrop";
import {
  ISidebar2SubsectionDataObj,
  Sidebar2ThemeMode,
} from "../../../../../interfaces/solution";
import { AuthContext } from "../../../../../context";
import dragAndDropIcon from "../../../../../assets/icons/listTag.svg";
import { useTheme } from "@emotion/react";
import { ITheme } from "../../../../../interfaces/theme";

interface ISidebar2Subsection {
  data: ISidebar2SubsectionDataObj[];
  onSubsectionTabDragEnd?: Function;
  defaultSubsectionTabIndex?: number;
  theme?: Sidebar2ThemeMode;
}
const Sidebar2Subsection = ({
  theme,
  data,
  onSubsectionTabDragEnd = noop,
  defaultSubsectionTabIndex = 0,
}: ISidebar2Subsection) => {
  const { authState } = useContext(AuthContext);
  const [activeSubsectionTabIndex, setActiveSubsectionTabIndex] =
    useState<number>(defaultSubsectionTabIndex);
  const [subsectionsData, setSubSectionsData] =
    useState<ISidebar2SubsectionDataObj[]>(data);
  const styleTheme: ITheme = useTheme();

  const subsectionTabs = document.getElementsByClassName(
    "sidebar-subsection-tab"
  );
  const isShared = authState.isGuestUser;

  useEffect(() => {
    setActiveSubsectionTabIndex(defaultSubsectionTabIndex);
  }, [defaultSubsectionTabIndex]);

  useEffect(() => {
    setSubSectionsData(data);
  }, [data]);

  const removeActiveSubsectionTabs = (subsectionId: string = "") => {
    Array.from(subsectionTabs).forEach((subsectionTabElement) => {
      if (subsectionTabElement.id !== subsectionId)
        subsectionTabElement.classList.remove("active");
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const updatedSubsectionData = cloneDeep(subsectionsData);
    const [reOrderedItem] = updatedSubsectionData.splice(
      result.source.index,
      1
    );
    if (reOrderedItem && result.destination) {
      updatedSubsectionData.splice(result.destination.index, 0, reOrderedItem);
      setSubSectionsData(updatedSubsectionData);
      onSubsectionTabDragEnd(
        result.draggableId,
        result.source.index,
        result.destination.index
      );
      setActiveSubsectionTabIndex(result.destination.index);
    }
  };

  if (!data.length) return <></>;

  return (
    <SidebarSubsectionContainer>
      <Droppable
        onDragEnd={handleDragEnd}
        droppableId={"droppable-solution-asset-Items"}
      >
        {subsectionsData.map((subSectionData, index: number) => (
          <Draggable
            key={subSectionData.key}
            draggableId={subSectionData.key}
            index={index}
          >
            {(provided) => (
              <SubsectionTab
                theme={theme}
                onClick={() => {
                  removeActiveSubsectionTabs(subSectionData.key);
                  subSectionData?.onClick(subSectionData.key, index);
                  setActiveSubsectionTabIndex(index);
                }}
                id={
                  theme === Sidebar2ThemeMode.LIGHT_MODE
                    ? `${subSectionData.key}`
                    : ""
                }
                className={
                  theme === Sidebar2ThemeMode.LIGHT_MODE
                    ? `sidebar-subsection-tab
                  ${activeSubsectionTabIndex === index ? "active" : ""}`
                    : ""
                }
              >
                <TabDragAndDropWrapper
                  {...provided.dragHandleProps}
                  hidden={!subSectionData.isReorderable}
                >
                  <img src={dragAndDropIcon} alt="drag-and-drop-icon"></img>
                </TabDragAndDropWrapper>
                <SubsectionTabLabel
                  isReorderable={subSectionData.isReorderable}
                  theme={theme}
                  id={
                    theme === Sidebar2ThemeMode.DARK_MODE
                      ? `${subSectionData.key}`
                      : ""
                  }
                  className={
                    theme === Sidebar2ThemeMode.DARK_MODE
                      ? `sidebar-subsection-tab-label
                  ${activeSubsectionTabIndex === index ? "active" : ""}`
                      : "sidebar-subsection-tab-label light-mode-tab-label"
                  }
                  style={
                    isShared
                      ? styleTheme?.sharedView?.style?.body?.content?.subSection
                          ?.navTitle
                      : {}
                  }
                >
                  {subSectionData.label}
                </SubsectionTabLabel>
              </SubsectionTab>
            )}
          </Draggable>
        ))}
      </Droppable>
    </SidebarSubsectionContainer>
  );
};

export default Sidebar2Subsection;
