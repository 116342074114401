import { useQuery } from "react-query";
import { IRelationshipParamerter } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getRelationshipParameters = async () => {
  const response = await axiosInstance.get("assets/relationship-parameters/");
  return response.data;
};

interface IGetRelationshipParameterRes {
  has_more: boolean;
  count: number;
  data: IRelationshipParamerter[];
}
const useGetRelationshipParameters = () => {
  const response = useQuery<IGetRelationshipParameterRes, Error>(
    [queryKeys.relationshipParameters],
    () => getRelationshipParameters(),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  return { ...response };
};

export default useGetRelationshipParameters;
