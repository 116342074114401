import styled from "@emotion/styled";
import { useOnClickOutside } from "../../../../../hooks";
import { IOption } from "../../../../../interfaces/common";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect, useRef, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";

interface ISettingsDropdownWrapper {
  width: number;
}

const SettingsDropdownWrapper = styled.div`
  width: ${({ width }: ISettingsDropdownWrapper) => `${width} rem`};
  position: relative;

  .show-menu {
    display: initial;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  .fade-menu {
    display: none;
    opacity: 0;
  }
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    border-top: 4px dashed;
    border-top: 4px solid #aaa;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    transition: transform 0.4s ease-in-out;
  }
  .rotate-180 {
    transform: rotate(-180deg);
  }
  .scrollbar {
    height: 100%;
    max-height: 14rem;
    .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
      min-height: 30px;
      max-height: 30px;
    }
  }
`;

interface IDisplay {
  isOpen: boolean;
  isDisabled: boolean;
}

const Display = styled.div`
  border-radius: 0.4rem;
  border-bottom-left-radius: ${({ isOpen }: IDisplay) =>
    isOpen ? 0 : "0.4rem"};
  border-bottom-right-radius: ${({ isOpen }: IDisplay) =>
    isOpen ? 0 : "0.4rem"};
  border: 1px solid #e0e0e0;
  border-bottom: ${({ isOpen }: IDisplay) =>
    isOpen ? "1px solid transparent" : "1px solid #e0e0e0"};

  box-shadow: ${({ isOpen }: IDisplay) =>
    isOpen ? 0 : "0 1px 4px 0px rgb(0 0 0 / 5%)"};
  padding: 1rem 2rem;
  cursor: ${({ isDisabled }: IDisplay) =>
    isDisabled ? "not-allowed" : "pointer"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.4s ease-in-out;
`;

const DisplayText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgb(0 0 0 / 5%);
  z-index: 10;
  background: white;
  padding: 0 1rem 1rem;
`;

const DropdownMenuDisplay = styled.div`
  border: 0;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownMenuSearchWrapper = styled.div`
  padding: 0.5rem 0;
  position: relative;
`;

const SearchInput = styled.input`
  background: #f4f4f4;
  width: 100%;
  border: 0;
  border-radius: 0.6rem;
  padding: 1rem;
`;

const DropdownMenuListItem = styled.div`
  padding: 1rem;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  &:hover,
  &.selected {
    color: #66676b;
    background-color: rgba(102, 103, 107, 0.07);
    border-radius: 0.4rem;
  }
`;

const NoItemsOnSearch = styled.div`
  padding: 1rem;
  cursor: pointer;
  color: #66676b;
  background-color: rgba(102, 103, 107, 0.07);
  border-radius: 0.4rem;
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 1rem;
  transform: translateY(-50%);
  color: #a0a0a0;
  font-size: 1.8rem;
`;

interface ISettingsDropdown {
  itemsToDisplay: IOption[];
  value: string;
  title: string;
  handleChange?: Function;
  isDisabled?: boolean;
  width?: number; //in rem
}

const SettingsDropdown = ({
  itemsToDisplay,
  value,
  title,
  handleChange,
  isDisabled = false,
  width = 40,
}: ISettingsDropdown) => {
  //Todo : need to be lifted up - will receive as props.
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(value);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  let itemsList = search
    ? itemsToDisplay.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase())
      )
    : itemsToDisplay;

  const noItemsOnSearch = search && !itemsList.length;
  const labelToDisplay = itemsToDisplay.find(
    (item) => item.value === selected
  )?.label;

  return (
    <SettingsDropdownWrapper width={width} ref={dropdownRef}>
      <Display
        className="display"
        isDisabled={isDisabled}
        isOpen={isOpen}
        onClick={() => {
          !isDisabled && setIsOpen((curr) => !curr);
          setSearch("");
        }}
      >
        <DisplayText>
          {isDisabled || !selected ? `No ${title} selected` : labelToDisplay}
        </DisplayText>
        <span className={`caret ${isOpen && "rotate-180"}`}></span>
      </Display>
      <DropdownMenu className={isOpen ? "show-menu" : "fade-menu"}>
        <DropdownMenuDisplay
          onClick={() => {
            setIsOpen((curr) => !curr);
            setSearch("");
          }}
        >
          <DisplayText>
            {itemsToDisplay.find((item) => item.value === selected)?.label}
          </DisplayText>
          <span className={`caret ${isOpen && "rotate-180"}`}></span>
        </DropdownMenuDisplay>
        <DropdownMenuSearchWrapper>
          <SearchInput
            value={search}
            autoFocus
            onChange={(e) => setSearch(e.target.value)}
          />
          <Icon>
            <IoIosSearch />
          </Icon>
        </DropdownMenuSearchWrapper>
        <OverlayScrollbarsComponent
          options={{
            scrollbars: {
              autoHide: "leave",
              autoHideDelay: 200,
            },
          }}
          className="scrollbar"
        >
          {noItemsOnSearch ? (
            <NoItemsOnSearch
              onClick={() => {
                setIsOpen(false);
                setSearch("");
              }}
            >
              No results matched {`"${search}"`}
            </NoItemsOnSearch>
          ) : (
            <>
              {itemsList.map((item) => {
                const active = selected === item.value;
                return (
                  <DropdownMenuListItem
                    key={item.label}
                    className={`${active && "selected"}`}
                    onClick={() => {
                      setSelected(item.value);
                      setIsOpen(false);
                      handleChange && handleChange(item);
                    }}
                  >
                    <p>{item.label}</p>
                    {active && (
                      <Icon>
                        <FiCheck />
                      </Icon>
                    )}
                  </DropdownMenuListItem>
                );
              })}
            </>
          )}
        </OverlayScrollbarsComponent>
      </DropdownMenu>
    </SettingsDropdownWrapper>
  );
};

export default SettingsDropdown;
