import { SearchBoxContainer, SearchIcon, TextInputField } from "./styled";

interface ISearchBoxProps {
  value?: string;
  placeholder?: string;
  onChange?: Function;
}
const SearchBox = ({ value, placeholder, onChange }: ISearchBoxProps) => {
  return (
    <SearchBoxContainer>
      <SearchIcon></SearchIcon>
      <TextInputField>
        <input
          type={"text"}
          value={value}
          className="SearchBox__text-input"
          placeholder={placeholder || "Search.."}
          onChange={(e) => onChange && onChange(e)}
        ></input>
      </TextInputField>
    </SearchBoxContainer>
  );
};

export default SearchBox;
