import noop from "lodash/noop";
import { IDropdownOption } from "../../../../../interfaces/dropdown";
import {
  DropdownList,
  DropdownListItem,
  ListItemCheckbox,
  ListItemLabel,
  ListItemWrapper,
} from "../DropdownCheckboxedOptions/styled";

interface DropdownMultiSelectOptionsProps {
  options: IDropdownOption[];
  onOptionClick: (option: IDropdownOption) => void;
  isMultiSelect?: boolean;
}

function DropdownMultiSelectOptions({
  options,
  onOptionClick,
  isMultiSelect = true,
}: DropdownMultiSelectOptionsProps) {
  return (
    <DropdownList>
      {options.map((option) => {
        return (
          <DropdownListItem
            key={option.value}
            onClick={() => onOptionClick(option)}
            className={option?.isSelectable === false ? "disabled" : ""}
          >
            <ListItemWrapper>
              <ListItemCheckbox
                type={isMultiSelect ? "checkbox" : "radio"}
                checked={Boolean(option?.isSelected)}
                onChange={noop}
              />
              <ListItemLabel>{option.label}</ListItemLabel>
            </ListItemWrapper>
          </DropdownListItem>
        );
      })}
    </DropdownList>
  );
}

export default DropdownMultiSelectOptions;
