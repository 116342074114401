import styled from "@emotion/styled";

export const Header = styled.header`
  width: 100%;
  .brand {
    width: 17.7rem;
    height: 6.7rem;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  margin-top: 12rem;
  padding: 2.4rem;
  width: 32rem;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  .app-icon {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 1rem;
  }
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  padding: 0.75rem 1rem;
  font-size: 1.4rem;
  display: inline-block;
  width: 100%;
  font-family: "Lato", sans-serif;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  border: 1px solid #c3c3c3;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  .button {
    padding: 0.5rem 2.5rem;
    margin: 0 0.5rem;
    cursor: pointer;
    border: 1px solid #c3c3c3;
    border-radius: 0.2rem;
    transition: all 200ms ease-in-out;
    &:hover {
      background: #858585;
      color: #f8faff;
      border: 1px solid #858585;
    }
  }
`;

export const ErrorText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #ff4c13;
  margin-bottom: 1rem;
`;
