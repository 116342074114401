import React, { useContext, useEffect } from "react";
import { FallBack } from "../../components";
import { AuthContext } from "../../context";
import { logout } from "../../helpers";
import { history } from "../../utils/history";

export default function Logout() {
  const { authDispatch } = useContext(AuthContext);
  useEffect(() => {
    logout(authDispatch);
    history.replace("/");
  }, []);
  return <FallBack fullPage />;
}
