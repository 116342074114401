import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import { FallBack } from "../../compositional";
import { history } from "../../../utils/history";
import { AppName } from "../../../constants";

function SalesforceOauth() {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code) {
      axiosInstance
        .post("/salesforce/login/", { code })
        .then((res) => {
          if (res.status === 200) {
            history.replace(
              `/user/settings/integrations/${AppName.SALESFORCE}?tab=import_data&type=success&message=Click on Sync with ${AppName.SALESFORCE} button to import data from ${AppName.SALESFORCE}`
            );
          } else {
            const error = res.data.error;
            history.replace(
              `/user/settings/integrations/${AppName.SALESFORCE}?tab=import_data&type=error&message=${error?.details}`
            );
          }
        })
        .catch((err) => {
          history.replace(
            `/user/settings/integrations/${AppName.SALESFORCE}?tab=import_data&type=error&message=server_error`
          );
        });
    } else {
      history.replace(
        `/user/settings/integrations/${AppName.SALESFORCE}?tab=import_data&type=error&message=authentication_error`
      );
    }
  }, []);

  return <FallBack />;
}

export default SalesforceOauth;
