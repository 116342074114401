import { css } from "@emotion/react";
import styled from "@emotion/styled";
import ReactSelect from "react-select";
import CreatableReactSelect from "react-select/creatable";
import { FormFieldWrapper } from "../styled";

interface ICustomSelectProps {
  isFocused?: boolean;
}

export const SelectFieldWrapper = styled(FormFieldWrapper)``;

const baseSelectStyle = (props: ICustomSelectProps) => css`
  min-width: 13rem;
  width: max-content;

  .Select__control {
    min-height: fit-content;
    min-width: 30rem;
    border: none;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 1.8rem;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }

  .Select__control--is-focused {
    box-shadow: none;
  }

  .Select__menu {
    min-width: 30rem;
    width: 30rem;
    font-family: var(--font-lexend);
    color: #475569;
    box-shadow: 0rem 1.6rem 3rem rgba(0, 0, 0, 0.1);
    border: 0.1rem solid #dae1e9;
    margin-top: 1.4rem;
    margin-left: -1.3rem;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 400;
  }

  .Select__menu-list {
    max-height: 18rem;
    overflow: auto;
  }

  .Select__value-container {
    padding: 0;
  }

  .Select__option {
    padding: 0.8rem 1.6rem;
    :hover {
      background: var(--dropdown-background);
    }
  }

  .Select__option--is-focused {
    background: var(--dropdown-background);
  }

  .Select__option--is-selected {
    background: var(--dropdown-background);
    color: #475569;
    font-weight: 600;
  }

  /* .Select__indicators {
    visibility: ${props.isFocused ? "hidden" : "visible"};
  } */

  .Select__indicator {
    font-size: 0.8rem;
  }

  .Select__placeholder {
    color: #a1a1aa;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2rem;
  }

  .Select__single-value {
    font-size: 1.3rem;
    font-weight: 400;
    color: #0f172a;
  }

  .value-prefix {
    color: #6b7280;
    font-weight: 400;
  }
`;

export const Select = styled(ReactSelect)<ICustomSelectProps>`
  ${baseSelectStyle}
`;

export const CreatableSelect = styled(CreatableReactSelect)<ICustomSelectProps>`
  ${baseSelectStyle}
`;

export const CreateNewOptionWrapper = styled.div`
  span {
    font-weight: 600;
  }
  #create-btn {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 400;
    padding: 0.3rem 1.2rem 0.5rem 1.2rem;
    background: #f1f5f9;
    border-radius: 0.9rem;
    border: 0.05rem solid #cbd5e1;
    cursor: pointer;
  }
  .Select__option {
    padding: 0.8rem 1.6rem;
    :hover {
      background: none;
    }
  }
  .Select__option--is-focused {
    background: white;
  }
`;
