import { useQuery } from "react-query";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { IGenericResourceType } from "../../interfaces/assets";

interface IGetGenericResourceTypesRes {
  has_more: boolean;
  count: number;
  data: IGenericResourceType[];
}

const getGenericResourceTypes = async () => {
  const response = await axiosInstance.get(`/assets/generic-resources/types/`);
  return response.data;
};
const useGetGenericResourceTypes = () => {
  const response = useQuery<IGetGenericResourceTypesRes, Error>(
    [queryKeys.genericResourceTypes],
    () => getGenericResourceTypes(),
    { refetchOnWindowFocus: false }
  );
  return response;
};

export default useGetGenericResourceTypes;
