import { useContext, useState } from "react";
import {
  Accordion as ReactAccordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { trackUserAction } from "../../../../../analytics";
import downArrow from "../../../../../assets/icons/downArrow.svg";
import { userActivityEvents } from "../../../../../config";
import { AssetsContext, ContentAreaContext } from "../../../../../context";
import {
  getFileNameFromUrl,
  getAbsoluteFileUrlFromPath,
} from "../../../../../helpers";
import { IAccordionData, IDocumentObject } from "../../../../../interfaces";
import { history } from "../../../../../utils/history";
import { ViewFileButton } from "../Buttons";
import { DocumentViewerModal } from "../Modals";
import { Tag } from "../Tags";
import { WidgetItem, WidgetToolbar } from "../WidgetToolbar";
import {
  AccordionContainer,
  AccordionFooter,
  AccordionHeader,
  AccordionTagsContainer,
  AccordionTitleText,
  DocumentsContainer,
  DownArrow,
  TagAndButtonContainer,
} from "./styled";

const Accordion = ({ items, title }: IAccordionData) => {
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const { setIsEdit, setEditItemId, activeTabSlug } = useContext(AssetsContext);
  const { setDeleteState } = useContext(ContentAreaContext);

  const getDocuments = (dataItem: any) => {
    return dataItem.documents
      .filter((documentObj: IDocumentObject) => documentObj.url)
      .map((documentObj: IDocumentObject) => {
        return (
          <ViewFileButton
            key={documentObj.id}
            filePath={documentObj.url}
            onClick={() => {
              setFileUrl(getAbsoluteFileUrlFromPath(documentObj.url));
              setShowModal(true);
              trackUserAction(
                userActivityEvents.LIBRARY__DOCUMENT_BUTTON_CLICKED,
                {
                  fileName: getFileNameFromUrl(documentObj.url),
                  assetName: dataItem.title,
                  assetType: dataItem.object,
                }
              );
            }}
          ></ViewFileButton>
        );
      });
  };

  return (
    <AccordionContainer>
      {title && <AccordionHeader>{title}</AccordionHeader>}
      <ReactAccordion allowMultipleExpanded={false} allowZeroExpanded={true}>
        {items.map((item, i) => {
          const isExpandable =
            item.title || item.description || !!item.tags?.length;
          return (
            <AccordionItem
              key={i}
              style={{ cursor: isExpandable ? "pointer" : "auto" }}
              onClick={() =>
                trackUserAction(userActivityEvents.LIBRARY__LIST_ITEM_CLICKED, {
                  id: item.id,
                  title: item.title,
                  type: title,
                })
              }
            >
              <AccordionItemHeading>
                <AccordionItemButton className="accordion-button">
                  <AccordionTitleText>{item.title}</AccordionTitleText>
                  <AccordionItemState>
                    {({ expanded }) => {
                      return (
                        isExpandable && (
                          <DownArrow
                            src={downArrow}
                            alt="Down Arrow"
                            isExpanded={!!expanded}
                          />
                        )
                      );
                    }}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              {isExpandable && (
                <AccordionItemPanel className="accordion-panel">
                  <AccordionItemButton>
                    <p>{item.description}</p>
                    <AccordionFooter>
                      <TagAndButtonContainer>
                        {!!item?.documents?.length && (
                          <DocumentsContainer>
                            {getDocuments(item)}
                          </DocumentsContainer>
                        )}
                        <AccordionTagsContainer>
                          {item.tags?.map((rpObject: any, i) => (
                            <Tag
                              key={rpObject.id}
                              label={rpObject.value}
                              toolTipText={rpObject.name}
                            />
                          ))}
                        </AccordionTagsContainer>
                      </TagAndButtonContainer>
                      <WidgetToolbar
                        style={{
                          alignSelf: "flex-end",
                        }}
                        config={[
                          {
                            type: WidgetItem.EDIT,
                            onClick: (e: MouseEvent) => {
                              e.stopPropagation();
                              setIsEdit(true);
                              setEditItemId(`${item.id}`);
                              history.push("/user/assets/");
                            },
                          },
                          {
                            type: WidgetItem.DELETE,
                            onClick: (e: MouseEvent) => {
                              e.stopPropagation();
                              setDeleteState({
                                isDelete: true,
                                assetItemId: item.id,
                                assetSlug: activeTabSlug,
                              });
                            },
                          },
                        ]}
                      ></WidgetToolbar>
                    </AccordionFooter>
                  </AccordionItemButton>
                </AccordionItemPanel>
              )}
            </AccordionItem>
          );
        })}
      </ReactAccordion>
      <DocumentViewerModal
        fileUrl={fileUrl}
        isOpen={showModal}
        onDismissal={() => setShowModal(false)}
      ></DocumentViewerModal>
    </AccordionContainer>
  );
};

export default Accordion;
