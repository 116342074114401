import "@reach/dialog/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Suspense, useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  AuthRoutes,
  FallBack,
  PrivateRoutes,
  PublicRoutes,
} from "./components";
import { AuthContext } from "./context";
import { ToastContainer } from "react-toastify";
import GuestRoutes from "./components/routes/GuestRoutes";
import { useSetAuthUser } from "./api/hooks/useSetAuthUser";
import AppErrorBoundary from "./components/compositional/ErrorBoundary/AppErrorBoundry";
import { ThemeProvider } from "@emotion/react";
import themes from "./themes";

const themeName: keyof typeof themes =
  (process.env.REACT_APP_THEME as keyof typeof themes) || "dark";
const theme = themeName ? themes[themeName] : themes.light;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 0,
      suspense: true,
    },
  },
});

function App() {
  return (
    <>
      <AppErrorBoundary>
        <AppComponent />
      </AppErrorBoundary>
    </>
  );
}

function AppComponent() {
  const { authState } = useContext(AuthContext);

  useSetAuthUser({ queryClient });
  return (
    <ThemeProvider theme={theme}>
      {authState.isLoggedIn ? (
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<FallBack fullPage />}>
            {authState.isGuestUser ? <GuestRoutes /> : <PrivateRoutes />}
          </Suspense>
        </QueryClientProvider>
      ) : (
        <AuthRoutes />
      )}
      <PublicRoutes />
      <ToastContainer
        autoClose={2500}
        position={"bottom-right"}
        style={{ width: "29rem" }}
      />
    </ThemeProvider>
  );
}
export default App;
