import styled from "@emotion/styled";

export const AppsContainer = styled.div``;

export const AppsTitle = styled.div`
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #1e293b;
  margin-bottom: 1.2rem;
`;

export const AppsCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const CardWithIcon = styled.div`
  width: 45rem;
  padding: 1.6rem;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  display: flex;
  column-gap: 1.6rem;
  cursor: pointer;
`;

export const CardIconContainer = styled.div``;

export const CardContent = styled.div`
  font-family: var(--font-lexend);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #1e293b;
  margin-bottom: 0.4rem;
`;

export const CardDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #8f95a0;
  margin-bottom: 1.6rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CardFooter = styled.div``;

export const CardButton = styled.div``;

export const StatusButtonContainer = styled.div`
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 2.6rem;
  padding: 0.5rem 1.2rem;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 0.7rem;
  position: relative;
  cursor: pointer;
  line-height: 150%;
  user-select: none;
  &.connected {
    background-color: rgba(21, 128, 61, 0.05);
    border-color: rgba(21, 128, 61, 0.5);
  }
  &.disconnected {
    background-color: #fffbeb;
    border-color: #fde68a;
  }
  &.error {
    background-color: #fef2f2;
    border-color: #fecaca;
  }
  .connected {
    color: #16a34a;
  }
  .disconnected {
    color: #d97706;
  }
  .error {
    color: #b91c1c;
  }
`;

export const StatusText = styled.div`
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: right;
  color: #475569;
  user-select: none;
`;

export const DropDownList = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.12);
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.8rem;
  width: max-content;
  &.dropdown-list {
    .disconnected {
      color: #374151;
    }
    .connected {
      color: #b91c1c;
    }
  }
`;

export const DropDownButtonItem = styled.div`
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  width: fit-content;
  padding: 0.8rem 1.2rem;
`;
