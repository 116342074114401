import { useQuery } from "react-query";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";
import { ISolutionAssetType } from "../../interfaces/solution";

interface IGetSolutionAssetTypesRes {
  has_more: boolean;
  count: number;
  data: ISolutionAssetType[];
}

const getSolutionAssetTypes = async (solutionId: string | number) => {
  if (!solutionId) return;
  const response = await axiosInstance.get(
    `/solutions/${solutionId}/assets/types/`
  );
  return response.data;
};

const useGetSolutionAssetTypes = (solutionId: string | number) => {
  const response = useQuery<IGetSolutionAssetTypesRes, Error>(
    [queryKeys.solutionAssetsType, { solutionId }],
    () => getSolutionAssetTypes(solutionId),
    {
      enabled: !!solutionId,
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetSolutionAssetTypes;
