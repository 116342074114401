import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiArrowUpRight, FiTrash2 } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { TbPencil } from "react-icons/tb";
import ClipLoader from "react-spinners/ClipLoader";
import WidgetToolbar, {
  IWidgetToolbarProps,
  WidgetItem,
} from "../WidgetToolbar/WidgetToolbar";
import {
  AddToSolutionPopupWidget,
  CancelWidget,
  CloseWidget,
  EditPopupWidget,
  RemoveFromProposalWidget,
  SaveWidget,
  ViewInLibraryWidget,
} from "./styled";

const widgetMapper = {
  [WidgetItem.EDIT]: (props: any) => (
    <EditPopupWidget {...props}>
      <TbPencil size={18} />
      <span>{props.label ? props.label : "Edit"}</span>
    </EditPopupWidget>
  ),
  [WidgetItem.ADD_TO_SOLUTION]: ({ isLoading, ...props }: any) => (
    <AddToSolutionPopupWidget {...props}>
      {isLoading ? <ClipLoader size={18} /> : <AiOutlinePlusCircle size={18} />}
      <span>{props.label ? props.label : "Add to pitch"}</span>
    </AddToSolutionPopupWidget>
  ),
  [WidgetItem.REMOVE_FROM_SOLUTION]: ({ isLoading, ...props }: any) => (
    <RemoveFromProposalWidget {...props}>
      {isLoading ? (
        <ClipLoader size={18} color="#dc2626" />
      ) : (
        <FiTrash2 size={18} />
      )}
      <span>{props.label ? props.label : "Remove from pitch"}</span>
    </RemoveFromProposalWidget>
  ),
  [WidgetItem.VIEW_IN_LIBRARY]: (props: any) => (
    <ViewInLibraryWidget {...props}>
      <FiArrowUpRight size={18} />
      <span>{props.label ? props.label : "View in library"}</span>
    </ViewInLibraryWidget>
  ),
  [WidgetItem.CLOSE]: (props: any) => (
    <CloseWidget {...props}>
      <IoClose size={18} />
      <span>{props.label ? props.label : "Close"}</span>
    </CloseWidget>
  ),
  [WidgetItem.CANCEL]: (props: any) => (
    <CancelWidget {...props}>
      <span>{props.label ? props.label : "Cancel"}</span>
    </CancelWidget>
  ),
  [WidgetItem.SAVE]: (props: any) => (
    <SaveWidget {...props} disabled={props.isLoading}>
      {props.isLoading ? <ClipLoader color="var(--white)" size={16} /> : <></>}
      <span>{props.label ? props.label : "Save"}</span>
    </SaveWidget>
  ),
};

export const WidgetToolbarForLibraryPopup = ({
  config,
  ...props
}: IWidgetToolbarProps) => {
  return (
    <WidgetToolbar
      config={config}
      widgetMapper={widgetMapper}
      style={{
        padding: "0.8rem 1.2rem",
        gap: "1.2rem",
        boxShadow: "none",
        border: "none",
        ...props.style,
      }}
    />
  );
};

export default WidgetToolbarForLibraryPopup;
