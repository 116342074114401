import { useQuery } from "react-query";
import { AppName } from "../../constants";
import { IIntegrationAppData } from "../../interfaces/integration";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

interface IIntegrationAppsAPIFilters {
  active: boolean;
  name?: AppName;
}

interface IUseGetIntegrationApps {
  filters: IIntegrationAppsAPIFilters;
}

const getIntegrationApps = async (filters: IIntegrationAppsAPIFilters) => {
  const searchParams = new URLSearchParams(filters as any);
  const response = await axiosInstance.get(`/apps/?${searchParams.toString()}`);
  return response.data.data;
};

const useGetIntegrationApps = ({ filters }: IUseGetIntegrationApps) => {
  const response = useQuery<IIntegrationAppData[], Error>(
    [queryKeys.apps, filters],
    () => getIntegrationApps(filters)
  );
  return { ...response };
};

export default useGetIntegrationApps;
