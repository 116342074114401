import { isValidElement, useContext } from "react";
import { AppContext } from "../../../../../context/AppContext";
import SearchBar from "../SearchBar";
import {
  Button,
  HeaderRightSideContainer,
  HeaderSection,
  Title,
  Wrapper,
} from "./styled";
import { FilterContainer } from "../Filter";
import FilterToggleButton from "../Filter/FilterToggleButton";
import { noop } from "lodash";

interface IHeader {
  showFilterContainer?: boolean;
  showSearchBar?: boolean;
  showButton?: boolean;
  onButtonClick?: Function;
  searchbarPlaceholder?: string;
  excludeSearchObjects?: string[];
  includeSearchObjects?: string[];
  buttonComponent?: React.ReactNode;
  className?: string;
}

const Header = ({
  showFilterContainer = false,
  showSearchBar = true,
  showButton = false,
  onButtonClick = noop,
  searchbarPlaceholder,
  excludeSearchObjects,
  includeSearchObjects,
  buttonComponent,
  className,
}: IHeader) => {
  const {
    appState: { pageTitle },
  } = useContext(AppContext);

  return (
    <Wrapper className={className}>
      <HeaderSection>
        <Title className="Header__title">{pageTitle}</Title>
        <HeaderRightSideContainer>
          {showSearchBar && (
            <SearchBar
              excludeSearchObjects={excludeSearchObjects}
              searchbarPlaceholder={searchbarPlaceholder}
              includeSearchObjects={includeSearchObjects}
            />
          )}
          {showFilterContainer && <FilterToggleButton />}
          {showButton && isValidElement(buttonComponent) ? (
            buttonComponent
          ) : showButton ? (
            <Button onClick={() => onButtonClick()}>
              <span>+ Create new</span>
            </Button>
          ) : (
            <></>
          )}
        </HeaderRightSideContainer>
      </HeaderSection>
      {showFilterContainer && <FilterContainer />}
    </Wrapper>
  );
};

export default Header;
