import { WidgetWrapper } from "./styled";
import Download from "./Download";

interface IWidget {
  fileUrl: string | null;
}
const Widget = ({ fileUrl }: IWidget) => {
  return (
    <WidgetWrapper className="absolute flex align-center justify-center">
      <Download fileUrl={fileUrl} />
    </WidgetWrapper>
  );
};

export default Widget;
