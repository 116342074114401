import { useQuery } from "react-query";
import { IAssetCaseStudyObject } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const getIndividualCaseStudy = async (id: string | undefined) => {
  const response = await axiosInstance.get(`/assets/case-studies/${id}/`);
  return response.data;
};

interface IUseGetIndividualCaseStudy {
  id: string | undefined;
}

const useGetIndividualCaseStudy = ({ id }: IUseGetIndividualCaseStudy) => {
  const response = useQuery<IAssetCaseStudyObject, Error>(
    [`${queryKeys.caseStudy}-${id}`],
    () => getIndividualCaseStudy(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    }
  );
  return { ...response };
};

export default useGetIndividualCaseStudy;
