import { useEffect, Suspense, useContext } from "react";
import { useQueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Routes, Route } from "react-router-dom";
import { useLoggedInTeam } from "../../../api/hooks/useLoggedInTeam";
import { EXCLUDE_DOMAINS_FOR_SUBDOMAIN_CHECK } from "../../../config/appConfig";
import {
  AuthContext,
  ContentAreaContextProvider,
  SolutionsContextProvider,
} from "../../../context";
import { logout } from "../../../helpers";
import { history } from "../../../utils/history";
import { FallBack } from "../../compositional";
import { ContentAreaErrorBoundary } from "../../compositional/ErrorBoundary";
import { MainWrapper } from "../../compositional/Layout/styled";
import SharedResourcePage from "../../../pages/SharedResource/SharedResourcePage";

const ResetComponent = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries();
    const guestCode = sessionStorage.getItem("guestCode");
    window.location.replace(`/shared/${guestCode}`);
  }, []);
  return <FallBack></FallBack>;
};

const GuestRoutes = () => {
  const { domain: teamDomain } = useLoggedInTeam();
  const { authDispatch } = useContext(AuthContext);
  useEffect(() => {
    const subdomain = window.location.host.split(".")[0];
    if (
      subdomain &&
      !EXCLUDE_DOMAINS_FOR_SUBDOMAIN_CHECK.includes(subdomain) &&
      teamDomain !== subdomain
    ) {
      logout(authDispatch);
      history.replace("/");
    }
  }, []);

  return (
    <>
      <Suspense fallback={<FallBack fullPage />}>
        <ContentAreaErrorBoundary>
          <ContentAreaContextProvider>
            <SolutionsContextProvider>
              <MainWrapper background="#F8FAFF">
                <Routes>
                  <Route path="/" element={<ResetComponent />} />
                  <Route path="/shared">
                    <Route path="" element={<ResetComponent />}></Route>
                    <Route
                      path=":code"
                      element={<SharedResourcePage />}
                    ></Route>
                  </Route>
                  <Route path="*" element={<ResetComponent />}></Route>
                </Routes>
              </MainWrapper>
            </SolutionsContextProvider>
          </ContentAreaContextProvider>
        </ContentAreaErrorBoundary>
      </Suspense>
      <ReactQueryDevtools />
    </>
  );
};

export default GuestRoutes;
