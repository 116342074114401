import styled from "@emotion/styled";

export const FilterTagContainer = styled.div`
  min-height: 2.4rem;
  flex-wrap: wrap;
  margin: 0rem 3.2rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  .clear {
    color: #dc2626;
    font-size: 1.4rem;
    line-height: 2.56rem;
    font-weight: 400;
    cursor: pointer;
    margin-left: 2rem;
    img {
      display: inline-block;
      margin-right: 0.5rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const FilterTag = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border: 0.5px solid #cbd5e1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;

export const TagHeading = styled.div`
  height: 1.8rem;
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #475569;
  margin: 0.2rem 0.5rem;
  user-select: none;
`;

export const ClearAll = styled.div`
  font-family: var(--font-lexend);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  color: #2563eb;
`;

export const FilterTagWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.2rem;
  row-gap: 0.8rem;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 0.4rem;
  margin-right: 0.4rem;
  cursor: pointer;
`;

export const ClearAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0.8rem;
  cursor: pointer;
`;

export const CrossIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 0.2rem;
  margin-right: 0.3rem;
`;
