import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { categorizeByFieldType } from "../../helpers/common";
import { IMutationState } from "../../interfaces";
import axiosInstance from "../axios";
import { queryKeys } from "../constants";

const updateItemUsingFormData = async (url: string, mutationData: object) => {
  const formData = new FormData();
  Object.entries(mutationData).forEach(([key, file]) =>
    formData.append(key, file)
  );
  return await axiosInstance.patch(url, formData);
};

const updateSolution = async (postData: any) => {
  const { files, others } = categorizeByFieldType(postData);
  const data = { ...others };
  if (files.logo || data.logo) {
    delete data.logo;
  } else if (data.logo === "") {
    data.logo = null;
  }
  Object.keys(files).length &&
    (await updateItemUsingFormData(`/solutions/${postData.id}/`, files));
  return await axiosInstance.patch(`solutions/${postData.id}/`, data);
};

const createSolution = async (postData: any) => {
  const { files, others } = categorizeByFieldType(postData);
  const data = { ...others };
  delete data.logo;
  const newSolutionItem = await axiosInstance.post("solutions/", data);
  await updateItemUsingFormData(
    `/solutions/${newSolutionItem.data.id}/`,
    files
  );
  return newSolutionItem;
};

const useCreateUpdateSolution = () => {
  const [mutationState, setMutationState] = useState<IMutationState>({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });
  const queryClient = useQueryClient();
  const mutationOption = {
    onError: () => {
      toast.error("Oops, something went wrong. Please try again.");
    },
    onSettled: (data: any) => {
      if (data) {
        if (data.status === 201) {
          // toast.success(`Solution created successfully `);
        } else {
          // toast.success(`Solution updated successfully`);
        }
      }
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries([queryKeys.solutions]);
    },
  };

  const mutationFunction = (mutationData: any) => {
    return mutationData.id
      ? updateSolution(mutationData)
      : createSolution(mutationData);
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    mutationFunction,
    mutationOption
  );

  useEffect(() => {
    setMutationState({ isLoading, isSuccess, isError });
  }, [isError, isLoading, isSuccess]);

  return { mutate, ...mutationState };
};

export default useCreateUpdateSolution;
