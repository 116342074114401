import { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { ChooseWorkspace } from "../../";
import { FallBack } from "../../compositional";
import { SignedInUserJoin } from "../../auth";
import { appConfig } from "../../../config";
import { history } from "../../../utils/history";

const Layout = lazy(() => import("../../compositional/Layout"));

const {
  urls: {
    workspaceSelection: workspaceSelectionUrl,
    joinFromWorkspace: joinFromWorkspaceUrl,
  },
  pathsToBeOpenedInCompletePage,
} = appConfig;

const PrivateRoutes = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const redirectPath = sessionStorage.getItem("redirectPath");
    if (redirectPath) {
      history.replace(redirectPath);
      sessionStorage.removeItem("redirectPath");
    }
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <>
      {pathsToBeOpenedInCompletePage.includes(pathname) ? (
        <Suspense fallback={<FallBack />}>
          <Routes>
            <Route path={workspaceSelectionUrl} element={<ChooseWorkspace />} />
            <Route path={joinFromWorkspaceUrl} element={<SignedInUserJoin />} />
          </Routes>
        </Suspense>
      ) : (
        <Layout />
      )}
      <ReactQueryDevtools />
    </>
  );
};

export default PrivateRoutes;
