import { useLocation, useParams } from "react-router-dom";
import FallBack from "../../Fallback";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axios";
import { apiConfig } from "../../../../config";
import { IGoogleSlidesData } from "../../Reusable/components/Modals/GoogleSlideEditModal";
import { toast } from "react-toastify";
import { noop } from "lodash";

interface IGetGoogleSildesEditData {
  id?: string;
  solution_asset_type?: string | null;
  solution_asset?: string | null;
  generic_resource_type?: string | null;
  generic_resource?: string | null;
}

interface IGoogleSlidesEdit {
  data?: IGoogleSlidesData;
  onError?: Function;
  onEditRequestSuccess?: Function;
}

const getGoogleSlidesEditData = (postData: IGetGoogleSildesEditData) => {
  const googleSlideDocumentId = postData.id;
  delete postData.id;
  return axiosInstance.post(
    `${apiConfig.urls.documents}${googleSlideDocumentId}/edit/`,
    postData
  );
};

const GoogleSlidesEdit = ({
  data,
  onError = noop,
  onEditRequestSuccess = noop,
}: IGoogleSlidesEdit) => {
  const [googleSlidesEditData, setGoogleSlidesEditData] = useState({
    src_url: "",
    filename: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const solutionAssetId = data?.solutionAssetId;
  const solutionAssetTypeId = data?.solutionAssetTypeId;
  const genericResourceId = data?.genericResourceId;
  const genericResourceTypeId = data?.genericResourceTypeId;

  useEffect(() => {
    let googleSlidesEditData;
    (async () => {
      try {
        googleSlidesEditData = (
          await getGoogleSlidesEditData(
            solutionAssetTypeId
              ? {
                  id: data?.documentId,
                  solution_asset_type: solutionAssetTypeId,
                  solution_asset: solutionAssetId,
                }
              : {
                  id: data?.documentId,
                  generic_resource_type: genericResourceTypeId,
                  generic_resource: genericResourceId,
                }
          )
        ).data;
        onEditRequestSuccess(googleSlidesEditData);
        setGoogleSlidesEditData(googleSlidesEditData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
        onError();
        toast.error("Oops, something went wrong");
      }
    })();
  }, []);

  if (isLoading) return <FallBack fullPage />;

  return (
    <iframe
      src={googleSlidesEditData.src_url}
      title={googleSlidesEditData.filename}
      style={{ height: "100%", width: "100%" }}
    ></iframe>
  );
};

export default GoogleSlidesEdit;
