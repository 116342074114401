import { nanoid } from "nanoid";
import {
  DragDropContext,
  Droppable as DndDroppable,
  DropResult,
  Draggable as DndDraggable,
  DraggableProvided,
} from "react-beautiful-dnd";

interface IDroppableComponent {
  onDragEnd: (result: DropResult) => void;
  droppableId: string;
  children: React.ReactNode;
  className?: string;
}

interface IDraggableComponent {
  draggableId: string;
  index: number;
  children: (provided: DraggableProvided) => React.ReactNode;
}

const Droppable = ({
  onDragEnd,
  droppableId = nanoid(),
  children,
  className,
}: IDroppableComponent) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DndDroppable droppableId={droppableId}>
        {(provided) => (
          <div
            className={className} // styled can style any component as long as it accepts a className prop.
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </DndDroppable>
    </DragDropContext>
  );
};

export const Draggable = ({
  draggableId,
  index,
  children,
}: IDraggableComponent) => {
  return (
    <DndDraggable draggableId={draggableId} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {children(provided)}
        </div>
      )}
    </DndDraggable>
  );
};

export default Droppable;
