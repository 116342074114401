import { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { ContentAreaContext } from "../../../../../context";
import {
  IDropdownCollectionItem,
  IStateOfAllDropdowns,
} from "../../../../../interfaces/dropdown";
import { cloneDeep } from "lodash";
import {
  ClearAll,
  ClearAllContainer,
  CrossIconWrapper,
  FilterTag,
  FilterTagContainer,
  FilterTagWrapper,
  IconWrapper,
  TagHeading,
} from "./styled";
import cross from "../../../../../assets/icons/cross.svg";
import { trackUserAction } from "../../../../../analytics";
import { userActivityEvents } from "../../../../../config";
import { useGetAssetMetadata } from "../../../../../api/hooks";
import blueCross from "../../../../../assets/icons/blueCross.svg";
import useFilterDropdownInitialState from "../../../../../api/hooks/filter/useFilterDropdownInitialState";

interface IFilterTags {
  handleChange?: Function;
  Header?: false | (() => React.ReactNode);
  style?: Object;
  showClearAll?: boolean;
  type?: number;
  showTags?: boolean;
}

const FilterTags = ({
  handleChange,
  Header,
  style,
  showClearAll = true,
  type,
  showTags,
}: IFilterTags) => {
  const { stateOfAllDropdowns, setStateOfAllDropdowns } =
    useContext(ContentAreaContext);
  const [filtertagList, setFilterTagList]: any = useState([]);
  const [itemSelection, setItemSelection] = useState(false);
  const { data: assetsMetadata } = useGetAssetMetadata();
  const { data: filterDropdownInitialState } = useFilterDropdownInitialState();

  useEffect(() => {
    const data: IDropdownCollectionItem[] = [];
    if (stateOfAllDropdowns && assetsMetadata) {
      const relParamTagIndex =
        assetsMetadata.filters.relationship_parameters.findIndex(
          ({ id }) => id === type
        );
      (type
        ? [stateOfAllDropdowns[relParamTagIndex]]
        : Object.values(stateOfAllDropdowns as IStateOfAllDropdowns)
      ).forEach((item) => {
        item.selected.forEach((tag) => {
          data.push(tag);
        });
      });
    }
    setFilterTagList(data);
  }, [stateOfAllDropdowns]);

  function handleCheckboxSelection(item: IDropdownCollectionItem) {
    let currentDropdownIndex = 0;
    setItemSelection((curr) => !curr);
    assetsMetadata?.filters?.relationship_parameters?.forEach(
      (parameter: { data: any; label: any; id: number }, index: number) => {
        if (type) {
          if (parameter.id === type) currentDropdownIndex = index;
        } else {
          parameter.data.forEach((text: any) => {
            if (item.label === text.value) {
              currentDropdownIndex = index;
            }
          });
        }
      }
    );
    setStateOfAllDropdowns((state) => {
      // @ts-ignore
      const currentDropdownState = state[currentDropdownIndex];
      let newSelected = cloneDeep(currentDropdownState.selected);
      let newSuggested = cloneDeep(currentDropdownState.suggested);
      if (item.isSelected) {
        const indexOfItemClicked = newSelected.findIndex(
          (listItem) => listItem.value === item.value
        );
        const itemToRemove = newSelected[indexOfItemClicked];
        newSelected.splice(indexOfItemClicked, 1);
        newSuggested.push({ ...itemToRemove, isSelected: false });
      } else {
        const indexOfItemClicked = newSuggested.findIndex(
          (listItem) => listItem.value === item.value
        );
        let itemClicked = newSuggested[indexOfItemClicked];
        newSuggested.splice(indexOfItemClicked, 1);
        newSelected.push({
          ...itemClicked,
          isSelected: true,
        });
      }
      newSuggested.sort((a: any, b: any) => a.value - b.value);
      const selectedOptions = Object.values({
        ...state,
        [currentDropdownIndex]: {
          ...currentDropdownState,
          selected: newSelected,
          suggested: newSuggested,
        },
      })
        .map((dropdownObj: any) => dropdownObj.selected)
        .flat();
      trackUserAction(userActivityEvents.LIBRARY__FILTERS_APPLIED, {
        appliedFilters: selectedOptions,
      });
      // Callback for selected values
      handleChange && handleChange(newSelected);
      return {
        ...state,
        [currentDropdownIndex]: {
          ...currentDropdownState,
          selected: newSelected,
          suggested: newSuggested,
        },
      };
    });
  }
  const handleClearFilterClick = () => {
    trackUserAction(userActivityEvents.LIBRARY__FILTERS_CLEARED);
    setStateOfAllDropdowns(filterDropdownInitialState);
  };

  const filterTagListComponent = () => (
    <>
      {filtertagList.map((tag: IDropdownCollectionItem, index: any) => {
        return (
          <FilterTag key={nanoid()}>
            <TagHeading>{tag.label} </TagHeading>
            <IconWrapper>
              <img
                onClick={() => handleCheckboxSelection(tag)}
                src={cross}
                alt="Tag"
              />
            </IconWrapper>
          </FilterTag>
        );
      })}
    </>
  );

  if (!filtertagList.length) return <></>;

  if (showTags) return filterTagListComponent();

  return (
    <FilterTagContainer style={style}>
      <FilterTagWrapper>
        {Header && Header()}
        {filterTagListComponent()}
        {showClearAll && (
          <ClearAllContainer onClick={() => handleClearFilterClick()}>
            <CrossIconWrapper>
              <img src={blueCross} alt="close" />
            </CrossIconWrapper>
            <ClearAll>Clear all</ClearAll>
          </ClearAllContainer>
        )}
      </FilterTagWrapper>
    </FilterTagContainer>
  );
};

export default FilterTags;
