import styled from "@emotion/styled";
import { FileInputType } from "../../../../constants";
import { FormFieldWrapper } from "../styled";

interface IFileInputFieldWrapperProps {
  type?: FileInputType;
}

// prettier-ignore
export const FileInputFieldWrapper = styled(FormFieldWrapper)<IFileInputFieldWrapperProps>`
  padding: ${props => props.type === FileInputType.ICON ? "0" : ""};
  input {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    width: 0;
  }
  border: ${(props) => (props.type === FileInputType.ICON ? "none" : "")};
`;

export const FileInputFieldContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  label {
    display: flex;
    column-gap: 1rem;
    width: max-content;
  }
`;

interface IIconContainerProps {
  showBackground: boolean;
}

export const IconFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: center;

  &.compact {
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    label {
      column-gap: 0;
    }
    .remove-btn {
      margin-left: 0.8rem;
    }
  }
  .csv-upload-label {
    ::after {
      content: "";
    }
  }
`;

export const IconContainer = styled.div<IIconContainerProps>`
  height: 6rem;
  width: 6rem;
  position: relative;
  border-radius: 1.2rem;
  background-color: ${(props) => (props.showBackground ? "#f1f5f9" : "none")};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .icon {
    width: 100%;
    border-radius: 1.2rem;
  }
  .image-icon {
    padding: 0;
    color: #94a3b8;
    width: 2.4rem;
  }
`;

export const BrowseFileButton = styled.a`
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0.5rem 0.6rem;
  background: #eff6ff;
  border-radius: 0.6rem;
  color: #3b82f6;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  text-decoration: none;
`;

export const BrowseCSVButton = styled(BrowseFileButton)`
  background: none;
`;

export const RemoveFileButton = styled(BrowseFileButton)``;
