import React from "react";
import { IComponentToRender } from "../../../interfaces";
import SidebarWithTabs from "./components/SidebarWithTabs";
import SidebarWithTabsSettings from "./components/SidebarWithTabsSettings";
import { TabbedSidebarContainer } from "./styled";

interface IPageSpecificLayout {
  componentsToRender: IComponentToRender[];
  sideBar: string;
}

const PageSpecificLayout = ({
  componentsToRender,
  sideBar,
}: IPageSpecificLayout) => {
  return (
    <TabbedSidebarContainer
      id="tabbed-sidebar-container"
      style={{ padding: "0 3.4rem 2rem" }}
    >
      {sideBar === "settings" ? (
        <SidebarWithTabsSettings componentsToRender={componentsToRender} />
      ) : (
        <SidebarWithTabs componentsToRender={componentsToRender} />
      )}
    </TabbedSidebarContainer>
  );
};

export const PageSpecificLayout1 = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <TabbedSidebarContainer id="tabbed-sidebar-container">
      {children}
    </TabbedSidebarContainer>
  );
};

export default PageSpecificLayout;
